<template>
  <div>
        <div class="container">

            <h1 class="se-bp-title">
              Start
              <a class="btn-link se-page-help-icon" v-tooltip.top-center="'Learn More'" @click="showPageHelp = true">
                <i class="fa fa-info-circle"></i>
              </a>
            </h1>

              <div class="row justify-content-start ">
              <div class="pr-0 pl-0"
                   :class="[caseFileSearchResult && caseFileSearchResult.length > 1 ? 'col-md-4': 'col-md-5']">
                <div class="se-new-eval-card card bp-u-shadow-04">
                  <h2 class="se-bp-title-sb-sub mb-3">New Evaluation</h2>

                  <div class="row">
                    <div class="col-5">
                      <radio :label="SeRequestType.rtNewData"
                             v-model="requestType">Enter Data <a class="btn-link se-help-icon" v-tooltip.top-center="'Learn More'" @click="showEnterData = true"><i class="fa fa-info-circle"></i></a>
                      </radio>
                      <radio :label="SeRequestType.rtImport"
                             class="mt-2"
                             v-model="requestType">Import <a class="btn-link se-help-icon" v-tooltip.top-center="'Learn More'" @click="showImport = true"><i class="fa fa-info-circle"></i></a></radio>
                    </div>

                    <div class="col-7 mt-2">
                      <fg-input label="Originator NMLS ID"
                                type="text"
                                :optional=true
                                v-model="brokerOriginatorId"
                                @show-help="showOriginator = true">
                      </fg-input>
                    </div>
                  </div>

                  <div class="row flex-grow-1 align-items-end">
                    <div class="col">
                      <button type="button" @click="next" class="bp-button bp-button--primary mt-2 mb-3" aria-label="Start New Evaluation">
                        Start New Evaluation
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end of card-->

              <div class="col-md-1 d-flex justify-content-center p-3">
                <h4 class="align-self-center">OR</h4>
              </div>

              <div class="se-existing-eval-card card bp-u-shadow-04"
                   :class="[caseFileSearchResult && caseFileSearchResult.length > 1 ? 'col-md-7': 'col-md-5']">
                <h2 class="se-bp-title-sb-sub mb-3">Existing Evaluation</h2>

                <div class="row">
                  <div class="col">
                    <radio label="byRequestId"
                           v-model="searchType">Search Income Calculator ID <a class="btn-link se-help-icon" v-tooltip.top-center="'Learn More'" @click="showDuIncome = true"><i class="fa fa-info-circle"></i></a>
                    </radio>
                  </div>
                </div>

                <div class="row mt-1">
                  <div class="col">
                    <fg-input type="text"
                              inputClasses="col-6"
                              :input-classes="$v.requestId.$error ? 'is-error': ''"
                              v-model="$v.requestId.$model"
                              autocomplete="off"
                              @focus="searchType = 'byRequestId'"
                              @show-help="showDuIncome = true">

                      <template slot="infoBlock">
                        <!-- read it like this: show this warning when the 'required' validation fails-->
                        <div class="bp-help is-error" v-if="$v.requestId.$error">
                          <div class="bp-ucon" data-src="feedback-warning"><svg class="bp-svg" viewBox="0 0 24 24" id="feedback-warning" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M13.009 13.987V8.934h-1.956v5.053h1.956zm0 4.016v-1.962h-1.956v1.962h1.956zM2.988 20.962L12.016 3l9.025 17.962H2.988z"></path></svg></div>
                        </div>
                      </template>
                    </fg-input>
                  </div>
                </div>

                <template v-if="!externalMode">
                  <div class="row">
                    <div class="col">
                        <radio label="byCaseFileId"
                               v-model="searchType">Search DU Casefile ID <a class="btn-link se-help-icon" v-tooltip.top-center="'Learn More'" @click="showDuCasefile = true"><i class="fa fa-info-circle"></i></a>
                        </radio>
                        <fg-input class="mt-1"
                                  inputClasses="col-6"
                                  type="text"
                                  v-model="duCaseFileId"
                                  autocomplete="off"
                                  @focus="searchType = 'byCaseFileId'"
                                  @show-help="showDuCasefile = true">
                        </fg-input>
                    </div>
                  </div>
                </template>

                <div class="row bp-form-item" v-if="requestId && externalMode">
                  <div class="col">
                    <h4 class="mt-2">What is the amount on Form 1040, Line 8 or W-2, Box 5?</h4>
                    <input class="se-tax-field-input bp-form-input bp-u-shadow-04"
                           type="text"
                           v-mask="currencyMask"
                           v-model="incomeAmountHint" />

                    <div class="bp-help is-error" v-if="$v.incomeAmountHint.$error">
                      <div class="bp-ucon" data-src="feedback-warning"><svg class="bp-svg" viewBox="0 0 24 24" id="feedback-warning" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M13.009 13.987V8.934h-1.956v5.053h1.956zm0 4.016v-1.962h-1.956v1.962h1.956zM2.988 20.962L12.016 3l9.025 17.962H2.988z"></path></svg></div>
                      <template v-if="!$v.incomeAmountHint.required">Answer is required</template>
                    </div>
                  </div>
                </div>

                <div class="row bp-form-item mt-1" v-if="duCaseFileId">
                  <div class="col">
                    <fg-input inputClasses="col-2"
                              label="What is the 5-digit ZIP Code for the borrower’s current residence provided in the DU submission? "
                              type="text"
                              v-model="duResidencyPostalCode"
                              @show-help="showDuCasefile = true">

                      <template slot="infoBlock">
                        <div class="bp-help is-error" v-if="$v.duResidencyPostalCode.$error">
                          <div class="bp-ucon" data-src="feedback-warning"><svg class="bp-svg" viewBox="0 0 24 24" id="feedback-warning" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M13.009 13.987V8.934h-1.956v5.053h1.956zm0 4.016v-1.962h-1.956v1.962h1.956zM2.988 20.962L12.016 3l9.025 17.962H2.988z"></path></svg></div>
                          <template v-if="!$v.duResidencyPostalCode.required">Answer is required</template>
                        </div>
                      </template>
                    </fg-input>
                  </div>
                </div>

                <div class="row flex-grow-1 align-items-end">
                  <div class="col">
                    <button type="button" @click="search" class="bp-button bp-button--primary mt-2 mb-3" aria-label="Search Existing Evaluation">
                      Search Existing Evaluation
                    </button>
                  </div>
                </div>

                <div class="table-responsive se-card-search mt-3 mb-3" v-if="caseFileSearchResult && caseFileSearchResult.length > 1">
                  <h3>Evaluations</h3>
                  <el-table
                    ref="collectedDataTable"
                    highlight-current-row
                    header-cell-class-name="se-search-header-row"
                    row-class-name="se-search-row"
                    :data="this.caseFileSearchResult"
                    @current-change="handleSearchRowChange">

                    <el-table-column
                      label="Income Calculator ID"
                      property="requestId"
                      class-name="se-search-request-id"
                      align="center">
                    </el-table-column>
                    <el-table-column
                      label="Reference Name"
                      property="refName"
                      align="center">
                    </el-table-column>
                    <el-table-column
                      label="Last Submission"
                      align="center">
                      <template slot-scope="scope">
                        {{ scope.row.submissionDateTime | toDateTime }}
                      </template>
                    </el-table-column>
                  </el-table>
                </div>

                <div class="mt-4" v-if="requestLoaded">
                    <radio :label="SeRequestType.rtUpdate" v-model="requestType">Update
                      <a class="btn-link se-help-icon" v-tooltip.top-center="'Learn More'" @click="showUpdate = true">
                        <i class="fa fa-info-circle"></i>
                      </a>
                    </radio>
                    <radio :label="SeRequestType.rtCopy" v-model="requestType">Clone Evaluation
                      <a class="btn-link se-help-icon" v-tooltip.top-center="'Learn More'" @click="showClone = true">
                        <i class="fa fa-info-circle"></i>
                      </a>
                    </radio>
                    <radio :label="SeRequestType.rtExport" v-model="requestType">Export Data
                      <a class="btn-link se-help-icon" v-tooltip.top-center="'Learn More'" @click="showExport = true">
                        <i class="fa fa-info-circle"></i>
                      </a></radio>
                    <radio :label="SeRequestType.rtViewFindings" v-model="requestType">View Findings Report
                      <a class="btn-link se-help-icon" v-tooltip.top-center="'Learn More'" @click="showFindings = true">
                        <i class="fa fa-info-circle"></i>
                      </a>
                    </radio>
                  </div>

                  <div class="mt-2" v-if="requestLoaded">
                    <se-buttons
                      :display-save="false"
                      :display-submit="false"
                      @seNext="next"
                      @sePrevious="previous">
                    </se-buttons>
                  </div>

              </div>
              <!-- end of card-->

              </div>
              <!-- end of row-->
            </div>
            <!-- end of container-->

      <se-help :visible.sync="showPageHelp" title="Learn More">
        <p>
          Use this screen to begin a new {{ appName }} evaluation or retrieve information you previously entered.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba" icon-name="icon-right-arrow"><icon-arrow-right/></icon-base>
          Select <b>Enter Data</b> to begin a new evaluation by manually entering data.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba" icon-name="icon-right-arrow"><icon-arrow-right/></icon-base>
          Select <b>Import</b> to upload a file containing {{ appName }} data. <br>
          <a href="#" @click="showImport = true">More Import help</a>
        </p>

        <p>
          <b>FAQs:</b>
          <br>
            Q. Can {{ appName }} import .PDF files containing images of tax returns? What kinds of files can be imported?<br>
            A. {{ appName }} <b>does not support importing files with .PDF extension.</b> Only files with .XML and .JSON extensions are
            importable.
        </p>

        <p>
          Q. Is there an option to use a fully integrated service?<br>
          A. Yes. There are third-party service providers/vendors who use a variety of technologies to obtain
          tax return data and are directly integrated with {{ appName }}. Contact your Fannie Mae account team to learn more.
        </p>
        <p>
          Q. Can {{ appName }} import files containing tax return transcript data that may have been obtained from the
          IRS Income Verification Express Service (IVES) using IRS Forms 4506-C, for example the response to a Day 1 Certainty (D1C) request?<br>
          A. Not at this time.
        </p>

        <p>
          Q. How would I obtain an import file?<br>
          A. You can obtain an import file using one of the following methods:
        <ol>
          <li>
            Use {{ appName }}’s export functionality available on the Start screen or Findings Report screen.
          </li>
          <li>
            Lender and vendor systems may create data files of tax return data that can be imported to {{ appName }}, if properly formatted.
          </li>
        </ol>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba" icon-name="icon-right-arrow"><icon-arrow-right/></icon-base>
          Select <b>Search</b> (after entering the {{ appName }} ID) to
          retrieve information you previously entered. From there you can update, clone (copy), or export
          the data or view the {{ appName }} Findings Report.
          <br>

          <a href="#" @click="showDuIncome=true">More {{ appName }} ID Search help</a>
          <br>
        </p>
      </se-help>
      <se-help :visible.sync="showEnterData" title="Enter Data">
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba" icon-name="icon-right-arrow"><icon-arrow-right/></icon-base>
          Select <b>Enter Data</b> to begin a new evaluation by manually entering data.
          <br><br>
          <b>FAQs:</b><br>
          Q. What do I need before I get started?<br>
          A. You must have at least one year of individual (personal) federal income tax returns to use {{ appName }}.

        </p>
      </se-help>
      <se-help :visible.sync="showImport" title="Import">
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba" icon-name="icon-right-arrow"><icon-arrow-right/></icon-base>Select <b>Import</b> to upload a file containing {{ appName }} data.
          Importing enables you to avoid manual keying of tax return data.
        </p>
        <p>
      <b>FAQs:</b><br>
         Q. Can {{ appName }} import .PDF files containing images of tax returns? What kinds of files can be imported?<br>
         A. {{ appName }} <b>does not support importing files with .PDF extension. Only files with .XML and .JSON are importable.</b>
        </p>

        <p>
          Q. Is there an option to use a fully integrated service?<br>
          A. Yes. There are third-party service providers/vendors who use a variety of technologies to obtain
          tax return data and are directly integrated with Income Calculator. Contact your Fannie Mae account team to learn more.
        </p>

        <p>
          Q. Can {{ appName }} import files containing tax return transcript data that may have been obtained from the
          IRS Income Verification Express Service (IVES) using IRS Forms 4506-C, for example the response to a Day 1 Certainty (D1C) request?<br>
          A. Not at this time.
        </p>
        <p>
          Q. How would I obtain an import file?<br>
          A. You can obtain an import file using one of the following methods:
      <ol>
        <li>
          Use {{ appName }}’s export functionality available on the Start screen or Findings Report screen.
        </li>

        <li>
          Lender and vendor systems may create data files of tax return data that can be imported to {{ appName }}, if properly formatted.
        </li>
      </ol>
      <p>
      Q. I have an import file that contains only some of the data that {{ appName }} uses.
        Can I import a partial file?<br>
      A. Yes. You can import a data file even if the data is incomplete.
        You can use the {{ appName }} screens to add the missing data before making a submission.
      </p>
        <p>
      Q. Can I resubmit an income evaluation by importing a file with
          an existing {{ appName }} ID? <br>
      A. No. When you import a file, a new {{ appName }} ID will be
          issued. To resubmit an existing evaluation, use the Search feature.
        </p>
      </se-help>
      <se-help :visible.sync="showOriginator" title="Originator NMLS ID">
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-flag/></icon-base>
          Entering the Originator’s Nationwide Mortgage Licensing System (NMLS) ID is optional.
          {{ appName }} may use this in the future to support advanced search functionality.

        </p>
      </se-help>
      <se-help :visible.sync="showDuIncome" title="Income Calculator ID">
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba" icon-name="icon-right-arrow"><icon-arrow-right/></icon-base>
          Enter the {{ appName }} ID and click <b>Search</b> to retrieve information you
          previously entered. From there you can update, clone (copy), or export the data
          or view the {{ appName }} Findings Report. If you previously locked the input data, you will not be able to update it.
        </p>
        <p>
          <b>FAQs:</b><br>
          Q. How do I get the {{ appName }} ID? What does it look like?<br>
          A. {{ appName }} generates an ID during the evaluation process. It is displayed
          on the {{ appName }} Findings Report. All {{ appName }} IDs have seven
          characters and start with capital letter “D”, followed by three numeric digits, then three
          more capital letters, for example: D123ABC. Make sure you note the {{ appName }} ID
          if you don’t save or print the {{ appName }} Findings Report. The {{ appName }} ID is not
          retrievable using the Search Function.
        </p>
        <p>
          Q. Does the {{ appName }} ID ever expire?<br>
          A. {{ appName }} IDs linked to a DU Casefile ID are archived
          when the DU Casefile ID is archived. {{ appName }} IDs that are
          not linked to a DU Casefile ID are archived 180 days from the last time the data was updated in {{ appName }}.

        </p>
      </se-help>
      <se-help :visible.sync="showDuCasefile" title="Desktop Underwriter Casefile ID">
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba" icon-name="icon-right-arrow"><icon-arrow-right/></icon-base>
          Enter the Desktop Underwriter Casefile ID and click <b>Search</b> to find {{ appName }} IDs
          linked to the DU Casefile ID.
        </p>
        <p>
          <b>FAQs:</b><br>
          Q. I put in my DU Casefile ID, and I am getting a “Not Found” error.
          I know my DU Casefile ID is active. What’s wrong?<br>
          A. Your DU Casefile ID may be active, but no {{ appName }} IDs
          were linked to it. Linking can only be done from {{ appName }}.
        </p>
        <p>
          Q. There is more than one self-employed borrower or a borrower has multiple self-employment income sources.
          How will {{ appName }} know which evaluation to retrieve?<br>
          A. If there is more than one self-employed borrower or a borrower has
          multiple self-employment income sources, {{ appName }} will
          require additional information to determine the associated DU data.
        </p>
        <p>
          Q. When I update information in {{ appName }}, does it automatically update Desktop Underwriter (DU)?
          <br>
          A. No. {{ appName }} currently does not send any data to Desktop Underwriter (DU). To use the monthly
          qualifying income generated by {{ appName }}, you must ensure it is included in your Desktop Underwriter (DU) submission.


        </p>
      </se-help>
      <se-help :visible.sync="showUpdate" title="Update">
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba" icon-name="icon-right-arrow"><icon-arrow-right/></icon-base>
          Select <b>Update</b> to add, remove or modify information associated to this {{ appName }} ID.
        </p>
        <p>
        <b>  FAQs:</b><br>
          Q. When I update information in {{ appName }}, does it automatically update Desktop Underwriter (DU)?<br>
          A. No. {{ appName }} currently does not send any data to Desktop Underwriter (DU).
          If you want to use the monthly qualifying income generated by {{ appName }},
          you must ensure it is included in your Desktop Underwriter (DU) submission.


        </p>
      </se-help>
      <se-help :visible.sync="showClone" title="Clone Existing Evaluation">
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba" icon-name="icon-right-arrow"><icon-arrow-right/></icon-base>
          Select <b>Clone Evaluation</b> to create a new {{ appName }} evaluation using data copied from an existing {{ appName }}
          evaluation. Cloning an existing evaluation will create a new {{ appName }} ID.
        </p>

        <b> FAQs: </b><br>
          Q. Why would I want to clone (copy) data from an existing {{ appName }} evaluation?<br>
          A. You may want to clone (copy) data for the following reasons:<br>
        <ol>
         <li>The loan has more than one self-employed borrower or a borrower has multiple self-employment income sources,
           and you want to avoid manual rekeying of common tax return data.</li>
         <li>The input data was locked and you want to update the evaluation without rekeying the input data.</li>
        <li>You want to update the {{ appName }} data without disturbing the original input data,
          and you want to avoid keying the data. This might be useful between lending partners,
          such as when a lender has a loan underwritten by a
          mortgage insurer, or when a correspondent lender sells a loan to an aggregator. </li>
      </ol>


      </se-help>
      <se-help :visible.sync="showExport" title="Export Data File">
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba" icon-name="icon-right-arrow"><icon-arrow-right/></icon-base>
          Select <b>Export Data File</b> to create a file that contains the {{ appName }} input data.
        </p>
        <p>

          <b>FAQs:</b><br>
          Q. Why would I want to export an {{ appName }} data input file?<br>
          A. You may want to export data for the following reasons:<br>
          <ol>

        <li>You want to store {{ appName }} input data in an electronic format.</li>

         <li>You want to share the {{ appName }} input data with a lending partner,
           such as a mortgage insurer, correspondent lender, or aggregator.
         </li>
        <li>
          You want to create a file to import {{ appName }} input data to a lender or vendor system.
        </li>
      </ol>

      <p>
          Q. What export file types are supported?<br>
          A. {{ appName }} creates files with .XML and .JSON extensions.
      </p>
      <p>
          Q. How do I export an {{ appName }} Findings Report data file?<br>

          A. Navigate to the {{ appName }} Findings Report by using the navigation
          feature or selecting “View Findings Report” on this screen. From the {{ appName }} Findings Report
          screen, select the "Export" button at the bottom of the screen.
        </p>
        <p>
          Q. How do I save a .PDF of the {{ appName }} Findings Report??<br>
          A. Navigate to the {{ appName }} Findings Report by using the navigation feature or selecting “View Findings Report” on this screen.
          From the {{ appName }} Findings Report screen, select “View/Save/Print PDF” button at the bottom of the screen.
        </p>



      </se-help>
      <se-help :visible.sync="showFindings" title="View Findings Report">
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba" icon-name="icon-right-arrow"><icon-arrow-right/></icon-base>
          Select <b>View Findings Report</b> to skip directly to the {{ appName }}
          Findings Report screen. You can also select “Findings Report” from the navigation feature.

        </p>
      </se-help>

  </div>

</template>
<script>
  import {Checkbox, Radio, IconArrowRight, IconFlag} from 'src/components/index'
  import {Table, TableColumn} from 'element-ui'
  import LSwitch from 'src/components/Switch.vue'
  import SeButtons from './SeButtons.vue'
  import Swal from 'sweetalert2'
  import {mapGetters, mapState} from 'vuex'
  import Enums from 'src/model/enums'
  import SeHelp from './SeHelp.vue'
  import {
    GET_SE_REQUEST,
    GET_SE_REQUEST_ERROR,
    SET_REQUEST_DATA,
    SET_STATE_DATA,
    GET_SE_REQUEST_FROM_FILE,
    SAVE_SE_REQUEST,
    GET_SE_CASEFILE,
    SET_EXTRA_DATA,
    SET_CLONED_DATA,
  } from 'src/store/actions/se'
  import xml2js from "xml2js";
  import { required, requiredIf } from 'vuelidate/lib/validators'

  import appNameMixin from '../../../mixins/AppNameMixin'
  import createNumberMask from "text-mask-addons/dist/createNumberMask";

  const currMaskType = createNumberMask({
    prefix: '$',
    allowDecimal: true,
    includeThousandsSeparator: true,
    allowNegative: true,
    integerLimit: 9
  });

  export default {
    mixins: [appNameMixin],
    components: {
      Checkbox,
      Radio,
      LSwitch,
      SeHelp,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      SeButtons,
      IconArrowRight, IconFlag
    },
    data() {
      return {
        requestId: '',
        showPageHelp: false,
        showEnterData: false,
        showImport: false,
        showDuIncome: false,
        showDuCasefile: false,
        showOriginator: false,
        showFindings: false,
        showExport: false,
        showClone: false,
        showUpdate: false,
        duCaseFileId: '',
        duResidencyPostalCode: '',
        requestType: Enums.SeRequestType.rtNewData,
        searchType: 'byRequestId',
        requestLoaded: false,
        incomeAmountHint: '',
        currencyMask: currMaskType
      }
    },
    validations: {
      requestId: {
        // required: requiredIf(function () {
        //   return  [this.SeRequestType.rtExport, this.SeRequestType.rtUpdate, this.SeRequestType.rtViewFindings, this.SeRequestType.rtCopy].includes(this.requestType)
        // }),
        // validFormat: (v) => !v || v.length === 6   //return true when validation is a success
      },
      duResidencyPostalCode: {
        required: requiredIf( function () { return !!this.duCaseFileId })
      },
      incomeAmountHint: {
        required: requiredIf( function () { return this.externalMode && this.requestId })
      },
    },
    computed: {
      SeRequestType: () => Enums.SeRequestType,
      brokerOriginatorId: {
        get () { return this.seRequest.brokerOriginatorId },
        set (brokerOriginatorId) { this.$store.commit(SET_REQUEST_DATA, {brokerOriginatorId}) }
      },

    // requestType: {
    //   get() { return this.seRequest.requestType },
    //   set(requestType) {
    //     this.$store.commit(SET_REQUEST_DATA, {requestType})
    //     if (requestType === this.SeRequestType.rtNewData || requestType === this.SeRequestType.rtImport) {
    //       this.requestId = "";
    //     }
    //   }
    // },
    // requestId: {
    //   get() { return this.seRequest.requestId },
    //   set(requestId) { this.$store.commit(SET_REQUEST_DATA, {requestId}) }
    // },
    ...mapState({
      seRequest: state => state.se.request,
      caseFileSearchResult: state => state.se.caseFileSearchResult,
      externalMode: state => state.se.externalMode
    }),
    ...mapGetters([
      'getPreparedRequest'
    ])
  },
  methods: {
    next() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.$store.commit(SET_REQUEST_DATA, {requestType: this.requestType});

      if ( [this.SeRequestType.rtExport, this.SeRequestType.rtUpdate, this.SeRequestType.rtViewFindings, this.SeRequestType.rtCopy].includes(this.requestType)) {
        if (!this.requestId || this.requestId === '') {
          // Swal.fire({
          //   title: `Input Required`,
          //   icon: 'warning',
          //   buttonsStyling: false,
          //   customClass: {confirmButton: 'bp-button bp-button--primary'},
          //   html:
          //     `DU Income Underwriter ID from the previous evaluation is required`
          // })
          return;
        }

        if (this.requestType === this.SeRequestType.rtExport) {
          this.exportData();
        } else if (this.requestType === this.SeRequestType.rtCopy) {
          this.copyData()
        } else if (this.requestType === this.SeRequestType.rtUpdate) {
          this.$store.commit(SET_REQUEST_DATA, {requestType: this.SeRequestType.rtUpdate});
          this.$router.push('income-type')
        } else if (this.requestType === this.SeRequestType.rtViewFindings) {
          this.$store.commit(SET_REQUEST_DATA, {requestType: this.SeRequestType.rtViewFindings});
          this.$router.push('/se-eval/eval-findings')
        }

      } else if (this.requestType === this.SeRequestType.rtImport) {
        this.importData();
      } else {
        this.$router.push('income-type')
      }
    },
    previous() {
      this.$router.push('/home/welcome')
    },
    handleSearchRowChange(row) {
      this.$store.dispatch(GET_SE_REQUEST, {requestId: row.requestId})
        .then(() => {
          this.requestLoaded = true
          this.requestId = row.requestId
          this.requestType = Enums.SeRequestType.rtUpdate
        })
        .catch(resp => {
          this.requestLoaded = false
          Swal.fire({
            title: `Could not load`,
            icon: 'error',
            buttonsStyling: false,
            customClass: {confirmButton: 'bp-button bp-button--primary'},
            html:
              `The Income Calculator ID <b>${this.requestId}</b> could not be loaded.`
          })
        })
    },
    async importData() {
      const fnmSwal = Swal.mixin({
        customClass: {
          confirmButton: 'bp-button bp-button--primary',
          closeButton: 'bp-button bp-button--primary',
          cancelButton: 'bp-button'
        },
        buttonsStyling: false
      });

      const { value: file } = await fnmSwal.fire({
        title: 'Select file',
        input: 'file',
        confirmButtonText: "Import",
        showCancelButton: true,
        inputAttributes: {
          'accept': '.json',
          'aria-label': 'Select import data file'
        }
      })

      if (file) {
        try {
          await this.$store.dispatch(GET_SE_REQUEST_FROM_FILE, file);
          this.$store.commit(SET_REQUEST_DATA, {requestType: this.SeRequestType.rtImport});
          await this.$router.push('income-type')
        } catch (e) {
          Swal.fire({
            title: `Invalid file`,
            buttonsStyling: false,
            customClass: {confirmButton: 'bp-button bp-button--primary'},
            html:
              `Could not load data from the specified file`
          })
        }
      }
    },
    async exportData() {
      const fnmSwal = Swal.mixin({
        customClass: {
          confirmButton: 'bp-button bp-button--primary',
          cancelButton: 'bp-button'
        },
        buttonsStyling: false
      });

      const { value: fileType } = await fnmSwal.fire({
        title: 'Export collected data',
        input: 'select',
        inputOptions: {
          'json': 'Standard json',
          'xml': 'Standard XML',
          'json-ext': 'Expanded json'
        },
        inputValue: 'json',
        inputPlaceholder: 'Select file type',
        showCancelButton: true,
        confirmButtonText: "Export"
      })

      if (fileType) {
        this.exportDataFile(fileType);
      }
    },
    exportDataFile(fileType) {
      let fileContent = "";
      if (fileType == 'json') {
        let exportRequest = JSON.parse(JSON.stringify(this.getPreparedRequest));
        exportRequest.selectedTaxForms = null;
        exportRequest.extraData = null;
        exportRequest.duData = null;
        exportRequest.duCaseFileId = null;
        fileContent = JSON.stringify(exportRequest, null, 2)

      } else if (fileType == 'json-ext') {
        let exportRequest = JSON.parse(JSON.stringify(this.getPreparedRequest));
        exportRequest.duData = null;
        exportRequest.duCaseFileId = null;
        fileContent = JSON.stringify(exportRequest, null, 2)
        fileType = 'json'

      } else if (fileType == 'xml') {
        let builder = new xml2js.Builder();
        let xmlWrap = {
          DUIU: {
            $: {
              "xmlns": "http://fanniemae.com/duiu"
            },
            ...this.seRequest
          }
        };
        fileContent = builder.buildObject(xmlWrap);
      }
      this.processFile(fileContent, fileType)
    },
    processFile (fileContent, fileType) {
      let url = new Blob([fileContent], {type: 'application/' + fileType})
      let filename = `${this.seRequest.requestId}-Request.${fileType}`
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(url, filename)
      } else {
        // In FF link must be added to DOM to be clicked
        let objurl = window.URL.createObjectURL(url)
        let link = document.createElement('a')
        link.href = objurl
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()

        setTimeout(function () {
          document.body.removeChild(link)
          window.URL.revokeObjectURL(objurl)
        }, 100)
      }
    },
    copyData() {
      let origCreatedYear = new Date(this.seRequest.createdDate).getFullYear()

      if (this.seRequest.extraData.lastYearTaxReturnFiled && (new Date().getFullYear()) === origCreatedYear) {
        // cloned request created same year as original - clone with new Date
        this.$store.commit(SET_CLONED_DATA, {requestId: '', locked: false, requestType: Enums.SeRequestType.rtNewData, createdDate: new Date()})
        this.$store.commit(SET_EXTRA_DATA, {evaluationDataSource: Enums.SeEvaluationDataSourceType.dsClone});

      } else if (this.seRequest.extraData.lastYearTaxReturnFiled && (new Date().getFullYear()) - origCreatedYear === 1) {
        //original was created in previous calendar year and we are cloning it one year later - clone with new Date and update lastYearTaxReturnFiled
        this.$store.commit(SET_CLONED_DATA, {requestId: '', locked: false, requestType: Enums.SeRequestType.rtNewData, createdDate: new Date()})
        this.$store.commit(SET_EXTRA_DATA, {evaluationDataSource: Enums.SeEvaluationDataSourceType.dsClone, lastYearTaxReturnFiled: false});

      } else {
        //something old or unexpected is cloned - leave the created date as is
        this.$store.commit(SET_CLONED_DATA, {requestId: '', locked: false, requestType: Enums.SeRequestType.rtNewData})
        this.$store.commit(SET_EXTRA_DATA, {evaluationDataSource: Enums.SeEvaluationDataSourceType.dsClone});
      }

      this.$store.dispatch(SAVE_SE_REQUEST).then(() => {
        Swal.fire({
          title: 'Data changes were successfully copied',
          buttonsStyling: false,
          customClass: { confirmButton: 'bp-button bp-button--primary' },
          html:
            `<b>New</b> Income Calculator ID is <b>${this.seRequest.requestId} </b>` +
            "You can use the Income Calculator ID to retrieve and update previous inputs or to print or export previous findings"
        }).then(() =>
          this.$router.push('income-type')
        )
      }).catch(resp => {
        Swal.fire({
          title: `Could not save`,
          buttonsStyling: false,
          customClass: {confirmButton: 'bp-button bp-button--primary'},
          html:
            `Could not save Income Calculator data`
        })
      })
    },
    search() {

      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.$store.commit(SET_STATE_DATA, {key: 'caseFileSearchResult', value: []})
      if (this.searchType === "byRequestId" && this.requestId) {
        this.searchByRequestId()
      } else if (this.searchType === "byCaseFileId" && this.duCaseFileId) {
        this.searchByCasefileId()
      }
    },
    searchByCasefileId() {
      this.$store.dispatch(GET_SE_CASEFILE, {duCaseFileId: this.duCaseFileId, duResidencyPostalCode: this.duResidencyPostalCode})
        .then(resp  => {
          if (resp && resp.data && resp.status === 200) {
            if (Array.isArray(resp.data) && (resp.data.length === 1)) {
              //only one search result
              this.requestId = resp.data[0].requestId
              this.searchByRequestId()
            }
          }
        })
        .catch( resp  => {
          let msg = `No Income Calculator ID is associated to the Desktop Underwriter Casefile ID <b>${this.duCaseFileId}</b>.`
          if (resp.data?.errorCode === 400 || resp.data?.errorCode === 429) {
            msg = `${resp.data.errorMessage} <b>${this.duCaseFileId}</b>.`
          }

          Swal.fire({
            title: `Not found`,
            icon: 'error',
            buttonsStyling: false,
            customClass: {confirmButton: 'bp-button bp-button--primary'},
            html: msg
          })
        })

    },
    searchByRequestId() {
      this.$store.dispatch(GET_SE_REQUEST, {requestId: this.requestId, incomeAmountHint: this.incomeAmountHint.replace(/,|\$/g, '') })
        .then(() => {
          this.requestLoaded = true
          this.requestType = Enums.SeRequestType.rtUpdate
        })
        .catch(resp => {
          this.requestLoaded = false
          Swal.fire({
            title: `Not found`,
            icon: 'error',
            buttonsStyling: false,
            customClass: {confirmButton: 'bp-button bp-button--primary'},
            html:
              `The Income Calculator ID <b>${this.requestId}</b> does not correspond to an active evaluation.`
          })
        })
    },
  }
}
</script>
<style>

.el-table .se-start-table-description .cell  {
  word-break: normal !important;
}

.se-card-search {
  background-color: rgba(242,242,242,1);
  padding: 1rem;
}

.se-search-header-row {
  background-color: rgba(0, 0, 0, 0.2) !important;
  color: rgba(0, 0, 0, 0.75) !important;
  font-size: 1rem;
}
.se-search-row {
  font-size: 1rem;
  background-color: rgba(242,242,242,1) !important;
}
.se-search-request-id {
  color: #0a649d;
  text-decoration: underline;
  cursor: pointer;
}

.se-new-eval-card {
  padding: 15px;
  min-height: 240px;
}

.se-existing-eval-card {
  padding: 15px;
}



</style>
