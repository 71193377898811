<template >
  <div>

    <card>
        <h2>Learn More about Income Calculator<sup><small> ®</small></sup></h2>
        <h4>UNDER CONSTRUCTION: More info here about benefits and our phased approach</h4>
        <img src="static/img/learn-more-schedule.png" height="425" alt="crash"/>
    </card>

    <se-buttons
      @seNext="next"
      @sePrevious="previous">
    </se-buttons>

  </div>

</template>
<script>
import SeButtons from './SeButtons.vue'


export default {
  components: {SeButtons},
  methods: {
    next() {
      this.$router.push('/se-eval/start')
    },
    previous() {
      this.$router.push('welcome')
    }
  }

}
</script>
<style>


</style>
