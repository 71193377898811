<template>
  <div>
    <div class="container">

    <h1 class="se-bp-title">
      Length of Employment
      <a class="btn-link se-page-help-icon" v-tooltip.top-center="'Learn More'" @click="showHelp = true">
        <i class="fa fa-info-circle"></i>
      </a>
    </h1>

    <form class="form-horizontal" autocomplete="off">
      <h4>Provide the employment start date
        <a class="btn-link se-help-icon" v-tooltip.top-center="'Learn More'" @click="showStartEmploymentDateHelp = true">
          <i class="fa fa-info-circle"></i>
        </a>
      </h4>

      <div class="mt-1">
        <masked-input
          type="text"
          class="se-tax-field-input bp-form-input bp-u-shadow-04"
          :readonly="isLocked"
          placeholder="MM/DD/YYYY"
          :mask="[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]"
          :guide="false"
          :pipe="dateMaskPipe"
          v-model.lazy="employmentStartDateProvidedImpl"
          @blur="employmentStartDateProvided = employmentStartDateProvidedImpl">
        </masked-input>

        <div class="bp-help is-error mb-2" v-if="$v.employmentStartDateProvidedImpl.$error">
          <div class="bp-ucon" data-src="feedback-warning"><svg class="bp-svg" viewBox="0 0 24 24" id="feedback-warning" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M13.009 13.987V8.934h-1.956v5.053h1.956zm0 4.016v-1.962h-1.956v1.962h1.956zM2.988 20.962L12.016 3l9.025 17.962H2.988z"></path></svg></div>
          <template v-if="!$v.employmentStartDateProvidedImpl.isProvided">Valid Employment Start Date is required</template>
        </div>
      </div>

      <template v-if="getEmploymentMonthsCount < 24 && isTwelveMonthEarningsOnReturn">
        <br>
        There is less than a 2-year history of self-employment or business ownership.  Describe the self-employment or business ownership history:
        <a class="btn-link se-help-icon" v-tooltip.top-center="'Learn More'" @click="showHelp2Year = true">
          <i class="fa fa-info-circle"></i>
        </a>

        <div class="form-group mt-3">
            <radio :label="true" v-model="employmentHistoryConsistentIndicator">The employment or occupation history is similar to the current self-employment or business ownership, and the loan file contains documentation to support receipt of prior income from a similar employment/occupation at the same (or greater) level than the current self-employment or business ownership.</radio>
            <radio :label="false" v-model="employmentHistoryConsistentIndicator">The employment or occupation history is not similar to the current self-employment or business ownership, or the loan file does not contain documentation of receipt of income from a similar employment/occupation at the same (or greater) level than the current self-employment or business ownership.</radio>
        </div>
      </template>

    </form>

    <se-help :visible.sync="showHelp" title="Learn More">
      <p>
        Use this screen to provide the date the borrower began this employment.
      </p>
      <p>
        {{ appName }} requires an accurate employment start date to correctly calculate the monthly qualifying income.
    </p>
      <p>
      <b>FAQs:</b><br>
        Q. Should the employment start date provided to {{ appName }} match the employment start date on the loan application?<br>
        A. Yes. The employment start dates should match.
    </p>
      <p>
        Q. Does {{ appName }} validate the borrower is currently employed?<br>
        A. No. The lender remains responsible for obtaining Verbal Verification of Employment in accordance with the Selling Guide.
    </p>
      <p>
      <icon-base width="22" height="22" iconColor="#0c77ba" icon-name="icon-book"><icon-book/></icon-base>
      <a href="https://selling-guide.fanniemae.com/sel/b3-3.1-07/verbal-verification-employment" target="_blank">Learn more about Verbal Verification of Employment in the Fannie Mae Selling Guide.</a>
    </p>
     <p>
        Q. The tax returns indicate the borrower has multiple separate self-employed businesses, but the loan application appears to
        have merged some of the businesses together. What employment start date should I provide?<br>
        A. The loan application must separately identify each self-employed business with accurate employment data in Section 1b/1c of the Uniform Residential
        Loan Application. If the borrower has multiple self-employment businesses, you will need a separate {{ appName }} evaluation for each business.
      </p>

    </se-help>

    <se-help :visible.sync="showHelp2Year" title="Learn More">
      <p>
        Fannie Mae generally requires a minimum two-year history of self-employment from a
        business as a means of demonstrating the likelihood that income will continue to be
        received. However, a one-year history of self-employment from a business may be considered
        if the borrower has a history of employment with similar responsibilities to those in their current business.
      </p><p>

      <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
      Learn more about Length of Self-Employment in the <a href="https://selling-guide.fanniemae.com/sel/b3-3.2-01/underwriting-factors-and-documentation-self-employed-borrower" target="_blank">Underwriting Factors and Documentation for a Self-Employed Borrower</a>
      section of the Fannie Mae Selling Guide.

    </p><p>
      <b>FAQs:</b><br>
      Q. What would happen if the data entered does not meet the Fannie Mae Selling Guide requirements for
      satisfactory employment/occupation history?<br>
      A. {{ appName }} will not calculate a monthly qualifying income amount.
    </p>
    </se-help>

    <se-help :visible.sync="showStartEmploymentDateHelp" title="Learn More">
      <p>
        {{ appName }} requires an accurate employment start date to correctly calculate the monthly qualifying income.
      </p>
      <p>
      <b>FAQs:</b><br>
        Q. Should the employment start date provided to {{ appName }} match the employment start date on the loan application?<br>
        A. Yes. The employment start dates should match.
      </p>
    </se-help>

    <se-buttons
      :display-save="false"
      :display-submit="false"
      @seNext="next"
      @sePrevious="previous">
    </se-buttons>

  </div>
  </div>

</template>
<script>
import {Radio, IconArrowRight, IconBook} from 'src/components/index'
import LSwitch from 'src/components/Switch.vue'
import SeButtons from './SeButtons.vue'
import {mapGetters, mapState} from 'vuex'
import Enums from 'src/model/enums'
import {SET_EXTRA_DATA, SET_REQUEST_DATA} from 'src/store/actions/se'
import SeHelp from './SeHelp.vue'
import {differenceInMonths} from 'date-fns'
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";
import {isValidDate} from 'src/util/seutil'
import {isDate} from 'date-fns'
import appNameMixin from '../../../mixins/AppNameMixin'
import Swal from "sweetalert2";

export default {
  mixins:[appNameMixin],
  components: {
    Radio,
    LSwitch,
    SeButtons,
    SeHelp,
    IconArrowRight,
    IconBook
  },
  data() {
    return {
      employmentStartDateProvidedImpl: '',
      showHelp: false,
      showHelp2Year: false,
      showStartEmploymentDateHelp: false
    }
  },
  validations: {
    employmentStartDateProvidedImpl: {
      isProvided(value) {
        return value && isValidDate(value) && new Date(value) <= new Date()
      }
    }
  },
  created() {
    this.employmentStartDateProvidedImpl = this.employmentStartDateProvided
  },
  computed: {
    dateMaskPipe() {
      return createAutoCorrectedDatePipe('mm/dd/yyyy', {
        minYear: 1950,
        maxYear: new Date().getFullYear()
      })
    },
    employmentStartDateProvided: {
      get () {
        return this.seRequest.extraData.employmentStartDateProvided
      },
      set (employmentStartDateProvided) {
        this.$store.commit(SET_EXTRA_DATA, {employmentStartDateProvided})
      }
    },
    employmentHistoryConsistentIndicator: {
      get () {  return this.seRequest.employmentHistoryConsistentIndicator },
      set (employmentHistoryConsistentIndicator) { this.$store.commit(SET_REQUEST_DATA, {employmentHistoryConsistentIndicator})  }
    },
    isTwelveMonthEarningsOnReturn() {
      return (!!this.getDerivedEmploymentBusinessStartDate
        && isDate(this.getDerivedEmploymentBusinessStartDate)
        && (this.getDerivedEmploymentBusinessStartDate <= new Date(new Date().getFullYear() - 1, 0, 1))) ?? false
    },
    seTaxFormRoutes() {
      return this.$store.getters.getTaxFormRoutes
    },
    ...mapState({
      seRequest: state => state.se.request
    }),
    ...mapGetters([
      'getDerivedEmploymentBusinessStartDate',
      'getEmploymentMonthsCount',
      'getPY1',
      'isLocked'
    ]),
  },
  methods: {
    next() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      let routeTo = 'du-evaluation'
      if (this.getEmploymentMonthsCount < 24 && !this.isTwelveMonthEarningsOnReturn) {
        this.alertMonthCount(routeTo);

      } else {
        this.$router.push(routeTo)
      }
    },
    previous() {
      this.$router.push('business-name')
    },
    alertMonthCount(routeTo) {
      let msgIcon = "warning"
      let msgHtml = "<p>Based on the Employment Start Date, the borrower’s most recent tax returns do not reflect a full 12-months of income from self-employment or business ownership. The Fannie Mae Selling Guide income requirements cannot be met.</p>"
        + "<p>You may continue to provide tax return data for informational purposes, but Income Calculator will not return a qualifying income amount.</p>"

      Swal.fire({
        title: 'WARNING!',
        html: msgHtml,
        width: 480,
        buttonsStyling: false,
        showCancelButton: true,
        customClass: {confirmButton: 'bp-button bp-button--primary', cancelButton: 'bp-button'},
        confirmButtonText: `OK`,
        icon: msgIcon
      }).then((result) => {
        if (result.isConfirmed) {
          this.$router.push(routeTo)
        }
      })
    }

  }
}
</script>
<style>

</style>
