<template>
  <div>

    <div class="table-responsive table-full-width se-findings-table mt-1" >
      <el-table
        ref="collectedDataTable"
        :data="this.tableData"
        row-key="id"
        :border="false"
        header-row-class-name="se-findings-table-header"
        :header-cell-class-name="headerCellClassName"
        row-class-name="se-findings-table-row"
        :cell-class-name="cellClassName">

        <el-table-column
          label="Borrower's income from K-1"
          class-name="se-findings-item-column"
          :min-width="150">
          <template slot-scope="scope">
            {{scope.row.name}}
            <a class="se-help-icon btn-link" v-tooltip.top-center="'Learn More'" @click="handleFieldHelp(scope.row.id)" v-show="scope.row.hasHelp">
              <i class="fa fa-info-circle"></i>
            </a>
          </template>
        </el-table-column>

        <el-table-column
          header-align="center"
          label="Tax Returns">
          <el-table-column
            :label="getPY1"
            header-align="center"
            align="right"
            :width="185"
            :min-width="40">
            <template slot-scope="scope">
              <div class="row" v-if="scope.row.id !== 'Total'">
                <div class="col-8">{{ scope.row.currentYearTaxValue | toCurPercent(scope.row.isCurrency, scope.row.isPercent, scope.row.scale) }}<span v-if="scope.row.currentYearTaxValue2"><br>{{ scope.row.currentYearTaxValue2 }}</span></div>
                <div v-if="scope.row.currentYearFieldLocation">
                  <a href="#" @click="editField(scope.row.currentYearFieldLocation)">
                    <icon-base width="22" height="22" iconColor="rgba(0,0,0,0.80)" icon-name="Edit"><icon-edit/></icon-base>
                  </a>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="this.getNumberOfSelectedTaxFormYears > 1"
            :label="getPY2"
            header-align="center"
            align="right"
            :width="185"
            :min-width="40">
            <template slot-scope="scope">
              <div class="row" v-if="scope.row.id !== 'Total'">
                <div class="col-8">{{ scope.row.pastYearTaxValue | toCurPercent(scope.row.isCurrency, scope.row.isPercent, scope.row.scale) }}<span v-if="scope.row.pastYearTaxValue2"><br>{{ scope.row.pastYearTaxValue2 }}</span></div>
                <div v-if="scope.row.pastYearFieldLocation">
                  <a href="#" @click="editField(scope.row.pastYearFieldLocation)">
                    <icon-base width="22" height="22" iconColor="rgba(0,0,0,0.80)" icon-name="Edit" ><icon-edit/></icon-base>
                  </a>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column
          align="right"
          :width="20"
          :min-width="20">
        </el-table-column>

        <el-table-column
          header-align="center"
          :label="appName">
          <el-table-column
            :label="getPY1"
            label-class-name="se-findings-table-eval-result-header"
            class-name="se-eval-result"
            align="right"
            header-align="center"
            :width="185"
            :min-width="40">
            <template slot-scope="scope">
              <div class="col-10">
                    <span :class="{'font-weight-bold': scope.row.id === 'Total' }" >
                      <template v-if="scope.row.currentYearSeValueMemo"><a class="se-help-a" v-tooltip.top-center="'Learn More'" @click="showSeMemo(scope.row.id, scope.row.currentYearSeValue, scope.row.cy, scope.row.currentYearSeValueMemo)">{{ scope.row.currentYearSeValue | toCurPercent(scope.row.isCurrency, scope.row.isPercent, scope.row.scale) }}</a></template>
                      <template v-else>{{ scope.row.currentYearSeValue | toCurPercent(scope.row.isCurrency, scope.row.isPercent, scope.row.scale) }}</template>
                    </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :label="getPY2"
            align="right"
            label-class-name="se-findings-table-eval-result-header"
            class-name="se-eval-result"
            header-align="center"
            :width="185"
            :min-width="40">
            <template slot-scope="scope">
              <div class="col-10">
                    <span :class="{'font-weight-bold': scope.row.id === 'Total' }">
                      <template v-if="scope.row.pastYearSeValueMemo"><a class="se-help-a" v-tooltip.top-center="'Learn More'" @click="showSeMemo(scope.row.id, scope.row.pastYearSeValue, scope.row.py, scope.row.pastYearSeValueMemo)">{{ scope.row.pastYearSeValue | toCurPercent(scope.row.isCurrency, scope.row.isPercent, scope.row.scale) }}</a></template>
                      <template v-else>{{ scope.row.pastYearSeValue | toCurPercent(scope.row.isCurrency, scope.row.isPercent, scope.row.scale) }}</template>
                    </span>
              </div>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column
          v-if="this.getNumberOfSelectedTaxFormYears > 1"
          label="Change"
          align="center"
          class-name="cell-narrow se-eval-result-change"
          :width="100"
          :min-width="20">
          <template slot-scope="scope"><span :class="{'font-weight-bold': scope.row.id === 'Total' }" v-if="scope.row.isDisplayChange">{{ scope.row.seValueChangePct | toPercent}}</span></template>
        </el-table-column>
      </el-table>
    </div>

    <se-help :visible.sync="showFieldHelp.totalGuaranteedPayments1">
      <div class="se-field-help">
        <p>
          The sum of Schedule K-1, Lines 4a and 4b.<br>
          {{ appName }} includes applicable Guaranteed payments to partners in the monthly qualifying income calculation.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.totalGuaranteedPayments2">
      <div class="se-field-help">
        <p>
          The sum of Schedule K-1, Lines 4a and 4b.<br>
          {{ appName }} includes applicable Guaranteed payments to partners in the monthly qualifying income calculation.
        </p>
      </div>
    </se-help>


    <se-help :visible.sync="showFieldHelp.ordinaryBusinessIncomeLoss">
      <div class="se-field-help">
        <p>
          Schedule K-1, Line 1.<br>
          {{ appName }} determines whether income from Form K-1 can be included in the monthly
          qualifying income based on the history of distributions and, if necessary, business liquidity analysis.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.netRentalRealEstateIncomeLoss">
      <div class="se-field-help">
        <p>
          Schedule K-1, Line 2.<br>
          This version of {{ appName }} cannot evaluate rental income or loss received by the business.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.otherNetRentalIncomeLoss">
      <div class="se-field-help">
        <p>
          Schedule K-1, Line 3.<br>
          This version of {{ appName }} cannot evaluate rental income or loss received by the business.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.k1Distribution">
    <div class="se-field-help">
      <p>
        Schedule K-1, Line 19a.<br>
        {{ appName }} determines whether income from Form K-1 can be included in the monthly
        qualifying income based on the history of distributions and, if necessary, business liquidity analysis.
      </p>
    </div>
  </se-help>
    <se-help :visible.sync="showFieldHelp.guaranteedPaymentsForServices">
      <div class="se-field-help">
        <p>
          Schedule K-1, Line 4a.<br>
          {{ appName }} includes applicable Guaranteed payments to partners in the monthly qualifying income calculation.
        </p>
      </div>
    </se-help>
    <se-help :visible.sync="showFieldHelp.guaranteedPaymentsForCapital">
      <div class="se-field-help">
        <p>
          Schedule K-1, Line 4b.<br>
          {{ appName }} includes applicable Guaranteed payments to partners in the monthly qualifying income calculation.
        </p>
      </div>
    </se-help>
    <se-help :visible.sync="showFieldHelp.ordinaryIncomeLossFromOtherPartnershipsEstates">
      <div class="se-field-help">
        <p>
          Form 1065, Line 4.<br>
          Any amount shown on Line 4 has already been accounted for in the Ordinary business income (or loss) shown on the K-1.
        </p>
        <b>FAQs:</b>

        <p>
          Q. Why is {{ appName }} subtracting the Ordinary income from other partnerships estates and trusts reported on the tax returns?<br>
          A. If two years tax return data is provided, positive Ordinary income from other partnerships
          estates and trusts reported on the tax returns will be subtracted if they have not
          been received for two years.
        </p>
        <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
        Learn more about Adjustments to Business Cash Flow in the

        <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.4-01/analyzing-partnership-returns-partnership-or-llc">Analyzing Returns for a Partnership</a> section of the Fannie Mae Selling Guide.
        <br><br>
        <p>
          Q. Why is {{ appName }} not counting (setting to zero) the Ordinary income (loss) amount from other partnerships, estates, and trusts reported on the tax returns?<br>
          A. The Ordinary income (loss) amount from other partnerships, estates, and trusts is being included in the monthly qualifying income calculation.
          Any amount shown on Line 4 has already been accounted for in the Ordinary business income (loss) amount shown on the K-1.
        </p>
        <p>
          Q. The Fannie Mae Selling Guide allows me to document income using one year of tax returns.
          Will {{ appName }} consider Ordinary income from other partnerships estates and trusts to be recurring if they are only documented with one year of tax returns?<br>
          A. {{ appName }} may consider Ordinary income from other partnerships estates and trusts to be recurring even if they are documented with only one year of tax returns.
        </p>
        <p>
          Q. Am I required to obtain supporting documentation from the borrower regarding the nature of the Ordinary income from other partnerships estates and trusts?<br>
          A. {{ appName }} may not require supporting documentation for Ordinary income from other partnerships estates and trusts.
        </p>
        <p>
          Q. Why isn’t {{ appName }} adding back the Ordinary loss from other partnerships, estates, and trusts reported on the tax returns?<br>
          A. {{ appName }} does not add back Ordinary loss amounts from other partnerships, estates, and trusts since they have already been accounted
          for in the Ordinary business income (loss) amount shown on the K-1.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.netGainLoss4797">
      <div class="se-field-help">
        <p>
          Form 1065, Line 6.<br>
          Any amount shown on Line 6 has already been accounted for in the Ordinary business income (or loss) shown on the K-1.
        </p>
        <b>FAQs:</b>
        <p>
          Q. Why is {{ appName }} subtracting the Net gain amounts from Form 4797?<br>
          A. If two years tax return data is provided, Net gain amounts from Form 4797 gains will
          be subtracted if they have not been received for two years.
        </p>
        <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
        Learn more about Adjustments to Business Cash Flow in the
        <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.4-01/analyzing-partnership-returns-partnership-or-llc">Analyzing Returns for a Partnership</a> section of the Fannie Mae Selling Guide.

        <p>
          Q. Why is {{ appName }} not counting (setting to zero) the Net gain amounts from Form 4797?<br>
          A. The Net gain amounts from Form 4797 are being included in the monthly qualifying income calculation.
          Any amount shown on Line 6 has already been accounted for in the Ordinary business income (or loss) amount shown on the K-1.
        </p>
        <p>
          Q. The Fannie Mae Selling Guide allows me to document income using one year of tax returns.
          Will {{ appName }} consider Net gain (loss) amounts from Form 4797 to be recurring if they are only documented with one year of tax returns?<br>
          A. {{ appName }} may consider Net gain (loss) amounts from Form 4797 to be recurring even if they are documented with only one year of tax returns.
        </p>
        <p>
          Q. Am I required to obtain supporting documentation from the borrower regarding the nature of the Net gain (loss) amounts from Form 4797?<br>
          A. {{ appName }} may not require supporting documentation for Net gain (loss) amounts from Form 4797.
        </p>
        <p>
          Q. Why isn’t {{ appName }} adding back the Net loss amounts from Form 4797?<br>
          A. {{ appName }} does not add back Net losses from Form 4797 since they have already been accounted for in the Ordinary business income (loss)
          amount shown on the K-1.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.netFarmGainLoss">
      <div class="se-field-help">
        <p>
          Form 1065, Line 5.<br>
          Any amount shown on Line 5 has already been accounted for in the Ordinary business income (or loss) shown on the K-1.
        </p>
        <b>FAQs:</b>
        <p>
          Q. Why is {{ appName }} subtracting the Net farm profit reported on the tax returns?<br>
          A. If two years tax return data is provided, Net farm profits reported on the tax returns will be subtracted if they have not been received for two years.
        </p>
        <p>
        <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
        Learn more about Adjustments to Business Cash Flow in the
        <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.4-01/analyzing-partnership-returns-partnership-or-llc">Analyzing Returns for a Partnership</a> section of the Fannie Mae Selling Guide.
        </p>
        <p>
          Q. Why is {{ appName }} not counting (setting to zero) the Net farm profit (loss) amount reported on the tax returns?<br>
          A. The Net farm profit (loss) amount is being included in the monthly qualifying income calculation.
          Any amount shown on Line 5 has already been accounted for in the Ordinary business income (loss) amount shown on the K-1.
        </p>
        <p>
          Q. The Fannie Mae Selling Guide allows me to document income using one year of tax returns.
          Will {{ appName }} consider Net farm profit (loss) amounts to be recurring if they are only documented with one year of tax returns?<br>
          A. {{ appName }} may consider Net farm profit (loss) amounts to be recurring even if they are documented with only one year of tax returns.
        </p>
         <p>
          Q. Am I required to obtain supporting documentation from the borrower regarding the nature of the Net farm profit (loss) amounts?<br>
          A. {{ appName }} may not require supporting documentation for Net farm profit (loss) amounts.
        </p>
        <p>
          Q. Why isn’t {{ appName }} adding back the Net farm loss reported on the tax returns?<br>
          A. {{ appName }} does not add back Net farm loss amounts since they have already been accounted for in the Ordinary business income (loss) amount
          shown on the K-1.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.otherIncomeLoss">
      <div class="se-field-help">
        <p>
          Form 1065, Line 7.<br>
          Any amount shown on Line 7 has already been accounted for in the Ordinary business income (or loss) shown on the K-1.
        </p>
        <b>FAQs:</b>
        <p>
          Q. Why is {{ appName }} subtracting the Other Income reported on the tax returns?<br>
          A. If two years tax return data is provided, positive Other Income reported on the tax returns will be subtracted if it has not been received for two years.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about Adjustments to Business Cash Flow in the
          <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.4-01/analyzing-partnership-returns-partnership-or-llc">Analyzing Returns for a Partnership</a> section of the Fannie Mae Selling Guide.
        </p>
        <p>
          Q. Why is {{ appName }} not counting (setting to zero) the Other Income reported on the tax returns?<br>
          A. The Other income (loss) amount is being included in the monthly qualifying income calculation.
          Any amount shown on Line 7 has already been accounted for in the Ordinary business income (or loss) amount shown on the K-1.
        </p>
        <p>
          Q. The Fannie Mae Selling Guide allows me to document income using one year of tax returns.
          Will {{ appName }} consider Other income (loss) amounts to be recurring if they are only documented with one year of tax returns?<br>
          A. {{ appName }} may consider Other income (loss) amounts to be recurring even if they are documented with only one year of tax returns.
        </p>
        <p>
           Q. Am I required to obtain supporting documentation from the borrower regarding the nature of the Other income (loss) amounts?<br>
          A. {{ appName }} may not require supporting documentation for Other income (loss) amounts.
        </p>
        <p>
          Q. Why isn’t {{ appName }} adding back the Other loss amount reported on the tax returns?<br>
          A. {{ appName }} does not add back Other loss amounts since they have already been accounted for in the Ordinary business income (loss) amounts shown on the K-1.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.depreciation">
      <div class="se-field-help">
        <p>
          Form 1065, Line 16c<br>
          Any amount on this line is added to the monthly qualifying income calculation.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.depreciation8825">
      <div class="se-field-help">
        <p>
          Form 8825, Line 14(a) through 14(h).<br>
          Any amount on this line is added to the monthly qualifying income calculation.
        </p>
        <b>FAQs:</b>
        <p>
          Q. Why is the pencil icon grayed-out (not able to be selected)?<br>
          A. {{ appName }} will display Form 8825 for an S-Corporation or
          Partnership when there is a Net rental real estate income amount on Line 2 of the borrower’s K-1.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.depletion">
      <div class="se-field-help">
        <p>
          Form 1065, Line 17.<br>
          Any amount on this line is added to the monthly qualifying income.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.amortization">
      <div class="se-field-help">
        <p>
          Form 1065, Attached Statement.<br>
          Any amount identified as amortization is added to the monthly qualifying income.
        </p>
        <b>FAQs:</b>
        <p>
          Q. Why is the pencil icon grayed-out (not able to be selected)?<br>
          A. {{ appName }} will display Amortization when there are Other Deductions on Form 1065, Page 1, Line 21 (tax year 2023) or Line 20 (tax year 2022/2021).
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.amortization4562">
      <div class="se-field-help">
        <p>
          Form 4562, Line 44.<br>
          Any amount on this line is added to the monthly qualifying income.
        </p>
        <b>FAQs:</b>
        <p>
          Q. Why is the pencil icon grayed-out (not able to be selected)?<br>
          A. {{ appName }} will display Form 4562 when there are Other Deductions on Form 1065, Page 1, Line 21 (tax year 2023) or Line 20 (tax year 2022/2021) and no Amortization amount has been identified on Form 1065.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.casualtyTheftLoss">
    <div class="se-field-help">
      <p>
        Form 4684, Lines 31, 32, 36 and 37<br>
        {{ appName }} determines if there are gains or losses from any casualties or thefts.
        Any loss amounts are added back to the monthly qualifying income. If the borrower owns more than one business,
        ensure the amounts provided relate to the business you want {{ appName }} to evaluate.
      </p>
      <b>FAQs</b>
      <p>
        Q. Why is the pencil icon grayed-out (not able to be selected)?<br>
        A. {{ appName }} will display Form 4684 when it is identified as being included in the tax returns on the Losses and Expenses screen.
      </p>
    </div>
  </se-help>

    <se-help :visible.sync="showFieldHelp.extraordinaryOneTimeExpenses">
      <div class="se-field-help">
        <p>
          Any amount identified by the lender as an extraordinary one-time expense
          is added back to the monthly qualifying income.
        </p>
        <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
        Learn more about Analyzing Individual Tax Returns (and extraordinary one-time expenses) in the

        <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.3-01/general-information-analyzing-individual-tax-returns">General Information on Analyzing Individual Tax Returns</a> section of the Fannie Mae Selling Guide.

      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.mortgagesNotesPayableLessThanOneYearEndOfYear">
      <div class="se-field-help">
        <p>
          {{ appName }} determines the amount of Mortgages, notes or bonds payable in less than 1 year (short-term debt) that are subtracted from qualifying income,
          based on the business’ cash position and if necessary, supplemental information. {{ appName }} evaluates short term debt as follows:
        </p>
        <ul>
          <li>If the business has sufficient cash (shown on Form 1065, Schedule L, Line 1(d)) to pay off the short-term debt (shown on Form 1065,
            Schedule L, Line 16D), the short-term debt is not subtracted from the monthly qualifying income.</li>
          <li>If some or all of the short-term debt is a line of credit or is regularly rolled over, those amounts are not subtracted from the monthly qualifying income calculation.</li>
          <li>If the business does not have sufficient cash to pay off the short-term debt, and the short-term debt
            is not a line of credit or does not regularly roll over, the short-term debt is subtracted from the monthly qualifying income.</li>
        </ul>
        <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
        Learn more about Adjustments to Business Cash Flow in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.4-01/analyzing-partnership-returns-partnership-or-llc">Analyzing Returns for a Partnership</a> section of the Fannie Mae Selling Guide.
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.travelEntertainment">
      <div class="se-field-help">
        <p>
          Form 1065, Schedule M-1, Line 4b (Travel and Entertainment)<br>
          Any amount on this line is subtracted from the monthly qualifying income.<br>
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.deductibleMeals">
      <div class="se-field-help">
        <p>
          Form 1065, Schedule M-3, Part III, Line 6(c) (Meals and entertainment)<br>
          Any amount on this line is subtracted from the monthly qualifying income calculation.
        </p>
        <b>FAQs:</b>
        <p>
          Q. Why is the pencil icon grayed-out (not able to be selected)?<br>
          A. {{ appName }} will display Schedule M-3 for a Partnership when the user reports that the checkbox on Line J of Form 1065 has been checked.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.ownershipPercent">
      <div class="se-field-help">
        <p>
          Schedule K-1, Line J.<br>
          The percentage of the business owned by the borrower.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.totalBusinessIncomeShare">
      <div class="se-field-help">
        <p>
          {{ appName }} calculates the Borrower’s share of business income, by multiplying the Subtotal business income, by the Borrower’s ownership percentage.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.w2MedicareWages">
      <div class="se-field-help">
        <p>
          Form W-2, Box 5.<br>
          Any amount in this box is added to the monthly qualifying income calculation.
        </p>
        <b>FAQs:</b>
        <p>Q. Why is the pencil icon grayed-out (not able to be selected)?
          A. {{ appName }} will display Form W-2 for a Partnership when there are Wages, Salaries and Tips on Line 1/1a of Form 1040.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.distributionGuaranteedPaymentOrK1Income">
      <div class="se-field-help">
        <p>
          {{ appName }} determines whether income from Form K-1 can be included in the monthly qualifying income based on the history of
          distributions and, if necessary, business liquidity analysis.
       </p>
        The K-1 Income is used if:
        <ul>
          <li>business tax returns have been waived,</li>
          <li>only one year of business return data is present and the K-1 Income amount is supported by the K-1 Distributions amount,</li>
          <li>two years of business return data is present, and there is K-1 Income and K-1 Distributions in the current year, and the K-1
            Income amount for the current year is supported by the K-1 Distributions amount for the current year, and the K-1 Income amount
            for the prior year is supported by the K-1 Distributions amount for the prior year, or,</li>
          <li>the Business Liquidity Analysis section of this Findings Report indicates the business has demonstrated adequate
            liquidity to support the withdrawal of earnings because (a) the Quick Ratio is greater than or equal to 1 or (b)
            the business does not rely heavily on inventory and the Current Ratio is greater than or equal to 1,
            or (c) the lender has determined adequate business liquidity outside of {{ appName }}.</li>
        </ul>
        <p>
          If the Business Liquidity Section of this Findings Report indicates
          the business has NOT demonstrated adequate liquidity to support the withdrawal of earnings,
          and the lender has not determined adequate business liquidity outside of {{ appName }},
          the income from Form K-1 will be capped at the K-1 Distribution amount (if lower). If there is no K-1 income or
          no K-1 Distribution amount, no income from the Form K-1 will be included in the monthly qualifying income calculation.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showHelpMessageMemo" title="Learn More">
      <p>
        {{showHelpMessageMemoText}}
      </p>
      <p v-if="showHelpMessageLearnMoreUrl">
        <a target="_blank" :href="showHelpMessageLearnMoreUrl">Learn more in the Fannie Mae Selling Guide</a>
      </p>
    </se-help>
  </div>

</template>
<script>
import {Radio, IconEdit, IconCheckMark} from 'src/components/index'
import LSwitch from 'src/components/Switch.vue'
import SeButtons from './SeButtons.vue'
import Enums from 'src/model/enums'
import SeHelp from './SeHelp.vue'
import {Collapse, CollapseItem, Drawer, Table, TableColumn} from "element-ui"
import {FindingsMixin} from "src/mixins/FindingsMixin";
import appNameMixin from '../../../mixins/AppNameMixin'
export default {
  mixins: [FindingsMixin, appNameMixin],
  components: {
    Radio,
    LSwitch,
    SeButtons,
    SeHelp,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Drawer.name]: Drawer,
    IconEdit,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    IconCheckMark
  },
  data() {
    return {
      tableData: [],
      tableDataCasualtyTheftLoss: []
    }
  },
  mounted() {
    let currTaxFormFields1065k = this.getTaxFormFields(this.getPY1, Enums.SeTaxFormType.tf1065sk1);
    let prevTaxFormFields1065k = this.getTaxFormFields(this.getPY2, Enums.SeTaxFormType.tf1065sk1);

    let currTaxFormFields1065 = this.getTaxFormFields(this.getPY1, Enums.SeTaxFormType.tf1065);
    let prevTaxFormFields1065 = this.getTaxFormFields(this.getPY2, Enums.SeTaxFormType.tf1065);

    let currTaxFormFields4562 = this.getTaxFormFields(this.getPY1, Enums.SeTaxFormType.tf4562);
    let prevTaxFormFields4562 = this.getTaxFormFields(this.getPY2, Enums.SeTaxFormType.tf4562);

    let currTaxFormFields4684 = this.getTaxFormFields(this.getPY1, Enums.SeTaxFormType.tf4684);
    let prevTaxFormFields4684 = this.getTaxFormFields(this.getPY2, Enums.SeTaxFormType.tf4684);

    let currTaxFormFields8825 = this.getTaxFormFields(this.getPY1, Enums.SeTaxFormType.tf8825);
    let prevTaxFormFields8825 = this.getTaxFormFields(this.getPY2, Enums.SeTaxFormType.tf8825);

    let currTaxFormFields1065sm3 = this.getTaxFormFields(this.getPY1, Enums.SeTaxFormType.tf1065sm3);
    let prevTaxFormFields1065sm3 = this.getTaxFormFields(this.getPY2, Enums.SeTaxFormType.tf1065sm3);

    let currTaxFormFieldsW2 = this.getTaxFormFields(this.getPY1, Enums.SeTaxFormType.tfw2);
    let prevTaxFormFieldsW2 = this.getTaxFormFields(this.getPY2, Enums.SeTaxFormType.tfw2);

    let currTaxFormFieldsLossExp = this.getTaxFormFields(this.getPY1, Enums.SeTaxFormType.tfLossesExpenses);
    let prevTaxFormFieldsLossExp = this.getTaxFormFields(this.getPY2, Enums.SeTaxFormType.tfLossesExpenses);

    //casualty theft loss
    Object.entries(Enums.SeFindingsCasualtyTheftLossLineType).forEach( ([k,v]) => {
      let row = {};
      row['id'] = k;
      row['name'] = v.name;

      row.currentYearTaxValue = this.getTaxFormValue(currTaxFormFields4684, k);
      row.pastYearTaxValue = this.getTaxFormValue(prevTaxFormFields4684, k);
      row.currentYearFieldLocation = this.getTaxFormPartnershipFieldLocation(this.getPY1, Enums.SeTaxFormType.tf4684, currTaxFormFields4684, k);
      row.pastYearFieldLocation = this.getTaxFormPartnershipFieldLocation(this.getPY2, Enums.SeTaxFormType.tf4684, prevTaxFormFields4684, k);

      if ([Enums.SeFindingsCasualtyTheftLossLineType.casualtyTheftIncomePropertyShortTermLossAmount.id,
        Enums.SeFindingsCasualtyTheftLossLineType.casualtyTheftLongTermLossAmount.id].includes(k)) {
        row.currentYearTaxValue = row.currentYearTaxValue == 0 ? row.currentYearTaxValue : -row.currentYearTaxValue
        row.pastYearTaxValue = row.pastYearTaxValue == 0 ? row.pastYearTaxValue : -row.pastYearTaxValue
      }

      row.isCurrency = true
      row.seValueChangePct = 0
      row.currentYearSeValue = ''
      row.pastYearSeValue = ''
      row.isDisplayChange = false

      this.tableDataCasualtyTheftLoss.push(row)
    });

    //main entries
    Object.entries(Enums.SeFindingsSummaryPartnershipLineType).forEach( ([k,v]) => {
      let row = {};
      row['id'] = k;
      row['name'] = v.name;
      row.isDisplayChange = true
      row.changeOverYearFromTaxReturn = v.changeOverYearFromTaxReturn
      row.memoCy = v.memoCy
      row.memoPy = v.memoPy
      row.hideLt25 = v.hideLt25
      row.responseFieldId = v.responseFieldId

      let fieldId = v.fieldId ?? k;
      if (k === Enums.SeFindingsSummaryPartnershipLineType.amortization4562.id) {
        row.currentYearTaxValue = this.getTaxFormValue(currTaxFormFields4562, fieldId)
        row.pastYearTaxValue = this.getTaxFormValue(prevTaxFormFields4562, fieldId)
        row.currentYearFieldLocation = this.getTaxFormPartnershipFieldLocation(this.getPY1, Enums.SeTaxFormType.tf4562, currTaxFormFields4562, fieldId)
        row.pastYearFieldLocation = this.getTaxFormPartnershipFieldLocation(this.getPY2, Enums.SeTaxFormType.tf4562, prevTaxFormFields4562, fieldId)
        row.isCurrency = true

      } else if ([Enums.SeFindingsSummaryPartnershipLineType.k1Income.id].includes(k)) {
        row.currentYearTaxValue = ''
        row.pastYearTaxValue = ''
        row.isCurrency = true

      } else if (k === Enums.SeFindingsSummaryPartnershipLineType.totalGuaranteedPayments1.id) {
        row.currentYearTaxValue = this.getGuaranteedPaymentsTotal(currTaxFormFields1065k)
        row.pastYearTaxValue = this.getGuaranteedPaymentsTotal(prevTaxFormFields1065k)
        row.isCurrency = true
        row.isDisplayChange = true

      } else if (k === Enums.SeFindingsSummaryPartnershipLineType.totalGuaranteedPayments2.id) {
        row.currentYearTaxValue = ''
        row.pastYearTaxValue = ''
        row.isCurrency = true
        row.isDisplayChange = true

      } else if ([Enums.SeFindingsSummaryPartnershipLineType.businessIncomeTitle.id,
            Enums.SeFindingsSummaryPartnershipLineType.totalBusinessIncome.id,
            Enums.SeFindingsSummaryPartnershipLineType.totalBusinessIncomeShare.id,
            Enums.SeFindingsSummaryPartnershipLineType.distributionGuaranteedPaymentOrK1Income.id].includes(k)) {
        row.currentYearTaxValue = ''
        row.pastYearTaxValue = ''
        row.isCurrency = true
        row.isDisplayChange = false

      } else if (k === Enums.SeFindingsSummaryPartnershipLineType.casualtyTheftLoss.id) {
        row.currentYearTaxValue = this.getCasualtyTheftLossTotal(currTaxFormFields4684)
        row.pastYearTaxValue = this.getCasualtyTheftLossTotal(prevTaxFormFields4684)
        row.children = this.tableDataCasualtyTheftLoss
        row.isCurrency = true

      } else if ([Enums.SeFindingsSummaryPartnershipLineType.otherIncomeLoss.id,
            Enums.SeFindingsSummaryPartnershipLineType.netGainLoss4797.id,
            Enums.SeFindingsSummaryPartnershipLineType.netFarmGainLoss.id,
            Enums.SeFindingsSummaryPartnershipLineType.ordinaryIncomeLossFromOtherPartnershipsEstates.id,
            Enums.SeFindingsSummaryPartnershipLineType.depreciation.id,
            Enums.SeFindingsSummaryPartnershipLineType.amortization.id,
            Enums.SeFindingsSummaryPartnershipLineType.travelEntertainment.id,
            Enums.SeFindingsSummaryPartnershipLineType.depletion.id].includes(k)) {
        row.currentYearTaxValue = this.getTaxFormValue(currTaxFormFields1065, fieldId)
        row.pastYearTaxValue = this.getTaxFormValue(prevTaxFormFields1065, fieldId)
        row.currentYearFieldLocation = this.getTaxFormPartnershipFieldLocation(this.getPY1, Enums.SeTaxFormType.tf1065, currTaxFormFields1065, fieldId)
        row.pastYearFieldLocation = this.getTaxFormPartnershipFieldLocation(this.getPY2, Enums.SeTaxFormType.tf1065, prevTaxFormFields1065, fieldId)
        row.isCurrency = true

      } else if (k === Enums.SeFindingsSummaryPartnershipLineType.mortgagesNotesPayableLessThanOneYearEndOfYear.id) {
        row.currentYearTaxValue = this.getMortgagesNotesPayableLessThanOneYearEndOfYear(currTaxFormFields1065)
        row.pastYearTaxValue = this.getMortgagesNotesPayableLessThanOneYearEndOfYear(prevTaxFormFields1065)
        row.currentYearFieldLocation = this.getTaxFormPartnershipFieldLocation(this.getPY1, Enums.SeTaxFormType.tf1065, currTaxFormFields1065, fieldId)
        row.pastYearFieldLocation = this.getTaxFormPartnershipFieldLocation(this.getPY2, Enums.SeTaxFormType.tf1065, prevTaxFormFields1065, fieldId)
        row.isCurrency = true

      } else if (k === Enums.SeFindingsSummaryPartnershipLineType.depreciation8825.id) {
        row.currentYearTaxValue = this.getTaxFormValue(currTaxFormFields8825, fieldId)
        row.pastYearTaxValue = this.getTaxFormValue(prevTaxFormFields8825, fieldId)
        row.currentYearFieldLocation = this.getTaxFormPartnershipFieldLocation(this.getPY1, Enums.SeTaxFormType.tf8825, currTaxFormFields8825, fieldId)
        row.pastYearFieldLocation = this.getTaxFormPartnershipFieldLocation(this.getPY2, Enums.SeTaxFormType.tf8825, prevTaxFormFields8825, fieldId)
        row.isCurrency = true

      } else if (k === Enums.SeFindingsSummaryPartnershipLineType.deductibleMeals.id) {
        row.currentYearTaxValue = this.getTaxFormValue(currTaxFormFields1065sm3, fieldId)
        row.pastYearTaxValue = this.getTaxFormValue(prevTaxFormFields1065sm3, fieldId)
        row.currentYearFieldLocation = this.getTaxFormPartnershipFieldLocation(this.getPY1, Enums.SeTaxFormType.tf1065sm3, currTaxFormFields1065sm3, fieldId)
        row.pastYearFieldLocation = this.getTaxFormPartnershipFieldLocation(this.getPY2, Enums.SeTaxFormType.tf1065sm3, prevTaxFormFields1065sm3, fieldId)
        row.isCurrency = true

      } else if (k === Enums.SeFindingsSummarySCorpLineType.w2MedicareWages.id) {
        row.currentYearTaxValue = this.getTaxFormValue(currTaxFormFieldsW2, fieldId)
        row.pastYearTaxValue = this.getTaxFormValue(prevTaxFormFieldsW2, fieldId)
        row.currentYearFieldLocation = this.getTaxFormPartnershipFieldLocation(this.getPY1, Enums.SeTaxFormType.tfw2, currTaxFormFieldsW2, fieldId)
        row.pastYearFieldLocation = this.getTaxFormPartnershipFieldLocation(this.getPY2, Enums.SeTaxFormType.tfw2, prevTaxFormFieldsW2, fieldId)
        row.isCurrency = true

      } else if (k === Enums.SeFindingsSummaryPartnershipLineType.extraordinaryOneTimeExpenses.id) {
        row.currentYearTaxValue = this.getTaxFormValue(currTaxFormFieldsLossExp, fieldId)
        row.pastYearTaxValue = this.getTaxFormValue(prevTaxFormFieldsLossExp, fieldId)
        row.currentYearFieldLocation = this.getTaxFormPartnershipFieldLocation(this.getPY1, Enums.SeTaxFormType.tfLossesExpenses, currTaxFormFieldsLossExp, fieldId)
        row.pastYearFieldLocation = this.getTaxFormPartnershipFieldLocation(this.getPY2, Enums.SeTaxFormType.tfLossesExpenses, prevTaxFormFieldsLossExp, fieldId)
        row.isCurrency = true

      } else if (k === Enums.SeFindingsSummaryPartnershipLineType.ownershipPercent.id) {
        row.currentYearTaxValue = this.getTaxFormValue(currTaxFormFields1065k, fieldId)
        row.pastYearTaxValue = this.getTaxFormValue(prevTaxFormFields1065k, fieldId)
        row.currentYearFieldLocation = this.getTaxFormPartnershipFieldLocation(this.getPY1, Enums.SeTaxFormType.tf1065sk1, currTaxFormFields1065k, fieldId)
        row.pastYearFieldLocation = this.getTaxFormPartnershipFieldLocation(this.getPY2, Enums.SeTaxFormType.tf1065sk1, prevTaxFormFields1065k, fieldId)
        row.isCurrency = false
        row.isPercent = true
        row.scale = 4
        row.isDisplayChange = false

      } else if ([Enums.SeFindingsSummaryPartnershipLineType.guaranteedPaymentsForServices.id,
        Enums.SeFindingsSummaryPartnershipLineType.guaranteedPaymentsForCapital.id].includes(k)) {
        row.currentYearTaxValue = this.getTaxFormValue(currTaxFormFields1065k, fieldId)
        row.pastYearTaxValue = this.getTaxFormValue(prevTaxFormFields1065k, fieldId)
        row.currentYearFieldLocation = this.getTaxFormPartnershipFieldLocation(this.getPY1, Enums.SeTaxFormType.tf1065sk1, currTaxFormFields1065k, fieldId)
        row.pastYearFieldLocation = this.getTaxFormPartnershipFieldLocation(this.getPY2, Enums.SeTaxFormType.tf1065sk1, prevTaxFormFields1065k, fieldId)
        row.isCurrency = true
        row.isDisplayChange = false

      } else {
        row.currentYearTaxValue = this.getTaxFormValue(currTaxFormFields1065k, fieldId)
        row.pastYearTaxValue = this.getTaxFormValue(prevTaxFormFields1065k, fieldId)
        row.currentYearFieldLocation = this.getTaxFormPartnershipFieldLocation(this.getPY1, Enums.SeTaxFormType.tf1065sk1, currTaxFormFields1065k, fieldId)
        row.pastYearFieldLocation = this.getTaxFormPartnershipFieldLocation(this.getPY2, Enums.SeTaxFormType.tf1065sk1, prevTaxFormFields1065k, fieldId)
        row.isCurrency = true
      }

      row.seValueChangePct = 0
      row.currentYearSeValue = ''
      row.pastYearSeValue = ''

      if ([Enums.SeFindingsSummaryPartnershipLineType.k1Income.id, Enums.SeFindingsSummaryPartnershipLineType.totalBusinessIncome.id, , Enums.SeFindingsSummaryPartnershipLineType.businessIncomeTitle.id].includes(k)) {
        row.hasHelp = false
      } else {
        row.hasHelp = true
      }
      this.$set(this.showFieldHelp, k, false)

      //ignore certain fields for LT25 ownership
      if (this.isEmploymentOwnershipInterestDerivedGte25OrNull || !row.hideLt25) {
        this.tableData.push(row)
      }
    });
    this.tableData.push({id: 'Total', name: 'TOTAL', currentYearSeValue:'', pastYearSeValue:'', isCurrency:true, isDisplayChange:true});
  },
  computed: {
    isEmploymentOwnershipInterestDerivedGte25OrNull() {
      let currTaxFormFields1065k = this.getTaxFormFields(this.getPY1, Enums.SeTaxFormType.tf1065sk1);
      let ownershipPct = this.getTaxFormValueAsNumber(currTaxFormFields1065k, "partnerShareOfCapitalEndingPct")
      return ownershipPct == null || ownershipPct >= 25.0
    }
  },
  methods: {
    handleEvalResults() {
      let cyResponseFields = this.seResponse.taxYears?.find(ty => ty.year === this.getPY1) ?? {}
      let pyResponseFields = this.seResponse.taxYears?.find(ty => ty.year === this.getPY2) ?? {}
      let changeOverYearFields = this.seResponse.details.changeOverYear ?? {}
      let changeOverYearTaxReturnFields = this.seResponse.details.changeOverYearTaxReturn ?? {}

      this.tableData.forEach(row => {
        if (row.id === 'Total') {
          row.currentYearSeValue = cyResponseFields.totalIncome || 0
          row.pastYearSeValue = pyResponseFields.totalIncome || 0
          row.seValueChangePct = changeOverYearFields.totalIncome

        } else if ([Enums.SeFindingsSummaryPartnershipLineType.businessIncomeTitle.id,
          Enums.SeFindingsSummaryPartnershipLineType.guaranteedPaymentsForServices.id,
          Enums.SeFindingsSummaryPartnershipLineType.guaranteedPaymentsForCapital.id].includes(row.id)) {
          //title - no values

        } else {
          let responseFieldId = row.responseFieldId ?? row.id
          row.currentYearSeValue = cyResponseFields[responseFieldId] || 0;
          row.pastYearSeValue = pyResponseFields[responseFieldId] || 0;
          row.seValueChangePct = row.changeOverYearFromTaxReturn ? changeOverYearTaxReturnFields[responseFieldId] : changeOverYearFields[responseFieldId]

          row.cy = this.getPY1
          row.py = this.getPY2
          row.currentYearSeValueMemo = row.currentYearTaxValue != row.currentYearSeValue ? this.findFirstMessageId(row.memoCy) : false
          row.pastYearSeValueMemo = row.pastYearTaxValue != row.pastYearSeValue ? this.findFirstMessageId(row.memoPy) : false
        }
      });

    },
    getTaxFormPartnershipFieldLocation(taxYear, taxFormType, fields, fieldId) {
      return this.getTaxFormFieldLocation(taxYear, Enums.SeTaxFormType.tf1065, taxFormType, fields, fieldId)
    },
    getCasualtyTheftLossTotal(fields) {
      let total = this.getTaxFormValueAsNumber(fields, "casualtyTheftNonIncomePropertyShortTermGainLossAmount")
        - this.getTaxFormValueAsNumber(fields, "casualtyTheftIncomePropertyShortTermLossAmount")
        + this.getTaxFormValueAsNumber(fields, "casualtyTheftLongTermGainAmount")
        - this.getTaxFormValueAsNumber(fields, "casualtyTheftLongTermLossAmount")
      return total < 0 ? -Math.abs(total) : 0.0
    },
    getGuaranteedPaymentsTotal(fields) {
      return this.getTaxFormValueAsNumber(fields, "guaranteedPaymentsForServices")
        + this.getTaxFormValueAsNumber(fields, "guaranteedPaymentsForCapital")
    },
    cellClassName({row, column, rowIndex, columnIndex}) {
      let className = ''
      if (columnIndex === 1 || (this.getNumberOfSelectedTaxFormYears > 1 && columnIndex === 2)) {
        className += ' se-findings-tax-return-columns';
      }
      if (columnIndex === 3) {
        className += ' cell-no-border'
      }
      if (row.id === 'Total' || row.id === Enums.SeFindingsSummarySCorpLineType.businessIncomeTitle.id) {
        className += ' font-weight-bold'
      }
      return className;
    },
    headerCellClassName({row, column, rowIndex, columnIndex}) {
      let className = ''
      if (rowIndex === 0 && columnIndex === 0) {
        className += ' se-findings-table-items-header';
      }
      if (rowIndex === 0 && columnIndex === 2) {
        className += ' se-findings-table-sign-header';
      }
      if (rowIndex === 0 && columnIndex === 3) {
        className += ' se-findings-table-eval-result-header';
      }
      if (rowIndex === 0 && columnIndex === 4) {
        className += ' se-findings-table-change-header';
      }
      return className;
    },
    showSeMemo(id, value, year, msgId) {
      if (id === Enums.SeFindingsSummaryPartnershipLineType.otherIncomeLoss.id) {
        this.displayMessageMemo(msgId, "https://selling-guide.fanniemae.com/sel/b3-3.2-01/underwriting-factors-and-documentation-self-employed-borrower")

      } else if (msgId) {
        this.displayMessageMemo(msgId)
      }
    }

  }
}
</script>
<style>


.se-findings-table > div {
  border: 0;
  /*margin-right: 50px;*/
}

.se-findings-table .el-table:before {
  height: 0 !important;
}
.se-findings-table .el-table:after {
  width: 0 !important;
}

.el-table__row--level-1 .se-findings-item-column > div{
  text-indent: -36px;
  padding-left: 36px !important;
  word-break: break-word;
}

.se-findings-table-header {
  font-size: 1.1rem;
  color: rgba(0,0,0,0.75) ;
}

.se-findings-table-header > th {
  border-right: 0;
}

.se-findings-table-row > td {
  border-right: 0;
}

.se-findings-item-column {
  padding-left: 0 !important;
  font-size: 1.0rem;
}

.se-findings-item-column-total {
  padding-left: 0 !important;
  font-size: 1.0rem;
  font-weight: bold;
}

.cell-no-border {
  border-top: 0 !important;
  border-bottom: 0
}

.se-findings-tax-return-columns {
  background-color: #F5F7FA !important;
  font-size: 1.0rem;
}

.se-findings-table-items-header {
  background-color: #fff !important;
  vertical-align: bottom !important;
}

.se-findings-table-change-header {
  background-color: #fff !important;
  border-top: 0 !important;
  border-right: 0 !important;
}

.se-findings-table-sign-header {
  background-color: #fff !important;
  border-top: 0 !important;
  border-bottom: 0 !important;
}

.se-findings-table-eval-result-header {
  background-color: rgba(229,248,255,1.0) !important;
  /*background-color: rgba(35, 129, 150, 0.2) !important;*/
  font-weight: 700 !important;
  font-size: 1.1rem !important;
  color: rgba(0,0,0,0.75) !important;
}

.se-eval-result {
  background-color: rgba(229,248,255,1.0) !important;
  /*background-color: rgba(35, 129, 150, 0.2) !important;*/
  font-weight: 500;
  font-size: 1rem;
  color: black;
}

.se-eval-result-change {
  font-size: 1rem;
  color: black;
}

</style>
