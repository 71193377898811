<template>
  <div>

      <div class="row">
        <div class="col-md-6 pr-5">
          <h1 class="mt-0 se-std-bp-title-sb mb-3">Input Data: Form {{this.taxFormId}} ({{this.taxFormYear}})
            <a class="btn-link se-page-help-icon" v-tooltip.top-center="'Learn More'" @click="showPageHelp = true">
              <i class="fa fa-info-circle"></i>
            </a>
          </h1>

          <h4 class="mt-3 mb-1">For the business you want {{appName}} to evaluate, find IRS tax return form 1040 for {{this.taxFormYear}}.</h4>
          <div class="mb-2">
            Provide all requested data exactly as it appears on the tax returns.
          </div>

          <form autocomplete="off">
            <div class="bp-form-group mr-5"
                 v-for="(row, index) in tableData">

                <label :for="row.id" class="se-std-field-group-label">
                  <span>{{row.line}}</span>
                </label>
                <a class="se-help-icon btn-link" v-tooltip.top-center="'Learn More'" @click="handleFieldHelp(row.id)" v-show="row.hasHelp">
                  <i class="fa fa-info-circle"></i>
                </a>

                <div @mouseover="highlightHoveredOverFormField(row)" @mouseleave="highlightHoveredOverFormField()">
                  <radio :label="false"
                         v-model="row.isEmpty"
                         :disabled="isSkipped[index] || isLocked"
                         :class="{'opacity-5': isSkipped[index]}"
                         class="mb-1">
                    <span class="se-std-label">Enter {{row.name}}</span>
                  </radio>
                  <input :id="row.id"
                         :readonly="isLocked"
                         class="se-tax-field-input ml-4 bp-form-input bp-u-shadow-04"
                         autocomplete="off"
                         type="text"
                         v-mask="row.mask"
                         @focus="highlightFocusedFormField(row);"
                         @blur="fieldIsFocused = false;"
                         :disabled="row.isEmpty || isSkipped[index]"
                         v-model="row.value" />
                  <radio :label="true"
                         v-model="row.isEmpty"
                         :disabled="isSkipped[index] || isLocked"
                         :class="{'opacity-5': isSkipped[index]}"
                         class="mt-1">
                    <span class="se-std-label">There is no amount on this line</span>
                  </radio>
                  <div class="bp-help is-error" v-if="$v.tableData.$each[index].$error">
                    <div class="bp-ucon" data-src="feedback-warning"><svg class="bp-svg" viewBox="0 0 24 24" id="feedback-warning" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M13.009 13.987V8.934h-1.956v5.053h1.956zm0 4.016v-1.962h-1.956v1.962h1.956zM2.988 20.962L12.016 3l9.025 17.962H2.988z"></path></svg></div>
                      <template v-if="row.id === 'otherIncomeLoss' && !$v.tableData.$each[index].checkRequiredEx">Income Calculator expects an amount on this line. The tax return may not have been prepared correctly or business structure may not have been correctly identified. Use an alternative income calculation method.</template>
                      <template v-else-if="!$v.tableData.$each[index].checkHasAnswer">Please provide an answer</template>
                  </div>
                </div>
            </div>
          </form>

          <div class="bp-help is-error mb-2" v-if="$v.allAnswered.$error">
            <div class="bp-ucon" data-src="feedback-warning"><svg class="bp-svg" viewBox="0 0 24 24" id="feedback-warning" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M13.009 13.987V8.934h-1.956v5.053h1.956zm0 4.016v-1.962h-1.956v1.962h1.956zM2.988 20.962L12.016 3l9.025 17.962H2.988z"></path></svg>
            </div>
            <template v-if="!$v.allAnswered.answered">Provide answer for all items or select 'There is no amount on this line'</template>
          </div>

        </div>

        <div class="col-md-6 d-none d-sm-none d-md-block">
          <div class="std-pdf-row">
          <WebViewer :url="this.pdf"
                     ref="viewer"
                     :se-fields="seFields"
                     :tax-form-year="this.taxFormYear"
                     :tax-form-id="this.taxFormId"
                     @viewerLoaded="onViewerLoaded"
                     :key="this.pdfViewerKey"/>
          </div>
        </div>

      </div>

    <se-help :visible.sync="showPageHelp">
      <p>
        Use this screen to provide information from the borrower’s individual (personal) federal income tax returns.
      </p>
      <p>
        To ensure you are reviewing the correct returns, check that the name and social security number at the top of the tax return belong to a borrower on the loan application.
      </p>

      <p>
      <icon-base width="22" height="22" iconColor="#0c77ba"><icon-flag/></icon-base>
      There may be additional borrowers on the loan for whom you do not have tax returns.
      </p>

      <p>
      <icon-base width="22" height="22" iconColor="#0c77ba"><icon-flag/></icon-base>
      The tax returns may be filed jointly, and there is only one borrower on the loan.
      </p>

      <b>FAQs:</b>
      <p>
        Q. Should I enter zero if there is no amount on a line?<br>
        A. No. Click the radio button next to the words “There is no amount/value on this line”.
      </p>
      <p>
        Q. Am I required to provide all the tax return information requested by {{ appName }}?<br>
        A. Yes. You must provide the information or indicate that the tax returns do not have any value for the requested line.
        {{ appName }} may collect information not traditionally used in evaluating self-employment income. Some information may be used to validate
        data integrity or determine if income from other tax return forms or schedules may be needed.
      </p>
    </se-help>


    <se-help :visible.sync="showFieldHelp.totalW2Box1">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. There is no amount on this line. Is that common?<br>
          A. Yes. Many self-employed borrowers do not have Wages, salaries or tips to report on this line.
        </p>

        <p>
          Q. The tax returns contain income information from another borrower. Should I subtract the other borrower’s income when I input the tax return amounts?<br>
          A. No. You should provide all requested tax return data exactly as it appears on the tax return forms provided.
        </p>
        
        <p>
          Q. Why is this information important to {{ appName }}?<br>
          A. Some businesses pay their owners using a W-2 form, which the borrower may report as wages. {{ appName }} uses this
          information to determine what additional income data may be needed and what additional screens to display to you. You do not need to
          look at the borrower’s W-2 forms now. {{ appName }} will ask you to review and enter data from the W-2 form later.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.wagesSalaries">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. There is no amount on this line. Is that common?<br>
          A. Yes. Many self-employed borrowers do not have Wages, salaries or tips to report on this line.
        </p>

        <p>
          Q. Why is this information important to {{ appName }}?<br>
          A. Some businesses pay their owners using a W-2 form, which the borrower may report as wages. {{ appName }} uses this
          information to determine what additional income data may be needed and what additional screens to display to you. You do not need to
          look at the borrower’s W-2 forms now. {{ appName }} will ask you to review and enter data from the W-2 form later.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.otherIncomeLoss">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. There is no amount on this line. Is that common?<br>
          A. No. Except for businesses that are C-Corporations, all self-employed borrowers
          should report some amount on this line. If the business is not a C-Corporation and there is no amount on this line, the tax returns
          may have an issue or you may not be using the correct borrower’s tax returns for this evaluation. Double-check
          that the name and social security number on the tax returns correspond to a self-employed borrower on the loan application.
        </p>

        <p>
          Q. I think the tax return may have an issue. There is no amount on this line. What should I provide to
          {{ appName }}? Do I need to notify the borrower of this potential error?<br>
          A. You should provide the information to {{ appName }} exactly as it appears on the borrower’s tax returns. {{ appName }}
          will use other available information to help determine the borrower’s monthly qualifying income.
          You are not required to notify the borrower of any potential errors as part of using {{ appName }}.
          You should ensure the tax returns are complete, including all schedules and attachments.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-flag/></icon-base>
          {{ appName }} should not be relied upon for tax advice.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.businessIncomeLoss">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. There is no amount on this line. Is that normal?<br>
          A. Yes. Only borrowers who filed a Schedule C will have an amount on Line 13.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.rentalReRoyaltiesPartnerships">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. There is no amount on this line. Is that normal?<br>
          A. Yes. Only borrowers who filed a Schedule E will have an amount on Line 18.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.totalIncome">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. I’m not used to considering this information when I evaluate self-employment income. Will {{ appName }} be using this information in its monthly qualifying income calculation?<br>
          A. No. {{ appName }} will not use this information when it calculates the monthly qualifying income. {{ appName }} may collect information not traditionally used in evaluating
          self-employment income. Some information may be used to validate data integrity or determine if income from other tax return forms or schedules may be needed.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.adjustedGrossIncome">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. I’m not used to considering this information when I evaluate self-employment income. Will {{ appName }} be using this information in its monthly qualifying income calculation?<br>
          A. No. {{ appName }} will not use this information when it calculates the monthly qualifying income. {{ appName }} may collect information not traditionally used in evaluating
          self-employment income. Some information may be used to validate data integrity or determine if income from other tax return forms or schedules may be needed.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.taxableIncome">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. I’m not used to considering this information when I evaluate self-employment income. Will {{ appName }} be using this information in its monthly qualifying income calculation?<br>
          A. No. {{ appName }} will not use this information when it calculates the monthly qualifying income. {{ appName }} may collect information not traditionally used in evaluating
          self-employment income. Some information may be used to validate data integrity or determine if income from other tax return forms or schedules may be needed.
        </p>
      </div>
    </se-help>

    <se-buttons
      :display-submit="false"
      :display-save="false"
      :display-skip="this.isSkipFormBtnDisplayed"
      :display-un-skip="this.isUnSkipFormBtnDisplayed"
      :disable-submit="true"
      :disable-save="true"
      :disable-next="false"
      :disable-previous="false"
      @seNext="next"
      @sePrevious="previous"
      @seSkip="skipPyTaxForms"
      @seUnSkip="unSkipTaxYearForms">
    </se-buttons>

  </div>

</template>
<script>
import SeButtons from './SeButtons.vue'
import SeHelp from './SeHelp.vue'
import {Checkbox, Radio} from 'src/components/index'
import LSwitch from 'src/components/Switch.vue'
import Swal from 'sweetalert2'
import { Collapse, CollapseItem } from 'element-ui'
import Enums from 'src/model/enums'
import {SAVE_SE_REQUEST, SET_TAX_FORM_DATA, SKIP_YEAR_TAX_FORMS} from 'src/store/actions/se'
import WebViewer from 'src/components/WebViewerROC.vue'
import {Table, TableColumn, Drawer} from 'element-ui'
import { taxSingleFormMixin } from 'src/mixins/TaxSingleFormMixin'
import appNameMixin from '../../../mixins/AppNameMixin'

export default {
  mixins: [taxSingleFormMixin, appNameMixin],
  components: {
    Checkbox,
    Radio,
    LSwitch,
    SeButtons,
    SeHelp,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    WebViewer,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Drawer.name]: Drawer,
  },
  data() {
    return {
    }
  },
  validations: {
    tableData: {
      $each: {
        checkHasAnswer(row) {
          return this.checkHasAnswer(row)
        },
        checkRequiredEx(row) {
          // if (row.id === 'otherIncomeLoss' && this.seRequest.extraData.businessStructureProvided !== Enums.SeBusinessStructureType.corp.id) {
          //   return this.checkRequired(row) && (!row.isCurrency || (Number(row.value.replace(/,|\$|\%/g, '')) ?? 0) != 0)
          // }
          return true
        }
      }
    },
    allAnswered: {
      answered: (v) => v
    }
  },
  computed: {
    isSkipped() {
      //use map to return an array of skipped flags. one flag per field
      return this.tableData.map(f => {
        let skipped = false;
        if (this.skippedForm) return true

        if (f.id === "businessMileage") {
          if (!this.tableData.find(f => f.id === "carTruckExpenses").value) skipped = true
        } else if (f.id === "amortization") {
          if (!this.tableData.find(f => f.id === "otherExpenses").value) skipped = true
        }
        return skipped
      })
    },
  },
  methods: {
    handleHelp(index, row) {
      row.showHelp = true;
    },

    skipPyTaxForms() {
      let msgHtml = "One year of individual and business tax returns may be used ONLY if Desktop Underwriter has issued a message permitting this. Confirm the tax returns reflect at least 12 months of self-employment income."
      let msgIcon = "question"
      let documentationLevel = this.seRequest.duData?.documentationLevel
      if (["342", "355"].includes(documentationLevel)) {
        msgHtml = "Desktop Underwriter has issued a message permitting income to be supported by personal and business tax returns covering the most recent <b>one-year period</b>, provided the tax returns reflect at least 12 months of self-employment income. Confirm the tax returns reflect at least 12 months of self-employment income."

      } else if ( ["306", "308", "356"].includes(documentationLevel) ) {
        msgHtml = "WARNING: Desktop Underwriter has issued a message requiring income to be supported by personal and business tax returns covering the most recent <b>two-year</b> period. Use caution to ensure the monthly qualifying income calculated by Income Calculator is resubmitted to Desktop Underwriter AND confirm that Desktop Underwriter messaging from that resubmission permits income to be supported by personal and business tax returns covering the most recent one-year period."
        msgIcon = "warning"
      }
      msgHtml += "<br><br>Income Calculator calculates the borrower’s maximum qualifying income based on the provided tax return data. It is possible a higher qualifying income amount could be returned if two years of tax return data are provided."

      Swal.fire({
        title: `Do you want to skip ${this.taxFormYear} year tax returns?`,
        html: msgHtml,
        showCancelButton: true,
        buttonsStyling: false,
        customClass: { confirmButton: 'bp-button bp-button--primary', cancelButton: 'bp-button'},
        confirmButtonText: `Skip ${this.taxFormYear} Year`,
        cancelButtonText: `Provide ${this.taxFormYear} Year`,
        icon: msgIcon
      }).then((result) => {
        if (result.isConfirmed) {
          this.skipTaxYearForms()
        }
      })
    },
    skipTaxYearForms() {
      this.skippedForm = true
      this.$store.dispatch(SKIP_YEAR_TAX_FORMS, {year: this.taxFormYear, skipped: this.skippedForm})
      this.next()
    },
    unSkipTaxYearForms() {
      this.skippedForm = false
      this.$store.dispatch(SKIP_YEAR_TAX_FORMS, {year: this.taxFormYear, skipped: this.skippedForm})
    },

  }
}
</script>
<style >

.required > div:after {
  content:" *";
  color: red;
  text-nowrap: true
}

.std-pdf-row {
  position: fixed;
  width: 40%;
  height: 90%;

  /*height: 75vh;*/
  /*height: 1850px;*/
  /*min-height: 650px;*/
}


</style>
