<template>
  <nav class="navbar navbar-expand-lg">

      <div class="navbar-minimize">
        <button class="navbar-toggler d-none d-lg-block mb-1" @click="minimizeSidebar">
<!--          <i :class="$sidebar.isMinimized ? 'fa fa-ellipsis-v' : 'fa fa-navicon'"></i>-->
          <span class="navbar-toggler-bar burger-lines"></span>
          <span class="navbar-toggler-bar burger-lines"></span>
          <span class="navbar-toggler-bar burger-lines"></span>
        </button>
      </div>

    <a class="navbar-brand fnm-brand m-0 p-0 mr-5">
      <img class="fnm-logo" src="static/img/fnm-logo-sm.png"/>| {{appName}}
    </a>


    <div class="navbar-nav ml-auto">
      <h5 class="collapse navbar-collapse nav-ref-name text-nowrap ml-3 mr-1" v-if="seRequest.requestId">{{appName}} ID:</h5>
      <h5 class="nav-ref-name text-nowrap" v-if="seRequest.requestId">{{seRequest.requestId}}</h5>
      <h5 class="collapse navbar-collapse nav-ref-name text-nowrap" v-if="seRequest.refName">Reference Name: {{seRequest.refName}}</h5>
    </div>

    <button type="button"
            class="navbar-toggler navbar-toggler-right mb-1"
            :class="{toggled: $sidebar.showSidebar}"
            aria-expanded="false"
            @click="toggleSidebar">
      <span class="navbar-toggler-bar burger-lines"></span>
      <span class="navbar-toggler-bar burger-lines"></span>
      <span class="navbar-toggler-bar burger-lines"></span>
    </button>


  </nav>
</template>
<script>
  import Enums from "../../../model/enums";
  import {SET_REQUEST_DATA} from "../../../store/actions/se";
  import {mapState} from "vuex";
  import appNameMixin from '../../../mixins/AppNameMixin'


  export default {
    mixins: [appNameMixin],
    computed: {
      routeName () {
        const {name} = this.$route
        return this.capitalizeFirstLetter(name)
      },
      ...mapState({
        seRequest: state => state.se.request
      }),
    },
    data () {
      return {
        activeNotifications: false
      }
    },
    methods: {
      capitalizeFirstLetter (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      },
      toggleNotificationDropDown () {
        this.activeNotifications = !this.activeNotifications
      },
      closeDropDown () {
        this.activeNotifications = false
      },
      toggleSidebar () {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
      },
      hideSidebar () {
        this.$sidebar.displaySidebar(false)
      },
      minimizeSidebar () {
        this.$sidebar.toggleMinimize()
      }
    }
  }

</script>
<style>

.nav-ref-name {
  font-size: 1.25rem !important;
  font-weight: 400 !important;
  color: white;
  margin-top: 0.75rem;
  margin-right: 1rem;
}

.fnm-logo {
  height: 40px;
  margin-right: 10px;
}

.fnm-brand {
  font-size: 1.5rem !important;
  line-height: 1.75rem !important;
  font-weight: 400 !important;
}

</style>
