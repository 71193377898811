<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       :viewBox="viewBox"
       :aria-labelledby="iconName"
  >
    <title :id="iconName" lang="en">{{iconName}}</title>
    <defs>
      <path d="M8,0 C12.431891,0 16,3.56810897 16,8 C16,12.431891 12.431891,16 8,16 C3.56810897,16 0,12.431891 0,8 C0,3.56810897 3.56810897,0 8,0 Z M12.3593495,5.51576573 L11.4453096,6.42970354 L9.56996914,4.55457262 L10.484009,3.64063482 C10.6715431,3.45312173 10.9998534,3.45312173 11.1873874,3.64063482 L12.3593495,4.81246596 C12.5468835,4.99997905 12.5468835,5.32825264 12.3593495,5.51576573 Z M3.5,10.6248691 L9.03150008,5.09398696 L10.9068405,6.96911787 L5.37534042,12.5 L3.5,12.5 L3.5,10.6248691 Z" id="editing-path-1"></path>
    </defs>
    <g id="editing-V2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="editing-Tax-Forms---1040" transform="translate(-64.000000, -394.000000)">
        <g id="editing-.-Navigation/Stepper/Vertical/Sample" transform="translate(40.000000, 88.000000)">
          <g id="editing-Input-Tax-Data" transform="translate(0.000000, 280.000000)">
            <g id="editing-Icon" transform="translate(24.000000, 26.000000)">
              <mask id="editing-mask-2" fill="white">
                <use xlink:href="#editing-path-1"></use>
              </mask>
              <g id="editing-Icon/feedback-attention"></g>
              <rect id="editing-Icon-Color" fill="#0C48C0" mask="url(#editing-mask-2)" x="0" y="0" width="16" height="16"></rect>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    viewBox: { type: String, default: '0 0 24 24'},
    iconName: { type: String, default: ''},
    width: { type: [Number, String], default: 18 },
    height: {type: [Number, String], default: 18 },
    iconColor: {type: String, default: 'currentColor'}
  }
}
</script>
