<template>
  <div>

    <div class="row">
      <div class="col-md-6 pr-5">

        <h1 class="mt-0 se-std-bp-title-sb mb-3">Input Data: Form 1120 ({{this.taxFormYear}})
          <a class="btn-link se-page-help-icon" v-tooltip.top-center="'Learn More'" @click="showPageHelp = true">
            <i class="fa fa-info-circle"></i>
          </a>
        </h1>

        <form autocomplete="off">
          <div class="bp-form-group mr-5"
             v-for="(row, index) in tableData">

          <template v-if="row.id === 'amortization' && !isHidden[index]">
            <p>
              Look for an attached statement that contains a total amount of {{otherDeductionsTableData}}. Look at the descriptions of each expense in the attachment.
              Do any descriptions contain the word “Amortization” or abbreviations like “Amort” or “Amtz”?
            </p>
          </template>
          <template v-if="row.id === 'isCompensationOfOfficersIncludedAsIncome' && !isHidden[index]">
            <p>
              In most cases the borrower receives a W-2 form for this amount. When a business is owned by only one person, the business may not generate a W-2 form for its one owner. If a W-2 form is not available, {{appName}} will consider the Compensation of officers amount in its qualifying income calculation if it is determined the amount was included as income on the borrower’s individual (personal) tax return (Line 1 of Form 1040).
            </p>
          </template>


          <template v-if="!isHidden[index]">
            <template v-if="row.pageHeader">
              <h4>{{row.pageHeader}}</h4>
            </template>

            <label :for="row.id" class="se-std-field-group-label" :class="{'opacity-5': isSkipped[index]}"><span>{{row.line}}</span></label>
            <a class="se-help-icon btn-link" v-tooltip.top-center="'Learn More'" @click="handleFieldHelp(row.id)" v-show="row.hasHelp">
              <i class="fa fa-info-circle"></i>
            </a>
          </template>

          <div @mouseover="highlightHoveredOverFormField(row)" @mouseleave="highlightHoveredOverFormField()">

            <template v-if="row.id === 'taxYearEnding'">
              <radio :label="false"
                     v-model="row.isEmpty"
                     :disabled="isSkipped[index] || isLocked"
                     :class="{'opacity-5': isSkipped[index]}"
                     class="mb-1"><span class="se-std-label">Enter {{row.name}}</span>
              </radio>
              <masked-input :id="row.id"
                            class="se-tax-field-input ml-4 bp-form-input"
                            type="text"
                            @focus="highlightFocusedFormField(row)"
                            @blur="fieldIsFocused = false;"
                            :mask="[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]"
                            :guide="false"
                            :pipe="dateMaskPipe"
                            :disabled="row.isEmpty || isSkipped[index]"
                            placeholder="MM/DD/YYYY"
                            v-model="row.value" />
              <radio :label="true"
                     v-model="row.isEmpty"
                     :disabled="isSkipped[index] || isLocked"
                     :class="{'opacity-5': isSkipped[index]}"
                     class="mt-1"><span class="se-std-label">There is no date on this line</span>
              </radio>
            </template>

            <template v-else-if="row.id === 'isM3Attached' && !isHidden[index]">
              <radio label="Yes"
                     v-model="row.value"
                     :disabled="isSkipped[index] || isLocked"
                     :class="{'opacity-5': isSkipped[index]}"
                     class="mb-1"><span class="se-std-label">Yes, The checkbox for Schedule M-3 is checked</span>
              </radio>
              <radio label="No"
                     v-model="row.value"
                     :disabled="isSkipped[index] || isLocked"
                     :class="{'opacity-5': isSkipped[index]}"
                     class="mt-1"><span class="se-std-label">No, The checkbox for Schedule M-3 is not checked</span>
              </radio>
            </template>

            <template v-else-if="row.id === 'incorporatedDate'">
              <radio :label="false"
                     v-model="row.isEmpty"
                     :disabled="isSkipped[index] || isLocked"
                     :class="{'opacity-5': isSkipped[index]}"
                     class="mb-1"><span class="se-std-label">Enter {{row.name}}</span>
              </radio>
              <masked-input :id="row.id"
                     class="se-tax-field-input ml-4 bp-form-input"
                     type="text"
                     @focus="highlightFocusedFormField(row)"
                     @blur="fieldIsFocused = false;"
                     :mask="[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]"
                     :guide="false"
                     :pipe="dateMaskPipe"
                     :disabled="row.isEmpty || isSkipped[index]"
                     placeholder="MM/DD/YYYY"
                     v-model="row.value" />
              <radio :label="true"
                     v-model="row.isEmpty"
                     :disabled="isSkipped[index] || isLocked"
                     :class="{'opacity-5': isSkipped[index]}"
                     class="mt-1"><span class="se-std-label">There is no date on this line</span>
              </radio>
            </template>

            <template v-else-if="row.id === 'isFinal'">
              <radio label="Yes"
                     v-model="row.value"
                     :disabled="isSkipped[index] || isLocked"
                     :class="{'opacity-5': isSkipped[index]}"
                     class="mb-1"><span class="se-std-label">Yes, The checkbox for {{row.line}} checked</span>
              </radio>
              <radio label="No"
                     v-model="row.value"
                     :disabled="isSkipped[index] || isLocked"
                     :class="{'opacity-5': isSkipped[index]}"
                     class="mt-1"><span class="se-std-label">No, The checkbox for {{row.line}} is not checked</span>
              </radio>
            </template>

            <template v-else-if="row.id === 'isFinalReturnOverride' && !isHidden[index]">
              <radio label="Yes"
                     v-model="row.value"
                     :disabled="isSkipped[index] || isLocked"
                     :class="{'opacity-5': isSkipped[index]}"
                     class="mb-1"><span class="se-std-label">The loan file contains documentation the business is open and operating</span>
              </radio>
              <radio label="No"
                     v-model="row.value"
                     :disabled="isSkipped[index] || isLocked"
                     :class="{'opacity-5': isSkipped[index]}"
                     class="mt-1"><span class="se-std-label">This is the corporation's final return; it will no longer exist</span>
              </radio>
            </template>

            <template v-else-if="['amortization'].includes(row.id)">
            <template v-if="!isHidden[index]">
              <radio :label="false"
                     v-model="row.isEmpty"
                     :disabled="isSkipped[index] || isLocked"
                     :class="{'opacity-5': isSkipped[index]}"
                     class="mb-1"><span class="se-std-label">Enter {{row.name}}</span>
              </radio>
              <input :id="row.id"
                     :readonly="isLocked"
                     class="se-tax-field-input ml-4 bp-form-input"
                     type="text"
                     v-mask="row.mask"
                     @focus="highlightFocusedFormField(row);"
                     @blur="fieldIsFocused = false;"
                     :disabled="row.isEmpty || isSkipped[index]"
                     v-model="row.value" />
              <radio :label="true"
                     v-model="row.isEmpty"
                     :disabled="isSkipped[index] || isLocked"
                     :class="{'opacity-5': isSkipped[index]}"
                     class="mt-1"><span class="se-std-label">There is no attachment or no Amortization amount on the attachment</span>
              </radio>
            </template>
            </template>

            <template v-else-if="row.id === 'mortgagePayableLess1YrRolloverType'">
              <div class="mb-5" v-if="displayMortgagesNotesPayableLessThanOneYearSCorpExcludedAmount">
                <p class="mb-0">
                  Mortgages, notes and bonds payable in less than 1 year will be subtracted from qualifying income unless there is evidence that these obligations
                  rollover regularly or they are a line of credit. Select one of the following descriptions:
                </p>
                <br>

                <radio :label="MortgagePayableLess1YrRolloverType.all"
                       v-model="row.value"
                       :disabled="row.isEmpty || isSkipped[index] || isLocked"
                       :class="{'opacity-5': isSkipped[index]}"
                       class="mb-2"><span class="se-std-label">The loan file contains evidence that <b>all</b> mortgages, notes and bonds payable in less than 1 year rollover regularly or are a line of credit.</span>
                </radio>
                <radio :label="MortgagePayableLess1YrRolloverType.some"
                       v-model="row.value"
                       :disabled="row.isEmpty || isSkipped[index] || isLocked"
                       :class="{'opacity-5': isSkipped[index]}"
                       class="mt-2"><span class="se-std-label">The loan file contains evidence that <b>some</b> mortgages, notes and bonds payable in less than 1 year rollover regularly or are a line of credit.</span>
                </radio>
                <p class="ml-3 mt-3">
                  Of the ${{mortgagesNotesPayableLess1YrEndOfTaxYear}} of mortgages, notes and bonds payable in less than 1 year reported on page 4 of the Form 1120, Line 17(d),
                  what is the amount that does not rollover regularly or is not a line of credit?
                  <br>
                  <br>
                  Enter an amount between $0 and ${{mortgagesNotesPayableLess1YrEndOfTaxYear}}
                </p>
                <input id="nonRolloverMortgagePayableLess1YrAmount"
                       :readonly="isLocked"
                       class="se-tax-field-input ml-4 mb-2 bp-form-input"
                       type="text"
                       v-mask="currencyPositiveMask"
                       @focus="highlightFocusedFormField(row)"
                       @blur="fieldIsFocused = false"
                       :disabled="nonRolloverMortgagePayableLess1YrAmount.isEmpty || isSkipped[13]"
                       v-model="nonRolloverMortgagePayableLess1YrAmount.value" />

                <i class="ml-3">This amount will be subtracted from qualifying income.</i>

                <div class="bp-help is-error" v-if="$v.nonRolloverMortgagePayableLess1YrAmount.$error">
                  <div class="bp-ucon" data-src="feedback-warning"><svg class="bp-svg" viewBox="0 0 24 24" id="feedback-warning" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M13.009 13.987V8.934h-1.956v5.053h1.956zm0 4.016v-1.962h-1.956v1.962h1.956zM2.988 20.962L12.016 3l9.025 17.962H2.988z"></path></svg></div>
                  <template v-if="!$v.nonRolloverMortgagePayableLess1YrAmount.withinLimits">Must be between $0 and ${{mortgagesNotesPayableLess1YrEndOfTaxYear}}</template>
                </div>

                  <radio :label="MortgagePayableLess1YrRolloverType.none"
                       v-model="row.value"
                       :disabled="row.isEmpty || isSkipped[index] || isLocked"
                       :class="{'opacity-5': isSkipped[index]}"
                       class="mt-2"><span class="se-std-label">The loan file contains evidence that <b>none</b> of the mortgages, notes and bonds payable in less than 1 year rollover regularly or are a line of credit; or the loan file contains no evidence related to the rollover behavior or line of credit for mortgages, notes and bonds payable in less than 1 year.</span>
                </radio>
              </div>
            </template>

            <template v-else-if="row.id === 'nonRolloverMortgagePayableLess1YrAmount'">
            </template>

            <template v-else-if="row.id === 'isCompensationOfOfficersIncludedAsIncome' && !isHidden[index]">
              Was the Compensation of officers amount included as income in the borrower’s personal tax return?
              <radio label="Yes"
                     v-model="row.value"
                     :disabled="isSkipped[index] || isLocked"
                     :class="{'opacity-5': isSkipped[index]}"
                     class="mb-1"><span class="se-std-label">Yes</span>
              </radio>
              <radio label="No"
                     v-model="row.value"
                     :disabled="isSkipped[index] || isLocked"
                     :class="{'opacity-5': isSkipped[index]}"
                     class="mt-1"><span class="se-std-label">No</span>
              </radio>
            </template>

            <template v-else-if="!isHidden[index]">
              <radio :label="false"
                     v-model="row.isEmpty"
                     :disabled="isSkipped[index] || isLocked"
                     :class="{'opacity-5': isSkipped[index]}"
                     class="mb-1"><span class="se-std-label">Enter {{row.name}}</span>
              </radio>
              <input :id="row.id"
                     :readonly="isLocked"
                     class="se-tax-field-input ml-4 bp-form-input"
                     type="text"
                     v-mask="row.mask"
                     @focus="highlightFocusedFormField(row);"
                     @blur="fieldIsFocused = false;"
                     :disabled="row.isEmpty || isSkipped[index]"
                     v-model="row.value" />
              <radio :label="true"
                     v-model="row.isEmpty"
                     :disabled="isSkipped[index] || isLocked"
                     :class="{'opacity-5': isSkipped[index]}"
                     class="mt-1"><span class="se-std-label">There is no amount on this line</span>
              </radio>
            </template>
          </div>

        </div>
        </form>

        <div class="bp-help is-error mb-2" v-if="$v.allAnswered.$error">
          <div class="bp-ucon" data-src="feedback-warning"><svg class="bp-svg" viewBox="0 0 24 24" id="feedback-warning" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M13.009 13.987V8.934h-1.956v5.053h1.956zm0 4.016v-1.962h-1.956v1.962h1.956zM2.988 20.962L12.016 3l9.025 17.962H2.988z"></path></svg>
          </div>
          <template v-if="!$v.allAnswered.required">Provide answer for all items or select 'There is no amount on this line'</template>
        </div>

      </div>

      <div class="col-md-6 d-none d-sm-none d-md-block">
        <div class="std-pdf-row">
          <WebViewer :url="this.pdf"
                     ref="viewer"
                     :se-fields="seFields"
                     :tax-form-year="this.taxFormYear"
                     :tax-form-id="this.taxFormId"
                     @viewerLoaded="onViewerLoaded"
                     :key="this.pdfViewerKey"/>
        </div>
      </div>
    </div>

    <se-help :visible.sync="showPageHelp">
      <p>
        Use this screen to provide information from the borrower’s Form 1120, U. S. Corporation Income Tax Return.
      </p>
      <p>
        Q. I have business tax returns, but the borrower satisfies all the conditions to waive business tax returns,
        am I required to provide business tax return data to Income Calculator?<br>
        A. No. {{ appName }} will evaluate income based on the data provided.
      </p>
      <p>
        <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
        Learn more about Verification of Income (and waiving business tax returns) in the
        <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.2-01/underwriting-factors-and-documentation-self-employed-borrower">Underwriting Factors and Documentation for a Self-Employed Borrower</a>
        section of the Fannie Mae Selling Guide.
      </p>
      <p>
        <icon-base width="22" height="22" iconColor="#0c77ba"><icon-flag/></icon-base>
        {{ appName }} may collect information not traditionally used in evaluating
        self-employment income. Some data may be used to validate data integrity or determine
        if income from other tax return forms or schedules may be needed.
      </p>
    </se-help>

    <se-help :visible.sync="showFieldHelp.taxYearEnding">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. This date is used to determine if the business operates on a calendar year (January 1 to December 31)
          or a non-calendar fiscal year. Any date other than December 31 indicates the business operates on a non-calendar
          fiscal year.
          Currently {{ appName }} cannot evaluate income from a business that operates on a non-calendar fiscal year.
        </p>
        <p>
          Q. There is no date on this line of the tax returns. Is that common? Should I provide 12/31 as the date?<br>
         A. Most businesses operate on a calendar year. If there is no date on this line of the tax return this typically means the business operates 
          on a calendar year and you should select “There is no date on this line.” 
        </p>
      </div>
    </se-help>


    <se-help :visible.sync="showFieldHelp.isFinal">
      <div class="se-field-help">
        <p>When the “Final Return” checkbox is checked on the tax returns, it means the owner (borrower) will
          no longer receive income from the Corporation. If you select “Yes, the checkbox for Final Return is
          checked”, you will be given an opportunity to identify if the loan file contains documentation that the
          business is open and operating as a Corporation.</p>
        
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. The income must be stable in order to be used for loan qualification.
        </p>
        <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
        Learn more about Length of Self-Employment in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.2-01/underwriting-factors-and-documentation-self-employed-borrower">Underwriting Factors and Documentation for a Self-Employed Borrower</a> section of the Fannie Mae Selling Guide.

        <p>
          Q. The “Final Return” checkbox is checked on the Form, but the business has
          not closed and is not closing. The Corporation has changed (or is changing)
          to/from another business structure, such as an S-Corporation or a Partnership. What
          information should I provide to {{ appName }}? How do I get {{ appName }} to
          evaluate this income?<br>
          A. You should provide the information to {{ appName }} exactly as it
          appears on the borrower’s tax returns. When you select “Yes, the checkbox for Final return is checked”,
          an additional question will appear. Make the appropriate selection for this additional question. {{ appName }} cannot be used to
          evaluate income when the business structure changes during the tax period it
          is evaluating. You may use another method to evaluate the borrower’s income.
        </p>

        <p>
          Q. I think the tax return may have an issue. The “Final Return” checkbox
          is checked, but the business has not closed and is not closing. What information should I provide
          to {{ appName }}? Do I need to notify the borrower of this potential error?
          How do I get {{ appName }} to evaluate this income?<br>
          A. You should provide the information to {{ appName }} exactly as
          it appears on the borrower’s tax returns. You are not required to notify
          the borrower of any potential errors as part of using {{ appName }}. If you select “Yes, the checkbox for Final Return is checked”,
          you will be given an opportunity to identify if the loan file contains documentation that
          the business is open and operating as a Corporation.
        </p>
        
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          {{ appName }} should not be relied upon for tax advice.
        </p>

      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.isM3Attached">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. I’m not used to considering this information when I evaluate self-employment income.
          Will {{ appName }} be using this information in its monthly qualifying income calculation?<br>
         A. No. {{ appName }} will not use this information when it calculates the monthly qualifying income. 
          {{ appName }} may collect information not traditionally used in evaluating self-employment income. 
          Some information may be used to validate data integrity or determine if income from other tax return forms or schedules may be needed.
        </p>
        <p>
          Q. Why is this information important to {{ appName }}?<br>
          A. Schedule M-3 may contain Meals and entertainment expenses that need to be subtracted from the monthly qualifying income.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.incorporatedDate">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. I’m not used to considering this information when I evaluate self-employment income. 
          Will {{ appName }} be using this information in its monthly qualifying income calculation?<br>
          A. {{ appName }} will not use this information when it calculates the monthly qualifying income. 
          {{ appName }} may collect information not traditionally used in evaluating self-employment income. 
          Some information may be used to validate data integrity or determine if income from other tax return forms or schedules may be needed.
        </p>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. {{ appName }} needs to evaluate the length of time the borrower has had this income.
        </p>
        <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
        Learn more about Length of Self-Employment in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.2-01/underwriting-factors-and-documentation-self-employed-borrower">Underwriting Factors and Documentation for a Self-Employed Borrower</a> section of the Fannie Mae Selling Guide.
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.totalAssets">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. I’m not used to considering this information when I evaluate self-employment income. Will {{ appName }} be using this information in its monthly qualifying income calculation?<br>
          A. {{ appName }} does not use this amount when it calculates the monthly qualifying income. However, {{ appName }} may use this amount to validate other data input.
        </p>
        <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
        Learn more about Adjustments to Business Cash Flow in the <a target="_blank" href="https://selling-guide.fanniemae.com/Selling-Guide/Origination-thru-Closing/Subpart-B3-Underwriting-Borrowers/Chapter-B3-3-Income-Assessment/Section-B3-3-4-Self-Employment-Documentation-for-Business/1035541161/B3-3-4-03-Analyzing-Returns-for-a-Corporation-06-05-2019.htm">Analyzing Returns for a Corporation</a> section of the Fannie Mae Selling Guide.
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.grossReceiptsOrSales">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. {{ appName }} uses this data in the Comparative Income Analysis.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about Analysis of Borrower’s Business Income in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.2-01/underwriting-factors-and-documentation-self-employed-borrower">Underwriting Factors and Documentation for a Self-Employed Borrower</a> section of the Fannie Mae Selling Guide.
        </p>
        <p>
          Q. There is no amount on this line. Is that common?<br>
          A. No. Most Corporations have Gross receipts or sales to report on Line 1a. Please double-check the line entry.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.capitalGainNetIncome">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. Capital gain net income amounts will be included in the monthly qualifying income calculation, if the borrower(s) are 100% owners of the business.
        </p>

        <p>
          Q. The Fannie Mae Selling Guide allows me to document income using one year of tax returns. 
          Will {{ appName }} consider Capital gain net income amounts to be recurring if they are only documented with one year of tax returns?<br>
          A. {{ appName }} may consider Capital gain net income amounts to be recurring even if they are documented with only one year of tax returns.
        </p>
        
        <p>
          Q. Am I required to obtain supporting documentation from the borrower regarding the nature of the Capital gain net income amounts?<br>
          A. {{ appName }} may not require supporting documentation for Capital gain net income amounts.
        </p>
        
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.netGainLoss4797">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. Net gain (loss) amounts from Form 4797 will be included in the monthly qualifying income calculation, if the borrower(s) are 100% owners of the business.
        </p>

        <p>
         Q. The Fannie Mae Selling Guide allows me to document income using one year of tax returns. 
          Will {{ appName }} consider Net gain (loss) amounts from Form 4797 to be recurring if they are only documented with one year of tax returns?<br>
          A. {{ appName }} may consider Net gain (loss) amounts from Form 4797 to be recurring even if they are documented with only one year of tax returns.
        </p>

        <p>
           Q. Am I required to obtain supporting documentation from the borrower regarding the nature of the Net gain (loss) amounts from Form 4797?<br>
          A. {{ appName }} may not require supporting documentation for Net gain (loss) amounts from Form 4797.
        </p>
      </div>
    </se-help>


    <se-help :visible.sync="showFieldHelp.returnsAndAllowances">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. {{ appName }} uses this data in the Comparative Income Analysis.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about Analysis of Borrower’s Business Income in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.2-01/underwriting-factors-and-documentation-self-employed-borrower">Underwriting Factors and Documentation for a Self-Employed Borrower</a> section of the Fannie Mae Selling Guide.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.costOfGoodsSold">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. {{ appName }} uses this data in the Comparative Income Analysis.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about Analysis of Borrower’s Business Income in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.2-01/underwriting-factors-and-documentation-self-employed-borrower">Underwriting Factors and Documentation for a Self-Employed Borrower</a> section of the Fannie Mae Selling Guide.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.otherIncomeLoss">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. Other income (loss) amounts will be included in the monthly qualifying income calculation, if the borrower(s) are 100% owners of the business.
        </p>

        <p>
         Q. The Fannie Mae Selling Guide allows me to document income using one year of tax returns. 
          Will {{ appName }} consider Other income (loss) amounts to be recurring if they are only documented with one year of tax returns?<br>
          A. {{ appName }} may consider Other income (loss) amounts to be recurring even if they are documented with only one year of tax returns.
        </p>

        <p>
           Q. Am I required to obtain supporting documentation from the borrower regarding the nature of the Other income (loss) amounts?<br>
          A. {{ appName }} may not require supporting documentation for Other income (loss) amounts.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.compensationOfOfficers">
      <div class="se-field-help">
        <p>
          When no W-2 data is provided, {{ appName}} will attempt to determine if the borrower received wage income from the business. Compensation of Officers amounts for a
          one-owner business will be considered as monthly qualifying income if it can be determined the amount was included in the borrower’s individual (personal) tax returns.
        </p>
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{  appName }}?<br>
          A. The income must be included in the borrower’s individual (personal) tax returns to be used for loan qualification.
        </p>
        <p>
          Q. How do I determine if the Compensation of Officers amount was included in the borrower’s individual (personal) tax returns?<br>
          A. Ensure you have the complete personal tax return, including all schedules, forms, and attachments. In some cases, the tax return will contain an
          attachment or a statement that lists the sources of income reported on Form 1040, Line 1/1a. You may need additional documentation if the tax
          returns do not contain sufficient information to make this determination.
        </p>
        <p>
          Q. I think there may be an issue with a missing W-2 Form. Do I need to notify the borrower of this potential error? Is the borrower required to provide a W-2 Form?<br>
          A. You are not required to notify the borrower of any potential errors as part of using {{ appName }}. The recommended documentation of wage income is a W-2 Form.
          The borrower is not required to provide a W-2 Form, however Compensation of Officers amounts will not be considered as monthly qualifying income if it cannot be
          determined the amount was included in the borrower’s individual (personal) tax returns.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          {{ appName }} should not be relied upon for tax advice.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.depreciation">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. Any amount on this line will be added to the monthly qualifying income, if the borrower(s) are 100% owners of the business.
        </p>
        <p>
          Q. There is no amount on this line. Is that common?<br>
          A. Yes. Many Corporations do not have Depreciation to report.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.depletion">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. Any amount on this line will be added to the monthly qualifying income, if the borrower(s) are 100% owners of the business.
        </p>
        
        <p>
          Q. There is no amount on this line. Is that common?<br>
          A. Yes. Most Corporations do not have Depletion to report.

        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.otherDeductions">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. I’m not used to considering this information when I evaluate self-employment income. Will {{ appName }} be using this information in its monthly qualifying income calculation?<br>
          A. No. {{ appName }} will not use this information when it calculates the monthly qualifying income. 
          {{ appName }} may collect information not traditionally used in evaluating self-employment income. 
          Some information may be used to validate data integrity or determine if income from other tax return forms or schedules may be needed.
        </p>
        <p>
          Q. Why is this information important to {{ appName }}?<br>
          A. {{ appName }} uses this information to determine if income data from other tax return schedules may be needed.
        </p>
      </div>
    </se-help>
    <se-help :visible.sync="showFieldHelp.netOperatingLossDeduction">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. Any amount on this line will be added to the monthly qualifying income calculation, if the borrower(s) are 100% owners of the business.
        </p>
      </div>
    </se-help>
    <se-help :visible.sync="showFieldHelp.specialDeductions">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. Any amount on this line will be added to the monthly qualifying income calculation, if the borrower(s) are 100% owners of the business.
        </p>
      </div>
    </se-help>
    <se-help :visible.sync="showFieldHelp.taxableIncome">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. {{ appName }} uses this data in the Comparative Income Analysis.
          Any amount on this line will be added to the monthly qualifying income, if the borrower(s) are 100% owners of the business.
        </p>
        
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about Analysis of Borrower’s Business Income in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.2-01/underwriting-factors-and-documentation-self-employed-borrower">Underwriting Factors and Documentation for a Self-Employed Borrower</a> section of the Fannie Mae Selling Guide.
        </p>
        <p>
          Q. There is no amount on this line. Is that common?<br>
          A. No. Most Corporations have Taxable Income to report. Please double-check the line entry.
        </p>
        
      </div>
    </se-help>
    <se-help :visible.sync="showFieldHelp.totalTax">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. Any amount on this line will be subtracted from the monthly qualifying income, if the borrower(s) are 100% owners of the business.
        </p>

        <p>
          Q. There is no amount on this line. Is that common?<br>
          A. No. Most Corporations have Total Tax to report. Please double-check the line entry.
        </p>
      </div>
    </se-help>


    <se-help :visible.sync="showFieldHelp.totalDeductions">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. {{ appName }} uses this data in the Comparative Income Analysis.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about Analysis of Borrower’s Business Income in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.2-01/underwriting-factors-and-documentation-self-employed-borrower">Underwriting Factors and Documentation for a Self-Employed Borrower</a> section of the Fannie Mae Selling Guide.
        </p>
        <p>
          Q. There is no amount on this line. Is that common?<br>
          A. No. Most Corporations have Total deductions to report. Please double-check the line entry.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.ordinaryBusinessIncomeLoss">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. {{ appName }} uses this data as Taxable Income in the Comparative Income Analysis.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about Analysis of Borrower’s Business Income in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.2-01/underwriting-factors-and-documentation-self-employed-borrower">Underwriting Factors and Documentation for a Self-Employed Borrower</a> section of the Fannie Mae Selling Guide.
        </p>
        <p>
          Q. There is no amount on this line. Is that common?<br>
          A. No. Most Corporations have Ordinary business income (or loss) to report. Please double-check the line entry.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.amortization">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. Any Amortization amount will be added to the monthly qualifying income.
        </p>
        <p>
          Q. There is no amount on this line. Is that common?<br>
          A. Yes. Many self-employed borrowers do not have Amortization to report.
        </p>

        <p>
          Q. Why is the Amortization information grayed-out (not able to be provided)?<br>
          A. Based on the Other deductions entered on Line 19, the business tax returns do not contain an attached statement where amortization would be reported.
        </p>
        <p>
          Q. I reviewed the complete 1120S and I cannot find an attachment or statement in the tax return with the word “Amortization” or abbreviations like “Amort.” or “AMZT”. What should I do?<br>
          A. If you cannot locate an attachment or statement with amortization, select “There is no attachment or no Amortization amount on the attachment”.
        </p>
        <p>
          Q. There is an expense on an attachment or statement identified as “BLDG AMTZ”, “Standard Amort.”, “Equipment Amortization”, or similar language; should I include these in the amortization amount?<br>
          A. Yes. Some tax returns will simply identity amortization as the expense description without any other detail. Other tax returns may identify the property being amortized or provide some other kind of description. All amortization amounts should be summed and reported to {{ appName }}.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.nonDeductibleExpenses">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. {{ appName }} uses this data in the Business Liquidity Analysis and in its determination of whether the
          business has sufficient liquid assets to cover the total amount of obligations on mortgages, notes, or bonds that are payable in less than one year.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about Borrower’s Proportionate Share of Income or Loss (and business liquidity) in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.4-03/analyzing-returns-corporation">Analyzing Returns for a Corporation</a> section of the Fannie Mae Selling Guide.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about Adjustments to Business Cash Flow in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.4-03/analyzing-returns-corporation">Analyzing Returns for a Corporation</a> section of the Fannie Mae Selling Guide.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.cashEndOfTaxYear">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to Income Calculator?<br>
          A. {{ appName }} uses this data in the Business Liquidity Analysis and in its determination of whether
          the business has sufficient liquid assets to cover the total amount of obligations on mortgages, notes, or
          bonds that are payable in less than one year.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about Borrower’s Share of Income or Loss (and business liquidity) in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.4-03/analyzing-returns-corporation">Analyzing Returns for a Corporation</a> section of the Fannie Mae Selling Guide.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about Adjustments to Business Cash Flow in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.4-03/analyzing-returns-corporation">Analyzing Returns for a Corporation</a> section of the Fannie Mae Selling Guide.
        </p>

      </div>

    </se-help>

    <se-help :visible.sync="showFieldHelp.tradeNotesAndAccountsReceivableLessAllowanceEndOfTaxYear">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. {{ appName }} uses this data in the Business Liquidity Analysis.
        </p>
        <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
        Learn more about Borrower’s Proportionate Share of Income or Loss (and business liquidity) in the
        <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.4-03/analyzing-returns-corporation">Analyzing Returns for a Corporation</a> section of the Fannie Mae Selling Guide.
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.inventoriesEndOfTaxYear">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. {{ appName }} uses this data in the Business Liquidity Analysis.
        </p>
        <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
        Learn more about Borrower’s Proportionate Share of Income or Loss (and business liquidity) in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.4-03/analyzing-returns-corporation">Analyzing Returns for a Corporation</a> section of the Fannie Mae Selling Guide.
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.govObligationsEndOfTaxYear">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. {{ appName }} uses this data in the Business Liquidity Analysis.
        </p>
        <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
        Learn more about Borrower’s Proportionate Share of Income or Loss (and business liquidity) in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.4-03/analyzing-returns-corporation">Analyzing Returns for a Corporation</a> section of the Fannie Mae Selling Guide.
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.taxExemptSecuritiesEndOfTaxYear">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. {{ appName }} uses this data in the Business Liquidity Analysis.
        </p>
        <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
        Learn more about Borrower’s Proportionate Share of Income or Loss (and business liquidity) in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.4-03/analyzing-returns-corporation">Analyzing Returns for a Corporation</a> section of the Fannie Mae Selling Guide.
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.otherCurrentAssetsEndOfTaxYear">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. {{ appName }} uses this data in the Business Liquidity Analysis.
        </p>
        <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
        Learn more about Borrower’s Proportionate Share of Income or Loss (and business liquidity) in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.4-03/analyzing-returns-corporation">Analyzing Returns for a Corporation</a> section of the Fannie Mae Selling Guide.
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.accountsPayableEndOfTaxYear">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. {{ appName }} uses this data in the Business Liquidity Analysis.
        </p>
        <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
        Learn more about Borrower’s Proportionate Share of Income or Loss (and business liquidity) in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.4-03/analyzing-returns-corporation">Analyzing Returns for a Corporation</a> section of the Fannie Mae Selling Guide.
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.mortgagesNotesPayableLess1YrBeginningOfTaxYear">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. I’m not used to considering this information when I evaluate self-employment income. 
          Will {{ appName }} be using this information in its monthly qualifying income calculation?<br>
          A. No. {{ appName }} will not use this information when it calculates the monthly qualifying income. 
          {{ appName }} may collect information not traditionally used in evaluating self-employment income. 
          Some information may be used to validate data integrity or determine if income from other tax return forms or schedules may be needed.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.mortgagesNotesPayableLess1YrEndOfTaxYear">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. {{ appName }} uses this data in the Business Liquidity Analysis. In addition, mortgages, notes or bonds payable in less than 1 year (short-term debt) may be subtracted from qualifying income, based on the business’ cash position and if necessary, supplemental information provided. {{ appName }} evaluates short term debt as follows:
        </p>
        <ul>
          <li>If the business has sufficient cash (shown on Form 1120S, Schedule L, Line 1(d) to pay off the short-term debt (shown on Form 1120S, Schedule L, Line 17D), the short-term debt is not subtracted from the monthly qualifying income.</li>
          <li>If some or all of the short-term debt is a line of credit or is regularly rolled over, those amounts are not subtracted from the monthly qualifying income.</li>
          <li>If the business does not have sufficient cash to pay off the short-term debt, and the short-term debt is not a line of credit or does not regularly roll over, the short-term debt is subtracted from the monthly qualifying income.</li>
        </ul>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about Borrower’s Proportionate Share of Income or Loss (and business liquidity) in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.4-03/analyzing-returns-corporation">Analyzing Returns for a Corporation</a> section of the Fannie Mae Selling Guide.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about Adjustments to Business Cash Flow in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.4-03/analyzing-returns-corporation">Analyzing Returns for a Corporation</a> section of the Fannie Mae Selling Guide.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.otherCurrentLiabilitiesEndOfTaxYear">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. {{ appName }} uses this data in the Business Liquidity Analysis.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about Borrower’s Proportionate Share of Income or Loss (and business liquidity) in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.4-03/analyzing-returns-corporation">Analyzing Returns for a Corporation</a> section of the Fannie Mae Selling Guide.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.retainedEarningsEndOfTaxYear">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. I’m not used to considering this information when I evaluate self-employment income. 
          Will {{ appName }} be using this information in its monthly qualifying income calculation?<br>
          A. No. {{ appName }} will not use this information when it calculates the monthly qualifying income. 
          {{ appName }} may collect information not traditionally used in evaluating self-employment income. 
          Some information may be used to validate data integrity or determine if income from other tax return forms or schedules may be needed.
        </p>

      </div>
    </se-help>
    <se-help :visible.sync="showFieldHelp.retainedEarningsAppropriatedEndOfTaxYear">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. I’m not used to considering this information when I evaluate self-employment income. 
          Will {{ appName }} be using this information in its monthly qualifying income calculation?<br>
          A. No. {{ appName }} will not use this information when it calculates the monthly qualifying income. 
          {{ appName }} may collect information not traditionally used in evaluating self-employment income. 
          Some information may be used to validate data integrity or determine if income from other tax return forms or schedules may be needed.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.retainedEarningsUnappropriatedEndOfTaxYear">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. I’m not used to considering this information when I evaluate self-employment income. 
          Will {{ appName }} be using this information in its monthly qualifying income calculation?<br>
          A. No. {{ appName }} will not use this information when it calculates the monthly qualifying income. 
          {{ appName }} may collect information not traditionally used in evaluating self-employment income. 
          Some information may be used to validate data integrity or determine if income from other tax return forms or schedules may be needed.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.travelEntertainment">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. Any Travel and entertainment amount will be subtracted from the monthly qualifying income, if the borrower(s) are 100% owners of the business.

        </p>
      </div>
    </se-help>
    <se-help :visible.sync="showFieldHelp.cashDistributions">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. Cash distributions will be subtracted from the monthly qualifying income if the borrower(s) are 100% owners of the business. 
          Cash distributions include dividends paid to the corporation’s stockholders (owners). {{ appName }} does not include dividends paid by the business in its evaluation. 
          When Cash Distributions are shown on Form 1120, {{ appName }} will generate a reminder message that dividends may be used as qualifying income 
          in accordance with the Fannie Mae Selling Guide. Dividend income is reported to the IRS on Schedule B of the borrower’s individual (personal) tax returns. 
          Eligible dividend income used for loan qualification should be reported to Desktop Underwriter as dividends income.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about Other Sources of Income (including Dividends) in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.1-09/other-sources-income"> Income Assessment</a> section of the Fannie Mae Selling Guide.
        </p>


      </div>
    </se-help>



    <se-buttons
      :display-submit="false"
      :display-save="false"
      :display-waive="this.isWaiveFormBtnDisplayed"
      :display-un-waive="this.isUnWaiveFormBtnDisplayed"
      @seNext="next"
      @sePrevious="previous"
      @seWaive="waiveBusinessReturn"
      @seUnWaive="unWaiveBusinessReturn">
    </se-buttons>

  </div>

</template>
<script>
import SeButtons from './SeButtons.vue'
import SeHelp from './SeHelp.vue'
import {Checkbox, Radio} from 'src/components/index'
import LSwitch from 'src/components/Switch.vue'
import Swal from 'sweetalert2'
import { Collapse, CollapseItem } from 'element-ui'
import {mapGetters, mapState} from 'vuex'
import Enums from 'src/model/enums'
import {
  SKIP_BUSINESS_TAX_FORMS
} from 'src/store/actions/se'
import WebViewer from 'src/components/WebViewerROC.vue'
import {Table, TableColumn, Drawer} from 'element-ui'
import {required, requiredIf} from "vuelidate/lib/validators";
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'
import { taxMultiPartFormMixin } from 'src/mixins/TaxMultiPartFormMixin'
import { getTaxFormFieldValue } from 'src/util/seutil'
import {getConditionalWagesFieldName} from "@/util/seYearSpecific";
import appNameMixin from '../../../mixins/AppNameMixin'
export default {
  mixins: [taxMultiPartFormMixin, appNameMixin],
  components: {
    Checkbox,
    Radio,
    LSwitch,
    SeButtons,
    SeHelp,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    WebViewer,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Drawer.name]: Drawer
  },
  props: {
  },
  data() {
    return {
    }
  },
  validations: {
    allAnswered: {
      required: (v) => v
    },
    nonRolloverMortgagePayableLess1YrAmount: {
      withinLimits(row) {
        // console.log("nonRolloverMortgagePayableLess1YrAmount", row)
        if (!row) return true
        let numValue = Number(row.value.replace(/,|\$|\%/g, '')) ?? 0
        return 0 <= numValue && numValue <= this.mortgagesNotesPayableLess1YrEndOfTaxYear
      }
    }
  },
  mounted() {
    this.$nextTick(function () {
      //check and ask to waive business tax return if applicable
      if (this.isWaiveFormBtnDisplayed && !this.$route.query?.loc) {
        this.waiveBusinessReturn()
      }
    })
  },
  computed: {
    dateMaskPipe() {
      return createAutoCorrectedDatePipe('mm/dd/yyyy', {
        minYear: 1900,
        maxYear: parseInt(this.taxFormYear)
      })
    },

    MortgagePayableLess1YrRolloverType: () => Enums.MortgagePayableLess1YrRolloverType,

    otherDeductionsTableData() {
      return this.getValue("otherDeductions")
    },
    cashEndOfTaxYear() {
      return this.getValueAsNumber("cashEndOfTaxYear")
    },
    mortgagesNotesPayableLess1YrEndOfTaxYear() {
      return this.getValueAsNumber("mortgagesNotesPayableLess1YrEndOfTaxYear")
    },
    mortgagePayableLess1YrRolloverType() {
      return this.getValue("mortgagePayableLess1YrRolloverType")
    },
    displayMortgagesNotesPayableLessThanOneYearSCorpExcludedAmount() {
      return (this.mortgagesNotesPayableLess1YrEndOfTaxYear > 0) && (this.cashEndOfTaxYear - this.mortgagesNotesPayableLess1YrEndOfTaxYear < 0)
    },
    nonRolloverMortgagePayableLess1YrAmount() {
      return this.getField('nonRolloverMortgagePayableLess1YrAmount')
    },
    isSkipped() {
      return this.tableData.map(f => {
        if (this.skippedForm) return true
        let skipped = false;
        if (f.id === "amortization") {
          if (!this.showAmortization) skipped = true;
        }
        if (!this.allCorporationOwnersAreBorrowers && !['taxYearEnding', 'incorporatedDate', 'isFinal', 'isFinalReturnOverride'].includes(f.id)) {
          skipped = true
        }
        if (f.id === "isFinalReturnOverride") {
          skipped = "No" === this.getValue("isFinal")
        }
        if (f.id === "isCompensationOfOfficersIncludedAsIncome") {
          skipped = !this.displayIsCompensationOfOfficersIncludedAsIncome
        }
        return skipped;
      })
    },
    isHidden() {
      return this.tableData.map((f,index) => {
        if (this.isSkipped[index]) {
          return this.isSkipped[index]

        } else if (["amortization", "isCompensationOfOfficersIncludedAsIncome"].includes(f.id)) {
          return this.isSkipped[index]

        } else if ("mortgagePayableLess1YrRolloverType" === f.id) {
          return !this.displayMortgagesNotesPayableLessThanOneYearSCorpExcludedAmount

        } else if ("nonRolloverMortgagePayableLess1YrAmount" === f.id) {
          return true
        }
        return false
      })
    },
    showAmortization() {
      return this.getValueAsNumber("otherDeductions") > 0
    },
    allCorporationOwnersAreBorrowers() {
      return this.getFormFieldValue(Enums.SeTaxFormType.tf1125e, 'allCorporationOwnersAreBorrowers') === 'Yes'
        || this.getFormFieldValue(Enums.SeTaxFormType.tf1125e, 'businessOwnerCount') == '1' || this.getFormFieldValue(Enums.SeTaxFormType.tf1125e, 'corporationOwnerCount') == '1'
    },
    isWaiveFormBtnDisplayed() {
      return (this.getPY1 === this.taxFormYear
        && this.$store.getters.getBusinessIncomeIncreasingReturnWaiverIndicator
        && this.$store.getters.isFiveYearSelfEmploymentIndicator
        && !this.$store.getters.get1040SkippedPY2
        && !this.seRequest.extraData.waiveBusinessReturnsIndicator)
    },
    isUnWaiveFormBtnDisplayed() {
      return (this.getPY1 === this.taxFormYear
        && this.$store.getters.getBusinessIncomeIncreasingReturnWaiverIndicator
        && !this.$store.getters.get1040SkippedPY2
        && this.seRequest.extraData.waiveBusinessReturnsIndicator)
    },
    compensationOfOfficers() {
      return this.getValueAsNumber("compensationOfOfficers")
    },
    displayIsCompensationOfOfficersIncludedAsIncome() {
      return (this.getFormFieldValue(Enums.SeTaxFormType.tf1125e, 'businessOwnerCount') == 1) && (this.compensationOfOfficers > 0)
        && (this.getFormFieldValueAsNumber(Enums.SeTaxFormType.tf1040, getConditionalWagesFieldName(this.taxFormYear)) >= this.compensationOfOfficers
          || this.getFormFieldValueAsNumber(Enums.SeTaxFormType.tf1040sr, getConditionalWagesFieldName(this.taxFormYear)) >= this.compensationOfOfficers
          || this.getFormFieldValueAsNumber(Enums.SeTaxFormType.tf1040nr, getConditionalWagesFieldName(this.taxFormYear)) >= this.compensationOfOfficers)
        && this.getFormField(Enums.SeTaxFormType.tfw2, "w2Wages").isEmpty
        && this.getFormField(Enums.SeTaxFormType.tfw2, "w2MedicareWages").isEmpty
    },

  },
  watch: {
    showAmortization(show) {
      let f = this.getField('amortization');
      if (f) {
        if (show) {
          f.isEmpty = false
        } else {
          f.isEmpty = true
        }
      }
    },
    displayMortgagesNotesPayableLessThanOneYearSCorpExcludedAmount(display) {
      let f = this.getField('mortgagePayableLess1YrRolloverType');
      if (f) {
        if (display) {
          f.isEmpty = false
        } else {
          f.isEmpty = true
          f.value = ''
        }
      }
    },
    mortgagePayableLess1YrRolloverType(value) {
      let f = this.getField('nonRolloverMortgagePayableLess1YrAmount')
      if (f) {
        if (value === Enums.MortgagePayableLess1YrRolloverType.some) {
          f.isEmpty = false
        } else {
          f.value = ""
          f.isEmpty = true
        }
      }
    },
  },
  methods: {
    handleHelp(index, row) {
      row.showHelp = true;
    },
    waiveBusinessReturn() {
      let msgIcon = "question"
      let msgHtml = "The borrower’s individual tax returns show an increase in self-employment income over the past two years. The requirement for business tax returns may be waived if:"
        + "<p align='left'><ul>"
        + " <li align='left'>the borrower has been self-employed in the same business for at least five years, and</li>"
        + " <li align='left'>the borrower is using his or her own individual funds to pay the down payment and closing costs and satisfy applicable reserve requirements</li>"
        + "</ul></p>"

      Swal.fire({
        title: `Do you want to waive business tax returns?`,
        html: msgHtml,
        width: 800,
        showCancelButton: true,
        buttonsStyling: false,
        customClass: {confirmButton: 'bp-button bp-button--primary', cancelButton: 'bp-button'},
        confirmButtonText: `Waive Business Tax Returns`,
        cancelButtonText: `Provide Business Tax Return Data`,
        icon: msgIcon
      }).then((result) => {
        if (result.isConfirmed) {
          this.skippedForm = true
          this.$store.dispatch(SKIP_BUSINESS_TAX_FORMS, {skipped: this.skippedForm});
        }
      })
    },
    unWaiveBusinessReturn() {
      this.skippedForm = false
      this.$store.dispatch(SKIP_BUSINESS_TAX_FORMS, {skipped: this.skippedForm});
    }
  }
}
</script>
<style >

.required > div:after {
  content:" *";
  color: red;
  text-nowrap: true
}

.std-pdf-row {
  position: fixed;
  width: 40%;
  height: 90%;
}


</style>
