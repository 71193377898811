import axios from 'axios';
import { load } from 'recaptcha-v3'
import store from '../store'
import {SET_STATE_DATA} from "@/store/actions/se";

const env = [
  // {uiloc: ['localhost'], apiloc: 'http://localhost:8082/duiu', siteKey: '6LduzgcpAAAAAI49WlyGxPQJbgt5wbrILXKGaU7r'},
  {uiloc: ['localhost'], apiloc: 'https://api-ecs.duiu.devl-du.aws.fanniemae.com/duiu', siteKey: '6LduzgcpAAAAAI49WlyGxPQJbgt5wbrILXKGaU7r'},

  {uiloc: ['ui-ecs.duiu.devl-du.aws.fanniemae.com'], apiloc: 'https://api-ecs.duiu.devl-du.aws.fanniemae.com/duiu', siteKey: '6LduzgcpAAAAAI49WlyGxPQJbgt5wbrILXKGaU7r'},
  {uiloc: ['devl.duiu.du.devl.intgfanniemae.com'], apiloc: 'https://devlapi.duiu.du.devl.intgfanniemae.com/duiu', siteKey: '6LduzgcpAAAAAI49WlyGxPQJbgt5wbrILXKGaU7r', external: true},

  {uiloc: ['ui-ecs.duiu.test-du.aws.fanniemae.com'], apiloc: 'https://api-ecs.duiu.test-du.aws.fanniemae.com/duiu', siteKey: '6LduzgcpAAAAAI49WlyGxPQJbgt5wbrILXKGaU7r'},
  {uiloc: ['test.duiu.du.test.intgfanniemae.com'], apiloc: 'https://testapi.duiu.du.test.intgfanniemae.com/duiu', siteKey: '6LduzgcpAAAAAI49WlyGxPQJbgt5wbrILXKGaU7r', external: true},

  {uiloc: ['ui-ecs-alb.duiu.acpt-du.aws.fanniemae.com'], apiloc: 'https://api-ecs-alb.duiu.acpt-du.aws.fanniemae.com/duiu', siteKey: '6Ldql9QoAAAAALihpiXo8fBVrqE7bO2bwpWJY0N9'},
  {uiloc: ['ui-01-ecs.duiu.acpt-du.aws.fanniemae.com'], apiloc: 'https://api-01-ecs.duiu.acpt-du.aws.fanniemae.com/duiu', siteKey: '6Ldql9QoAAAAALihpiXo8fBVrqE7bO2bwpWJY0N9'},
  {uiloc: ['ui-02-ecs.duiu.acpt-du.aws.fanniemae.com'], apiloc: 'https://api-02-ecs.duiu.acpt-du.aws.fanniemae.com/duiu', siteKey: '6Ldql9QoAAAAALihpiXo8fBVrqE7bO2bwpWJY0N9'},
  {uiloc: ['acpt.duiu.du.acpt.intgfanniemae.com'], apiloc: 'https://acptapi.duiu.du.acpt.intgfanniemae.com/duiu', siteKey: '6Ldql9QoAAAAALihpiXo8fBVrqE7bO2bwpWJY0N9', external: true},
  {uiloc: ['incomecalculator.acptfanniemae.com'], apiloc: 'https://acptapi.duiu.du.acpt.intgfanniemae.com/duiu', siteKey: '6Ldql9QoAAAAALihpiXo8fBVrqE7bO2bwpWJY0N9', external: true},

  {uiloc: ['ui-ecs.duiu.acnt-du.aws.fanniemae.com'], apiloc: 'https://api-ecs.duiu.acnt-du.aws.fanniemae.com/duiu', siteKey: '6Ldql9QoAAAAALihpiXo8fBVrqE7bO2bwpWJY0N9'},

  {uiloc: ['ui-ecs-alb.duiu.prod-du.aws.fanniemae.com'], apiloc: 'https://api-ecs-alb.duiu.prod-du.aws.fanniemae.com/duiu', siteKey: '6Ld0mdQoAAAAAOb8wlmTxVq05Jb17sK-9On_Ylrb'},
  {uiloc: ['ui-01-ecs.duiu.prod-du.aws.fanniemae.com'], apiloc: 'https://api-01-ecs.duiu.prod-du.aws.fanniemae.com/duiu', siteKey: '6Ld0mdQoAAAAAOb8wlmTxVq05Jb17sK-9On_Ylrb'},
  {uiloc: ['ui-02-ecs.duiu.prod-du.aws.fanniemae.com'], apiloc: 'https://api-02-ecs.duiu.prod-du.aws.fanniemae.com/duiu', siteKey: '6Ld0mdQoAAAAAOb8wlmTxVq05Jb17sK-9On_Ylrb'},
  {uiloc: ['prod.duiu.du.prod.fanniemae.com'], apiloc: 'https://prodapi.duiu.du.prod.fanniemae.com/duiu', siteKey: '6Ld0mdQoAAAAAOb8wlmTxVq05Jb17sK-9On_Ylrb', external: true},
  {uiloc: ['incomecalculator.fanniemae.com'], apiloc: 'https://prodapi.duiu.du.prod.fanniemae.com/duiu', siteKey: '6Ld0mdQoAAAAAOb8wlmTxVq05Jb17sK-9On_Ylrb', external: true}
]


const setEnv = async () => {
  axios.defaults.baseURL = "localhost"
  for (let cfg of env) {
    if (hasMatchingUrl(cfg.uiloc)) {
      axios.defaults.baseURL = cfg.apiloc
      await store.commit(SET_STATE_DATA, {key: 'externalMode', value: !!cfg.external})
      await load(cfg.siteKey)
      return
    }
  }
}

const hasMatchingUrl = (matchesArr) => {
  for (let host of matchesArr) {
    if (window.location.hostname.indexOf(host) >= 0) {
      return true
    }
  }
  return false
}

const getCaptchaSiteKey = () => {
  for (let cfg of env) {
    if (hasMatchingUrl(cfg.uiloc)) {
      return cfg.siteKey
    }
  }
  return undefined
}

const saveRequest = (request) => {
    let preparedRequest = request;
    if (request.requestId && request.requestId.length > 0) {
      return axios.put(`request/${request.requestId}`, preparedRequest);
    } else {
      return axios.post('request', preparedRequest);
    }
}

const getRequest = (requestId, amount) => {
  // console.log('getRequest', requestId, amount)
    return axios.get(`request/${requestId}`,
      {
        params: {
          ...(store.state.se.externalMode ? {'amount': amount}: {})
        }
      });
}

const evaluate = (request) => {
  let preparedRequest = request;
  return axios.post('eval', preparedRequest);
}

const getPdfReport = (requestId, amount) => {
  return axios.get(`request/${requestId}/report`, {
    responseType: 'blob',
    headers: {'accept': 'application/pdf'},
    params: {
      ...(store.state.se.externalMode ? {'amount': amount}: {})
    }
  });
}

const createCaseFileAssociation = (caseFileId, requestId, zip, employmentStartDate, incomeAmount) => {
  incomeAmount = incomeAmount ?? null
  return axios.post(`casefile/${caseFileId}/${requestId}`,
    {
      headers: {'Access-Control-Allow-Origin': '*'}
    },
    {
      params: {
        'zip': zip,
        'start_date': employmentStartDate,
        'income_amount': incomeAmount
    }
  });
}

const removeCaseFileAssociation = (caseFileId, requestId) => {
  return axios.delete(`casefile/${caseFileId}/${requestId}`);
}

const getCaseFile = (duCaseFileId, zip) => {
  return axios.get(`casefile/${duCaseFileId}?zip=${zip}`);
}

const checkConversationId = () => {
  return axios.get(`conversation`);
}

axios.interceptors.request.use(async (config) => {
  const recaptcha = await load(getCaptchaSiteKey())
  const token = await recaptcha.execute('api')
  config.headers['x-fnma-recaptcha-token'] = token

  if (store.state.se.conversationId) {
    config.headers['x-conversation-id'] = store.state.se.conversationId
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});


axios.interceptors.response.use((response) => {
    return response;
  }, (error) => {
 if (error.response.status === 403) {
    router.push('/forbidden-error')
  }
  return Promise.reject(error);
})

export default { setEnv, saveRequest, getRequest, evaluate, getPdfReport, createCaseFileAssociation,
  removeCaseFileAssociation, getCaseFile, checkConversationId }
