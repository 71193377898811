import Enums from "../../../../model/enums";
import {ty} from "./ty";

export default {
  id: Enums.SeTaxFormType.tf1065,
  waivable: true,
  parts: [
    {
      part: "1",
      pdf: `static/forms/${ty}/${ty}_1065Pg1.pdf`,
      route: `/se-eval/std-tax-form-1065/${Enums.SeTaxFormType.tf1065}/${ty}/1`,
      fields: [
        {
          id: "businessActivityCode",
          fieldName: "topmostSubform[0].Page1[0].ABC[0].f1_09[0]",
          label: "Business code number",
          line: "Line C",
          isInteger: true,
          hasHelp: true,
          pageHeader: `Find Business Tax Returns for ${ty}`
        },
        {
          id: "businessStartedDate",
          fieldName: "topmostSubform[0].Page1[0].f1_11[0]",
          label: "Date business started",
          required: true,
          line: "Line E",
          hasHelp: true
        },
        {
          id: "totalAssets",
          fieldName: "topmostSubform[0].Page1[0].f1_12[0]",
          label: "Total assets",
          line: "Line F",
          isCurrency: true,
          isPositive: false,
          hasHelp: true
        },
        {
          id: "numberOfSchedulesK1",
          fieldName: "topmostSubform[0].Page1[0].f1_14[0]",
          label: "Number of K-1 Schedules",
          line: "Line I",
          isInteger: true,
          isCurrency: false,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "isSchCAndM3Attached",
          fieldName: "topmostSubform[0].Page1[0].c1_9[0]",
          label: "Check if Sch. C and M-3 attached",
          isBoolean: true,
          required: true,
          line: "Line J",
          hasHelp: true,
          defaultValue: "No"
        },
        {
          id: "grossReceiptsOrSales",
          fieldName: "topmostSubform[0].Page1[0].f1_15[0]",
          label: "Gross receipts or sales",
          line: "Line 1 (a)",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "returnsAndAllowances",
          fieldName: "topmostSubform[0].Page1[0].f1_16[0]",
          label: "Returns and allowances",
          line: "Line 1 (b)",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "costOfGoodsSold",
          fieldName: "topmostSubform[0].Page1[0].f1_18[0]",
          label: "Cost of goods sold",
          line: "Line 2",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "ordinaryIncomeLossFromOtherPartnershipsEstates",
          fieldName: "topmostSubform[0].Page1[0].f1_20[0]",
          label: "Ordinary income (loss) from other partnerships, estates, and trusts",
          line: "Line 4",
          isCurrency: true,
          isPositive: false,
          hasHelp: true
        },
        {
          id: "netFarmProfitLoss",
          fieldName: "topmostSubform[0].Page1[0].f1_21[0]",
          label: "Net farm profit (loss)",
          line: "Line 5",
          isCurrency: true,
          isPositive: false,
          hasHelp: true
        },
        {
          id: "netGainLoss4797",
          fieldName: "topmostSubform[0].Page1[0].f1_22[0]",
          label: "Net gain (loss) from Form 4797",
          line: "Line 6",
          isCurrency: true,
          isPositive: false,
          hasHelp: true
        },
        {
          id: "otherIncomeLoss",
          fieldName: "topmostSubform[0].Page1[0].f1_23[0]",
          label: "Other income (loss)",
          line: "Line 7",
          isCurrency: true,
          isPositive: false,
          hasHelp: true
        },
        {
          id: "guaranteedPaymentToPartners",
          fieldName: "topmostSubform[0].Page1[0].f1_26[0]",
          label: "Guaranteed payments to partners",
          line: "Line 10",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "depreciation",
          fieldName: "topmostSubform[0].Page1[0].f1_34[0]",
          label: "Depreciation",
          line: "Line 16c",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "depletion",
          fieldName: "topmostSubform[0].Page1[0].f1_35[0]",
          label: "Depletion",
          line: "Line 17",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "otherDeductions",
          fieldName: "topmostSubform[0].Page1[0].f1_38[0]",
          label: "Other deductions",
          line: "Line 21",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "totalDeductions",
          fieldName: "topmostSubform[0].Page1[0].f1_39[0]",
          label: "Total deductions",
          line: "Line 22",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "ordinaryBusinessIncomeLoss",
          fieldName: "topmostSubform[0].Page1[0].f1_40[0]",
          label: "Ordinary business income (loss)",
          line: "Line 23",
          isCurrency: true,
          isPositive: false,
          hasHelp: true
        },
        {
          id: "amortization",
          fieldName: "",
          label: "Amortization from attachment",
          line: "Amortization",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        }
      ]
    },
    {
      part: "2",
      pdf: `static/forms/${ty}/${ty}_1065Pg5.pdf`,
      route: `/se-eval/std-tax-form-1065/${Enums.SeTaxFormType.tf1065}/${ty}/2`,
      fields: [
        {
          id: "netRentalRealEstateIncomeLoss",
          fieldName: "topmostSubform[0].Page5[0].f5_02[0]",
          label: "Net rental real estate income (loss)",
          line: "Line 2",
          isCurrency: true,
          isPositive: false,
          hasHelp: true,
          pageHeader: "Find Schedule K of Form 1065 (page 5)"
        },
        {
          id: "nonDeductibleExpenses",
          fieldName: "topmostSubform[0].Page5[0].f5_49[0]",
          label: "Nondeductible expenses",
          line: "Line 18c",
          isCurrency: true,
          isPositive: true,
          hasHelp: true,
        }
      ]
    },
    {
      part: "3",
      pdf: `static/forms/${ty}/${ty}_1065Pg6.pdf`,
      route: `/se-eval/std-tax-form-1065/${Enums.SeTaxFormType.tf1065}/${ty}/3`,
      fields: [
        {
          id: "cashEndOfTaxYear",
          fieldName: "topmostSubform[0].Page6[0].Table_Assets[0].Line1[0].f6_17[0]",
          label: "Cash (End of tax year)",
          line: "Line 1(d)",
          isCurrency: true,
          isPositive: false,
          hasHelp: true,
          pageHeader: "Find Schedule L of Form 1065 (page 6)"
        },
        {
          id: "tradeNotesAndAccountsReceivableLessAllowanceEndOfTaxYear",
          fieldName: "topmostSubform[0].Page6[0].Table_Assets[0].Line2b[0].f6_25[0]",
          label: "Trade notes and accounts receivable less allowances (End of tax year)",
          line: "Line 2b(d)",
          isCurrency: true,
          isPositive: false,
          hasHelp: true
        },
        {
          id: "inventoriesEndOfTaxYear",
          fieldName: "topmostSubform[0].Page6[0].Table_Assets[0].Line3[0].f6_29[0]",
          label: "Inventories (End of tax year)",
          line: "Line 3(d)",
          isCurrency: true,
          isPositive: false,
          hasHelp: true
        },
        {
          id: "govObligationsEndOfTaxYear",
          fieldName: "topmostSubform[0].Page6[0].Table_Assets[0].Line4[0].f6_33[0]",
          label: "U.S. government obligations (End of tax year)",
          line: "Line 4(d)",
          isCurrency: true,
          isPositive: false,
          hasHelp: true
        },
        {
          id: "taxExemptSecuritiesEndOfTaxYear",
          fieldName: "topmostSubform[0].Page6[0].Table_Assets[0].Line5[0].f6_37[0]",
          label: "Tax-exempt securities (End of tax year)",
          line: "Line 5(d)",
          isCurrency: true,
          isPositive: false,
          hasHelp: true
        },
        {
          id: "otherCurrentAssetsEndOfTaxYear",
          fieldName: "topmostSubform[0].Page6[0].Table_Assets[0].Line6[0].f6_41[0]",
          label: "Other current assets (End of tax year)",
          line: "Line 6(d)",
          isCurrency: true,
          isPositive: false,
          hasHelp: true
        },
        {
          id: "accountsPayableEndOfTaxYear",
          fieldName: "topmostSubform[0].Page6[0].Table_Liabilities[0].Line15[0].f6_93[0]",
          label: "Accounts payable (End of tax year)",
          line: "Line 15(d)",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "mortgagesNotesPayableLess1YrBeginningOfTaxYear",
          fieldName: "topmostSubform[0].Page6[0].Table_Liabilities[0].Line16[0].f6_95[0]",
          label: "Mortgages, notes, bonds payable in less than 1 year (Beginning of tax year)",
          line: "Line 16(b)",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "mortgagesNotesPayableLess1YrEndOfTaxYear",
          fieldName: "topmostSubform[0].Page6[0].Table_Liabilities[0].Line16[0].f6_97[0]",
          label: "Mortgages, notes, bonds payable in less than 1 year (End of tax year)",
          line: "Line 16(d)",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "otherCurrentLiabilitiesEndOfTaxYear",
          fieldName: "topmostSubform[0].Page6[0].Table_Liabilities[0].Line17[0].f6_101[0]",
          label: "Other current liabilities (End of tax year)",
          line: "Line 17(d)",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "mortgagePayableLess1YrRolloverType",
          fieldName: "",
          label: "Amount that does not rollover regularly or is not a lone of credit",
          line: "",
          radio: true,
          isEmptyByDefault: true
        },
        {
          id: "nonRolloverMortgagePayableLess1YrAmount",
          fieldName: "",
          label: "Amount that does not rollover regularly or is not a loan of credit",
          line: "",
          isCurrency: true,
          isPositive: true,
          isEmptyByDefault: true
        },
        {
          id: "travelEntertainment",
          fieldName: "topmostSubform[0].Page6[0].SchM-1_Left[0].f6_131[0]",
          label: "Travel and entertainment",
          line: "Line 4b",
          isCurrency: true,
          isPositive: true,
          hasHelp: true,
          pageHeader: "Find Schedule M-1 of Form 1065 (page 6)"
        },
        {
          id: "capAccountsBalanceEndOfTaxYear",
          fieldName: "topmostSubform[0].Page6[0].SchM-2_Right[0].f6_155[0]",
          label: "Balance at the end of year",
          line: "Line 9",
          isCurrency: true,
          isPositive: false,
          hasHelp: true,
          pageHeader: "Find Schedule M-2 of Form 1065 (page 6)"
        }
      ]
    }
  ]
}

