<template>
  <div>

      <div class="row">
        <div class="col-md-6 pr-5">

          <template v-if="businessStructureEvaluated === Enums.SeBusinessStructureType.soleProprietorship" >
            <h1 class="mt-0 se-std-bp-title-sb mb-3">Input Data: W-2 ({{this.taxFormYear}})
              <a class="btn-link se-page-help-icon" v-tooltip.top-center="'Learn Mores'" @click="showPageHelp = true">
                <i class="fa fa-info-circle"></i>
              </a>
            </h1>
            <p>
              While it is not common, some borrowers report wages from their schedule C business to the IRS using a W-2 form.
            </p>
            <p>
              Because wages were shown on both the 1040 and on Schedule C, it is possible that the borrower pays themself wages reported on a W-2.
            </p>
            <p>
              Compare the information on the W-2 form(s) to the information on the Schedule C for the borrower and business you want {{appName}} to evaluate.
            </p>

            <p>
              <img src="static/img/w2sc.png"/>
            </p>

            <p>
              If the W-2 and Schedule C data matches, report the borrower’s W-2 income below.
            </p>
          </template>
          <template v-else-if="businessStructureEvaluated === Enums.SeBusinessStructureType.sCorp">
            <h1 class="mt-0 se-std-bp-title-sb mb-3">Input Data: W-2 S-Corp ({{this.taxFormYear}})
              <a class="btn-link se-page-help-icon" v-tooltip.top-center="'Learn More'" @click="showPageHelp = true">
                <i class="fa fa-info-circle"></i>
              </a>
            </h1>
            <p>
              Some borrowers report wages from their S-Corporation business to the IRS using a W-2 form.
            </p>
            <p>
              Compare the information on the W-2 form(s) to the information on the Form 1120S for the borrower and business you want {{appName}} to evaluate.
            </p>
            <p>
              <img  src="static/img/w2scorp.png"/>
            </p>
          </template>
          <template v-else-if="businessStructureEvaluated === Enums.SeBusinessStructureType.partnership">
            <h1 class="mt-0 se-std-bp-title-sb mb-3">Input Data: W-2 Partnership ({{this.taxFormYear}})
              <a class="btn-link se-page-help-icon" v-tooltip.top-center="'Learn More'" @click="showPageHelp=true">
                <i class="fa fa-info-circle"></i>
              </a>
            </h1>
            <p>
              While it is not common, some borrowers report wages from their Partnership business to the IRS using a W-2 form.
            </p>
            <p>
              Compare the information on the W-2 form(s) to the information on the Form 1065 for the borrower and business you want {{appName}} to evaluate.
            </p>
            <p>
              <img  src="static/img/w2partnership.png"/>
            </p>
          </template>
          <template v-else-if="businessStructureEvaluated === Enums.SeBusinessStructureType.corp">
            <h1 class="mt-0 se-std-bp-title-sb mb-3">Input Data: W-2 Corporation ({{this.taxFormYear}})
              <a class="btn-link se-page-help-icon" v-tooltip.top-center="'Learn More'" @click="showPageHelp = true">
                <i class="fa fa-info-circle"></i>
              </a>
            </h1>
            <p>
              Some borrowers report wages from their Corporation business to the IRS using a W-2 form.
            </p>
            <p>
              Compare the information on the W-2 form(s) to the information on the Form 1120 for the borrower and business you want {{appName}} to evaluate.
            </p>
            <p>
              <img  src="static/img/w2corp.png"/>
            </p>
          </template>
          <p>
            Do not report W-2 income from another borrower.
          </p>
          <p>
            If no W-2 Form was provided by the borrower for this employment, do not provide any amounts. Select "There is no matching W-2 or no amount on Line 1 / Line 5" and  Click the Continue button.
          </p>

          <form autocomplete="off">
            <div class="bp-form-group mr-5"
                 v-for="(row, index) in tableData">

                <label :for="row.id" class="se-std-field-group-label">
                  <span>{{row.line}}</span>
                </label>
                <a class="se-help-icon btn-link" v-tooltip.top-center="'Learn More'" @click="handleFieldHelp(row.id)" v-show="row.hasHelp">
                  <i class="fa fa-info-circle"></i>
                </a>

                <div @mouseover="highlightHoveredOverFormField(row)" @mouseleave="highlightHoveredOverFormField()">
                  <radio :label="false"
                         v-model="row.isEmpty"
                         :disabled="isSkipped[index] || isLocked"
                         :class="{'opacity-5': isSkipped[index]}"
                         class="mb-1">
                    <span class="se-std-label">Enter {{row.name}}</span>
                  </radio>
                  <input :id="row.id"
                         :readonly="isLocked"
                         class="se-tax-field-input ml-4 bp-form-input bp-u-shadow-04"
                         type="text"
                         v-mask="row.mask"
                         @focus="highlightFocusedFormField(row)"
                         @blur="fieldIsFocused = false"
                         :disabled="row.isEmpty || isSkipped[index]"
                         v-model="row.value" />
                  <radio :label="true"
                         v-model="row.isEmpty"
                         :disabled="isSkipped[index] || isLocked"
                         :class="{'opacity-5': isSkipped[index]}"
                         class="mt-1"><span class="se-std-label">There is no matching W-2 or no amount on {{row.line}}</span>
                  </radio>
                </div>
            </div>
          </form>

          <div class="bp-help is-error mb-2" v-if="$v.allAnswered.$error">
            <div class="bp-ucon" data-src="feedback-warning"><svg class="bp-svg" viewBox="0 0 24 24" id="feedback-warning" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M13.009 13.987V8.934h-1.956v5.053h1.956zm0 4.016v-1.962h-1.956v1.962h1.956zM2.988 20.962L12.016 3l9.025 17.962H2.988z"></path></svg>
            </div>
            <template v-if="!$v.allAnswered.required">Provide answer for all items or select 'There is no amount on this line'</template>
          </div>

        </div>

        <div class="col-md-6 d-none d-sm-none d-md-block">
          <div class="std-pdf-row">
          <WebViewer :url="this.pdf"
                     ref="viewer"
                     :se-fields="seFields"
                     :tax-form-year="this.taxFormYear"
                     :tax-form-id="this.taxFormId"
                     @viewerLoaded="onViewerLoaded"
                     :key="this.pdfViewerKey"/>
          </div>
        </div>

      </div>

    <template v-if="businessStructureEvaluated === Enums.SeBusinessStructureType.partnership" >
      <se-help :visible.sync="showPageHelp">
        <p>
          Use this screen to provide information from the W-2, Wage and Tax Statement. {{appName}} will display Form W-2 for a
          Partnership when there are Wages, Salaries and Tips on Line 1/1a of Form 1040.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-flag/></icon-base>
          Some self-employed borrowers whose business is structured as a Partnership pay themselves wage income. 
          This may be an indication that the Partnership has changed (or is changing) to/from another business structure, such as a Corporation or S-Corporation. 
          {{appName}} cannot be used to evaluate income when the business structure changes during the tax period it is evaluating. 
          If the business structure is not changing, and the borrower has wage income, consider requesting W-2 Form(s) to ensure wage income is included in the evaluation.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-flag/></icon-base>
          Carefully compare the names, social security numbers and employer identification numbers from the W-2 to the business returns 
          to ensure the W-2 income should be provided to {{appName}}.
        </p>
      </se-help>

      <se-help :visible.sync="showFieldHelp.w2Wages">
        <div class="se-field-help">
          <b>FAQs:</b>
          <p>
            Q. The amount in Box 1 is more than the amount in Box 5. Can the Box 1 amount be used for qualifying?<br>
            A. No. The amount in Box 1 may include adjustments that affect the borrower's taxable income, for example deferred employee contributions 
            to a section 401(k) or 403(b) plan, insurance premiums paid by the business, or certain employee business expense reimbursements that the 
            IRS considers compensation for tax purposes, but are not income paid to the borrower. {{appName}} will use the amount in Box 5 in its evaluation. 
          </p>
        </div>
      </se-help>
      <se-help :visible.sync="showFieldHelp.w2MedicareWages">
        <div class="se-field-help">
          <b>FAQs:</b>
          <p>
            Q. Why is this important to {{ appName }}?<br>
            A. Any amount on this line will be added to the monthly qualifying income calculation.
          </p>
        </div>
      </se-help>
    </template>


    <template v-if="businessStructureEvaluated === Enums.SeBusinessStructureType.soleProprietorship" >
      <se-help :visible.sync="showPageHelp">
        <p>
          Use this screen to provide information from the W-2, Wage and Tax Statement. {{ appName }} will display Form W-2 for a Sole Proprietorship when there are Wages, Salaries and Tips on Line 1 of Form 1040 and there are Wages on Line 26 of Schedule C.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-flag/></icon-base>
          Most self-employed borrowers whose business is structured as a Sole Proprietorship do not pay themselves using a W-2 form. The recommended
          documentation of wage income is a W-2 Form. If the borrower has wage income, consider requesting W-2 Form(s) to ensure wage income is included in the evaluation.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-flag/></icon-base>
          Carefully compare the names, social security numbers and employer identification numbers from the W-2 to the Schedule C to ensure the
          W-2 income should be provided to {{ appName }}.
        </p>
      </se-help>

      <se-help :visible.sync="showFieldHelp.w2Wages">
        <div class="se-field-help">
          <b>FAQs:</b>
          <p>
            Q. There are W-2s included with the tax returns, but they are for other employments or other borrowers, not for this borrower’s employment. Is that common?<br>
            A. Yes. Tax returns may contain W-2s from sources other than the business being evaluated by {{ appName }}. 
            Most sole proprietors do not pay themselves wages that are reported to the IRS on a W-2.
          </p>
          <p>
           Q. The amount in Box 1 is more than the amount in Box 5. Can the Box 1 amount be used for qualifying?<br>
           A. No. The amount in Box 1 may include adjustments that affect the borrower's taxable income, for example
            deferred employee contributions to a section 401(k) or 403(b) plan, insurance premiums paid by the business,
            or certain employee business expense reimbursements that the IRS considers compensation for tax purposes, but
            are not income paid to the borrower. {{  appName  }} will use the amount in Box 5 in its evaluation.
          </p>
        </div>
      </se-help>
      <se-help :visible.sync="showFieldHelp.w2MedicareWages">
        <div class="se-field-help">
          <b>FAQs:</b>
          <p>
            Q. Why is this important to {{ appName }}?<br>
            A. Any amount on this line will be added to the monthly qualifying income calculation.
          </p>
          <p>
            Q. There are W-2s included with the tax returns, but they are for other employments or other borrowers, not for this borrower’s employment. Is that common?<br>
            A. Yes. Tax returns may contain W-2s from sources other than the business being evaluated by {{ appName }}. 
            Most sole proprietors do not pay themselves wages that are reported to the IRS on a W-2.
          </p>
        </div>
      </se-help>
    </template>
    <template v-else-if="businessStructureEvaluated === Enums.SeBusinessStructureType.sCorp">
      <se-help :visible.sync="showPageHelp">
        <p>
          Use this screen to provide information from the W-2, Wage and Tax Statement. {{ appName }} will display Form W-2 for an S-Corporation when
          there are Wages, Salaries and Tips on Line 1/1a of Form 1040.
        </p>
        <p>
          Some self-employed borrowers whose business is structured as an S-Corporation pay themselves wage income. The recommended documentation
          of wage income is a W-2 Form. Some S-Corporations, especially those with fewer than five shareholders (owners) may not issue W-2 Forms.
          {{ appName }} will attempt to use other tax return data to determine the borrower’s wage income without requiring a W-2 Form.
          If the borrower has wage income, consider requesting W-2 Form(s) to ensure wage income is included in the evaluation.
        </p>
        <p>
          Carefully compare the names, social security numbers and employer identification numbers from the W-2 to the business
          returns to ensure the W-2 income should be provided to {{ appName }}.
        </p>
      </se-help>

      <se-help :visible.sync="showFieldHelp.w2Wages">
        <div class="se-field-help">
          <b>FAQs:</b>
          <p>
           Q. The amount in Box 1 is more than the amount in Box 5. Can the Box 1 amount be used for qualifying?<br>
            A. No. The amount in Box 1 may include adjustments that affect the borrower's taxable income, for example
            deferred employee contributions to a section 401(k) or 403(b) plan, insurance premiums paid by the business,
            or certain employee business expense reimbursements that the IRS considers compensation for tax purposes, but
            are not income paid to the borrower. {{  appName  }} will use the amount in Box 5 in its evaluation.
          </p>
        </div>
      </se-help>
      <se-help :visible.sync="showFieldHelp.w2MedicareWages">
        <div class="se-field-help">
          <b>FAQs:</b>
          <p>
            Q. Why is this important to {{ appName }}?<br>
            A. Any amount on this line will be added to the monthly qualifying income calculation.
          </p>
        </div>
      </se-help>
    </template>
    <template v-else-if="businessStructureEvaluated === Enums.SeBusinessStructureType.corp">
      <se-help :visible.sync="showPageHelp">
        <p>
          Use this screen to provide information from the W-2, Wage and Tax Statement. {{ appName }} will display
          Form W-2 for all Corporations.
        </p>
        <p>
          Some self-employed borrowers whose business is structured as a Corporation pay themselves wage income.
          The recommended documentation of wage income is a W-2 Form. Some S-Corporations, especially those with fewer than five shareholders (owners) 
          may not issue W-2 Forms. {{ appName }} will attempt to use other tax return data to determine the borrower’s wage income without requiring a W-2 Form. 
          If the borrower has wage income, consider requesting W-2 Form(s) to ensure wage income is included in the evaluation.
        </p>
        <p>
          Carefully compare the names, social security numbers and employer identification numbers from the W-2 to the business returns
          to ensure the W-2 income should be provided to {{ appName }}.
        </p>
      </se-help>

      <se-help :visible.sync="showFieldHelp.w2Wages">
        <div class="se-field-help">
          <b>FAQs:</b>
          <p>
            Q. The amount in Box 1 is more than the amount in Box 5. Can the Box 1 amount be used for qualifying?<br>
            A. No. The amount in Box 1 may include adjustments that affect the borrower's taxable income, for example
            deferred employee contributions to a section 401(k) or 403(b) plan, insurance premiums paid by the business,
            or certain employee business expense reimbursements that the IRS considers compensation for tax purposes, but
            are not income paid to the borrower. {{ appName }} will use the amount in Box 5 in its evaluation.
          </p>
        </div>
      </se-help>
      <se-help :visible.sync="showFieldHelp.w2MedicareWages">
        <div class="se-field-help">
          <b>FAQs:</b>
          <p>
            Q. Why is this important to {{ appName }}?<br>
            A. Any amount on this line will be added to the monthly qualifying income calculation.
          </p>
          <p>
            Q. None of the W-2s included with the tax return are for this borrower’s employment. Is that common?<br>
            A. No. When a corporation pays wages to a stockholder/shareholder, the corporation typically reports the wages to the IRS on a W-2. Please double-check the line entry.
          </p>
        </div>
      </se-help>
    </template>

    <se-buttons
      :display-submit="false"
      :display-save="false"
      :disable-submit="true"
      :disable-save="true"
      :disable-next="false"
      :disable-previous="false"
      @seNext="next"
      @sePrevious="previous">
    </se-buttons>

  </div>

</template>
<script>
import SeButtons from './SeButtons.vue'
import SeHelp from './SeHelp.vue'
import {Checkbox, Radio} from 'src/components/index'
import LSwitch from 'src/components/Switch.vue'
import { Collapse, CollapseItem } from 'element-ui'
import WebViewer from 'src/components/WebViewerROC.vue'
import {Table, TableColumn, Drawer} from 'element-ui'
import {required, requiredIf} from "vuelidate/lib/validators";
import { taxSingleFormMixin } from 'src/mixins/TaxSingleFormMixin'
import appNameMixin from '../../../mixins/AppNameMixin'
import Enums from 'src/model/enums'
export default {
  mixins: [taxSingleFormMixin, appNameMixin],
  components: {
    Checkbox,
    Radio,
    LSwitch,
    SeButtons,
    SeHelp,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    WebViewer,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Drawer.name]: Drawer,
  },
  data() {
    return {
    }
  },
  validations: {
    allAnswered: {
      required: (v) => v
    }
  },
  computed: {
    isSkipped() {
      return this.tableData.map(f => {
        if (this.skippedForm) return true

        return false;
      })
    },
    businessStructureEvaluated() {
      return this.$store.getters.getBusinessStructureEvaluatedAndDerived.evaluated
    }
  },
  methods: {
    isEmptyByDefault(field) {
      if (this.businessStructureEvaluated === Enums.SeBusinessStructureType.sCorp
        || this.businessStructureEvaluated === Enums.SeBusinessStructureType.corp) {
        return false
      }
      return true
    },
    handleHelp(index, row) {
      row.showHelp = true;
    },
  }
}
</script>
<style >

.required > div:after {
  content:" *";
  color: red;
  text-nowrap: true
}

.std-pdf-row {
  position: fixed;
  width: 40%;
  height: 90%;
}


</style>
