import Enums from "../../../../model/enums";
import {ty} from "./ty";

export default {
  id: Enums.SeTaxFormType.tf1040se,
  pdf: `static/forms/${ty}/${ty}_1040se.pdf`,
  route: `/se-eval/std-tax-form-1040se/${Enums.SeTaxFormType.tf1040se}/${ty}`,
  fields: [
    {
      id: "pOrS",
      fieldName: "topmostSubform[0].Page2[0].Table_Line28a-e[0].RowA[0].f2_4[0]",
      label: "Enter P for partnership, S for S corporation",
      line: "Line 28(b)",
      radio: true,
      isCurrency: false,
      required: true,
      hasHelp: true
    }
  ]
}

