import Enums from "../../../../model/enums";
import {ty} from "./ty";

export default {
  id: Enums.SeTaxFormType.tf1040nr,
  pdf: `static/forms/${ty}/${ty}_1040nr.pdf`,
  route: `/se-eval/std-tax-form-1040/${Enums.SeTaxFormType.tf1040nr}/${ty}`,
  fields: [
    {
      id: "wagesSalaries",
      fieldName: "topmostSubform[0].Page1[0].f1_25[0]",
      label: "Wages, Salaries, Tips, etc.",
      line: "Line 1a",
      isCurrency: true,
      isPositive: true,
      hasHelp: true
    },
    {
      id: "otherIncomeLoss",
      fieldName: "topmostSubform[0].Page1[0].f1_38[0]",
      label: "Other Income Schedule 1",
      line: "Line 8",
      isCurrency: true,
      isPositive: false,
      required: true,
      hasHelp: true
    },
    {
      id: "totalIncome",
      fieldName: "topmostSubform[0].Page1[0].f1_39[0]",
      label: "Total Effectively Connected Income ",
      line: "Line 9",
      isCurrency: true,
      isPositive: false,
      hasHelp: true
    },
    {
      id: "adjustedGrossIncome",
      fieldName: "topmostSubform[0].Page1[0].f1_44[0]",
      label: "Adjusted Gross Income",
      line: "Line 11",
      isCurrency: true,
      isPositive: false,
      hasHelp: true
    },
    {
      id: "taxableIncome",
      fieldName: "topmostSubform[0].Page1[0].f1_50[0]",
      label: "Taxable Income",
      line: "Line 15",
      isCurrency: true,
      isPositive: false,
      hasHelp: true
    }
  ]
}
