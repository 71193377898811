import Enums from "../../../../model/enums";
import {ty} from "./ty";

export default {
  id: Enums.SeTaxFormType.tf1040sr,
  pdf: `static/forms/${ty}/${ty}_1040sr.pdf`,
  route: `/se-eval/std-tax-form-1040/${Enums.SeTaxFormType.tf1040sr}/${ty}`,
  fields: [
    {
      id: "totalW2Box1",
      fieldName: "topmostSubform[0].Page1[0].f1_31[0]",
      label: "Total amount from Form(s) W-2, box 1",
      line: "Line 1a",
      isCurrency: true,
      isPositive: true,
      hasHelp: true
    },
    {
      id: "wagesSalaries",
      fieldName: "topmostSubform[0].Page1[0].f1_40[0]",
      label: "Wages, Salaries, Tips, etc. (add lines 1a-1h)",
      line: "Line 1z",
      isCurrency: true,
      isPositive: true,
      hasHelp: true
    },
    {
      id: "otherIncomeLoss",
      fieldName: "topmostSubform[0].Page2[0].f2_02[0]",
      label: "Other Income Schedule 1",
      line: "Line 8",
      isCurrency: true,
      isPositive: false,
      required: true,
      hasHelp: true
    },
    {
      id: "totalIncome",
      fieldName: "topmostSubform[0].Page2[0].f2_03[0]",
      label: "Total Income",
      line: "Line 9",
      isCurrency: true,
      isPositive: false,
      hasHelp: true
    },
    {
      id: "adjustedGrossIncome",
      fieldName: "topmostSubform[0].Page2[0].f2_05[0]",
      label: "Adjusted Gross Income",
      line: "Line 11",
      isCurrency: true,
      isPositive: false,
      hasHelp: true
    },
    {
      id: "taxableIncome",
      fieldName: "topmostSubform[0].Page2[0].f2_09[0]",
      label: "Taxable Income",
      line: "Line 15",
      isCurrency: true,
      isPositive: false,
      hasHelp: true
    }
  ]
}
