<template>

    <el-drawer
      title=""
      :visible="this.visible"
      @close="hide"
      custom-class="se-help"
      :size="size"
      direction="rtl"
    >
      <div slot="title">
        <slot name="header">
          <span>Learn More</span><br>
          <span style="font-size:.85rem">Send questions to Income_Calculator@FannieMae.com</span>
        </slot>
      </div>

      <div class="ml-3 mr-3">
        <slot></slot>
      </div>
    </el-drawer>

</template>
<script>
import {Drawer} from 'element-ui'

export default {
  components: {
    [Drawer.name]: Drawer,
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: "Learn More"
    },
    size: {
      type: String,
      default: "40%"
    }
  },
  data() {
    return {
      vis: this.visible
      }
  },
  watch: {
    visible(val) {
      if (val) {
        this.$emit('open');
      } else {
        if (!this.closed) this.$emit('close');
      }
    }
  },
  methods: {
    hide() {
      this.$emit('update:visible', false);
    }
  }
}
</script>
<style>

.se-help > header {
  color: white;
  background: #085280;
  padding-bottom: 1rem;
}

.el-drawer__header {
  padding:10px 10px 10px 15px!important;

}

</style>
