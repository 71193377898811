import Enums from "../../../../model/enums";
import {ty} from "./ty";

export default {
  id: Enums.SeTaxFormType.tf1125e,
  pdf: `static/forms/${ty}/${ty}_1125e.pdf`,
  route: `/se-eval/std-tax-form-1125e/${Enums.SeTaxFormType.tf1125e}/${ty}`,
  fields: [
    {
      id: "form1125eIncluded",
      fieldName: "",
      label: "Form 1125-E is included",
      line: "",
      isBoolean: true,
      required: true,
    },
    {
      id: "businessOwnerCount",
      fieldName: "topmostSubform[0].Page1[0].Line1Table[0].BodyRow1[0].f1_3[0]",
      fieldHighlights: [
        "topmostSubform[0].Page1[0].Line1Table[0].BodyRow1[0].f1_3[0]",
        "topmostSubform[0].Page1[0].Line1Table[0].BodyRow2[0].f1_9[0]",
        "topmostSubform[0].Page1[0].Line1Table[0].BodyRow3[0].f1_15[0]"
      ],
      label: "How many officers are named in column A?",
      line: "",
      isInteger: true,
      isCurrency: false,
      isPositive: true,
      hasHelp: true
    },
    {
      id: "commonStockOwnershipPercent",
      fieldName: "topmostSubform[0].Page1[0].Line1Table[0].BodyRow1[0].f1_6[0]",
      label: "Percent of common stock owned by the borrower",
      line: "Line (d)",
      isCurrency: false,
      isPositive: true,
      isPercent: true,
      isEmptyByDefault: true,
      hasHelp: true
    },
    {
      id: "corporationOwnershipInterestGte25Pct",
      fieldName: "",
      label: "Did the borrower own 25% or more of the corporation during this tax year? ",
      line: "Did the borrower own 25% or more of the corporation during this tax year? ",
      isBoolean: true,
      defaultValue: "Yes",
      hasHelp: true
    },
    {
      id: "allCorporationOwnersAreBorrowers",
      fieldName: "",
      label: "Are all officers named in column A borrowers on the loan?",
      line: "Are all officers named in column A borrowers on the loan?",
      isBoolean: true,
      defaultValue: "No",
      hasHelp: true
    },
    {
      id: "corporationOwnerCount",
      fieldName: "",
      label: "Total number of corporation owners",
      line: "",
      isInteger: true,
      isCurrency: false,
      isEmptyByDefault: true
    },
    {
      id: "compensationOfOfficers",
      fieldName: "topmostSubform[0].Page1[0].Line1Table[0].BodyRow1[0].f1_8[0]",
      label: "Amount of compensation for the borrower",
      line: "Line (f)",
      isCurrency: true,
      isPositive: false,
      hasHelp: true
    },
    {
      id: "isCompensationOfOfficersIncludedAsIncome",
      fieldName: "",
      label: "Was the Compensation of officers amount included as income in the borrower’s personal tax return?",
      line: "Compensation of officers",
      isBoolean: true,
      hasHelp: true
    }

  ]
}

