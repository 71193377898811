import Enums from "../../../../model/enums";
import {ty} from "./ty";

export default {
  id: Enums.SeTaxFormType.tf8825,
  waivable: true,
  pdf: `static/forms/${ty}/${ty}_8825.pdf`,
  route: `/se-eval/std-tax-form-8825/${Enums.SeTaxFormType.tf8825}/${ty}`,
  fields: [
    {
      id: "depreciation",
      fieldName: "topmostSubform[0].Page1[0].Table_Lines2-17[0].Line14[0].#subform[0].f1_131[0]",
      label: "Depreciation - add the depreciation amounts from Line 14, columns A, B, C, and D.  If there is an amount on Line 14 column D, add the depreciation amounts from Line 14, columns E, F, G, and H shown on Page 2.",
      line: "Line 14",
      radio: true,
      isCurrency: true,
      isPositive: true,
      required: true,
      hasHelp: true
    }
  ]
}

