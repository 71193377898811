<template>
  <div>

      <div class="row">

        <div class="col-md-6 pr-5">
          <h1 class="mt-0 se-std-bp-title-sb mb-3">Input Data: Form 8825 ({{this.taxFormYear}})
            <a class="btn-link se-page-help-icon" v-tooltip.top-center="'Learn More'" @click="showPageHelp = true">
              <i class="fa fa-info-circle"></i>
            </a>
          </h1>

          <p>While it is not common, some borrowers report property depreciation on Form 8825.
          </p>

          <p>Some borrowers have more than one business and will file more than one Form 8825.
            To ensure you are reviewing the correct Form 8825, check that the <b>Employer Identification Number</b>
            in the header section of Form 8825 matches the Employer Identification Number of the
            business you want {{appName}} to evaluate.
          </p>

          <form autocomplete="off">
            <div class="bp-form-group mr-5"
                 v-for="(row, index) in tableData">

                <label :for="row.id" class="se-std-field-group-label">
                  <span>{{row.line}}</span>
                </label>
                <a class="se-help-icon btn-link" v-tooltip.top-center="'Learn More'" @click="handleFieldHelp(row.id)" v-show="row.hasHelp">
                  <i class="fa fa-info-circle"></i>
                </a>

                <div @mouseover="highlightHoveredOverFormField(row)" @mouseleave="highlightHoveredOverFormField()">
                  <radio :label="false"
                         v-model="row.isEmpty"
                         :disabled="isSkipped[index] || isLocked"
                         :class="{'opacity-5': isSkipped[index]}"
                         class="mb-1">
                    <span class="se-std-label">{{row.name}}</span>
                  </radio>
                  <input :id="row.id"
                         :readonly="isLocked"
                         class="se-tax-field-input ml-4 bp-form-input bp-u-shadow-04"
                         type="text"
                         v-mask="row.mask"
                         @focus="highlightFocusedFormField(row)"
                         @blur="fieldIsFocused = false"
                         :disabled="row.isEmpty || isSkipped[index]"
                         v-model="row.value" />
                  <radio :label="true"
                         v-model="row.isEmpty"
                         :disabled="isSkipped[index] || isLocked"
                         :class="{'opacity-5': isSkipped[index]}"
                         class="mt-1"><span class="se-std-label">There is no Form 8825 or no amounts on this line</span>
                  </radio>
                </div>
            </div>
          </form>

          <div class="bp-help is-error mb-2" v-if="$v.allAnswered.$error">
            <div class="bp-ucon" data-src="feedback-warning"><svg class="bp-svg" viewBox="0 0 24 24" id="feedback-warning" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M13.009 13.987V8.934h-1.956v5.053h1.956zm0 4.016v-1.962h-1.956v1.962h1.956zM2.988 20.962L12.016 3l9.025 17.962H2.988z"></path></svg>
            </div>
            <template v-if="!$v.allAnswered.required">Provide answer for all items or select 'There is no Form 8825 or no amounts on this line '</template>
          </div>

        </div>

        <div class="col-md-6 d-none d-sm-none d-md-block">
          <div class="std-pdf-row">
          <WebViewer :url="this.pdf"
                     ref="viewer"
                     :se-fields="seFields"
                     :tax-form-year="this.taxFormYear"
                     :tax-form-id="this.taxFormId"
                     @viewerLoaded="onViewerLoaded"
                     :key="this.pdfViewerKey"/>
          </div>

        </div>
      </div>

    <se-help :visible.sync="showPageHelp">
      <p>
        Use this screen to provide information about Rental Real Estate Income and Expenses of a Partnership
        or an S-Corporation. {{ appName }} will display Form 8825 for an S-Corporation or Partnership when there is
        a Net rental real estate income (loss) amount on Line 2 of the borrower’s K-1.
      </p>
    </se-help>

    <se-help :visible.sync="showFieldHelp.depreciation">
      <div class="se-field-help">
        <p>This version of {{ appName }} cannot evaluate rental income or loss received by the business.</p>
      </div>
    </se-help>


    <se-buttons
      :display-submit="false"
      :display-save="false"
      :disable-submit="true"
      :disable-save="true"
      :disable-next="false"
      :disable-previous="false"
      @seNext="next"
      @sePrevious="previous">
    </se-buttons>

  </div>

</template>
<script>
import SeButtons from './SeButtons.vue'
import SeHelp from './SeHelp.vue'
import {Checkbox, Radio} from 'src/components/index'
import LSwitch from 'src/components/Switch.vue'
import { Collapse, CollapseItem } from 'element-ui'
import WebViewer from 'src/components/WebViewerROC.vue'
import {Table, TableColumn, Drawer} from 'element-ui'
import {required, requiredIf} from "vuelidate/lib/validators";
import { taxSingleFormMixin } from 'src/mixins/TaxSingleFormMixin'
import appNameMixin from '../../../mixins/AppNameMixin'
export default {
  mixins: [taxSingleFormMixin, appNameMixin],
  components: {
    Checkbox,
    Radio,
    LSwitch,
    SeButtons,
    SeHelp,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    WebViewer,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Drawer.name]: Drawer,
  },
  data() {
    return {
    }
  },
  validations: {
    allAnswered: {
      required: (v) => v
    }
  },
  computed: {
    isSkipped() {
      return this.tableData.map(f => {
        if (this.skippedForm) return true
        return false;
      })
    },
  },
  methods: {
    handleHelp(index, row) {
      row.showHelp = true;
    },
  }
}
</script>
<style >

.required > div:after {
  content:" *";
  color: red;
  text-nowrap: true
}

.std-pdf-row {
  position: fixed;
  width: 40%;
  height: 90%;
}


</style>
