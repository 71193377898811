import Enums from "../../../../model/enums";
import {ty} from "./ty";

export default {
  id: Enums.SeTaxFormType.tf4684,
  waivable: true,
  pdf: `static/forms/${ty}/${ty}_4684.pdf`,
  route: `/se-eval/std-tax-form-4684/${Enums.SeTaxFormType.tf4684}/${ty}`,
  fields: [
    {
      id: "casualtyTheftNonIncomePropertyShortTermGainLossAmount",
      fieldName: "topmostSubform[0].Page2[0].f2_51[0]",
      label: "Combined amount",
      line: "Line 31",
      isCurrency: true,
      isPositive: false,
      hasHelp: true
    },
    {
      id: "casualtyTheftIncomePropertyShortTermLossAmount",
      fieldName: "topmostSubform[0].Page2[0].f2_52[0]",
      label: "Amount",
      line: "Line 32",
      isCurrency: true,
      isPositive: true,
      hasHelp: true
    },
    {
      id: "casualtyTheftLongTermGainAmount",
      fieldName: "topmostSubform[0].Page2[0].f2_64[0]",
      label: "Total gains",
      line: "Line 36",
      isCurrency: true,
      isPositive: true,
      hasHelp: true
    },
    {
      id: "casualtyTheftLongTermLossAmount",
      fieldName: "topmostSubform[0].Page2[0].f2_65[0]",
      label: "Amount",
      line: "Line 37",
      isCurrency: true,
      isPositive: true,
      hasHelp: true
    },
  ]
}

