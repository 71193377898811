<template>
  <div id="webviewer" ref="viewer"></div>
</template>

<script>
import { ref, onMounted } from 'vue';
import WebViewer from '@pdftron/webviewer';

export default {
  name: 'WebViewer',
  props: {
    url: String,
    readOnly: {
      type: Boolean,
      default: true
    },
  },

  mounted: function () {

    const path = `${process.env.BASE_URL}webviewer`;
    WebViewer({
      licenseKey: "Federal National Mortgage Association(fanniemae.com):OEM:Self Employment Calculator::B+:AMS(20221005):D3A5A16204A7360A7360B13AC9A2537860613FC51E785A25062C45CA5CE508F642A2B6F5C7",
      path,
      disabledElements: [
        'viewControlsOverlay',
        'viewControlsButton',
        'ribbons',
        'toolsHeader',
        'leftPanelButton',
        'selectToolButton',
        'searchButton',
        'toggleNotesButton',
        'menuButton',
      ],
      preloadWorker: "pdf",
      backendType: "ems",
      initialDoc: this.url, // replace with your own PDF file
    }, this.$refs.viewer).then((instance) => {

      const { docViewer, annotManager, Annotations, CoreControls } = instance;

      var FitMode = instance.FitMode;
      instance.setFitMode(FitMode.FitWidth);

      //download substitute fonts from the DUIU server
      if (location.hostname != "localhost") {
        CoreControls.setCustomFontURL(`${location.origin}/static/fonts/pdf/`);
      }

      if (this.readOnly) {
        annotManager.on('annotationChanged', function (annotations, action, {imported}) {
          if (imported) {
            annotations.forEach(function (annot) {
              if (annot instanceof Annotations.WidgetAnnotation) {
                annot.fieldFlags.set('ReadOnly', true);
              }
            });
          }
        });
      }

      docViewer.on('documentLoaded', () => {
        // docViewer.getAnnotationsLoadedPromise().then(function() {
        //   const fieldManager = annotManager.getFieldManager();
        //   const field = fieldManager.getField('topmostSubform[0].Page1[0].FilingStatus[0].c1_01[0]');
        //   field.widgets.map(annot => {
        //     annot.fieldFlags.set('ReadOnly', true);
        //   });
        // });
      });


    });
  }

};
</script>

<style>
#webviewer {
  height: 100%;
}
</style>
