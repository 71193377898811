import Enums from "../../../../model/enums";
import {ty} from "./ty";

export default {
  id: Enums.SeTaxFormType.tfLossesExpenses,
  waivable: true,
  pdf: `static/forms/${ty}/${ty}_4684.pdf`,
  route: `/se-eval/std-tax-form-losses-expenses/${Enums.SeTaxFormType.tfLossesExpenses}/${ty}`,
  fields: [
    {
      id: "form4684Included",
      fieldName: "",
      label: "Form 4684 is included",
      line: "Casualty & Theft Loss",
      isBoolean: true,
      required: true,
      defaultValue: "No",
      hasHelp: true
    },
    {
      id: "extraordinaryOneTimeExpenses",
      formPart: 3,
      fieldName: "",
      label: "Extraordinary One-Time Expenses Determined by the Lender",
      line: "Extraordinary One-Time Expenses Determined by the Lender",
      isCurrency: true,
      isPositive: true,
      isEmptyByDefault: true,
      hasHelp: true
    },
  ]
}

