<template xmlns="http://www.w3.org/1999/html">
  <div>
    <div class="container">

    <h1 class="se-bp-title">
        Select Tax Forms
        <a class="btn-link se-page-help-icon" v-tooltip.top-center="'Learn More'" @click="showPageHelp = true">
          <i class="fa fa-info-circle"></i>
        </a>
      </h1>

      <h4 class="mt-3">Thinking about the business you want {{appName}} to evaluate, what IRS tax return form(s) do you have? (select all that apply)</h4>

      <div class="se-select-tax-forms">
        <el-collapse :value="[getTaxYears.py1, getTaxYears.py2]">
          <el-collapse-item :title="`Tax Year - ${taxYear.year}`" :name="taxYear.year" v-for="taxYear in getConfTaxYears" :key="taxYear.year">

            <div class="form-check"
                 :class="{ 'ml-4': taxForm.indent, disabled: taxForm.disabled || isLocked }"
                 v-for="taxForm in taxYear.forms">
              <label :for="genId(taxYear.year, taxForm.id)" :class="{'opacity-5': taxForm.disabled || isDisabledBusinessForm(taxYear.year, taxForm.id)}" v-if="!taxForm.hidden">

                <input v-if="all1040Forms.includes(taxForm.id)" :id="genId(taxYear.year, taxForm.id)"
                       class="bp-form-check mr-1 se-undo-checkbox-disabled"
                       type="checkbox"
                       :disabled="taxForm.disabled || isLocked"
                       :checked="getSelectedFormState(taxYear.year, taxForm.id)"
                       @input="setSelectedFormState(taxYear.year, taxForm.id, all1040Forms, $event)"
                       />

                <input v-else-if="allBusinessForms.includes(taxForm.id)" :id="genId(taxYear.year, taxForm.id)"
                       class="bp-form-check mr-1"
                       type="checkbox"
                       :disabled="taxForm.disabled || isLocked || isDisabledBusinessForm(taxYear.year, taxForm.id)"
                       :checked="getSelectedFormState(taxYear.year, taxForm.id)"
                       @input="setSelectedFormState(taxYear.year, taxForm.id, allBusinessForms, $event)"
                       />

                {{taxYear.year}} Form {{taxForm.id}} - <i>{{taxForm.name}}</i>
              </label>
            </div>

          </el-collapse-item>
        </el-collapse>
      </div>

      <div>
        <checkbox v-model="noTaxReturns" :disabled="isLocked">I don't have any tax returns</checkbox>
      </div>


    <se-help :visible.sync="showPageHelp" size="50%" title="Learn More">
      <p>
        Use this screen to take an inventory of the IRS tax return forms you have related to this income source.
        At minimum, you must have the most recent year individual (personal) tax returns. All tax returns must be
        complete, including all schedules and attachments.
      </p>
      <p>
        <icon-base width="22" height="22" iconColor="#0c77ba" icon-name="icon-right-arrow"><icon-arrow-right/></icon-base>
        You must select at least <b>Form 1040, Form 1040-SR</b> or <b>Form 1040-NR</b> for the most recent year to continue.
      </p>
      <p>
        <a href="#" @click="showTaxFormsHelp=true">View examples of IRS Return Forms</a>
      </p>

      <b>FAQs</b>
      <p>
        Q. Why are some selections grayed-out (not able to be selected)?<br>
        A. Based on the Income Type, borrower's percentage of business ownership and/or Business Structure previously provided,
         {{ appName }} has limited the selections to display only the relevant tax
         return forms. If you’re not sure what the Business Structure is, select “I don’t know” on the Business Structure Screen and {{ appName }} will help you
        identify the correct business structure as you provide the tax return data.
      </p>
      <p>
      <a href="#" @click="$router.push('business-structure')">Update the Business Structure</a><br>
      <a href="#" @click="$router.push('income-type')">Update the Income Type</a>
      </p>

      <p>
        Q. The borrower’s business structure has recently changed. For example, the business is
        structured as a Corporation in the current tax year but was structured as a Partnership
        in the prior tax year. How do I select the correct values? Does {{ appName }}
        evaluate income under this scenario?<br>
        A. If two year’s business tax returns are required for documentation of income, you will need to use another method to
        calculate the borrower’s income. {{ appName }} does not currently evaluate income when the borrower’s business structure has
        changed from one year to the next. Note the Fannie Mae Selling Guide requires the borrower's most recent signed personal and
        business tax returns reflect 12 months of self-employment income from the business in order to be used for loan qualification.
      </p>
      <p>
      Q. The borrower satisfies all the conditions to waive business tax returns,
        and I did not collect them. Do I still need to provide K-1 information from the
        S-Corporation or Partnership? How do I tell {{ appName }}
        that I only have K-1s, and not the complete business tax return?<br>
      A. Even if business tax returns are waived, you must still provide {{ appName }}
        certain data from the borrower’s K-1 form. If you do not have business tax returns,
        select only the relevant Individual Income Tax Return (Form 1040, 1040-SR, or 1040-NR).
        {{ appName }} will display screens to collect K-1 data for all S-Corporations and Partnerships.
      </p>

      <p>
      Q. I have two years tax returns, but Desktop Underwriter (DU) has issued a message
        that only requires one year of tax returns. Am I required to provide two years of
        tax return data to {{ appName }}?<br>
      A. No, If the borrower provides information beyond what is required in the Selling Guide, you may provide
        it to {{ appName }} for consideration. {{ appName }} will evaluate income based on data provided.
      </p>
      <p>
      Q. Desktop Underwriter (DU) has issued a message that only requires one year of tax returns,
        am I required to provide only one year of tax return data to {{ appName }}?<br>
      A. No. If the borrower provides information beyond what is required in the Selling Guide,
        you may provide it to {{ appName }} for consideration. {{ appName }} will evaluate income based on the data provided.
      </p>

      <p>
      Q. I input two years of tax return data into {{ appName }}. How can
        I delete the prior year’s tax data and have {{ appName }} only use the current year tax data.<br>
      A. On the Select Tax Forms screen, deselect the tax return forms for the prior year.
      </p>
      <p>
      Q. The borrower’s ownership percentage in the business has recently changed to 25% or more.
        For example, in the current tax year the borrower owns 30% of the business, but in the prior
        tax year the borrower owned 10% of the business. What are the correct values to select on this screen?<br>
      A. If two year’s business tax returns are required for documentation of income, select the
        individual (personal) returns for current and prior tax year, and select the business return
        for the current tax year.
      </p>
      <p>
      Q. The selections shown on the screen don’t match the tax returns I have. How can I change the tax years that appear on this screen?<br>
      A. Based on the information previously provided, Income Calculator has limited the selections to display only the relevant tax years.
        Use the link below to make corrections to the tax years available.
      </p>
      <p>
        <a href="#" @click="$router.push('tax-year')">Update the Tax Years</a><br>
      </p>
      <p>
        <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
        Learn more about the Allowable Age of Federal Income Tax Returns in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b1-1-03/allowable-age-credit-documents-and-federal-income-tax-returns">Allowable Age of Credit Documents and Federal Income Tax Returns</a> section of the Fannie Mae Selling Guide.
      </p>
      <p>
      Q. The borrower’s business uses a fiscal year that does not end in December.
        Does {{ appName }} evaluate income under this scenario?<br>
      A. No. {{ appName }} does not currently evaluate income when the borrower’s business
        uses a fiscal year that does not end in December. You will need to use another method to calculate the borrower’s income.
      </p>
    </se-help>

    <se-tax-forms-help :visible.sync="showTaxFormsHelp" title="View Examples of IRS tax return forms"></se-tax-forms-help>

    <se-buttons
      :display-save="false"
      :display-submit="false"
      @seNext="next"
      @sePrevious="previous">
    </se-buttons>

  </div>
  </div>

</template>
<script>
import {Checkbox, Radio, IconArrowRight, IconBook} from 'src/components/index'
import LSwitch from 'src/components/Switch.vue'
import SeButtons from './SeButtons.vue'
import SeHelp from './SeHelp.vue'
import SeTaxFormsHelp from './SeHelpTaxFormExamples.vue'
import Swal from 'sweetalert2'
import { Collapse, CollapseItem } from 'element-ui'
import {mapGetters, mapState} from 'vuex'
import Enums from 'src/model/enums'
import {SET_REQUEST_DATA, RETAIN_TAX_DATA, SKIP_BUSINESS_TAX_FORMS, SKIP_YEAR_TAX_FORMS} from 'src/store/actions/se'
import {toEnum} from "../../../util/seutil";
import appNameMixin from '../../../mixins/AppNameMixin'

const all1040Forms = [Enums.SeTaxFormType.tf1040, Enums.SeTaxFormType.tf1040sr, Enums.SeTaxFormType.tf1040nr]
const allBusinessForms = [Enums.SeTaxFormType.tf1040c, Enums.SeTaxFormType.tf1065, Enums.SeTaxFormType.tf1120, Enums.SeTaxFormType.tf1120s]

export default {
  mixins:[appNameMixin],
  components: {
    Checkbox,
    Radio,
    LSwitch,
    SeButtons,
    SeHelp,
    SeTaxFormsHelp,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    IconArrowRight,
    IconBook
  },
  data() {
    return {
      noTaxReturns: false,
      selectedTaxForms: {},
      showPageHelp: false,
      showTaxFormsHelp: false,
      showSelectionHelp: false,
      all1040Forms,
      allBusinessForms,
      previousPY2Forms: []
    }
  },
  created() {
    this.SeTaxFormType = Enums.SeTaxFormType;
  },
  mounted() {
    let tf = this.seRequest.selectedTaxForms.reduce((acc, f) => {acc[f.year] = [...f.formIds]; return acc;}, {});
    if (!tf[this.getPY1]) {
      tf[this.getPY1] = []
    }
    if (!(tf[this.getPY1].some(stf => all1040Forms.includes(stf)))) {
      //if there are no individual forms, push 1040 for CY
      tf[this.getPY1].push(Enums.SeTaxFormType.tf1040)
    }
    this.previousPY2Forms.push(...tf[this.getPY2])
    this.selectedTaxForms = tf
  },
  beforeRouteLeave(to, from, next) {
    //if not PY2 forms selected (they were deselected) but we have PY2 data is store
    if (this.selectedTaxForms[this.getPY2].length === 0 && this.seRequest.taxData.some(form => form.year === this.getPY2)) {
      this.alert1SkipPriorYearWarnDeselectExisting(next)
      return
    } else {
      this.compactAndRetain()
      next()
    }
  },
  watch: {
    noTaxReturns(newValue) {
      if (newValue === true) {
        Object.entries(this.selectedTaxForms).forEach(([k,v]) => {
          this.selectedTaxForms[k] = [];
        })
      } else {
        this.selectedTaxForms[this.getPY1].push(Enums.SeTaxFormType.tf1040)
      }
    },
    selectedTaxForms: {
      handler(newValue, oldValue) {
        if (Object.values(this.selectedTaxForms).some(v => v.length > 0)) {
          this.noTaxReturns = false;

          //user cannot select just previous tax year but only together with the most recent year
          //this is no longer needed as the CY is always selected
          // let py1 = this.getTaxYears.py1
          // let py2 = this.getTaxYears.py2
          // if (this.selectedTaxForms[py2] && this.selectedTaxForms[py2].length > 0 && (!this.selectedTaxForms[py1] || this.selectedTaxForms[py1].length === 0)) {
          //   this.$nextTick(() => {
          //     this.selectedTaxForms[py2].splice(0);
          //   });
          // }

          //if no business return was selected, select schedule c by default
          Object.keys(this.selectedTaxForms).forEach(k => {
            //if any of 1040 is selected
            if (this.selectedTaxForms[k].some(formId => all1040Forms.includes(formId))) {
              if (this.selectedTaxForms[k].every(formId => !allBusinessForms.includes(formId))) {
                this.selectedTaxForms[k].push(Enums.SeTaxFormType.tf1040c)
              }
            } else {
              const i = this.selectedTaxForms[k].indexOf(Enums.SeTaxFormType.tf1040c)
              if (i > -1) {
                this.selectedTaxForms[k].splice(i, 1)
              }
            }
          })
        }

        //do not save py1 without any 1040 selected
        let py1 = this.getTaxYears.py1
        if (!this.selectedTaxForms[py1].some(formId => all1040Forms.includes(formId))) {
          return
        }
        this.saveToStore();
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      seRequest: state => state.se.request,
      seConf: state => state.se.conf
    }),
    ...mapGetters([
      'getTaxFormRoutes',
      'isDisplaySelectTaxYear',
      'getTaxYears',
      'getConfTaxYears',
      'getPY1',
      'getPY2',
      'isLocked',
      'getEmploymentMonthsCount'
    ]),
  },
  methods: {
    isDisabledBusinessForm(taxYear, taxFormId) {
      let enabled = false
      let businessStructureProvided = toEnum(Enums.SeBusinessStructureType, this.seRequest.extraData.businessStructureProvided)
      let py1 = this.getTaxYears.py1
      let py2 = this.getTaxYears.py2

      if (all1040Forms.includes(taxFormId)) {
        enabled = true
      } else if (taxFormId === Enums.SeTaxFormType.tf1120s
          && [Enums.SeBusinessStructureType.sCorp, Enums.SeBusinessStructureType.llc, Enums.SeBusinessStructureType.unknown].includes(businessStructureProvided)
          && (taxYear === py1 || (this.selectedTaxForms[py1]?.includes(Enums.SeTaxFormType.tf1120s) && (this.seRequest.extraData.employmentOwnershipInterestProvided !== Enums.EmploymentOwnershipInterestProvidedType.lt25)))) {
        enabled = true
      } else if (taxFormId === Enums.SeTaxFormType.tf1120 && [Enums.SeBusinessStructureType.corp, Enums.SeBusinessStructureType.llc, Enums.SeBusinessStructureType.unknown].includes(businessStructureProvided)
          && (taxYear === py1 || this.selectedTaxForms[py1]?.includes(Enums.SeTaxFormType.tf1120))) {
        enabled = true
      } else if (taxFormId === Enums.SeTaxFormType.tf1065 && [Enums.SeBusinessStructureType.partnership, Enums.SeBusinessStructureType.llc, Enums.SeBusinessStructureType.unknown].includes(businessStructureProvided)
          && (taxYear === py1 || (this.selectedTaxForms[py1]?.includes(Enums.SeTaxFormType.tf1065) && (this.seRequest.extraData.employmentOwnershipInterestProvided !== Enums.EmploymentOwnershipInterestProvidedType.lt25)))) {
        enabled = true
      }
      return !enabled
    },
    getSelectedFormState(year, taxFormId) {
      return this.selectedTaxForms[year] ? this.selectedTaxForms[year].includes(taxFormId) : false;
    },
    setSelectedFormState(year, taxFormId, allFormIds, event) {
      if (event.target.checked) {
        //deselect all
        allFormIds.forEach(f => {
          const i = this.selectedTaxForms[year]?.indexOf(f)
          if (i > -1) {
            this.selectedTaxForms[year].splice(i, 1)
          }
        })
        this.selectedTaxForms[year].push(taxFormId)

      } else {
        const i = this.selectedTaxForms[year].indexOf(taxFormId)
        if (i > -1) {
          this.selectedTaxForms[year].splice(i, 1)
        }

        //do not allow deselection of the PY1 individual forms
        if (year === this.getPY1 && all1040Forms.includes(taxFormId)) {
          this.$nextTick(() => {
            this.selectedTaxForms[this.getPY1].push(taxFormId)
          })
        }
      }

      //on any change of business returns select for PY1 clean up all business selections for PY2
      if (year === this.getPY1 && allBusinessForms.includes(taxFormId)) {
        allFormIds.forEach(f => {
          const i = this.selectedTaxForms[this.getPY2]?.indexOf(f)
          if (i > -1) {
            this.selectedTaxForms[this.getPY2].splice(i, 1)
          }
        })
      }

    },
    genId(year, id) {
      return "" + year + id;
    },
    getSelectedTaxFormsForStoring() {
      let selectedTaxForms = Object.entries(this.selectedTaxForms).reverse().map( ([k,v]) => ({ year: k, formIds: v.slice()}) )
      //sort form Id in proper order, as listed in the configuration
      selectedTaxForms.forEach(stf => {
        let orderedFormIds = this.getConfTaxYears.find(ty => ty.year == stf.year)?.
        forms.map(f => f.id).filter(f => stf.formIds.includes(f))
        stf.formIds = orderedFormIds ?? []
      })
      return selectedTaxForms
    },
    saveToStore() {
      let selectedTaxForms = this.getSelectedTaxFormsForStoring()
      if (this.seRequest.extraData.skipIndividualPriorYearReturnsIndicator) {
        //check if there was a change in PY2 tax form selection. If so, reset the skipIndividualPriorYearReturnsIndicator and all skipped forms
        let py2 = this.getTaxYears.py2
        let py2Forms = selectedTaxForms.find(stf => stf.year === py2)
        let py2FormsStore = this.seRequest.selectedTaxForms.find(stf => stf.year === py2)
        let py2Changed = JSON.stringify(py2Forms) !== JSON.stringify(py2FormsStore)
        if (py2Changed) {
          this.$store.dispatch(SKIP_YEAR_TAX_FORMS, {year: py2, skipped: false})
        }
      }

      if (this.seRequest.extraData.waiveBusinessReturnsIndicator) {
        //check if there was a change in PY1 or PY2 business form selections. If so, reset the waiveBusinessReturnsIndicator and all skipped forms
        let py1 = this.getTaxYears.py1
        let py2 = this.getTaxYears.py2
        let busForm = selectedTaxForms.filter(stf => [py1, py2].includes(stf.year))
          .map(stf => stf.formIds.filter(f => [Enums.SeTaxFormType.tf1065, Enums.SeTaxFormType.tf1120, Enums.SeTaxFormType.tf1120s].includes(f)))
        let busFormStore = this.seRequest.selectedTaxForms.filter(stf => [py1, py2].includes(stf.year))
          .map(stf => stf.formIds.filter(f => [Enums.SeTaxFormType.tf1065, Enums.SeTaxFormType.tf1120, Enums.SeTaxFormType.tf1120s].includes(f)))
        let busChanged = JSON.stringify(busForm) !== JSON.stringify(busFormStore)
        if (busChanged) {
          this.$store.dispatch(SKIP_BUSINESS_TAX_FORMS, {skipped: false})
        }
      }
      this.$store.commit(SET_REQUEST_DATA, { selectedTaxForms })
    },
    compactAndRetain() {
      let selectedTaxForms = this.getSelectedTaxFormsForStoring()
      //these forms are included in llc/unknown flow but should not be used in defined partn/corp/scorp
      let excludeForms = [Enums.SeTaxFormType.tf1040c, Enums.SeTaxFormType.tf1040se]
      let excludeFormsFor = [Enums.SeTaxFormType.tf1065, Enums.SeTaxFormType.tf1120, Enums.SeTaxFormType.tf1120s]

      let allRetainedForms = []
      selectedTaxForms.forEach(stf => {
        let year = stf.year;

        stf.formIds.forEach(formId => {
          if (year && formId) {

            const retainedForms = this.seConf.taxYears
              .find(ty => ty.year === year).forms
              .filter(f => f.id === formId)
              .flatMap(rtn => rtn.forms)
              .filter(f => !(excludeFormsFor.includes(formId) && excludeForms.includes(f.id)))
              .map(f => ({'year': year, 'id': f.id}));

            allRetainedForms = allRetainedForms.concat(retainedForms);
          }
        })
      });
      this.$store.commit(RETAIN_TAX_DATA, allRetainedForms)
    },
    next() {
      //validate
      let businessStructureProvided = toEnum(Enums.SeBusinessStructureType, this.seRequest.extraData.businessStructureProvided)
      let busTaxReturns = [Enums.SeTaxFormType.tf1065, Enums.SeTaxFormType.tf1120, Enums.SeTaxFormType.tf1120s]

      if (this.isEmploymentOwnershipInterestProvidedGte25OrNull()) {
        //GTE 25% ownership
        if (this.selectedTaxForms[this.getPY2].length === 0) {
          if (!this.seRequest.duData?.documentationLevel && this.getEmploymentMonthsCount < 60) {
            //no documentation level
            this.alert2SkipPriorYearWarnDefault()
            return
          }

          if (["306", "308", "356"].includes(this.seRequest.duData?.documentationLevel)) {
            //DU does not confirm 1 year, explicit DU doc code
            this.alert4SkipPriorYearWarnDU2Years()
            return
          }

        } else {
          if (["342", "355"].includes(this.seRequest.duData?.documentationLevel)) {
            //DU confirms 1 year
            this.alert3SkipPriorYearWarnDU1Year()
            return
          }
        }

        if (this.noTaxReturns) {
          this.alert5aTaxFormsRequired()
          return;
        }
        if (!Object.values(this.selectedTaxForms).some(v => v.length > 0)) {
          //no forms selected
          this.alert5aTaxFormsRequired()
          return
        }

        if (businessStructureProvided !== Enums.SeBusinessStructureType.soleProprietorship) {
          if (!this.selectedTaxForms[this.getPY1].some(taxForm => busTaxReturns.includes(taxForm))
            && this.selectedTaxForms[this.getPY2].length === 0) {
            this.alert5aTaxFormsRequired()
            return
          }

          if (this.selectedTaxForms[this.getPY1].some(taxForm => busTaxReturns.includes(taxForm))
            && this.selectedTaxForms[this.getPY2].some(formId => all1040Forms.includes(formId))
            && !this.selectedTaxForms[this.getPY2].some(taxForm => busTaxReturns.includes(taxForm))) {
            this.alert5aTaxFormsRequired()
            return
          }

          if (this.selectedTaxForms[this.getPY1].some(taxForm => busTaxReturns.includes(taxForm))
            && !this.selectedTaxForms[this.getPY2].some(formId => all1040Forms.includes(formId))
            && this.selectedTaxForms[this.getPY2].some(taxForm => busTaxReturns.includes(taxForm))) {
            this.alert5aTaxFormsRequired()
            return
          }
        }

        if ([Enums.SeBusinessStructureType.sCorp, Enums.SeBusinessStructureType.partnership, Enums.SeBusinessStructureType.corp, Enums.SeBusinessStructureType.llc].includes(businessStructureProvided)) {
          if (!this.selectedTaxForms[this.getPY1].some(taxForm => busTaxReturns.includes(taxForm))
            && this.selectedTaxForms[this.getPY2].some(formId => all1040Forms.includes(formId))
            && !this.selectedTaxForms[this.getPY2].some(taxForm => busTaxReturns.includes(taxForm))) {

            if (this.getEmploymentMonthsCount >= 60) {
              this.alert6aSkipBusinessTaxReturns()
              return
            } else {
              this.alert7CannotWaiveBusiness()
              return
            }
          }
        }

      } else {
        //LE 25% ownership
        if (this.noTaxReturns) {
          this.alert5bLt25TaxFormsRequired()
          return;
        }

        if ([Enums.SeBusinessStructureType.partnership, Enums.SeBusinessStructureType.sCorp, Enums.SeBusinessStructureType.llc].includes(businessStructureProvided)) {
          if (!this.selectedTaxForms[this.getPY2].some(formId => all1040Forms.includes(formId))) {
            this.alert5bLt25TaxFormsRequired()
            return;
          }

          if (this.selectedTaxForms[this.getPY1].some(formId => all1040Forms.includes(formId))
            && this.selectedTaxForms[this.getPY2].some(formId => all1040Forms.includes(formId))
            && !this.selectedTaxForms[this.getPY1].some(taxForm => busTaxReturns.includes(taxForm))) {

            this.alert6bLt25SkipBusinessTaxReturns()
            return
          }
        }

        if (Enums.SeBusinessStructureType.corp === businessStructureProvided) {
          this.alert8Lt25Corp()
          return
        }
      }
      this.doNext()
    },
    doNext() {
      if (this.getTaxFormRoutes && this.getTaxFormRoutes.length > 0) {
        this.$router.push(this.getTaxFormRoutes[0]).catch(() => {})
      }
    },
    previous() {
      this.$router.push(this.isDisplaySelectTaxYear ? 'tax-year' : 'business-structure').catch(() => {})
    },
    setNoTaxReturns() {
      this.selectedTaxForms = {};
    },

    //warn 1
    alert1SkipPriorYearWarnDeselectExisting(next) {
      let msgIcon = "warning"
      let msgHtml = "<p>Data from prior year tax returns will be permanently removed.</p>"

      Swal.fire({
        title: 'WARNING!',
        html: msgHtml,
        width: 480,
        showCancelButton: true,
        buttonsStyling: false,
        customClass: {confirmButton: 'bp-button bp-button--primary', cancelButton: 'bp-button'},
        confirmButtonText: `Skip Prior Year`,
        cancelButtonText: `Provide Prior Year`,
        icon: msgIcon
      }).then((result) => {
        if (result.isConfirmed) {
          this.compactAndRetain()
          next(true)
        } else {
          //restore original PY2 selection
          this.selectedTaxForms[this.getPY2].push(...this.previousPY2Forms)
          next(false)
        }
      })
    },

    //warn 2
    alert2SkipPriorYearWarnDefault() {
      let msgIcon = "question"
      let msgHtml = "<div align='left'><p>One year of individual and business tax returns may be used only if:</p>" +
      "    <ol>" +
      "      <li>" +
      "        the Desktop Underwriter (DU) Casefile ID was created prior to January 1, 2024, and DU has issued a message permitting income to be documented with one year of personal and business tax returns, <br>or" +
      "      </li>" +
      "      <li>" +
      "        the borrower has had 25% or more ownership interest in the business for a minimum of five (5) years as reflected on the Uniform Residential Loan Application." +
      "      </li>" +
      "    </ol>" +
      "<p>" +
      "  Confirm the tax returns reflect at least 12 months of self-employment income." +
      "</p>" +
      "<p>" +
      "  Income Calculator calculates the borrower's maximum qualifying income based on the provided tax return data. It is possible a higher qualifying income amount could be returned if two years of tax return data are provided." +
      "</p></div>"

      Swal.fire({
        title: `Do you want to skip prior year tax returns?`,
        html: msgHtml,
        width: 850,
        showCancelButton: true,
        buttonsStyling: false,
        customClass: {confirmButton: 'bp-button bp-button--primary', cancelButton: 'bp-button'},
        confirmButtonText: `Skip Prior Year`,
        cancelButtonText: `Provide Prior Year`,
        icon: msgIcon
      }).then((result) => {
        if (result.isConfirmed) {
          this.doNext()
        }
      })
    },

    //warn 3
    alert3SkipPriorYearWarnDU1Year() {
      let msgIcon = "question"
      let msgHtml = "<p>Desktop Underwriter has issued a message permitting self-employment income to be documented using one year of federal tax returns.</p>"

      Swal.fire({
        title: `Do you want to skip Prior year tax returns?`,
        html: msgHtml,
        width: 640,
        showCancelButton: true,
        buttonsStyling: false,
        customClass: {confirmButton: 'bp-button bp-button--primary', cancelButton: 'bp-button'},
        confirmButtonText: `Skip Prior Year`,
        cancelButtonText: `Provide Prior Year`,
        icon: msgIcon
      }).then((result) => {
        if (result.isConfirmed) {
          this.doNext()
        }
      })
    },

    //warn 4
    alert4SkipPriorYearWarnDU2Years() {
      let msgIcon = "warning"
      let msgHtml = "<div align='left'><p>One year of individual and business tax returns may be used only if:</p>" +
        "    <ol>" +
        "      <li>" +
        "        the Desktop Underwriter (DU) Casefile ID was created prior to January 1, 2024, and DU has issued a message permitting income to be documented with one year of personal and business tax returns, <br>or" +
        "      </li>" +
        "      <li>" +
        "        the borrower has had 25% or more ownership interest in the business for a minimum of five (5) years as reflected on the Uniform Residential Loan Application." +
        "      </li>" +
        "    </ol>" +
        "<p>" +
        "  Confirm the tax returns reflect at least 12 months of self-employment income." +
        "</p>" +
        "<p>" +
        "  Income Calculator calculates the borrower's maximum qualifying income based on the provided tax return data. It is possible a higher qualifying income amount could be returned if two years of tax return data are provided." +
        "</p></div>"

      Swal.fire({
        title: 'WARNING!<br>Desktop Underwriter has issued a message requiring<br>two years of tax returns<br><br><b>Do you want to skip prior year tax returns?</b>',
        html: msgHtml,
        width: 850,
        showCancelButton: true,
        buttonsStyling: false,
        customClass: {confirmButton: 'bp-button bp-button--primary', cancelButton: 'bp-button'},
        confirmButtonText: `Skip Prior Year`,
        cancelButtonText: `Provide Prior Year`,
        icon: msgIcon
      }).then((result) => {
        if (result.isConfirmed) {
          this.doNext()
        }
      })
    },

    //warn 5a
    alert5aTaxFormsRequired () {
      Swal.fire({
        title: 'Warning!',
        icon: 'warning',
        width: 600,
        buttonsStyling: false,
        customClass: { confirmButton: 'bp-button bp-button--primary' },
        html:
        "One of the following is required to continue: <br><br>" +
        "1. Two years of personal and business federal tax returns,<br>" +
        "2. One year of personal and business federal tax returns (permitted under certain conditions), or<br>" +
        "3. Two years of personal federal tax returns (permitted under certain conditions)"
      })
    },

    //warn 5b
    alert5bLt25TaxFormsRequired () {
      Swal.fire({
        title: 'Warning!',
        icon: 'warning',
        width: 600,
        buttonsStyling: false,
        customClass: { confirmButton: 'bp-button bp-button--primary' },
        html:
        "For borrowers with less than 25% business ownership, two years of personal federal tax returns (including Form K-1) are required. " +
          "If current year business tax returns are provided, Income Calculator will evaluate if the business has adequate liquidity to support the withdrawal of earnings."
      })
    },

    //warn 6a
    alert6aSkipBusinessTaxReturns() {
      let msgIcon = "question"
      let msgHtml = "<p align='left'>Business tax returns may ONLY be waived if:</p>"
        + "<ul>"
        + "<li align='left'>the borrower has been self-employed in the same business for at least five years,</li>"
        + "<li align='left'>the borrower is using his or her own personal funds to pay the down payment and closing costs and satisfy applicable reserve requirements, and</li>"
        + "<li align='left'>the borrower’s individual tax returns show an increase in self-employment income over the past two years</li>"
        + "</ul>"

      Swal.fire({
        title: `Do you want to waive Business tax returns?`,
        html: msgHtml,
        width: 800,
        showCancelButton: true,
        buttonsStyling: false,
        customClass: {confirmButton: 'bp-button bp-button--primary', cancelButton: 'bp-button'},
        confirmButtonText: `OK`,
        cancelButtonText: `Cancel`,
        icon: msgIcon
      }).then((result) => {
        if (result.isConfirmed) {
          this.doNext()
        }
      })
    },

    //warn 6b
    alert6bLt25SkipBusinessTaxReturns() {
      let msgIcon = "question"
      let msgHtml = "For borrowers with less than 25% business ownership, business tax returns are not required. " +
        "If current year business tax returns are provided, Income Calculator will evaluate if the business has adequate liquidity to support the withdrawal of earnings."

      Swal.fire({
        title: `Do you want Income Calculator to analyze business liquidity?`,
        html: msgHtml,
        width: 800,
        showCancelButton: true,
        buttonsStyling: false,
        customClass: {confirmButton: 'bp-button bp-button--primary', cancelButton: 'bp-button'},
        confirmButtonText: `OK`,
        cancelButtonText: `Cancel`,
        icon: msgIcon
      }).then((result) => {
        if (result.isConfirmed) {
          this.doNext()
        }
      })
    },

    //warn 7
    alert7CannotWaiveBusiness() {
      Swal.fire({
        title: 'Warning!',
        icon: 'warning',
        width: 600,
        buttonsStyling: false,
        customClass: { confirmButton: 'bp-button bp-button--primary' },
        html: "Business tax returns may not be waived because the borrower has not been self-employed in the same business for at least five years."
      })
    },

    //warn 8
    alert8Lt25Corp() {
      let msgIcon = "warning"
      let msgHtml = "This version of Income Calculator cannot evaluate Corporation income if the borrower has an ownership share of less than 25%.<br><br>" +
        "You may continue to provide tax return data for informational purposes, but Income Calculator will not return a qualified income amount."

      Swal.fire({
        title: `Warning!`,
        html: msgHtml,
        width: 600,
        showCancelButton: true,
        buttonsStyling: false,
        customClass: {confirmButton: 'bp-button bp-button--primary', cancelButton: 'bp-button'},
        confirmButtonText: `OK`,
        cancelButtonText: `Cancel`,
        icon: msgIcon
      }).then((result) => {
        if (result.isConfirmed) {
          this.doNext()
        }
      })
    },

    isEmploymentOwnershipInterestProvidedGte25OrNull() {
      return !this.seRequest.extraData.employmentOwnershipInterestProvided ||
        this.seRequest.extraData.employmentOwnershipInterestProvided === Enums.EmploymentOwnershipInterestProvidedType.gte25;
    }

  }
}
</script>
<style>

.se-select-tax-forms label {
  font-size: 1rem;
  margin-bottom: 10px;
}

.se-select-tax-forms .el-collapse-item__header {
  font-size: 1rem;
  font-weight: 600;
}

.se-undo-checkbox-disabled {
  color: black !important;
}


</style>
