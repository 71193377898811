<template>

  <div>
    <top-navbar></top-navbar>

  <div class="wrapper" :class="{'nav-open': $sidebar.showSidebar}">
    <notifications></notifications>
    <side-bar>
      <template slot-scope="props" slot="links">
        <sidebar-item :link="{name: 'Welcome', icon: 'nc-icon nc-grid-45',  path: '/home/welcome'}"/>

        <template v-if="menuEnabled">
          <sidebar-item :link="{name: 'Start', icon: 'nc-icon nc-grid-45',  path: '/se-eval/start'}"/>
          <sidebar-item :link="{name: 'Income Type', icon: 'nc-icon nc-grid-45',  path: '/se-eval/income-type'}"/>
          <sidebar-item :link="{name: 'Employment Name', icon: 'nc-icon nc-grid-45',  path: '/se-eval/business-name'}"/>
          <sidebar-item :link="{name: 'Length of Employment', icon: 'nc-icon nc-grid-45',  path: '/se-eval/business-length'}" v-if="menuEnabled"/>
          <sidebar-item :link="{name: 'Desktop Underwriter', icon: 'nc-icon nc-grid-45',  path: '/se-eval/du-evaluation'}"/>
          <sidebar-item :link="{name: 'Business Structure', icon: 'nc-icon nc-grid-45',  path: '/se-eval/business-structure'}"/>
          <sidebar-item :link="{name: 'Select Tax Year', icon: 'nc-icon nc-grid-45',  path: '/se-eval/tax-year'}" v-show="isDisplaySelectTaxYear"/>
          <sidebar-item :link="{name: 'Select Tax Forms', icon: 'nc-icon nc-grid-45',  path: '/se-eval/tax-form-select'}"/>
        </template>

        <sidebar-item :link="{name: 'Input Tax Data', icon: 'nc-icon nc-notes', isFolder: true}" :menu="true" v-if="menuEnabled">

          <template v-for="taxYear in getSelectedTaxYearsWithForms">
            <sidebar-item :link="{name: `${taxYear} Forms`}" v-if="menuEnabled">

                <sidebar-item :link="{name: 'Form 1040', icon: 'nc-icon nc-grid-45',  path: `/se-eval/std-tax-form-1040/${SeTaxFormType.tf1040}/${taxYear}`}" v-show="isTaxFormIncluded(taxYear, SeTaxFormType.tf1040, 'std-tax-form-1040' )"/>
                <sidebar-item :link="{name: 'Form 1040-SR', icon: 'nc-icon nc-grid-45',  path: `/se-eval/std-tax-form-1040/${SeTaxFormType.tf1040sr}/${taxYear}`}" v-show="isTaxFormIncluded(taxYear, SeTaxFormType.tf1040sr, 'std-tax-form-1040' )"/>
                <sidebar-item :link="{name: 'Form 1040-NR', icon: 'nc-icon nc-grid-45',  path: `/se-eval/std-tax-form-1040/${SeTaxFormType.tf1040nr}/${taxYear}`}" v-show="isTaxFormIncluded(taxYear, SeTaxFormType.tf1040nr, 'std-tax-form-1040' )"/>
                <sidebar-item :link="{name: 'Form 1040 Sch 1', icon: 'nc-icon nc-grid-45',  path: `/se-eval/std-tax-form-1040s1/${SeTaxFormType.tf1040s1}/${taxYear}`}" v-show="isTaxFormIncluded(taxYear, SeTaxFormType.tf1040s1, 'std-tax-form-1040s1' )"/>
                <sidebar-item :link="{name: 'Form 1040 Sch C', icon: 'nc-icon nc-grid-45',  path: `/se-eval/std-tax-form-1040sc/${SeTaxFormType.tf1040c}/${taxYear}/1`}" v-show="isTaxFormIncluded(taxYear, SeTaxFormType.tf1040c, 'std-tax-form-1040sc' )"/>
                <sidebar-item :link="{name: 'Form 1040 Sch E', icon: 'nc-icon nc-grid-45',  path: `/se-eval/std-tax-form-1040se/${SeTaxFormType.tf1040se}/${taxYear}`}" v-show="isTaxFormIncluded(taxYear, SeTaxFormType.tf1040se, 'std-tax-form-1040se' )"/>

                <sidebar-item :link="{name: 'K-1', icon: 'nc-icon nc-grid-45',  path: `/se-eval/std-tax-form-1120ssk/${SeTaxFormType.tf1120ssk}/${taxYear}`}" v-show="isTaxFormIncluded(taxYear, SeTaxFormType.tf1120ssk, 'std-tax-form-1120ssk' )"/>
                <sidebar-item :link="{name: 'Form W2', icon: 'nc-icon nc-grid-45',  path: `/se-eval/std-tax-form-w2/${SeTaxFormType.tfw2}/${taxYear}`}" v-if="isTaxFormIncluded(taxYear, SeTaxFormType.tfw2, 'std-tax-form-w2', null, Enums.SeBusinessStructureType.sCorp )"/>
                <sidebar-item :link="{name: 'Form 1120S', icon: 'nc-icon nc-grid-45',  path: `/se-eval/std-tax-form-1120s/${SeTaxFormType.tf1120s}/${taxYear}/1`}" v-show="isTaxFormIncluded(taxYear, SeTaxFormType.tf1120s, 'std-tax-form-1120s' )"/>

                <sidebar-item :link="{name: 'K-1', icon: 'nc-icon nc-grid-45',  path: `/se-eval/std-tax-form-1065sk1/${SeTaxFormType.tf1065sk1}/${taxYear}`}" v-show="isTaxFormIncluded(taxYear, SeTaxFormType.tf1065sk1, 'std-tax-form-1065sk1' )"/>
                <sidebar-item :link="{name: 'Form W2', icon: 'nc-icon nc-grid-45',  path: `/se-eval/std-tax-form-w2/${SeTaxFormType.tfw2}/${taxYear}`}" v-if="isTaxFormIncluded(taxYear, SeTaxFormType.tfw2, 'std-tax-form-w2', null, Enums.SeBusinessStructureType.partnership )"/>
                <sidebar-item :link="{name: 'Form 1065', icon: 'nc-icon nc-grid-45',  path: `/se-eval/std-tax-form-1065/${SeTaxFormType.tf1065}/${taxYear}/1`}" v-show="isTaxFormIncluded(taxYear, SeTaxFormType.tf1065, 'std-tax-form-1065' )"/>

                <sidebar-item :link="{name: 'Form W2', icon: 'nc-icon nc-grid-45',  path: `/se-eval/std-tax-form-w2/${SeTaxFormType.tfw2}/${taxYear}`}" v-if="isTaxFormIncluded(taxYear, SeTaxFormType.tfw2, 'std-tax-form-w2', null, Enums.SeBusinessStructureType.corp)"/>
                <sidebar-item :link="{name: 'Form 1125-E', icon: 'nc-icon nc-grid-45',  path: `/se-eval/std-tax-form-1125e/${SeTaxFormType.tf1125e}/${taxYear}`}" v-show="isTaxFormIncluded(taxYear, SeTaxFormType.tf1125e, 'std-tax-form-1125e' )"/>
                <sidebar-item :link="{name: 'Form 1120', icon: 'nc-icon nc-grid-45',  path: `/se-eval/std-tax-form-1120/${SeTaxFormType.tf1120}/${taxYear}/1`}" v-show="isTaxFormIncluded(taxYear, SeTaxFormType.tf1120, 'std-tax-form-1120' )"/>

                <!-- both pages for 4562 are optional so we need to show menu for the enabled page-->
                <sidebar-item :link="{name: 'Form 4562', icon: 'nc-icon nc-grid-45',  path: `/se-eval/std-tax-form-4562/${SeTaxFormType.tf4562}/${taxYear}/1`}" v-if="isTaxFormIncluded(taxYear, SeTaxFormType.tf4562, 'std-tax-form-4562', '1')"/>
                <sidebar-item :link="{name: 'Form 4562', icon: 'nc-icon nc-grid-45',  path: `/se-eval/std-tax-form-4562/${SeTaxFormType.tf4562}/${taxYear}/2`}" v-else-if="isTaxFormIncluded(taxYear, SeTaxFormType.tf4562, 'std-tax-form-4562', '2' )"/>

                <sidebar-item :link="{name: 'Losses and Expenses', icon: 'nc-icon nc-grid-45',  path: `/se-eval/std-tax-form-losses-expenses/${SeTaxFormType.tfLossesExpenses}/${taxYear}`}" v-show="isTaxFormIncluded(taxYear, SeTaxFormType.tfLossesExpenses, 'std-tax-form-losses-expenses' )"/>
                <sidebar-item :link="{name: 'Form 4684', icon: 'nc-icon nc-grid-45',  path: `/se-eval/std-tax-form-4684/${SeTaxFormType.tf4684}/${taxYear}`}" v-show="isTaxFormIncluded(taxYear, SeTaxFormType.tf4684, 'std-tax-form-4684' )"/>
                <sidebar-item :link="{name: 'Form 8825', icon: 'nc-icon nc-grid-45',  path: `/se-eval/std-tax-form-8825/${SeTaxFormType.tf8825}/${taxYear}`}" v-show="isTaxFormIncluded(taxYear, SeTaxFormType.tf8825, 'std-tax-form-8825' )"/>

                <sidebar-item :link="{name: 'Form 1120S Sch M-3', icon: 'nc-icon nc-grid-45',  path: `/se-eval/std-tax-form-1120sm3/${SeTaxFormType.tf1120sm3}/${taxYear}`}" v-show="isTaxFormIncluded(taxYear, SeTaxFormType.tf1120sm3, 'std-tax-form-1120sm3' )"/>
                <sidebar-item :link="{name: 'Form 1065 Sch M-3', icon: 'nc-icon nc-grid-45',  path: `/se-eval/std-tax-form-1065sm3/${SeTaxFormType.tf1065sm3}/${taxYear}`}" v-show="isTaxFormIncluded(taxYear, SeTaxFormType.tf1065sm3, 'std-tax-form-1065sm3' )"/>
                <sidebar-item :link="{name: 'Form 1120 Sch M-3', icon: 'nc-icon nc-grid-45',  path: `/se-eval/std-tax-form-1120m3/${SeTaxFormType.tf1120m3}/${taxYear}`}" v-show="isTaxFormIncluded(taxYear, SeTaxFormType.tf1120m3, 'std-tax-form-1120m3' )"/>

                <sidebar-item :link="{name: 'Form W2', icon: 'nc-icon nc-grid-45',  path: `/se-eval/std-tax-form-w2/${SeTaxFormType.tfw2}/${taxYear}`}" v-if="isTaxFormIncluded(taxYear, SeTaxFormType.tfw2, 'std-tax-form-w2', null, Enums.SeBusinessStructureType.soleProprietorship)"/>

            </sidebar-item>
          </template>

        </sidebar-item>

        <sidebar-item :link="{name: 'Findings Report', icon: 'nc-icon nc-money-coins',  path: '/se-eval/eval-findings'}" v-if="menuEnabled"/>

      </template>

      <template slot-scope="props" slot="buttons">
        <div class="mt-3 d-flex justify-content-center nav-item nav-link" v-if="seRequest.requestId">
          <button class="bp-button bp-button--primary" @click="saveAndExit">Save or Manage Data</button>
        </div>
      </template>

    </side-bar>

    <div class="main-panel">
      <dashboard-content @click.native="toggleSidebar">
      </dashboard-content>
<!--      <content-footer></content-footer>-->
    </div>
  </div>

  </div>



</template>
<script>
  import TopNavbar from './TopNavbar.vue'
  import ContentFooter from './ContentFooter.vue'
  import DashboardContent from './Content.vue'
  import PerfectScrollbar from 'perfect-scrollbar';
  import 'perfect-scrollbar/css/perfect-scrollbar.css';
  import {mapGetters, mapState} from 'vuex'
  import Enums from 'src/model/enums'
  import { Collapse, CollapseItem } from 'element-ui'
  import Swal from "sweetalert2";
  import {RESET_STATE, SAVE_SE_REQUEST, SET_STATE_DATA, SET_REQUEST_DATA} from "../../../store/actions/se";


  function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }

  export default {
    components: {
      TopNavbar,
      ContentFooter,
      DashboardContent,
      [Collapse.name]: Collapse,
      [CollapseItem.name]: CollapseItem
    },
    created() {
      this.Enums = Enums
      window.seSaveExitHandle = this.seSaveExitHandle
    },
    computed: {
      SeTaxFormType: () => Enums.SeTaxFormType,
      businessStructureEvaluated() {
        return this.$store.getters.getBusinessStructureEvaluatedAndDerived.evaluated
      },
      menuEnabled() {
        return this.seAcceptedTerms && this.seAccessAllowed
      },
      ...mapState({
        seAcceptedTerms: state => state.se.acceptedTerms,
        seAccessAllowed: state => state.se.accessAllowed,
        seRequest: state => state.se.request,
        seConf: state => state.se.conf,
        visitedFindings: state => state.se.visitedFindings
      }),
      ...mapGetters([
        'getSelectedTaxYearsWithForms',
        'isDisplaySelectTaxYear',
        'getTaxFormRoutes'
      ]),
    },
    methods: {
      isTaxFormIncluded(year, formId, routeName, part, businessStructure) {
        //uses the calculated routes to determine if the tax form should be shown in the menu
        let path = `/se-eval/${routeName}/${formId}/${year}`
        if (part) {
          path += `/${part}`
        }
        let isInRoutes = this.getTaxFormRoutes.some(r => r.includes(path))
        let isValidForBusinessStructure = businessStructure ? (businessStructure === this.businessStructureEvaluated) : true
        return isInRoutes && isValidForBusinessStructure
      },
      toggleSidebar () {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false)
        }
      },
      initScrollbar() {
        let docClasses = document.body.classList;
        let isWindows = navigator.platform.startsWith('Win');
        if (isWindows) {
          // if we are on windows OS we activate the perfectScrollbar function
          initScrollbar('main-panel');

          docClasses.add('perfect-scrollbar-on');
        } else {
          docClasses.add('perfect-scrollbar-off');
        }
      },
      seSaveExitHandle(action) {
        let confirmButtonText = 'Confirm'
        if (action === 'saveExit') {
          confirmButtonText = 'Save and Exit'
        } else if (action === 'startNew') {
          confirmButtonText = 'Start New'
        } else if (action === 'lockData') {
          confirmButtonText = 'Lock Data'
        } else if (action === 'hideData') {
          confirmButtonText = 'Hide Data'
        }

        Swal.fire({
          title: `Are you sure to ${confirmButtonText}?`,
          icon: 'question',
          buttonsStyling: false,
          customClass: { confirmButton: 'bp-button bp-button--primary', cancelButton: 'bp-button'},
          showCancelButton: true,
          confirmButtonText: confirmButtonText
        }).then((result) => {
            if (result.isConfirmed) {
              if (action === "saveExit") {
                this.saveRequest(false)
                this.exit(true)

              } else if (action === "startNew") {
                this.saveRequest(false)
                this.exit(false)

              } else if (action === "lockData") {
                this.$store.commit(SET_REQUEST_DATA, {locked: true})
                this.saveRequest(true)
                this.exit()

              } else if (action === "hideData") {
                this.$store.commit(SET_REQUEST_DATA, {hidden: true})
                this.saveRequest(true)
                this.exit()
              }
            }
        })
      },
      saveAndExit() {
        this.$store.commit(SET_STATE_DATA, {key: 'isSavingTaxFormData', value: true})

        let msgHtml = "<h2 class='text-center' style='color: red'><b>Don't lose your data - note the ID!</b><br></h2>"
        let msgIcon = "question"
        if (this.seRequest.duCaseFileId) {
          msgHtml += `<b>${this.seRequest.requestId}</b> is the Income Calculator ID for this evaluation. Make a note of this ID to view or copy this data or to view the findings report in the future. If you exit Income Calculator without retaining this ID, you will need to have the associated DU Casefile ID to enable a search.`
        } else {
          msgHtml += `<b>${this.seRequest.requestId}</b> is the Income Calculator ID for this transaction. Make a note of this ID to view or copy this data or to view the findings report in the future. If you exit Income Calculator without retaining this ID, you will need to enter the data again.`
        }

        msgHtml += "<div class='row mt-4'>"
        msgHtml += "<div class='col-3'>"
        msgHtml += `<button class='bp-button bp-button--primary w-75 mt-2' onclick='seSaveExitHandle("saveExit")'>Save and Exit</button>`
        msgHtml += `</div>`
        msgHtml += "<div class='col-9 bp-u-text-left'>"
        msgHtml += "Income Calculator automatically saves the input data as you move from screen to screen. If you are finished using Income Calculator, use the 'Save and Exit' button to exit to the Welcome screen."
        msgHtml += `</div>`
        msgHtml += `</div>`

        msgHtml += "<div class='row mt-4'>"
        msgHtml += "<div class='col-3'>"
        msgHtml += `<button class='bp-button bp-button--primary w-75 mt-2' onclick='seSaveExitHandle("startNew")'>Start New</button>`
        msgHtml += `</div>`
        msgHtml += "<div class='col-9 bp-u-text-left'>"
        msgHtml += "Income Calculator automatically saves the input data as you move from screen to screen. If you are finished with the current evaluation and want to start a new evaluation, use the 'Start New' button to return to the Start screen."
        msgHtml += `</div>`
        msgHtml += `</div>`

        if (this.visitedFindings) {
          msgHtml += "<div class='row mt-4'>"
          msgHtml += "<div class='col-3'>"
          msgHtml += `<button class='bp-button w-75 mt-2' onclick='seSaveExitHandle("lockData")'>Lock Data</button>`
          msgHtml += `</div>`
          msgHtml += "<div class='col-9 bp-u-text-left'>"
          msgHtml += "Use the Lock Data button to permanently lock the input data for this Income Calculator ID. If you lock the input data, you will be able to come back and view it or clone (copy) it, but you will not be able to make updates. Once the input data is locked, it cannot be unlocked."
          msgHtml += `</div>`
          msgHtml += `</div>`
        }

        msgHtml += "<div class='row mt-4'>"
        msgHtml += "<div class='col-3'>"
        msgHtml += `<button class='bp-button w-75 mt-2' onclick='seSaveExitHandle("hideData")'>Hide Data</button>`
        msgHtml += `</div>`
        msgHtml += "<div class='col-9 bp-u-text-left'>"
        msgHtml += "Use the Hide Data button to permanently make the data for this Income Calculator ID unsearchable in the future. If you hide the data, you will not be able to come back and view it or clone it. Once the data is hidden it cannot be unhidden. Before hiding the data, consider using the Export Data File selection to store an electronic copy of the data. You can import this file later, if you need to recreate it."
        msgHtml += `</div>`
        msgHtml += `</div>`

        Swal.fire({
          title: `Save or Manage Data`,
          width: '900px',
          html: msgHtml,
          showCancelButton: true,
          showConfirmButton: false,
          buttonsStyling: false,
          customClass: { confirmButton: 'bp-button bp-button--primary', cancelButton: 'bp-button'},
          cancelButtonText: "Close",
          focusCancel: true,
          icon: msgIcon
        }).then((result) => {
        })
      },
      async saveRequest(forceSave) {
        try {
            await this.$store.dispatch(SAVE_SE_REQUEST, forceSave);
        } catch (e) {
          await Swal.fire({
            title: `Could not save`,
            buttonsStyling: false,
            customClass: {confirmButton: 'bp-button bp-button--primary'},
            html:
              `Could not save Income Calculator data`
          })
        }
      },
      exit(displayReminder) {
        if (displayReminder) {
          let requestId = this.seRequest.requestId
          this.$router.push('/home/welcome')
          this.$store.commit(RESET_STATE)
          let timerInterval
          Swal.fire({
            title: 'Data saved',
            html: `Income Calculator ID for the previous transaction is <b>${requestId}</b>.`,
            timer: 30000,
            timerProgressBar: true,
            buttonsStyling: false,
            customClass: {confirmButton: 'bp-button bp-button--primary'}
          })
        } else {
          this.$router.push('/se-eval/start')
          this.$store.commit(RESET_STATE)
        }
      }
    },
    mounted() {
      this.initScrollbar()
    },
    watch: {
      $route (to, from) {
        if (this.seRequest.requestId && (to.path === '/se-eval/start' || to.path === '/home/welcome')) {
          this.saveAndExit()
        }
      }
    }
  }

</script>
