import Enums from "../../../../model/enums";
import {ty} from "./ty";

export default {
  id: Enums.SeTaxFormType.tf1065sk1,
  pdf: `static/forms/${ty}/${ty}_1065sk1.pdf`,
  route: `/se-eval/std-tax-form-1065sk1/${Enums.SeTaxFormType.tf1065sk1}/${ty}`,
  fields: [
    {
      id: "isBorrowerTrustGrantor",
      fieldName: "",
      label: "If the Partner’s name is a trust, make the appropriate selection",
      isBoolean: true,
      line: "If the Partner’s name is a trust, make the appropriate selection",
      hasHelp: true,
      isEmptyByDefault: "",
      pageHeader: "<table class='el-table el-table--mini w-75'><tr><td><b>Loan Application</b></td><td><b>K-1</b></td></tr><tr><td>Borrower's Social Security Number</td><td>Box E (Partner's SSN or TIN)</td></tr><tr><td>Borrower Name's</td><td>Box F (Partner's name)</td></tr></table>"
    },
    {
      id: "taxPeriodEndDate",
      fieldName: "topmostSubform[0].Page1[0].Pg1Header[0].ForCalendarYear[0].f1_3[0]",
      fieldHighlights: [
        "topmostSubform[0].Page1[0].Pg1Header[0].ForCalendarYear[0].f1_3[0]",
        "topmostSubform[0].Page1[0].Pg1Header[0].ForCalendarYear[0].f1_4[0]",
        "topmostSubform[0].Page1[0].Pg1Header[0].ForCalendarYear[0].f1_5[0]",
      ],
      label: "Tax year ending",
      line: "Header",
      isCurrency: false,
      isPositive: false,
      isEmptyByDefault: true,
      hasHelp: true
    },
    {
      id: "isFinalK1",
      fieldName: "topmostSubform[0].Page1[0].Pg1Header[0].c1_1[0]",
      label: "Final K-1",
      isBoolean: true,
      required: true,
      line: "Final K-1",
      hasHelp: true,
      defaultValue: "No"
    },
    {
      id: "isFinalReturnOverride",
      fieldName: "",
      label: "Final return",
      isBoolean: true,
      required: true,
      line: "Final return",
      hasHelp: false,
      defaultValue: "Yes"
    },
    {
      id: "partnerType",
      fieldName: "topmostSubform[0].Page1[0].LeftCol[0].c1_4[0]",
      fieldHighlights: [
        "topmostSubform[0].Page1[0].LeftCol[0].c1_4[0]",
        "topmostSubform[0].Page1[0].LeftCol[0].c1_4[1]"
      ],
      label: "Partner Type",
      required: true,
      line: "Line G",
      hasHelp: true,
      defaultValue: "generalPartner"
    },
    {
      id: "partnerShareOfCapitalEndingPct",
      fieldName: "topmostSubform[0].Page1[0].LeftCol[0].LineJTable[0].Capital[0].f1_19[0]",
      label: "Partner's share of capital ending percentage",
      line: "Line J",
      isCurrency: false,
      isPositive: true,
      isPercent: true,
      hasHelp: true
    },
    {
      id: "partnerShareOfLiabilitiesRecourseEnding",
      fieldName: "topmostSubform[0].Page1[0].LeftCol[0].LineK1Table[0].LineK1Table[0].Recourse[0].f1_25[0]",
      label: "Partner’s share of liabilities recourse ending amount",
      line: "Line K",
      isCurrency: true,
      isPositive: false,
      hasHelp: true
    },
    {
      id: "partnerEndingCapitalAccount",
      fieldName: "topmostSubform[0].Page1[0].LeftCol[0].LIneLTable[0].LineLTable[0].Row6[0].f1_31[0]",
      label: "Partner’s ending capital account amount",
      line: "Line L",
      isCurrency: true,
      isPositive: false,
      hasHelp: true
    },
    {
      id: "ordinaryBusinessIncomeLoss",
      fieldName: "topmostSubform[0].Page1[0].RightCol[0].RightCol1[0].f1_34[0]",
      label: "Ordinary business income (loss)",
      line: "Line 1",
      isCurrency: true,
      isPositive: false,
      hasHelp: true
    },
    {
      id: "netRentalRealEstateIncomeLoss",
      fieldName: "topmostSubform[0].Page1[0].RightCol[0].RightCol1[0].f1_35[0]",
      label: "Net rental real estate income (loss)",
      line: "Line 2",
      isCurrency: true,
      isPositive: false,
      hasHelp: true
    },
    {
      id: "otherNetRentalIncomeLoss",
      fieldName: "topmostSubform[0].Page1[0].RightCol[0].RightCol1[0].f1_36[0]",
      label: "Other net rental income (loss)",
      line: "Line 3",
      isCurrency: true,
      isPositive: false,
      hasHelp: true
    },
    {
      id: "guaranteedPaymentsForServices",
      fieldName: "topmostSubform[0].Page1[0].RightCol[0].RightCol1[0].f1_37[0]",
      label: "Guaranteed payments for services amount",
      line: "Line 4a",
      isCurrency: true,
      isPositive: true,
      hasHelp: true
    },
    {
      id: "guaranteedPaymentsForCapital",
      fieldName: "topmostSubform[0].Page1[0].RightCol[0].RightCol1[0].f1_38[0]",
      label: "Guaranteed payments for capital amount",
      line: "Line 4b",
      isCurrency: true,
      isPositive: true,
      hasHelp: true
    },
    {
      id: "guaranteedPaymentsToPartnersAlternative",
      fieldName: "",
      label: "There is less than a two-year history of receiving Guaranteed payments for services and/or capital. Describe the partnership and available documentation:",
      isBoolean: true,
      line: "There is less than a two-year history of receiving Guaranteed payments for services and/or capital. Describe the partnership and available documentation:",
      hasHelp: true,
      defaultValue: ""
    },
    {
      id: "ordinaryDividends",
      fieldName: "topmostSubform[0].Page1[0].RightCol[0].RightCol1[0].f1_41[0]",
      label: "Ordinary dividends",
      line: "Line 6a",
      isCurrency: true,
      isPositive: false,
      hasHelp: true
    },
    {
      id: "distributionCodeA",
      fieldName: "topmostSubform[0].Page1[0].RightCol[0].RightCol2[0].Line19[0]",
      fieldHighlights: [
        "topmostSubform[0].Page1[0].RightCol[0].RightCol2[0].Line19[0]",
        "topmostSubform[0].Page1[0].RightCol[0].RightCol2[0].f1_90[0]"
      ],
      label: "Are any codes A",
      line: "Line 19",
      isBoolean: true,
      isCurrency: false,
      defaultValue: "Yes"
    },
    {
      id: "distributionCodeAAmount",
      fieldName: "topmostSubform[0].Page1[0].RightCol[0].RightCol2[0].f1_89[0]",
      fieldHighlights: [
        "topmostSubform[0].Page1[0].RightCol[0].RightCol2[0].f1_89[0]",
        "topmostSubform[0].Page1[0].RightCol[0].RightCol2[0].f1_91[0]"
      ],
      label: "Code A, Line 19 amount",
      line: "Line 19 A",
      isCurrency: true,
      isPositive: false,
      hasHelp: true
    },
    {
      id: "isBusinessLiquidityAdequate",
      fieldName: "",
      label: "Business liquidity adequate confirmation",
      isBoolean: true,
      line: "Business liquidity adequate confirmation",
      hasHelp: false,
      defaultValue: "Yes"
    }
  ]
}

