import {mapGetters, mapState} from "vuex";
import Enums from "../model/enums";
import {
  SKIP_YEAR_TAX_FORMS,
  SET_TAX_FORM_DATA,
  SAVE_SE_REQUEST,
  SET_STATE_DATA,
  SKIP_BUSINESS_TAX_FORMS, PURGE_HIDDEN_TAX_FORMS
} from "../store/actions/se";
import Swal from "sweetalert2";
import {IconFlag, IconBook, IconArrowRight} from 'src/components/index'
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import {getTaxFormFieldValue, getTaxFormField} from "../util/seutil";

const currMaskType = createNumberMask({
  prefix: '$',
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: true,
  integerLimit: 9
});

const currPositiveMaskType = createNumberMask({
  prefix: '$',
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
  integerLimit: 9
});

const percentMaskType = createNumberMask({
  prefix: '',
  suffix: '%',
  allowDecimal: true,
  includeThousandsSeparator: false,
  allowNegative: false,
  integerLimit: 4,
  decimalLimit: 4
});

export default {
  components: {
    IconFlag, IconBook, IconArrowRight
  },
  data() {
    return {
      taxFormId: "",
      taxFormYear: "",
      pdf: "",
      tableData: [],
      seFields: [],
      skippedForm: false,
      fieldIsFocused: false,
      viewerLoaded: false,
      showPageHelp: false,
      showFieldHelp: {},
      currencyMask: currMaskType,
      currencyPositiveMask: currPositiveMaskType,
      percentMask: percentMaskType
    }
  },
  mounted() {
  },
  created() {
  },
  beforeRouteLeave(to, from, next) {
    //when user clicks on the left side nav this method is called
    //the "to" route may no longer be valid after we save the updates to the store
    this.saveToStore();

    //we need to handle this and check if we are going to a route which is still in the flow
    if (this.seTaxFormRoutes.includes(to.path) || !to.path.includes('std-tax-form')) {
      next();
    } else {
      next(false);
    }
  },
  computed: {
    seTaxFormRoutes() {
      return this.$store.getters.getTaxFormRoutes
    },

    ...mapState({
      seRequest: state => state.se.request,
      seConf: state => state.se.conf,
      seVisitedFindings: state => state.se.visitedFindings,
      isSavingTaxFormData: state => state.se.isSavingTaxFormData
    }),
    ...mapGetters([
      'getNumberOfSelectedTaxFormYears',
      'getPY1',
      'getPY2',
      'getCalCy',
      'getCalPy',
      'isLocked',
      'isEmploymentOwnershipInterestProvidedGte25OrNull',
      'isEmploymentOwnershipInterestProvidedLt25',
      'getEmploymentMonthsCount'
    ]),

  },

  watch: {
    //clear up the value when user selects is empty
    isEmpty(newValue, oldValue) {
      this.tableData.forEach(f => {
        if (f.isEmpty) {
          f.value = ''
        }
      })
    },

    //clear up the value when field is marked as skipped
    isSkipped(newValues, oldValues) {
      if (Array.isArray(newValues)) {
        newValues.forEach((v, index) => {
          if (v) {
            this.tableData[index].value = this.tableData[index].defaultValue ?? ''
          }
        })
      }
    },

    //triggered when during global saving event
    isSavingTaxFormData(isSaving) {
      if (isSaving) {
        this.saveToStore()
        this.$store.commit(SET_STATE_DATA, {key: 'isSavingTaxFormData', value: false})
      }
    }
  },

  methods: {
    next() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.saveToStore();
      this.saveRequest();
      let ri = this.seTaxFormRoutes.indexOf(this.$router.currentRoute.path);
      if (ri < this.seTaxFormRoutes.length - 1) {
        this.$router.push(this.seTaxFormRoutes[ri + 1])
      } else {
        this.$router.push('/se-eval/eval-findings')
      }
    },
    previous() {
      let ri = this.seTaxFormRoutes.indexOf(this.$router.currentRoute.path);
      if (ri > 0) {
        this.$router.push(this.seTaxFormRoutes[ri - 1])
      } else {
        this.$router.push('/se-eval/tax-form-select')
      }
    },

    saveToStore() {
      let storedData = this.tableData;
      storedData = storedData.map(r => ({
        // fieldName: r.fieldName,
        id: r.id,
        isEmpty: r.isEmpty,
        value: r.value.replace(/,|\$|\%/g, '')
      }));
      this.$store.commit(SET_TAX_FORM_DATA, { year: this.taxFormYear, formId: this.taxFormId, skipped: this.skippedForm, fields: storedData })
      this.$store.dispatch(PURGE_HIDDEN_TAX_FORMS);
    },

    async saveRequest() {
      try {
        await this.$store.dispatch(SAVE_SE_REQUEST);
      } catch (e) {
        let extraMsg = this.seRequest.locked ? `<p>The data has been locked by a user. The data may be cloned (copied) to a new Income Calculator ID, where updates can be saved.</p><p>From the Start screen, search for the locked Income Calculator ID. Select "Clone Existing Evaluation" to copy the data.</p>` : ``

        await Swal.fire({
          title: `Could not save`,
          buttonsStyling: false,
          customClass: {confirmButton: 'bp-button bp-button--primary'},
          html:
            `<p>Could not save Income Calculator data</p>`
            + extraMsg
        })
      }
    },

    onViewerLoaded() {
      this.viewerLoaded = true;
    },

    highlightFocusedFormField(row) {
      if (this.$refs.viewer) {
        this.$refs.viewer.highlightField(row.fieldHighlights, 1, 0);
        this.fieldIsFocused = true;
      }
    },
    highlightHoveredOverFormField(row) {
      if (this.$refs.viewer) {
        if (!this.fieldIsFocused) {
          if (row) {
            this.$refs.viewer.highlightField(row.fieldHighlights, 1, 0);
          } else {
            this.$refs.viewer.highlightField([], 1, 0);
          }
        }
      }
    },

    getValueAsNumber(fieldId) {
      let value = Number(this.tableData.find(f => f.id === fieldId)?.value.replace(/,|\$|\%/g, '') ?? 0)
      return Number.isNaN(value) ? 0 : value
    },

    getValue(fieldId) {
      return this.tableData.find(f => f.id === fieldId)?.value
    },

    getFormFieldValueAsNumber(formId, fieldId) {
      return Number(this.getFormFieldValue(formId, fieldId)?.replace(/,|\$|\%/g, '')) ?? 0
    },

    getFormFieldValue(formId, fieldId) {
      return getTaxFormFieldValue(this.seRequest.taxData, this.taxFormYear, formId, fieldId)
    },

    getYearFormFieldValue(year, formId, fieldId) {
      return getTaxFormFieldValue(this.seRequest.taxData, year, formId, fieldId)
    },

    getFormField(formId, fieldId) {
      return getTaxFormField(this.seRequest.taxData, this.taxFormYear, formId, fieldId)
    },

    getField(fieldId) {
      return this.tableData.find(f => f.id === fieldId)
    },

    setFieldEmpty(fieldId) {
      let f = this.tableData.find(f => f.id === fieldId)
      if (f) f.isEmpty = true
    },

    skipTaxForm() {
      this.skippedForm = true
      this.next()
    },
    unSkipTaxForm() {
      this.skippedForm = false
    },

    handleFieldHelp(fieldId) {
      this.showFieldHelp[fieldId] = true
    },

    getFieldMask(field) {
      let mask = ''
      if (field.isCurrency) {
        if (field.isPositive) {
            mask = this.currencyPositiveMask
        } else {
          mask = this.currencyMask
        }
      } else if (field.isPercent) {
        mask = this.percentMask
      }
      return mask
    },

    getPreparedStoredData(value, row) {
      let res = value
      if (value && row) {

        if (row.isBoolean) {
          let valueUpper = value.toUpperCase()
          if (valueUpper === "TRUE") {
            res = Enums.SeYesNoType.Yes
          } else if (valueUpper === "FALSE") {
            res = Enums.SeYesNoType.No
          }

        } else if (row.isInteger) {
          if (value.includes(".")) {
            try {
              let i = parseInt(value)
              res = isNaN(i) ? "0" : i.toString()
            } catch (err) {
              //noop
            }
          }
        } else if (row.isCurrency) {
          if (value.startsWith("0")) {
            try {
              res = Number(value).toString()
            } catch (err) {
              //noop
            }
          }
        }

      }
      return res;
    },

    checkHasAnswer(field) {
      return this.skippedForm || this.isLocked || field.value || field.isEmpty || this.tableData.some((f, index) => f.id === field.id && this.isSkipped[index])
    },
    checkRequired(field) {
      if (!this.skippedForm && !this.isLocked && field.required) {
        return field.value && !field.isEmpty
      }
      return true
    },
    withinLimitsOrEmpty(field, min, max) {
      if (!field || field.isEmpty) return true
      let numValue = Number(field.value.replace(/,|\$|\%/g, '')) ?? 0
      return min <= numValue && numValue <= max
    },
    setFieldFocus(focusFirstWhenNoLoc) {
      let fieldToSelect = undefined

      if (focusFirstWhenNoLoc) {
        fieldToSelect = this.tableData[0];
      }
      if (this.$route.query && this.$route.query.loc) {
        let selectFieldId = this.$route.query.loc;
        fieldToSelect = this.tableData.find(r => r.id === selectFieldId) ?? this.tableData[0]
      }

      if (fieldToSelect) {
        this.$nextTick(() => {
          setTimeout(() => {
            let el = document.getElementById(fieldToSelect.id)
            if (el) {
              el.focus()
              if (this.$route.query && this.$route.query.loc) {
                el.select()
              }
            }
          }, 250);
        })
      }
    },
    equalZeroWithTolerance(value) {
      return value != null && Math.abs(value) < 0.001
    },
    notEqualZeroWithTolerance(value) {
      return value != null && Math.abs(value) > 0.001
    }

  }

}
