<template>
  <div>

    <h1 class="se-bp-title mt-0">
      Findings Report
      <a class="btn-link se-page-help-icon" v-tooltip.top-center="'Learn More'" @click="showHelpLenderInstr = true">
        <i class="fa fa-info-circle"></i>
      </a>
    </h1>

    <div class="row mb-2">
      <div class="col-auto">
        <div>
          {{appName}} ID
        </div>
        <div>
          Monthly Qualifying Income
        </div>
        <div>
          Business Structure / Ownership&nbsp;%
        </div>
        <div>
          Employment Start Date
        </div>
        <div v-if="isEmploymentOwnershipInterestProvidedGte25OrNull">
          <a href="#" class="btn-link se-help-icon" @click.prevent="showComparativeIncomeAnalysis">
            Comparative Income Analysis
          </a>
        </div>
        <div v-if="busLiq">
          <a href="#" class="btn-link se-help-icon" @click.prevent="showBusinessLiquidityAnalysis">
            Business Liquidity Analysis
          </a>
        </div>
        <div v-if="seRequest.duCaseFileId && seRequest.duData && seRequest.duData.associationSuccessIndicator">
          Associated to Desktop Underwriter
        </div>

      </div>

      <div class="col">
        <div>
          {{seRequest.requestId}}
          <a class="btn-link se-help-icon" v-tooltip.top-center="'Learn More'" @click="showHelpReqId = true">
            <i class="fa fa-info-circle"></i>
          </a>
        </div>

        <div>
          {{seResponse.monthlyQualifiedIncome | toCurrency(true)}}
          <a class="btn-link se-help-icon" v-tooltip.top-center="'Learn More'" @click="showHelpIncome = true">
            <i class="fa fa-info-circle"></i>
          </a>
        </div>

        <div>
          {{getBusinessStructureEvaluatedAndDerived.derived}} / {{ownershipPct}}
          <a class="btn-link se-help-icon" v-tooltip.top-center="'Learn More'" @click="showHelpIncomeBusStructure = true">
            <i class="fa fa-info-circle"></i>
          </a>
        </div>

        <div>
          {{employmentStartDate}}
          <a class="btn-link se-help-icon" v-tooltip.top-center="'Learn More'" @click="showHelpEmploymentStartDate = true">
            <i class="fa fa-info-circle"></i>
          </a>
        </div>

        <div v-if="isEmploymentOwnershipInterestProvidedGte25OrNull">
          <template v-if="cmpIncomeAnalysisResult === Enums.SeAnalysisResultType.satisfactory">
            <icon-base width="16" height="16" iconColor="rgba(9,134,32,1)" icon-name="checkMark"><icon-check-mark/></icon-base>
          </template>
          <template v-else-if="cmpIncomeAnalysisResult === Enums.SeAnalysisResultType.needsReview">
            <icon-base width="16" height="16" iconColor="rgba(189, 43, 43, 1)" icon-name="checkMark"><icon-warning/></icon-base>
            {{cmpIncomeAnalysisResult}}
          </template>
          <a href="#" class="btn-link se-help-icon ml-1" v-tooltip.top-center="'Learn More'" @click="showHelpCompIncomeAnalysis = true">
            <i class="fa fa-info-circle"></i>
          </a>
        </div>

        <div v-if="busLiq">
          <template v-if="busLiquidityAnalysisResult === Enums.SeAnalysisResultType.satisfactory">
            <icon-base width="16" height="16" iconColor="rgba(9,134,32,1)" icon-name="checkMark"><icon-check-mark/></icon-base>
          </template>
          <template v-else-if="busLiquidityAnalysisResult === Enums.SeAnalysisResultType.needsReview">
            <icon-base width="16" height="16" iconColor="rgba(189, 43, 43, 1)" icon-name="checkMark"><icon-warning/></icon-base>
            {{busLiquidityAnalysisResult}}
          </template>
          <a href="#" class="btn-link se-help-icon ml-1" v-tooltip.top-center="'Learn More'" @click="showHelpBusLiquidityAnalysis = true">
            <i class="fa fa-info-circle"></i>
          </a>
        </div>

        <div v-if="seRequest.duCaseFileId && seRequest.duData && seRequest.duData.associationSuccessIndicator">
          <span>{{seRequest.duCaseFileId}}</span><span class="ml-3">{{duData.automatedUnderwritingSubmissionDate}}</span>
          <a class="btn-link se-help-icon" v-tooltip.top-center="'Learn More'" @click="showHelpAssociated = true">
            <i class="fa fa-info-circle"></i>
          </a>
        </div>

      </div>
    </div>

    <template v-if="getBusinessStructureEvaluatedAndDerived.evaluated === Enums.SeBusinessStructureType.soleProprietorship">
      <se-eval-findings-sch-c ref="schCResults"/>
      <se-help :visible.sync="showHelpIncomeBusStructure">
        <p>
          A sole proprietorship is an unincorporated business that is individually owned and managed.
          The individual owner has unlimited personal liability for all debts of the business.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba" icon-name="icon-right-arrow"><icon-book/></icon-base>
          Learn more about Sole Proprietorships in the
          <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.2-02/business-structures">
            Business Structures
          </a>
          section of the Fannie Mae Selling Guide.
        </p>
        <p>
          All Sole Proprietorships are 100% owned by the borrower.
        </p>
      </se-help>

    </template>
    <template v-else-if="getBusinessStructureEvaluatedAndDerived.evaluated === Enums.SeBusinessStructureType.sCorp">
      <se-eval-findings-s-corp ref="sCorpResults"/>
      <se-help :visible.sync="showHelpIncomeBusStructure">
        <p>
          An S corporation is a legal entity that has a limited number of stockholders and elects not to be taxed as a regular corporation. Business gains and losses are passed on to the stockholders.
        </p>
        <p>
          A limited liability company (LLC) is a hybrid business structure that is designed to offer its member-owners the tax efficiencies of a partnership or sole proprietorship and the limited liability advantages of a corporation.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba" icon-name="icon-right-arrow"><icon-book/></icon-base>Learn more about an S corporation in the
          <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.2-02/business-structures">
            Business Structures
          </a>
          section of the Fannie Mae Selling Guide.
        </p>
        <p>
          The percentage of the business owned by the borrower is reported on Schedule K-1, Line G.
        </p>
      </se-help>
    </template>

    <template v-else-if="getBusinessStructureEvaluatedAndDerived.evaluated === Enums.SeBusinessStructureType.partnership">
      <se-eval-findings-partnership ref="partnershipResults"/>
      <se-help :visible.sync="showHelpIncomeBusStructure">
        <p>
          A partnership is an arrangement between two or more individuals who
          have pooled their assets and skills to form a business and who will share profits and
          losses according to predetermined proportions that are set out in the partnership agreement.
        </p>
        <p>
          A limited liability company (LLC) is a hybrid business structure that is
          designed to offer its member-owners the tax efficiencies of a partnership
          or sole proprietorship and the limited liability advantages of a corporation.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba" icon-name="icon-right-arrow"><icon-book/></icon-base>Learn more about Partnerships in the
          <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.2-02/business-structures">
            Business Structures
          </a>
          section of the Fannie Mae Selling Guide.
        </p>
        <p>
          The percentage of the business owned by the borrower is reported on Schedule K-1, Line J.
        </p>
      </se-help>
      <se-help :visible.sync="showHelpQuickRatio">
        <div class="se-field-help">
          <p>
            The Quick Ratio is the sum of Total Current Assets (excluding Inventory) divided by the Total Current Liabilities.
          </p>
          <p>
            <icon-base width="22" height="22" iconColor="#0c77ba" icon-name="icon-flag"><icon-book/></icon-base>
            Learn more about Borrower’s Proportionate Share of Income or Loss (including liquidity ratios) in
            the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.4-01/analyzing-partnership-returns-partnership-or-llc">Analyzing Returns for a Partnership</a> section of the Fannie Mae Selling Guide.
          </p>
        </div>
      </se-help>

      <se-help :visible.sync="showHelpCurrentRatio">
        <div class="se-field-help">
          <p>
            The Current Ratio is the sum of Total Current Assets (including Inventory) divided by the Total Current Liabilities.
          </p>
          <p>
            <icon-base width="22" height="22" iconColor="#0c77ba" icon-name="icon-flag"><icon-book/></icon-base>
            Learn more about Borrower’s Proportionate Share of Income or Loss (including liquidity ratios)
            in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.4-01/analyzing-partnership-returns-partnership-or-llc">Analyzing Returns for a Partnership</a> section of the Fannie Mae Selling Guide.
          </p>
        </div>
      </se-help>
    </template>

    <template v-else-if="getBusinessStructureEvaluatedAndDerived.evaluated === Enums.SeBusinessStructureType.corp">
      <se-eval-findings-corp ref="corpResults"/>
      <se-help :visible.sync="showHelpIncomeBusStructure">
        <p>
          A corporation is a state-chartered legal entity that
          exists separately and distinctly from its owners (who are called stockholders or shareholders).
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba" icon-name="icon-right-arrow"><icon-book/></icon-base>Learn more about Corporations in the
          <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.2-02/business-structures">
            Business Structures
          </a>
          section of the Fannie Mae Selling Guide.
        </p>
        <p>
          The percentage of the business owned by the borrower is reported on Form 1125-E, Line (d).
        </p>
      </se-help>
    </template>


    <div class="se-monthly-qualified-income-math" v-if="this.seResponse.monthlyQualifiedIncome || this.seResponse.monthlyQualifiedIncome === 0" >
      <b>Monthly Qualifying Income</b><span class="ml-3 mr-3">|</span>
      <template v-if="this.seResponse.monthlyQualifiedIncome !== 0">
        {{this.currYearTotalQualifiedIncome | toCurrency(true)}} +

        <template v-if="prevYearIncomeNotIncluded && findFirstMessageId(Enums.SePYQualifiedIncomeZeroMemos)"><a class="se-help-a" v-tooltip.top-center="'Learn More'" @click="displayMessageMemo(findFirstMessageId(Enums.SePYQualifiedIncomeZeroMemos))">{{ this.prevYearTotalQualifiedIncome | toCurrency(true) }}</a></template>
        <template v-else>{{ this.prevYearTotalQualifiedIncome | toCurrency(true) }}</template>
        = {{this.seResponse.totalQualifiedIncome | toCurrency(true)}}

        / {{this.seResponse.numberOfMonths}} months
        =
      </template>
        <span class="font-weight-bold">
          <template v-if="this.seResponse.monthlyQualifiedIncome === 0 && findFirstMessageId(Enums.SeMonthlyQualifiedIncomeZeroMemos)"><a class="se-help-a" v-tooltip.top-center="'Learn More'" @click="displayMessageMemo(findFirstMessageId(Enums.SeMonthlyQualifiedIncomeZeroMemos))">{{this.seResponse.monthlyQualifiedIncome | toCurrency(true)}}</a></template>
          <template v-else>{{this.seResponse.monthlyQualifiedIncome | toCurrency(true)}}</template>
        </span>
    </div>


    <div class="se-analysis">
      <el-collapse v-model="collapseAnalysis">
        <el-collapse-item name="cmpIncomeAnalysis" v-if="isEmploymentOwnershipInterestProvidedGte25OrNull">
          <div ref="cmpIncomeAnalysis"></div>
          <template slot="title">
            Comparative Income Analysis
            <span class="ml-2">
              <template v-if="cmpIncomeAnalysisResult === Enums.SeAnalysisResultType.satisfactory">
                <icon-base width="16" height="16" iconColor="rgba(9,134,32,1)" icon-name="checkMark"><icon-check-mark/></icon-base>
              </template>
              <template v-else-if="cmpIncomeAnalysisResult === Enums.SeAnalysisResultType.needsReview">
                <icon-base width="16" height="16" iconColor="rgba(189, 43, 43, 1)" icon-name="checkMark"><icon-warning/></icon-base>
                {{cmpIncomeAnalysisResult}}
              </template>
            </span>
            <a class="btn-link ml-1" v-tooltip.top-center="'Learn More'" @click.stop="showHelpCompIncomeAnalysis=true" >
              <i class="fa fa-info-circle"></i>
            </a>
          </template>
          <template v-if="!individualPriorYearReturnsProvided">
            Prior Year Tax Return Data Not Provided
          </template>
          <template v-else-if="!businessReturnsProvided">
            Business Tax Return Data Not Provided
          </template>
          <template v-else>
            <div class="table-responsive se-findings-table se-cmp-income-analysis-table mt-1" >
              <el-table
                :data="this.tableDataCmpIncomeAnalysis"
                row-key="id"
                :border="false"
                header-row-class-name="se-findings-table-header"
                :header-cell-class-name="headerCmpIncomeAnalysisCellClassName"
                :row-class-name="rowCmpIncomeAnalysisCellClassName"
                :cell-class-name="cellClassName">

                <el-table-column
                  property="name"
                  class-name="se-findings-item-column"
                  :min-width="150">
                </el-table-column>

                <el-table-column
                  :label="getPY1"
                  header-align="center"
                  align="right"
                  :width="185"
                  :min-width="40">
                  <template slot-scope="scope">
                    <div class="row" v-if="scope.row.id !== 'Total'">
                      <div class="col-8">{{ scope.row.currentYearTaxValue | toCurrency(scope.row.isCurrency) }}</div>
                      <div v-if="scope.row.currentYearFieldLocation">
                        <a href="#" @click="editField(scope.row.currentYearFieldLocation)">
                          <icon-base width="22" height="22" iconColor="rgba(0,0,0,0.80)" icon-name="Edit"><icon-edit/></icon-base>
                        </a>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="getPY2"
                  header-align="center"
                  align="right"
                  :width="185"
                  :min-width="40">
                  <template slot-scope="scope">
                    <div class="row" v-if="scope.row.id !== 'Total'">
                      <div class="col-8">{{ scope.row.pastYearTaxValue | toCurrency(scope.row.isCurrency) }}</div>
                      <div v-if="scope.row.pastYearFieldLocation">
                        <a href="#" @click="editField(scope.row.pastYearFieldLocation)">
                          <icon-base width="22" height="22" iconColor="rgba(0,0,0,0.80)" icon-name="Edit" ><icon-edit/></icon-base>
                        </a>
                      </div>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column
                  v-if="this.getNumberOfSelectedTaxFormYears > 1"
                  label="Change"
                  align="center"
                  class-name="cell-narrow se-eval-result-change"
                  :width="120"
                  :min-width="20">
                  <template slot-scope="scope">{{ scope.row.changePct | toPercent}}</template>
                </el-table-column>

              </el-table>

              <h4 class="ml-2 mt-3">
                Taxable Income Trend:
                <a class="btn-link se-help-icon mr-5" v-tooltip.top-center="'Learn More'" @click="showHelpTaxableIncomeTrend = true">
                  <i class="fa fa-info-circle"></i>
                </a>
                {{cmpIncomeAnalysisTrend}}
              </h4>
            </div>
          </template>
        </el-collapse-item>

        <el-collapse-item name="busLiquidityAnalysis" v-if="busLiq">
          <div ref="busLiquidityAnalysis"></div>
          <template slot="title">
            Business Liquidity Analysis
            <span class="ml-2">
              <template v-if="busLiquidityAnalysisResult === Enums.SeAnalysisResultType.satisfactory">
                <icon-base width="16" height="16" iconColor="rgba(9,134,32,1)" icon-name="checkMark"><icon-check-mark/></icon-base>
              </template>
              <template v-else-if="busLiquidityAnalysisResult === Enums.SeAnalysisResultType.needsReview">
                <icon-base width="16" height="16" iconColor="rgba(189, 43, 43, 1)" icon-name="checkMark"><icon-warning/></icon-base>
                {{busLiquidityAnalysisResult}}
              </template>
            </span>
            <a class="btn-link ml-1" v-tooltip.top-center="'Learn More'" @click.stop="showHelpBusLiquidityAnalysis=true" >
              <i class="fa fa-info-circle"></i>
            </a>
          </template>
          <template v-if="!businessReturnsProvided">
            Business Tax Return Data Not Provided
          </template>
          <template v-else>
            <div class="se-business-liquidity-analysis">

<!--              <template v-if="[Enums.SeBusinessStructureType.partnership, Enums.SeBusinessStructureType.sCorp].includes(getBusinessStructureEvaluatedAndDerived.evaluated)">-->
<!--                <div class="row">-->
<!--                  <div class="col-2">Average K-1 Income-->
<!--                    <a class="btn-link se-help-icon mr-5" v-tooltip.top-center="'Learn More'" @click="showHelpAverageK1Income = true">-->
<!--                      <i class="fa fa-info-circle"></i>-->
<!--                    </a>-->
<!--                  </div>-->
<!--                  <div class="col-3">{{busLiqCy.k1Income | toCurrency(true)}} <sub>{{busLiqCy.year}}</sub> {{busLiqPy.k1Income ? '+' : ''}} {{busLiqPy.k1Income | toCurrency(true)}} <sub>{{busLiqPy.year}}</sub> = </div>-->
<!--                  <div class="col-1 text-right">{{busLiq.totalK1IncomeAmount | toCurrency(true)}}</div>-->
<!--                </div>-->
<!--                <div class="row" v-if="[12,24].includes(busLiq.numberOfMonths)">-->
<!--                  <div class="col-2">Annual Average</div>-->
<!--                  <div class="col-3"></div>-->
<!--                  <div class="col-1 text-right">{{busLiq.totalK1IncomeAnnualAmount | toCurrency(true)}}</div>-->
<!--                </div>-->
<!--                <div class="row" v-else>-->
<!--                  <div class="col-2"></div>-->
<!--                  <div class="col-3">{{busLiq.totalK1IncomeAmount | toCurrency(true)}} / {{busLiq.numberOfMonths}} months = {{busLiq.monthlyK1IncomeAmount | toCurrency(true)}} * 12 = </div>-->
<!--                  <div class="col-1 text-right">{{busLiq.totalK1IncomeAnnualAmount | toCurrency(true)}}</div>-->
<!--                </div>-->
<!--                <div class="row mb-2">-->
<!--                  <div class="col-2">{{getPY1}} K-1 Distributions-->
<!--                    <a class="btn-link se-help-icon mr-5" v-tooltip.top-center="'Learn More'" @click="showHelpK1Distribution = true">-->
<!--                      <i class="fa fa-info-circle"></i>-->
<!--                    </a>-->
<!--                  </div>-->
<!--                  <div class="col-3"></div>-->
<!--                  <div class="col-1 text-right">{{busLiq.k1DistributionCY | toCurrency(true)}}</div>-->
<!--                  <div class="col-1 pl-0" v-if="busLiqK1DistributionCYLoc">-->
<!--                    <a href="#" @click="editField(busLiqK1DistributionCYLoc)">-->
<!--                      <icon-base width="20" height="20" iconColor="rgba(0,0,0,0.80)" icon-name="Edit"><icon-edit/></icon-base>-->
<!--                    </a>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </template>-->

              <span class="font-weight-bold">Liquidity Ratios</span>
              <div class="row">
                <div class="col-4">
                  <el-table
                    :data="this.tableDataBusLiqAnalysisCredit"
                    :border="false"
                    :show-header=false
                    row-class-name="se-findings-table-row"
                    :cell-class-name="cellClassName">

                    <el-table-column
                      property="name"
                      class-name="se-findings-item-column"
                      :min-width="50">
                    </el-table-column>

                    <el-table-column
                      :label="getPY1"
                      header-align="center"
                      align="right"
                      :width="185"
                      :min-width="40">
                      <template slot-scope="scope">
                        <hr class="se-cell-underline" v-if="scope.row.isUnderlined">
                        <div class="row">
                          <div class="col-8">{{ scope.row.cyValue | toCurrency(scope.row.isCurrency) }}</div>
                          <div v-if="scope.row.fieldLocation">
                            <a href="#" @click="editField(scope.row.fieldLocation)">
                              <icon-base width="22" height="22" iconColor="rgba(0,0,0,0.80)" icon-name="Edit"><icon-edit/></icon-base>
                            </a>
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <div class="col-4">
                  <el-table
                    :data="this.tableDataBusLiqAnalysisDebit"
                    :border="false"
                    :show-header=false
                    row-class-name="se-findings-table-row"
                    :cell-class-name="cellClassName">

                    <el-table-column
                      class-name="se-findings-item-column"
                      :min-width="50">
                      <template slot-scope="scope">
                        <div>{{scope.row.name}}
                          <a class="se-help-icon btn-link" v-tooltip.top-center="'Learn More'" @click="$data[scope.row.helpFlag] = true" v-show="scope.row.hasHelp">
                            <i class="fa fa-info-circle"></i>
                          </a>
                          <span v-if="scope.row.name2"><br>{{ scope.row.name2 }}</span>
                        </div>
                      </template>
                    </el-table-column>

                    <el-table-column
                      :label="getPY1"
                      header-align="center"
                      align="right"
                      :width="185"
                      :min-width="40">
                      <template slot-scope="scope">
                        <hr class="se-cell-underline" v-if="scope.row.isUnderlined">
                        <div class="row">
                          <div class="col-8">{{ scope.row.cyValue | toCurDecimal(scope.row.isCurrency, scope.row.isDecimal) }}</div>
                          <div v-if="scope.row.fieldLocation">
                            <a href="#" @click="editField(scope.row.fieldLocation)">
                              <icon-base width="22" height="22" iconColor="rgba(0,0,0,0.80)" icon-name="Edit"><icon-edit/></icon-base>
                            </a>
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
          </template>

        </el-collapse-item>
      </el-collapse>
    </div>

    <br>
    <h4 class="mt-0 mb-3 se-bp-message-title">{{appName}} Messages</h4>

    <div class="row mb-1" v-if="this.$store.state.se.status === Enums.SeStatus.error">
      <span class="ml-3 mr-3 se-bp-message">1. {{ this.$store.state.se.errorMessage}}</span>
    </div>


    <div class="mb-1" v-for="grpMessages in this.groupedMessages">
      <h4 class="mt-4 mb-3 se-bp-message-header">{{grpMessages.header}}</h4>

      <div class="row mb-1" v-for="indexedMsg in grpMessages.msgs">
            <div class="mb-2 ml-3 mr-3 se-bp-message">
              {{indexedMsg.index}}. {{ indexedMsg.msg.msg }}
            </div>
      </div>
    </div>

    <se-help :visible.sync="showHelpReqId" title="Learn More">
      <p>
        <b>FAQs:</b><br>
        Q. What is the {{ appName }} ID? How is it used?<br>
        A. {{ appName }} generates an ID for each new evaluation. You can
        use the {{ appName }} ID to retrieve and update previous inputs or to
        print or export previous Findings Reports. Knowing the {{ appName }}
        ID prevents you from having to reinput the tax return data. Make sure you note
        the {{ appName }} ID if you don’t save or print the Findings Report.

      </p>
      <p>
        Q. Does the {{ appName }} ID ever expire?<br>
        A. {{ appName }} IDs linked to a DU Casefile ID
        are archived when the DU Casefile ID is archived. DU Income
        Underwriter IDs that are not linked to a DU Casefile ID are
        archived 180 days from the last {{ appName }} update.

      </p>
    </se-help>

    <se-help :visible.sync="showHelpEmploymentStartDate" title="Learn More">
      <p>
        <b>FAQs:</b><br>
        Q. How does the {{ appName }} use the employment start date?<br>
        A. {{ appName }} requires an accurate employment start date to correctly calculate the monthly qualifying income.
      </p>
    </se-help>

    <se-help :visible.sync="showHelpIncome" size="60%" title="Learn More">
      <p>
        <b>FAQs:</b><br>
        Q. What is the Monthly Qualifying Income? How is it used?<br>
        A. {{ appName }} determines the amount of monthly income from this employment that can be
        used for loan qualification. This amount may be included in submissions to Desktop
        Underwriter (DU).
      </p>
      <p>
        Q. Why is the Monthly Qualifying Income amount zero?<br>
        A. {{ appName }} will return zero as the monthly qualifying income if:
      </p>

      <ol>
        <li>
          The Employment Start Date is within the past 12 months,
        </li>
        <li>
          The user indicated the borrower's business ownership percentage is greater than 100,
        </li>
        <li>
          The Employment Start Date is within the past 12-23 months and the user
          indicated the borrower does not have similar employment/occupation history,
        </li>
        <li>The business structure changed. For example, in the current year the business is structured as a Corporation,
          but in the prior year the business was structured as a Sole Proprietorship (Schedule C),
        </li>
        <li>The tax returns indicate the filing is the last one expected.
          Either the business has closed, or the borrower no longer has any ownership interest in the business.
          <ol type="a">
            <li>Corporation: identified in the header of Form 1120</li>
            <li>Partnership/LLC: identified in Schedule K-1</li>
            <li>S-Corporation/LLC: identified in Schedule K-1</li>
          </ol>
        </li>
        <li>The business operates on a non-calendar fiscal year.
        </li>
      </ol>
      <p>
        Q. How did {{ appName }} determine the number of months it used to average the borrower’s income?<br>
        A. {{ appName }} uses the year-over-year income trend and/or the Employment Start Date
        to determine how many months to use for income averaging.
      </p>
      <ul>
        <li>When one year of income data is provided, qualifying income will be averaged over 12 months.</li>
        <li>In most cases, when two years of income data are provided, the income will be averaged by 24 months. However:
          <ul>
            <li>if the income in the current year is significantly less than the income in the prior year, the current year income will be averaged over 12 months.</li>
            <li>if the Employment Start Date is within the past 23 months, the income will be averaged over the number of months of employment. The Employment Start Date can be viewed or updated on the Length of Employment screen.</li>
          </ul>
        </li>
      </ul>
      <p>
        Q. I input two years of tax return data into {{ appName }}. How can I delete the prior year’s
        tax data and have {{ appName }} only use the current year tax data?<br>
        A. On the Select Tax Forms screen, deselect the tax return forms for the prior year.
      </p>
      <p>
        Q. How do I enter self-employment income in Desktop Underwriter (DU)?<br>
        A. See the following instructions to enter self-employment income in Desktop Underwriter (DU).
      </p>
      <img src="static/img/help-add-income-du.png" alt="crash"/>

    </se-help>

    <se-help :visible.sync="showHelpLenderInstr" title="Lender Instructions">
      <b>Lender Instructions</b><br>
      <ol class="">
        <li>
          Print the {{ appName }} Findings Report and store it in the loan file. (Use View/Save/Print PDF button at the bottom of the page.)
        </li>
        <li>
          If applicable, submit the loan to Desktop Underwriter (DU) using the Monthly Qualifying Income on the Findings Report and,
        </li>
        <li>
          Associate this evaluation to Desktop Underwriter (DU) on the Desktop Underwriter (DU) screen in {{ appName }} prior to loan delivery.
        </li>
        <li>
          The lender remains responsible for the data’s integrity (ensuring the data was transcribed correctly from the tax returns to {{ appName }}).
        </li>
      </ol>

      <p>
        <icon-base width="22" height="22" iconColor="#0c77ba" icon-name="icon-right-arrow"><icon-arrow-right/></icon-base>
        Select <b>Export Data</b> to create a file that contains the data displayed on the {{ appName }}
        Findings Report along with calculations and key input data used in the {{ appName }} evaluation.
      </p>
      <p>
        <b>FAQs:</b><br>
        Q. Why would I want to export a {{ appName }} data file?<br>
        A. You may want to export data for the following reasons:<br>
      <ol>
        <li>
          You want to store {{ appName }} Findings Report data in an electronic format.</li>
        <li>
          You want to share the {{ appName }} Findings Report data with a lending partner, such as a mortgage insurer, correspondent lender, or aggregator.</li>
      </ol>

      <p>
        Q. What export file types are supported?<br>
        A. {{ appName }} creates files with .JSON extensions.
      </p>
    </se-help>

    <se-help :visible.sync="showHelpDay1CertaintyNew" size="50%" title="Day One Certainty">

<!--      <p>-->
<!--        {{ appName }} provides lenders relief from enforcement of representations-->
<!--        and warranties for the monthly qualifying income amounts it calculates. The lender-->
<!--        remains responsible for the data’s integrity (ensuring the data was transcribed-->
<!--        correctly from the tax returns to the screens). Additional Day 1 Certainty (D1C) representation-->
<!--        and warranty relief for data integrity may be obtained using the DU validation Service (DVS).-->
<!--      </p><p>-->
<!--      <b>FAQs:</b><br>-->
<!--      Q. Does {{ appName }} validate I correctly input the tax return data?<br>-->
<!--      A. No. If you make a mistake inputting data into {{ appName }} you may not-->
<!--      get the correct calculation result. For example, if {{ appName }} asks you for the-->
<!--      amount shown on Line 4 of the tax returns, and you enter the amount-->
<!--      shown on Line 5 of the tax returns, {{ appName }} will not know-->
<!--      the amount is incorrect. The calculated income will not be eligible for representation and warranty relief.-->
<!--    </p><p>-->
<!--      Q. I used a proprietary lender or vendor system to extract tax return-->
<!--      data from PDF images of tax returns or retrieve tax return data from third-party e-filers.-->
<!--      Does {{ appName }} validate the submitted tax return data is correct?-->
<!--      <br>-->
<!--      A. No. If your proprietary lender or vendor system makes a mistake extracting-->
<!--      tax return data or maps it incorrectly to a {{ appName }} import file-->
<!--      you may not get the correct calculation result. The calculated income will not-->
<!--      be eligible for representation and warranty relief.-->
<!--    </p><p>-->
<!--      Q. What does it mean when it says, “Monthly Qualifying Income”?<br>-->
<!--      A. Fannie Mae will provide the lender relief from enforcement of representations and warranties for the-->
<!--      Monthly Qualifying Income calculated by {{ appName }}.-->
<!--    </p>-->
<!--      <p>-->
<!--        Q. What does it mean when it says, “if DU Casefile ID is associated”?<br>-->
<!--        A. Linking a DU Casefile ID is <b>required</b> to obtain the representation-->
<!--        and warranty relief for income calculation available with {{ appName }}.-->
<!--        When “if DU Casefile ID is associated” is displayed, you need-->
<!--        to associate the DU Casefile ID. Navigate to the Desktop Underwriter (DU) screen to do this.<br><br>-->

<!--        <a href="">More DU Casefile ID association help</a>-->
<!--      </p>-->
    </se-help>

    <se-help :visible.sync="showHelpDay1Certainty" title="Day One Certainty">
      <p>
        To obtain rep and warrant relief for the Monthly Qualifying Income calculated by {{ appName }},
        submit the loan to DU using the Monthly Qualifying Income on the Findings Summary.
        <br>
        <a href="#" @click="showHelpAddIncomeDu = true">Learn more about how to enter self-employment income in DU.</a>
      </p>
      <p>
        <span class="opacity-5">Ensure the {{ appName }} ID is included in your DU submission.</span>
        <br>
        <a href="#" @click="showHelpSubmitSeId = true">Learn more about how to enter the {{ appName }} ID in DU.</a>
      </p>

      <p>
        To obtain rep and warrant relief for the integrity of the data used to derive the Monthly Qualifying Income calculated by {{ appName }},
        collect a signed IRS Form 4506-C from the borrower and order Tax Transcripts from your DU Validation Service® (DVS®) vendor.
        <br>
        <a  target="_blank" href="https://singlefamily.fanniemae.com/applications-technology/desktop-underwriter-validation-service">Learn more about DVS.</a>
      </p>
    </se-help>

    <se-help :visible.sync="showHelpAssociated" title="">
      <p>This {{ appName }} evaluation has been associated (linked) to the displayed DU Casefile ID.
      </p>
      <P>
        <b>FAQs:</b><br>
        Q. How do I associate (link) a DU Casefile ID?<br>
        A. Navigate to the Desktop Underwriter (DU) screen and follow the instructions provided.
      </p>
      <P>
        Q. Do I need to associate (link) a DU Casefile ID to obtain the enforcement relief from
        representations and warranties related to the income calculation available with {{ appName }}?
        <br>
        A. Yes. Linking the DU Casefile ID used for loan delivery is <b>required</b> to obtain the relief from enforcement of representations
        and warranties for income calculation available with {{ appName }}. For manually underwritten loans, provide the DU Casefile ID generated by the UCD Collection Solution.
      </p>
      <P>
        Q. I resubmitted the loan to Desktop Underwriter (DU) after I associated it, is {{ appName }} automatically updated?
        <br>
        A. Yes. {{ appName }} retrieves the most recent Desktop Underwriter (DU) when it is associated (linked) and with each {{ appName }} resubmission.
        <br>
      </p>
      <p>
        <a href="">More DU Casefile ID association help</a>
      </p>

    </se-help>


    <se-help :visible.sync="showHelpCompIncomeAnalysis" title="Comparative Income Analysis">
      <p>
        A Comparative Income Analysis determines year-to-year trends in gross income, expenses, and taxable income.
        A Comparative Income Analysis will not be performed if the lender:<br>
      </p>
      <ul>
        <li>waives business tax returns, or</li>
        <li>provides {{  appName  }} only one year of tax return data.</li>
      </ul>
      <p>
        A check (<icon-base width="16" height="16" iconColor="rgba(9,134,32,1)" icon-name="checkMark"><icon-check-mark/></icon-base>) indicates the trend in overall
        business income calculated by Income Calculator is sufficient to demonstrate the business’ viability. No other lender action is required, even if
        year-over-year trends in gross income, expenses, and/or taxable income are declining.
      </p>
      <p>
        A caution (<icon-base width="16" height="16" iconColor="rgba(189, 43, 43, 1)" icon-name="checkMark"><icon-warning/></icon-base>) indicates the trend in
        overall business income calculated by Income Calculator is declining. This may occur even if the year-over-year trends in gross income, expenses, and/or
        taxable income are increasing or level. The lender must confirm (outside of Income Calculator) the stability of the borrower’s business income and
        the potential for long-term earnings.
      </p>
      <p>
        <icon-base width="22" height="22" iconColor="#0c77ba" icon-name="icon-right-arrow"><icon-flag/></icon-base>You must print the {{ appName }} Findings Report
        and store it in the loan file in order to obtain relief from enforcement of representations and warranties related to the income calculation.
      </p>
      <p>
        <icon-base width="22" height="22" iconColor="#0c77ba" icon-name="icon-right-arrow"><icon-book/></icon-base>Learn more about Analysis of Borrower’s Business Income in the
        <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.2-01/underwriting-factors-and-documentation-self-employed-borrower">Underwriting Factors and
          Documentation for a Self-Employed Borrower </a>
        section of the Fannie Mae Selling Guide.
      </p>
    </se-help>

    <se-help :visible.sync="showHelpBusLiquidityAnalysis" title="Business Liquidity Analysis">
      <p>
        The Business Liquidity evaluation confirms the business’ ability to support the borrower’s withdrawal of earnings.
        {{ appName }} evaluates business liquidity by analyzing actual distributions and by comparing assets to liabilities.
        A Business Liquidity evaluation will not be performed if business tax returns are waived.
      </p>
      <p>
        A check (<icon-base width="16" height="16" iconColor="rgba(9,134,32,1)" icon-name="checkMark"><icon-check-mark/></icon-base>)
        indicates {{ appName }} has included income from Form K-1 in the monthly qualifying income calculation. In some cases, a
        message will appear in the {{ appName }} Findings Report requiring the lender to confirm (outside of {{ appName }})
        that the business has adequate liquidity to support the withdrawal of earnings; otherwise, no other documentation is required
        by Fannie Mae related to the Business Liquidity analysis.
      </p>
      <p>
        A caution (<icon-base width="16" height="16" iconColor="rgba(189, 43, 43, 1)" icon-name="checkMark"><icon-warning/></icon-base>)
        indicates {{ appName }} has not included income from Form K-1 in the monthly qualifying income calculation because adequate business liquidity
        cannot be demonstrated. Fannie Mae permits lender discretion in selecting the method used to confirm the business has adequate liquidity.
        If you can confirm (outside of {{ appName }}) that the business has adequate liquidity to support the withdrawal of earnings,
        indicate this on the K-1 screen and resubmit the evaluation; otherwise, the business income may not be used as qualifying income.
      </p>

      <p>
        <icon-base width="22" height="22" iconColor="#0c77ba" icon-name="icon-flag"><icon-flag/></icon-base>
        You must print the {{ appName }} Findings Report and store it in the loan file.
      </p>
      <p>
        <icon-base width="22" height="22" iconColor="#0c77ba" icon-name="icon-book"><icon-book/></icon-base>
        <template v-if="this.getBusinessStructureEvaluatedAndDerived.evaluated === Enums.SeBusinessStructureType.sCorp">
          Learn more about Borrower’s Proportionate Share of Income or Loss (and business liquidity) in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.4-02/analyzing-returns-s-corporation">Analyzing Returns for an S-Corporation section</a> of the Fannie Mae Selling Guide.
        </template>
        <template v-else-if="this.getBusinessStructureEvaluatedAndDerived.evaluated === Enums.SeBusinessStructureType.partnership">
          Learn more about Borrower's Proportionate Share of Income or Loss (and business liquidity) in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.4-01/analyzing-partnership-returns-partnership-or-llc">Analyzing Returns for a Partnership section</a> of the Fannie Mae Selling Guide.
        </template>
        <template v-else-if="this.getBusinessStructureEvaluatedAndDerived.evaluated === Enums.SeBusinessStructureType.corp">
          Learn more about Determining the Corporation’s Financial Position (and business liquidity) in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.4-03/analyzing-returns-corporation">Analyzing Returns for a Corporation section</a> of the Fannie Mae Selling Guide.
        </template>
      </p>
    </se-help>

    <se-help :visible.sync="showHelpAddIncomeDu"
             title="Adding Monthly Qualifying Income to DU"
             size="60%">
      <img src="static/img/help-add-income-du.png" alt="crash"/>
    </se-help>

    <se-help :visible.sync="showHelpSubmitSeId"
             title="Entering Income Calculator ID in DU"
             size="60%">
      <img src="static/img/help-submit-iu-id.png" alt="crash"/>
    </se-help>

    <se-help :visible.sync="showHelpTaxableIncomeTrend">
      <p>
        {{ appName }} determines the year-over-year trend in taxable income. The Taxable Income Trend will not be performed if only one year of tax return data is provided.
      </p>
      <p>
        A “Declining” trend indicates the taxable business income from the current year is less than the taxable business income from the prior year.
        If the overall Comparative Income Analysis is acceptable, no other lender action is required related to a declining Taxable Income Trend. 
      </p>
      <p>
        <icon-base width="22" height="22" iconColor="#0c77ba" icon-name="icon-flag"><icon-book/></icon-base>
        Learn more about Analysis of Borrower’s Business Income in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.2-01/underwriting-factors-and-documentation-self-employed-borrower">Underwriting Factors and Documentation for a Self-Employed Borrower</a> section of the Fannie Mae Selling Guide.
      </p>
    </se-help>

    <se-help :visible.sync="showHelpAverageK1Income">
      <div class="se-field-help">
        <p>
          {{ appName }} calculates the K-1 Income as the sum of Schedule K-1, Lines 1, 2, and 3 divided by the applicable number of months.
        </p>
        <p>
          <b>FAQs:</b>
        </p>
        <p>
          Q. How did {{ appName }} determine the number of months it used to average the borrower’s income?<br>
          A. {{ appName }} uses the year-over-year income trend and/or the Employment Start Date to determine how many months to use for income averaging.
        </p>
        <ul>
          <li>When the lender identifies Desktop Underwriter messaging permits income documentation using one year of tax returns, the income will be averaged over 12 months.</li>
          <li>In most cases, when two years of income data are provided, the income will be averaged over 24 months. However:
            <ul>
              <li>if the income in the current year is significantly less than the income from the prior year, the current year income will be averaged over 12 months.</li>
              <li>if the Employment Start Date is within the past 23 months, the income will be averaged by the number of months of employment. The Employment Start Date can be viewed or updated on the Length of Employment screen.</li>
            </ul>
          </li>
        </ul>
      </div>
    </se-help>

    <se-help :visible.sync="showHelpK1Distribution">
      <div class="se-field-help">
        <p>
          Schedule K-1, Line 16D.
        </p>
      </div>
    </se-help>

    <template v-if="getBusinessStructureEvaluatedAndDerived.evaluated !== Enums.SeBusinessStructureType.partnership">
      <se-help :visible.sync="showHelpQuickRatio">
        <div class="se-field-help">
          <p>
            The Quick Ratio is the sum of Total Current Assets (excluding Inventory) divided by the Total Current Liabilities.
          </p>

          <template v-if="this.getBusinessStructureEvaluatedAndDerived.evaluated === Enums.SeBusinessStructureType.sCorp">
            <icon-base width="22" height="22" iconColor="#0c77ba" icon-name="icon-flag"><icon-book/></icon-base>
            Learn more about Borrower’s Proportionate Share of Income or Loss (including liquidity ratios) in
            the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.4-02/analyzing-returns-s-corporation">Analyzing Returns for an S-Corporation section</a> of the Fannie Mae Selling Guide.
          </template>

          <template v-else-if="this.getBusinessStructureEvaluatedAndDerived.evaluated === Enums.SeBusinessStructureType.corp">
            <b>FAQs</b>
            <p>
              Q. The Quick Ratio is less than 1.00. Do I need to use another method to validate the borrower’s business income?<br>
              A. Not for a Corporation.
            </p>
          </template>

        </div>
      </se-help>

      <se-help :visible.sync="showHelpCurrentRatio">
        <div class="se-field-help">
          <p>
            The Current Ratio is the sum of Total Current Assets (including Inventory) divided by the Total Current Liabilities.
          </p>

          <template v-if="this.getBusinessStructureEvaluatedAndDerived.evaluated === Enums.SeBusinessStructureType.sCorp">
            <icon-base width="22" height="22" iconColor="#0c77ba" icon-name="icon-flag"><icon-book/></icon-base>
            Learn more about Borrower’s Proportionate Share of Income or Loss (including liquidity ratios)
            in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.4-02/analyzing-returns-s-corporation">Analyzing Returns for an S-Corporation section</a> of the Fannie Mae Selling Guide.
          </template>

          <template v-else-if="this.getBusinessStructureEvaluatedAndDerived.evaluated === Enums.SeBusinessStructureType.corp">
            <b>FAQs</b>
            <p>
              Q. The Current Ratio is less than 1.00. Do I need to use another method to validate the borrower’s business income?<br>
              A. Not for a Corporation.
            </p>
          </template>

        </div>
      </se-help>
    </template>

    <se-help :visible.sync="showHelpMessageMemo" title="Learn More">
      <p>
        {{showHelpMessageMemoText}}
      </p>
      <p v-if="showHelpMessageLearnMoreUrl">
        <a target="_blank" :href="showHelpMessageLearnMoreUrl">Learn more in the Fannie Mae Selling Guide</a>
      </p>
    </se-help>

    <se-buttons
      :display-next="false"
      :display-save="false"
      :display-submit="false"
      :display-export="true"
      :display-print="true"
      @seNext="next"
      @sePrevious="previous"
      @sePrint="showPdf"
      @seExportData="exportData">
    </se-buttons>

  </div>

</template>
<script>
    import {Radio, IconEdit, IconCheckMark, IconWarning, IconArrowRight, IconBook, IconFlag} from 'src/components/index'
    import LSwitch from 'src/components/Switch.vue'
    import SeButtons from './SeButtons.vue'
    import Swal from 'sweetalert2'
    import Enums from 'src/model/enums'
    import {EVAL_SE_REQUEST, SET_REQUEST_DATA, SET_STATE_DATA} from 'src/store/actions/se'
    import SeHelp from './SeHelp.vue'
    import {Collapse, CollapseItem, Drawer, Table, TableColumn} from "element-ui"
    import seApi from 'src/util/seapi'
    import seUtil from 'src/util/seutil'
    import xml2js from 'xml2js'
    import SeEvalFindingsSchC from './SeEvalFindingsSchC.vue'
    import SeEvalFindingsSCorp from './SeEvalFindingsSCorp.vue'
    import SeEvalFindingsPartnership from './SeEvalFindingsPartnership.vue'
    import SeEvalFindingsCorp from './SeEvalFindingsCorp.vue'
    import {FindingsMixin} from "src/mixins/FindingsMixin"
    import appNameMixin from '../../../mixins/AppNameMixin'
    export default {
        mixins: [FindingsMixin, appNameMixin],
        components: {
            Radio,
            LSwitch,
            SeButtons,
            SeHelp,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            [Drawer.name]: Drawer,
            IconEdit,
            [Collapse.name]: Collapse,
            [CollapseItem.name]: CollapseItem,
            IconCheckMark,
            IconWarning,
            SeEvalFindingsSchC,
            SeEvalFindingsSCorp,
            SeEvalFindingsPartnership,
            SeEvalFindingsCorp,
            IconArrowRight,
            IconBook,
            IconFlag
        },
        data() {
            return {
                showHelpReqId: false,
                showHelpIncome: false,
                showHelpEmploymentStartDate: false,
                showHelpLenderInstr: false,
                showHelpDay1Certainty: false,
                showHelpDay1CertaintyNew: false,
                showHelpAssociated:false,
                prevYearIncomeNotIncluded: false,
                showHelpIncomeBusStructure: false,
                showHelpAddIncomeDu: false,
                showHelpSubmitSeId: false,
                showHelpCompIncomeAnalysis: false,
                showHelpBusLiquidityAnalysis: false,
                showHelpTaxableIncomeTrend: false,
                showHelpAverageK1Income: false,
                showHelpK1Distribution: false,
                showHelpQuickRatio: false,
                showHelpCurrentRatio: false,

                cmpIncomeAnalysisTrend: '',
                cmpIncomeAnalysisResult: '',
                busLiquidityAnalysisResult: '',
                tableDataCmpIncomeAnalysis: [],
                tableDataBusLiqAnalysisCredit: [],
                tableDataBusLiqAnalysisDebit: [],
                collapseAnalysis: [],
                busLiqK1DistributionCYLoc: ''
            }
        },
        created() {
            this.Enums = Enums
        },
        mounted() {
            this.evaluate();

            this.$store.commit(SET_STATE_DATA, {key:'visitedFindings', value:true})
        },
        computed: {
            SeEvalRequestYearsType: () => Enums.SeEvalRequestYearsType,
            seTaxFormRoutes() {
              return this.$store.getters.getTaxFormRoutes
            },
            employmentStartDate() {
                return this.seResponse.details?.employmentStartDate
            },
            currYearTotalQualifiedIncome() {
                if (this.seResponse) {
                    let data = this.seResponse.taxYears?.find(ty => ty.year === this.getPY1)
                    if (data) {
                        return data.totalQualifiedIncome;
                    }
                }
                return 0;
            },
            prevYearTotalQualifiedIncome() {
                if (this.seResponse) {
                    let data = this.seResponse.taxYears?.find(ty => ty.year === this.getPY2)
                    if (data) {
                        return data.totalQualifiedIncome;
                    }
                }
                return 0;
            },
            day1Message() {
                let msg = ''
                if (this.seResponse && this.seRequest) {
                    if (!this.seResponse.incomePerSellingGuideAndTaxTranscriptMatch && this.seRequest.duCaseFileId) {
                        msg = 'Monthly Qualifying Income'
                    } else if (!this.seResponse.incomePerSellingGuideAndTaxTranscriptMatch && !this.seRequest.duCaseFileId) {
                        msg ='Monthly Qualifying Income, if Desktop Underwriter Casefile ID is associated'
                    } else if (this.seResponse.incomePerSellingGuideAndTaxTranscriptMatch && this.seRequest.duCaseFileId) {
                        msg ='Monthly Qualifying Income. Request DVS tax transcripts to validate data integrity'
                    } else if (this.seResponse.incomePerSellingGuideAndTaxTranscriptMatch && !this.seRequest.duCaseFileId) {
                        msg ='Monthly Qualifying Income, if Desktop Underwriter Casefile ID is associated. Request DVS tax transcripts to validate data integrity'
                    }
                }
                return msg
            },
            individualPriorYearReturnsProvided() {
                return this.seResponse?.details?.individualPriorYearReturnsProvidedIndicator
            },
            businessReturnsProvided() {
                if (this.getBusinessStructureEvaluatedAndDerived.evaluated === Enums.SeBusinessStructureType.soleProprietorship) {
                    return true
                }
                return this.seResponse?.details?.taxYears?.find(ty => ty.year === this.getPY1)?.businessReturnsProvidedIndicator
            },
            isEmploymentOwnershipInterestProvidedGte25OrNull() {
              return this.seResponse?.details?.employmentOwnershipInterest === Enums.EmploymentOwnershipInterestProvidedType.gte25
            },
            busLiq() {
                return this.seResponse?.details?.businessLiquidityAnalysis
            },
            busLiqCy() {
                return this.busLiq?.taxYears?.find(ty => ty.year === this.getPY1) ?? {}
            },
            busLiqPy() {
                return this.busLiq?.taxYears?.find(ty => ty.year === this.getPY2) ?? {}
            },
            ownershipPct() {
                let ownershipPct = ''
                if (this.getBusinessStructureEvaluatedAndDerived.evaluated === Enums.SeBusinessStructureType.soleProprietorship) {
                    ownershipPct = '100%'
                } else if ([Enums.SeBusinessStructureType.sCorp, Enums.SeBusinessStructureType.partnership, Enums.SeBusinessStructureType.corp].includes(this.getBusinessStructureEvaluatedAndDerived.evaluated)) {
                    let cyResponseFields = this.seResponse.taxYears?.find(ty => ty.year === this.getPY1) ?? {}
                    if (cyResponseFields.ownershipPercent) {
                        ownershipPct = cyResponseFields.ownershipPercent + '%'
                    }
                }
                return ownershipPct
            },
            duData() {
                return this.seRequest.duData ?? {}
            },
            groupedMessages() {
              let groupedMessages = []
              let index = 1
              this.seResponse.messages?.forEach(msg => {
                let header = this.getMessageHeader(msg)
                let groupedMessage = groupedMessages.find(grp => grp.header === header)
                if (groupedMessage) {
                  groupedMessage.msgs.push({'index': index++, 'msg': msg})
                } else {
                  groupedMessages.push({'header': header, 'msgs': [{'index': index++, 'msg': msg}]})
                }
              })
              return groupedMessages
            }
        },
        methods: {
            getMessageHeader(msg) {
              let header = '';
              if (msg.severity === 'rating' && msg.category === 'error') {
                header = 'Error'
              } else if (msg.severity === 'rating' && msg.category !== 'error') {
                header = 'Risk / Eligibility'
              } else if (msg.severity === 'eligibility' && msg.category === 'riskAssessment') {
                header = 'Risk / Eligibility'
              } else if (msg.severity === 'eligibility' && msg.category === 'employmentIncome') {
                header = 'Risk / Eligibility'
              } else if (msg.severity === 'eligibility' && msg.category !== 'employmentIncome' && msg.category !== 'riskAssessment') {
                header = 'Risk / Eligibility'
              } else if (msg.severity === 'dataIntegrity') {
                header = 'Data Integrity'
              } else if (msg.severity === 'verification') {
                header = 'Verifications / Conditions'
              } else if (msg.severity === 'observation') {
                header = 'Observations'
              }
              return header
            },
            showComparativeIncomeAnalysis() {
                if (!this.collapseAnalysis.includes("cmpIncomeAnalysis")) {
                    this.collapseAnalysis.push("cmpIncomeAnalysis")
                }
                this.$nextTick(() => {
                    setTimeout(() => {
                        const el = this.$refs.cmpIncomeAnalysis
                        if (el) {
                            el.scrollIntoView({behavior: 'smooth', block: 'center'});
                        }
                    }, 250)
                })
            },
            showBusinessLiquidityAnalysis() {
                if (!this.collapseAnalysis.includes("busLiquidityAnalysis")) {
                    this.collapseAnalysis.push("busLiquidityAnalysis")
                }
                this.$nextTick(() => {
                    setTimeout(() => {
                        const el = this.$refs.busLiquidityAnalysis
                        if (el) {
                            el.scrollIntoView({behavior: 'smooth', block: 'center'});
                        }
                    }, 250)
                })
            },
            next() {
            },
            previous() {
              if (this.seTaxFormRoutes.length > 0) {
                this.$router.push(this.seTaxFormRoutes[this.seTaxFormRoutes.length - 1])
              } else {
                this.$router.push('tax-form-select')
              }
            },
            cellClassName({row, column, rowIndex, columnIndex}) {
                let className = ''
                if ([1,2].includes(columnIndex)) {
                    className+=' se-findings-tax-return-columns';
                }
                if (columnIndex === 3) {
                    className+=' cell-no-border'
                }
                if (row.id === 'Total') {
                    className+= 'font-weight-bold'
                }
                return className;
            },
            headerCmpIncomeAnalysisCellClassName({row, column, rowIndex, columnIndex}) {
                let className = ''
                if (columnIndex === 0) {
                    className+='se-findings-table-items-header';
                }
                if ( [1,2].includes(columnIndex)) {
                    className+=' se-findings-tax-return-columns';
                }
                if (columnIndex === 3) {
                    className+=' se-findings-table-change-header';
                }
                return className;
            },
            rowCmpIncomeAnalysisCellClassName({row, column, rowIndex, columnIndex}) {
                let className = ''
                if ( [2, 5, 8].includes(rowIndex) ) {
                    className+='se-findings-table-row-underlined';
                }
                return className;
            },

            evaluate() {
                this.$store.dispatch(EVAL_SE_REQUEST).then(() => {

                    let prevResponseFields = this.seResponse.taxYears?.find(ty => ty.year === this.getPY2) ?? {}
                    if (prevResponseFields.totalIncome && prevResponseFields.totalIncome != 0 && prevResponseFields.totalQualifiedIncome === 0) {
                        this.prevYearIncomeNotIncluded = true
                    }

                    //comparative income analysis
                    let currTaxFormFieldsSchC = this.getTaxFormFields(this.getPY1, Enums.SeTaxFormType.tf1040c);
                    let prevTaxFormFieldsSchC = this.getTaxFormFields(this.getPY2, Enums.SeTaxFormType.tf1040c);

                    let comparativeIncomeAnalysis = this.seResponse?.details?.comparativeIncomeAnalysis
                    if (comparativeIncomeAnalysis) {
                        let cmpIncAnCY = comparativeIncomeAnalysis.taxYears?.find(ty => ty.year === this.getPY1) ?? {};
                        let cmpIncAnPY = comparativeIncomeAnalysis.taxYears?.find(ty => ty.year === this.getPY2) ?? {};

                        this.cmpIncomeAnalysisTrend = comparativeIncomeAnalysis.taxableIncomeTrend
                        this.cmpIncomeAnalysisResult = comparativeIncomeAnalysis.resultType

                        Object.entries(Enums.SeFindingsCmpIncomeAnalysisLineType).forEach( ([k,v]) => {
                            let row = {};
                            row.id = k
                            row.name = v.name
                            row.fieldLoc = v.fieldLoc
                            row.changePct = ''

                            if ([ Enums.SeFindingsCmpIncomeAnalysisLineType.expenseIncomePercent.id,
                                Enums.SeFindingsCmpIncomeAnalysisLineType.taxableIncomePercent.id].includes(k)) {
                                row.isCurrency = false
                            } else {
                                row.isCurrency = true
                            }

                            row.currentYearTaxValue = cmpIncAnCY[row.id] || 0
                            row.pastYearTaxValue = cmpIncAnPY[row.id] || 0

                            if (Enums.SeFindingsCmpIncomeAnalysisLineType.grossIncome.id === row.id) {
                                row.changePct = comparativeIncomeAnalysis.grossIncomeChange
                            } else if (Enums.SeFindingsCmpIncomeAnalysisLineType.expenses.id === row.id) {
                                row.changePct = comparativeIncomeAnalysis.expensesChange
                            } else if (Enums.SeFindingsCmpIncomeAnalysisLineType.taxableIncome.id === row.id) {
                                row.changePct = comparativeIncomeAnalysis.taxableIncomeChange
                            }

                            if (!row.isCurrency) {
                                row.currentYearTaxValue += '%'
                                row.pastYearTaxValue += '%'
                            }
                            this.tableDataCmpIncomeAnalysis.push(row)
                        })
                    }

                    //business liquidity
                    if (this.busLiq) {
                        this.busLiquidityAnalysisResult = this.busLiq.resultType

                        this.tableDataBusLiqAnalysisCredit.push({
                            fieldId: 'cashEndOfTaxYear',
                            name: "Cash",
                            cyValue: this.busLiqCy.cashEndOfTaxYear,
                            isCurrency: true
                        })
                        this.tableDataBusLiqAnalysisCredit.push({
                            fieldId: 'tradeNotesAndAccountsReceivableLessAllowanceEndOfTaxYear',
                            name: "Accounts receivable",
                            cyValue: this.busLiqCy.tradeNotesAndAccountsReceivableLessAllowanceEndOfTaxYear,
                            isCurrency: true
                        })
                        this.tableDataBusLiqAnalysisCredit.push({
                            fieldId: 'govObligationsEndOfTaxYear',
                            name: "U.S. government obligations",
                            cyValue: this.busLiqCy.govObligationsEndOfTaxYear,
                            isCurrency: true
                        })
                        this.tableDataBusLiqAnalysisCredit.push({
                            fieldId: 'taxExemptSecuritiesEndOfTaxYear',
                            name: "Tax-exempt securities",
                            cyValue: this.busLiqCy.taxExemptSecuritiesEndOfTaxYear,
                            isCurrency: true
                        })
                        this.tableDataBusLiqAnalysisCredit.push({
                            fieldId: 'otherCurrentAssetsEndOfTaxYear',
                            name: "Other current assets",
                            cyValue: this.busLiqCy.otherCurrentAssetsEndOfTaxYear,
                            isCurrency: true,
                        })
                        this.tableDataBusLiqAnalysisCredit.push({
                            name: "Assets Excluding Inventory",
                            cyValue: this.busLiqCy.assetsExcludingInventoryAmount,
                            isCurrency: true,
                            isUnderlined: true
                        })
                        this.tableDataBusLiqAnalysisCredit.push({
                            fieldId: 'inventoriesEndOfTaxYear',
                            name: "Inventory",
                            cyValue: this.busLiqCy.inventoriesEndOfTaxYear,
                            isCurrency: true
                        })
                        this.tableDataBusLiqAnalysisCredit.push({
                            name: "Total Current Assets",
                            cyValue: this.busLiqCy.totalCurrentAssetsAmount,
                            isCurrency: true,
                            isUnderlined: true
                        })

                        this.tableDataBusLiqAnalysisDebit.push({
                            fieldId: 'accountsPayableEndOfTaxYear',
                            name: "Accounts Payable",
                            cyValue: this.busLiqCy.accountsPayableEndOfTaxYear,
                            isCurrency: true
                        })
                        this.tableDataBusLiqAnalysisDebit.push({
                            fieldId: 'mortgagesNotesPayableLess1YrEndOfTaxYear',
                            name: "Mortgages, notes, bonds payable in less than 1 year",
                            cyValue: this.busLiqCy.mortgagesNotesPayableLess1YrEndOfTaxYear,
                            isCurrency: true
                        })
                        this.tableDataBusLiqAnalysisDebit.push({
                            fieldId: 'otherCurrentLiabilitiesEndOfTaxYear',
                            name: "Other current liabilities",
                            cyValue: this.busLiqCy.otherCurrentLiabilitiesEndOfTaxYear,
                            isCurrency: true,
                        })
                        this.tableDataBusLiqAnalysisDebit.push({
                            name: "Total Current Liabilities",
                            cyValue: this.busLiqCy.totalCurrentLiabilitiesAmount,
                            isCurrency: true,
                            isUnderlined: true
                        })
                        this.tableDataBusLiqAnalysisDebit.push({
                            name: "Quick Ratio (Acid-Test Ratio)",
                            name2: `${this.$options.filters.toCurrency(this.busLiqCy.assetsExcludingInventoryAmount, true)} / ${this.$options.filters.toCurrency(this.busLiqCy.totalCurrentLiabilitiesAmount, true)}`,
                            cyValue: this.busLiqCy.quickRatio,
                            hasHelp: true,
                            isDecimal: true,
                            helpFlag: 'showHelpQuickRatio'
                        })
                        this.tableDataBusLiqAnalysisDebit.push({
                            name: "Current Ratio (Working Capital Ratio)",
                            name2: `${this.$options.filters.toCurrency(this.busLiqCy.totalCurrentAssetsAmount, true)} / ${this.$options.filters.toCurrency(this.busLiqCy.totalCurrentLiabilitiesAmount, true)}`,
                            cyValue: this.busLiqCy.currentRatio,
                            hasHelp: true,
                            isDecimal: true,
                            helpFlag: 'showHelpCurrentRatio'
                        })
                    }

                    //specific processing per business structure
                    if (this.getBusinessStructureEvaluatedAndDerived.evaluated === Enums.SeBusinessStructureType.soleProprietorship) {
                        this.$refs.schCResults.handleEvalResults()

                        //comparative analysis field locations
                        this.tableDataCmpIncomeAnalysis.forEach(row => {
                            if (row.fieldLoc) {
                                let fieldLoc = row.fieldLoc[Enums.SeBusinessStructureType.soleProprietorship.id]
                                row.currentYearFieldLocation = this.getTaxFormFieldLocation(this.getPY1, Enums.SeTaxFormType.tf1040c, fieldLoc.formId, currTaxFormFieldsSchC, fieldLoc.fieldId);
                                row.pastYearFieldLocation = this.getTaxFormFieldLocation(this.getPY2, Enums.SeTaxFormType.tf1040c, fieldLoc.formId, prevTaxFormFieldsSchC, fieldLoc.fieldId);
                            }
                        })

                    } else if (this.getBusinessStructureEvaluatedAndDerived.evaluated === Enums.SeBusinessStructureType.sCorp) {
                        this.$refs.sCorpResults.handleEvalResults()
                        let cyFields1120s = this.getTaxFormFields(this.getPY1, Enums.SeTaxFormType.tf1120s);
                        let pyFields1120s = this.getTaxFormFields(this.getPY2, Enums.SeTaxFormType.tf1120s);

                        //comparative analysis field locations
                        this.tableDataCmpIncomeAnalysis.forEach(row => {
                            if (row.fieldLoc) {
                                let fieldLoc = row.fieldLoc[Enums.SeBusinessStructureType.sCorp.id]
                                let cyFields = cyFields1120s
                                let pyFields = pyFields1120s
                                if (fieldLoc.formId === Enums.SeTaxFormType.tf1120ssk) {
                                    cyFields = this.getTaxFormFields(this.getPY1, Enums.SeTaxFormType.tf1120ssk)
                                    pyFields = this.getTaxFormFields(this.getPY2, Enums.SeTaxFormType.tf1120ssk)
                                }
                                row.currentYearFieldLocation = this.getTaxFormFieldLocation(this.getPY1, Enums.SeTaxFormType.tf1120s, fieldLoc.formId, cyFields, fieldLoc.fieldId);
                                row.pastYearFieldLocation = this.getTaxFormFieldLocation(this.getPY2, Enums.SeTaxFormType.tf1120s, fieldLoc.formId, pyFields, fieldLoc.fieldId);
                            }
                        })

                        //business liquidity analysis field locations
                        let cyFields1120ssk = this.getTaxFormFields(this.getPY1, Enums.SeTaxFormType.tf1120ssk);
                        this.busLiqK1DistributionCYLoc = this.getTaxFormFieldLocation(this.getPY1, Enums.SeTaxFormType.tf1120s, Enums.SeTaxFormType.tf1120ssk, cyFields1120ssk, 'itemsAffectingShareholderBasisCodeDAmount')
                        this.tableDataBusLiqAnalysisCredit.forEach(row => {
                            if (row.fieldId) {
                                row.fieldLocation = this.getTaxFormFieldLocation(this.getPY1, Enums.SeTaxFormType.tf1120s, Enums.SeTaxFormType.tf1120s, cyFields1120s, row.fieldId);
                            }
                        })
                        this.tableDataBusLiqAnalysisDebit.forEach(row => {
                            if (row.fieldId) {
                                row.fieldLocation = this.getTaxFormFieldLocation(this.getPY1, Enums.SeTaxFormType.tf1120s, Enums.SeTaxFormType.tf1120s, pyFields1120s, row.fieldId);
                            }
                        })

                    } else if (this.getBusinessStructureEvaluatedAndDerived.evaluated === Enums.SeBusinessStructureType.partnership) {
                        this.$refs.partnershipResults.handleEvalResults()
                        let cyFields1065 = this.getTaxFormFields(this.getPY1, Enums.SeTaxFormType.tf1065);
                        let pyFields1065 = this.getTaxFormFields(this.getPY2, Enums.SeTaxFormType.tf1065);

                        //comparative analysis field locations
                        this.tableDataCmpIncomeAnalysis.forEach(row => {
                            if (row.fieldLoc) {
                                let fieldLoc = row.fieldLoc[Enums.SeBusinessStructureType.partnership.id]
                                let cyFields = cyFields1065
                                let pyFields = pyFields1065
                                if (fieldLoc.formId === Enums.SeTaxFormType.tf1065sk1) {
                                    cyFields = this.getTaxFormFields(this.getPY1, Enums.SeTaxFormType.tf1065sk1)
                                    pyFields = this.getTaxFormFields(this.getPY2, Enums.SeTaxFormType.tf1065sk1)
                                }
                                row.currentYearFieldLocation = this.getTaxFormFieldLocation(this.getPY1, Enums.SeTaxFormType.tf1065, fieldLoc.formId, cyFields, fieldLoc.fieldId);
                                row.pastYearFieldLocation = this.getTaxFormFieldLocation(this.getPY2, Enums.SeTaxFormType.tf1065, fieldLoc.formId, pyFields, fieldLoc.fieldId);
                            }
                        })

                        //business liquidity analysis field locations
                        let cyFields1065k = this.getTaxFormFields(this.getPY1, Enums.SeTaxFormType.tf1065sk1);
                        this.busLiqK1DistributionCYLoc = this.getTaxFormFieldLocation(this.getPY1, Enums.SeTaxFormType.tf1065, Enums.SeTaxFormType.tf1065sk1, cyFields1065k, 'distributionCodeAAmount')
                        this.tableDataBusLiqAnalysisCredit.forEach(row => {
                            if (row.fieldId) {
                                row.fieldLocation = this.getTaxFormFieldLocation(this.getPY1, Enums.SeTaxFormType.tf1065, Enums.SeTaxFormType.tf1065, cyFields1065k, row.fieldId);
                            }
                        })
                        this.tableDataBusLiqAnalysisDebit.forEach(row => {
                            if (row.fieldId) {
                                row.fieldLocation = this.getTaxFormFieldLocation(this.getPY1, Enums.SeTaxFormType.tf1065, Enums.SeTaxFormType.tf1065, pyFields1065, row.fieldId);
                            }
                        })

                    } else if (this.getBusinessStructureEvaluatedAndDerived.evaluated === Enums.SeBusinessStructureType.corp) {
                        this.$refs.corpResults.handleEvalResults()
                        let cyFields1120 = this.getTaxFormFields(this.getPY1, Enums.SeTaxFormType.tf1120);
                        let pyFields1120 = this.getTaxFormFields(this.getPY2, Enums.SeTaxFormType.tf1120);

                        //comparative analysis field locations
                        this.tableDataCmpIncomeAnalysis.forEach(row => {
                            if (row.fieldLoc) {
                                let fieldLoc = row.fieldLoc[Enums.SeBusinessStructureType.corp.id]
                                let cyFields = cyFields1120
                                let pyFields = pyFields1120
                                if (fieldLoc.formId === Enums.SeTaxFormType.tf1120) {
                                    cyFields = this.getTaxFormFields(this.getPY1, Enums.SeTaxFormType.tf1120)
                                    pyFields = this.getTaxFormFields(this.getPY2, Enums.SeTaxFormType.tf1120)
                                }
                                row.currentYearFieldLocation = this.getTaxFormFieldLocation(this.getPY1, Enums.SeTaxFormType.tf1120, fieldLoc.formId, cyFields, fieldLoc.fieldId);
                                row.pastYearFieldLocation = this.getTaxFormFieldLocation(this.getPY2, Enums.SeTaxFormType.tf1120, fieldLoc.formId, pyFields, fieldLoc.fieldId);
                            }
                        })

                        //business liquidity analysis field locations
                        this.tableDataBusLiqAnalysisCredit.forEach(row => {
                            if (row.fieldId) {
                                row.fieldLocation = this.getTaxFormFieldLocation(this.getPY1, Enums.SeTaxFormType.tf1120, Enums.SeTaxFormType.tf1120, cyFields1120, row.fieldId);
                            }
                        })
                        this.tableDataBusLiqAnalysisDebit.forEach(row => {
                            if (row.fieldId) {
                                row.fieldLocation = this.getTaxFormFieldLocation(this.getPY1, Enums.SeTaxFormType.tf1120, Enums.SeTaxFormType.tf1120, pyFields1120, row.fieldId);
                            }
                        })
                    }

                })
            },
            showPdf() {
                this.$notify({
                    component: {
                        template: `<span class="ml-2">Generating report for ${this.seRequest.requestId}</span>`
                    },
                    icon: 'nc-icon nc-cloud-download-93',
                    horizontalAlign: 'right',
                    verticalAlign: 'bottom',
                    type: 'info'
                })

                seApi.getPdfReport(this.seRequest.requestId, this.getHintAmount)
                    .then(resp => {
                        this.$notify({
                            component: {
                                template: `<span class="ml-2">Document is ready</span>`
                            },
                            icon: 'nc-icon nc-cloud-download-93',
                            horizontalAlign: 'right',
                            verticalAlign: 'bottom',
                            type: 'success'
                        })

                        const url = window.URL.createObjectURL(new Blob([resp.data]))
                        const link = document.createElement('a')
                        link.href = url
                        let filename = `${this.seRequest.requestId}.pdf`

                        link.setAttribute('download', filename)
                        document.body.appendChild(link)
                        link.click()

                        setTimeout(function () {
                            document.body.removeChild(link)
                            window.URL.revokeObjectURL(url)
                        },100)
                    })
                    .catch(resp => {
                        this.$notify({
                            component: {
                                template: `<span>Could not get the document</span>`
                            },
                            icon: 'nc-icon nc-app',
                            horizontalAlign: 'right',
                            verticalAlign: 'bottom',
                            type: 'warning'
                        })
                    })
            },
            async exportData() {
                const fnmSwal = Swal.mixin({
                    customClass: {
                        confirmButton: 'bp-button bp-button--primary',
                        cancelButton: 'bp-button'
                    },
                    buttonsStyling: false
                });

                const { value: fileType } = await fnmSwal.fire({
                    title: 'Export data',
                    input: 'select',
                    inputOptions: {
                        'json-response': 'Findings response json',
                        'xml-response': 'Findings response XML',
                        'json-request': 'Standard request json',
                        'json-ext-request': 'Expanded request json',
                        'xml-request': 'Standard request XML'
                    },
                    inputValue: 'json-response',
                    inputPlaceholder: 'Select file type',
                    showCancelButton: true,
                    confirmButtonText: "Export",
                    inputValidator: (value) => {
                        return new Promise((resolve) => {
                            if (value) {
                                resolve()
                            } else {
                                resolve('You need to select file type')
                            }
                        })
                    }
                })

                if (fileType) {
                    this.exportDataFile(fileType);
                }
            },
            exportDataFile(fileType) {
                let fileContent = ""
                let suffix = ''

                if (fileType === 'json-response') {
                    fileContent = JSON.stringify(this.seResponse, null, 2)
                    fileType = 'json'
                    suffix = 'Response'

                } else if (fileType === 'xml-response') {
                    let builder = new xml2js.Builder();
                    let xmlWrap = {
                        DUIU: {
                            $: {
                                "xmlns": "http://fanniemae.com/duiu"
                            },
                            ...this.seResponse
                        }
                    };
                    fileContent = builder.buildObject(xmlWrap);
                    fileType = 'xml'
                    suffix = 'Response'

                } else if (fileType === 'json-request') {
                    let exportRequest = JSON.parse(JSON.stringify(this.getPreparedRequest));
                    exportRequest.selectedTaxForms = null;
                    exportRequest.extraData = null;
                    exportRequest.duData = null;
                    exportRequest.duCaseFileId = null;
                    fileContent = JSON.stringify(exportRequest, null, 2)
                    fileType = 'json'
                    suffix = 'Request'

                } else if (fileType === 'json-ext-request') {
                    let exportRequest = JSON.parse(JSON.stringify(this.getPreparedRequest));
                    exportRequest.duData = null;
                    exportRequest.duCaseFileId = null;
                    fileContent = JSON.stringify(exportRequest, null, 2)
                    fileType = 'json'
                    suffix = 'Request'

                } else if (fileType === 'xml-request') {
                    let builder = new xml2js.Builder();
                    let xmlWrap = {
                      DUIU: {
                        $: {
                          "xmlns": "http://fanniemae.com/duiu"
                        },
                        ...this.seRequest
                      }
                    };
                    fileContent = builder.buildObject(xmlWrap);
                    fileType = 'xml'
                    suffix = 'Request'
                }
                this.processFile(fileContent, fileType, suffix)
            },
            processFile (fileContent, fileType, suffix) {
                let url = new Blob([fileContent], {type: 'application/' + fileType})
                let filename = `${this.seRequest.requestId}-${suffix}.${fileType}`
                if (navigator.msSaveBlob) {
                    navigator.msSaveBlob(url, filename)
                } else {
                    // In FF link must be added to DOM to be clicked
                    let objurl = window.URL.createObjectURL(url)
                    let link = document.createElement('a')
                    link.href = objurl
                    link.setAttribute('download', filename)
                    document.body.appendChild(link)
                    link.click()

                    setTimeout(function () {
                        document.body.removeChild(link)
                        window.URL.revokeObjectURL(objurl)
                    }, 100)
                }
            }

        }

    }
</script>
<style>


  .se-findings-table > div {
    border: 0;
    /*margin-right: 50px;*/
  }

  .se-findings-table .el-table:before {
    height: 0 !important;
  }
  .se-findings-table .el-table:after {
    width: 0 !important;
  }

  .el-table__row--level-1 .se-findings-item-column > div{
    text-indent: -36px;
    padding-left: 36px !important;
    word-break: break-word;
  }

  .se-findings-table-header {
    font-size: 1.1rem;
    color: rgba(0,0,0,0.75) ;
  }

  .se-findings-table-header > th {
    border-right: 0;
  }


  .se-findings-table-row > td {
    border-right: 0;
  }

  .se-findings-table-row-underlined > td {
    border-bottom-width: 3px !important;
  }

  .se-findings-item-column {
    padding-left: 0 !important;
    font-size: 1.0rem;
  }

  .se-findings-item-column-total {
    padding-left: 0 !important;
    font-size: 1.0rem;
    font-weight: bold;
  }

  .cell-no-border {
    border-top: 0 !important;
    border-bottom: 0
  }

  .se-findings-tax-return-columns {
    background-color: #F5F7FA !important;
    font-size: 1.0rem;
  }

  .se-findings-table-items-header {
    background-color: #fff !important;
  }

  .se-findings-table-change-header {
    background-color: #fff !important;
    border-top: 0 !important;
    border-right: 0 !important;
  }

  .se-eval-result-change {
    font-size: 1rem;
    color: black;
  }

  .se-monthly-qualified-income-math {
    margin-top: 32px;
    padding: 10px;
    background-color: #F5F7FA;
    font-size: 1.0rem;
  }

  .se-bp-message-title {
    color: #085280;
    font-weight: 600;
    font-size: 1.5rem !important;
    margin: 0;
  }

  .se-bp-message-header {
    color: #085280;
    font-weight: 600;
    font-size: 1.5rem !important;
    margin: 0;
  }

  .se-bp-message {
    color: #085280;
    font-weight: 500;
    font-size: 1.1rem;
    margin: 0;
  }

  .se-analysis .el-collapse-item__header {
    margin-top: 20px;
    font-size: 1rem;
    font-weight: 600;
  }

  .se-cmp-income-analysis-table {
    max-width: 850px;
  }

  .se-business-liquidity-analysis {
    font-size: 1.0rem;
  }

  .se-cell-underline {
    margin: 0px 4rem 0.75rem 1rem;
    border-width: 2px;
    color: black;
  }

</style>
