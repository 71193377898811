<template>

    <el-drawer
      :title="title"
      :visible="this.visible"
      @close="hide"
      custom-class="se-help"
      size="65%"
      direction="rtl">

      <card>
        <h4 class="title-example-pdf">Form 1040 - U.S. Individual Income Tax Return</h4>
        <div class="row pdf-example-row">
          <div class="col-sm-7">
            <WebViewer url="static/forms/2022/2022_1040.pdf"/>
          </div>
          <div class="col-sm-5">
            <p>
              The tax return form is located at the top of the first page of the return.
            </p>
            <p>
              This is form 1040; the most common type of <b>personal</b> tax return filed.
            </p>
            <p>
              Personal tax returns are commonly required for loan applications with self-employment income, even if the business files a separate business return.
            </p>
            <p>
              For some businesses, all the income from the business is reported on the personal returns and there is no separate business return.
            </p>
          </div>
        </div>
      </card>

      <card>
        <h4 class="title-example-pdf">Form 1120-S - U.S. Income Tax Return for an S Corporation</h4>
        <div class="row pdf-example-row">
          <div class="col-sm-7">
            <WebViewer url="static/forms/2022/2022_1120sPg1.pdf"/>
          </div>
          <div class="col-sm-5">
            <p>
              The tax return form is located at the top of the first page of the return.
            </p>
          </div>
        </div>
      </card>

      <card>
        <h4 class="title-example-pdf">Form 1120 - U.S. Corporation Income Tax Return</h4>
        <div class="row pdf-example-row">
          <div class="col-sm-7">
            <WebViewer url="static/forms/2022/2022_1120Pg1.pdf"/>
          </div>
          <div class="col-sm-5">
            <p>
              The tax return form is located at the top of the first page of the return.
            </p>
          </div>
        </div>
      </card>

      <card>
        <h4 class="title-example-pdf">From 1065 - U.S. Return of Partnership Income</h4>
        <div class="row pdf-example-row">
          <div class="col-sm-7">
            <WebViewer url="static/forms/2022/2022_1065Pg1.pdf"/>
          </div>
          <div class="col-sm-5">
            <p>
              The tax return form is located at the top of the first page of the return.
            </p>
          </div>
        </div>
      </card>

    </el-drawer>

</template>
<script>
import {Drawer} from 'element-ui'
import WebViewer from 'src/components/WebViewerRO.vue'


export default {
  components: {
    [Drawer.name]: Drawer,
    WebViewer
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: "Why is this important to Income Calculator?"
    }
  },
  data() {
    return {
      vis: this.visible
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.$emit('open');
      } else {
        if (!this.closed) this.$emit('close');
      }
    }
  },
  methods: {
    hide() {
      this.$emit('update:visible', false);
    }
  }
}
</script>
<style>

.se-help > header {
  color: white;
  background: #085280;
  padding-bottom: 1rem;
}

.el-drawer__body {
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
}

.pdf-example-row {
  height: 50vh;
  /*min-height: 700px;*/
}

.title-example-pdf {
  margin-top: 0;
  color: #085280;
  font-weight: bold;
}

</style>
