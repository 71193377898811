<template>
  <div>

    <div class="row">
      <div class="col-md-6 pr-5">

        <h1 class="mt-0 se-std-bp-title-sb mb-3">Input Data: Form 1065 ({{this.taxFormYear}})
          <a class="btn-link se-page-help-icon" v-tooltip.top-center="'Learn More'" @click="showPageHelp = true">
            <i class="fa fa-info-circle"></i>
          </a>
        </h1>

        <form autocomplete="off">
          <div class="bp-form-group mr-5"
             v-for="(row, index) in tableData">

          <template v-if="row.id === 'amortization' && !isHidden[index]">
            <p>
              Look for an attached statement that contains a total amount of {{otherDeductionsTableData}}. Look at the descriptions of each expense in the attachment.
              Do any descriptions contain the word “Amortization” or abbreviations like “Amort” or “Amtz”?
            </p>
          </template>

          <template v-else-if="row.id === 'form4684Included'">
            <p class="se-std-field-page-sub-header mt-5">
              Casualty & Theft Loss
            </p>
            Losses from theft of business property and losses of business property due to a major federally declared disaster (also known as a casualty), such as a hurricane or earthquake are reported in detail on IRS Form 4684 Casualties and Thefts. Look for this form in the tax returns.
            <br>
          </template>

          <template v-if="!isHidden[index]">
            <template v-if="row.pageHeader">
              <h4>{{row.pageHeader}}</h4>
            </template>

            <label :for="row.id" class="se-std-field-group-label" :class="{'opacity-5': isSkipped[index]}"><span>{{row.line}}</span></label>
            <a class="se-help-icon btn-link" v-tooltip.top-center="'Learn More'" @click="handleFieldHelp(row.id)" v-show="row.hasHelp">
              <i class="fa fa-info-circle"></i>
            </a>
          </template>

          <div @mouseover="highlightHoveredOverFormField(row)" @mouseleave="highlightHoveredOverFormField()">
            <template v-if="row.id === 'businessActivityCode'">
              <radio :label="false"
                     v-model="row.isEmpty"
                     :disabled="isSkipped[index] || isLocked"
                     :class="{'opacity-5': isSkipped[index]}"
                     class="mb-1"><span class="se-std-label">Enter {{row.name}}</span>
              </radio>
              <input :id="row.id"
                     :readonly="isLocked"
                     class="se-tax-field-input ml-4 bp-form-input"
                     type="text"
                     v-mask="'######'"
                     @focus="highlightFocusedFormField(row)"
                     @blur="fieldIsFocused = false;"
                     :disabled="row.isEmpty || isSkipped[index]"
                     :class="{'opacity-5': isSkipped[index]}"
                     v-model="row.value" />
              <radio :label="true"
                     v-model="row.isEmpty"
                     :disabled="isSkipped[index] || isLocked"
                     :class="{'opacity-5': isSkipped[index]}"
                     class="mt-1"><span class="se-std-label">There is no number on this line</span>
              </radio>
            </template>

            <template v-else-if="row.id === 'isSchCAndM3Attached'">
              <radio label="Yes"
                     v-model="row.value"
                     :disabled="isSkipped[index] || isLocked"
                     :class="{'opacity-5': isSkipped[index]}"
                     class="mb-1"><span class="se-std-label">Yes, The checkbox for Schedules C and M-3 is checked</span>
              </radio>
              <radio label="No"
                     v-model="row.value"
                     :disabled="isSkipped[index] || isLocked"
                     :class="{'opacity-5': isSkipped[index]}"
                     class="mt-1"><span class="se-std-label">No, The checkbox for Schedules C and M-3 is not checked</span>
              </radio>
            </template>

            <template v-else-if="row.id === 'businessStartedDate'">
              <radio :label="false"
                     v-model="row.isEmpty"
                     :disabled="isSkipped[index] || isLocked"
                     :class="{'opacity-5': isSkipped[index]}"
                     class="mb-1"><span class="se-std-label">Enter {{row.name}}</span>
              </radio>
              <masked-input :id="row.id"
                     class="se-tax-field-input ml-4 bp-form-input"
                     type="text"
                     @focus="highlightFocusedFormField(row)"
                     @blur="fieldIsFocused = false;"
                     :mask="[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]"
                     :guide="false"
                     :pipe="dateMaskPipe"
                     :disabled="row.isEmpty || isSkipped[index]"
                     placeholder="MM/DD/YYYY"
                     v-model="row.value" />
              <radio :label="true"
                     v-model="row.isEmpty"
                     :disabled="isSkipped[index] || isLocked"
                     :class="{'opacity-5': isSkipped[index]}"
                     class="mt-1"><span class="se-std-label">There is no date on this line</span>
              </radio>
            </template>

            <template v-else-if="['amortization'].includes(row.id)">
            <template v-if="!isHidden[index]">
              <radio :label="false"
                     v-model="row.isEmpty"
                     :disabled="isSkipped[index] || isLocked"
                     :class="{'opacity-5': isSkipped[index]}"
                     class="mb-1"><span class="se-std-label">Enter {{row.name}}</span>
              </radio>
              <input :id="row.id"
                     :readonly="isLocked"
                     class="se-tax-field-input ml-4 bp-form-input"
                     type="text"
                     v-mask="row.mask"
                     @focus="highlightFocusedFormField(row);"
                     @blur="fieldIsFocused = false;"
                     :disabled="row.isEmpty || isSkipped[index]"
                     v-model="row.value" />
              <radio :label="true"
                     v-model="row.isEmpty"
                     :disabled="isSkipped[index] || isLocked"
                     :class="{'opacity-5': isSkipped[index]}"
                     class="mt-1"><span class="se-std-label">There is no attachment or no Amortization amount on the attachment.</span>
              </radio>
            </template>
            </template>

            <template v-else-if="row.id === 'mortgagePayableLess1YrRolloverType'">
              <div class="mb-5" v-if="displayMortgagesNotesPayableLessThanOneYearSCorpExcludedAmount">
                <p class="mb-0">
                  Mortgages, notes and bonds payable in less than 1 year will be subtracted from qualifying income unless there is evidence that these obligations
                  rollover regularly or they are a line of credit. Select one of the following descriptions:
                </p>
                <br>

                <radio :label="MortgagePayableLess1YrRolloverType.all"
                       v-model="row.value"
                       :disabled="row.isEmpty || isSkipped[index] || isLocked"
                       :class="{'opacity-5': isSkipped[index]}"
                       class="mb-2"><span class="se-std-label">The loan file contains evidence that <b>all</b> mortgages, notes and bonds payable in less than 1 year rollover regularly or are a line of credit.</span>
                </radio>
                <radio :label="MortgagePayableLess1YrRolloverType.some"
                       v-model="row.value"
                       :disabled="row.isEmpty || isSkipped[index] || isLocked"
                       :class="{'opacity-5': isSkipped[index]}"
                       class="mt-2"><span class="se-std-label">The loan file contains evidence that <b>some</b> mortgages, notes and bonds payable in less than 1 year rollover regularly or are a line of credit.</span>
                </radio>
                <p class="ml-3 mt-3">
                  Of the ${{mortgagesNotesPayableLess1YrEndOfTaxYear}} of mortgages, notes and bonds payable in less than 1 year reported on page 4 of the Form 1065, Line 17(d),
                  what is the amount that does not rollover regularly or is not a line of credit?
                  <br>
                  <br>
                  Enter an amount between $0 and ${{mortgagesNotesPayableLess1YrEndOfTaxYear}}
                </p>
                <input id="nonRolloverMortgagePayableLess1YrAmount"
                       :readonly="isLocked"
                       class="se-tax-field-input ml-4 mb-2 bp-form-input"
                       type="text"
                       v-mask="currencyPositiveMask"
                       @focus="highlightFocusedFormField(row)"
                       @blur="fieldIsFocused = false"
                       :disabled="nonRolloverMortgagePayableLess1YrAmount.isEmpty || isSkipped[13]"
                       v-model="nonRolloverMortgagePayableLess1YrAmount.value" />

                <i class="ml-3">This amount will be subtracted from qualifying income.</i>

                <div class="bp-help is-error" v-if="$v.nonRolloverMortgagePayableLess1YrAmount.$error">
                  <div class="bp-ucon" data-src="feedback-warning"><svg class="bp-svg" viewBox="0 0 24 24" id="feedback-warning" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M13.009 13.987V8.934h-1.956v5.053h1.956zm0 4.016v-1.962h-1.956v1.962h1.956zM2.988 20.962L12.016 3l9.025 17.962H2.988z"></path></svg></div>
                  <template v-if="!$v.nonRolloverMortgagePayableLess1YrAmount.withinLimits">Must be between $0 and ${{mortgagesNotesPayableLess1YrEndOfTaxYear}}</template>
                </div>

                  <radio :label="MortgagePayableLess1YrRolloverType.none"
                       v-model="row.value"
                       :disabled="row.isEmpty || isSkipped[index] || isLocked"
                       :class="{'opacity-5': isSkipped[index]}"
                       class="mt-2"><span class="se-std-label">The loan file contains evidence that <b>none</b> of the mortgages, notes and bonds payable in less than 1 year rollover regularly or are a line of credit; or the loan file contains no evidence related to the rollover behavior or line of credit for mortgages, notes and bonds payable in less than 1 year.</span>
                </radio>
              </div>
            </template>

            <template v-else-if="row.id === 'nonRolloverMortgagePayableLess1YrAmount'">
            </template>

            <template v-else-if="row.id === 'form4684Included'">
              <radio label="Yes"
                     v-model="row.value"
                     :disabled="isSkipped[index] || isLocked"
                     :class="{'opacity-5': isSkipped[index]}"
                     class="mb-1"><span class="se-std-label">Yes, Form 4684 is included in the tax returns</span>
              </radio>
              <radio label="No"
                     v-model="row.value"
                     :disabled="isSkipped[index] || isLocked"
                     :class="{'opacity-5': isSkipped[index]}"
                     class="mt-1"><span class="se-std-label">No, Form 4684 is not included in the tax returns</span>
              </radio>
            </template>

            <template v-else-if="row.id === 'numberOfShareholders'">
              <radio :label="false"
                     v-model="row.isEmpty"
                     :disabled="isSkipped[index] || isLocked"
                     :class="{'opacity-5': isSkipped[index]}"
                     class="mb-1"><span class="se-std-label">Enter {{row.name}}</span>
              </radio>
              <input :id="row.id"
                     :readonly="isLocked"
                     class="se-tax-field-input ml-4 bp-form-input"
                     type="text"
                     v-mask="'NNNN'"
                     @focus="highlightFocusedFormField(row);"
                     @blur="fieldIsFocused = false;"
                     :disabled="row.isEmpty || isSkipped[index]"
                     v-model="row.value" />
              <radio :label="true"
                     v-model="row.isEmpty"
                     :disabled="isSkipped[index] || isLocked"
                     :class="{'opacity-5': isSkipped[index]}"
                     class="mt-1"><span class="se-std-label">There is no number on this line</span>
              </radio>
            </template>

            <template v-else>
              <radio :label="false"
                     v-model="row.isEmpty"
                     :disabled="isSkipped[index] || isLocked"
                     :class="{'opacity-5': isSkipped[index]}"
                     class="mb-1"><span class="se-std-label">Enter {{row.name}}</span>
              </radio>
              <input :id="row.id"
                     :readonly="isLocked"
                     class="se-tax-field-input ml-4 bp-form-input"
                     type="text"
                     v-mask="row.mask"
                     @focus="highlightFocusedFormField(row);"
                     @blur="fieldIsFocused = false;"
                     :disabled="row.isEmpty || isSkipped[index]"
                     v-model="row.value" />
              <radio :label="true"
                     v-model="row.isEmpty"
                     :disabled="isSkipped[index] || isLocked"
                     :class="{'opacity-5': isSkipped[index]}"
                     class="mt-1"><span class="se-std-label">There is no amount on this line</span>
              </radio>
            </template>
          </div>

        </div>
        </form>

        <div class="bp-help is-error mb-2" v-if="$v.allAnswered.$error">
          <div class="bp-ucon" data-src="feedback-warning"><svg class="bp-svg" viewBox="0 0 24 24" id="feedback-warning" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M13.009 13.987V8.934h-1.956v5.053h1.956zm0 4.016v-1.962h-1.956v1.962h1.956zM2.988 20.962L12.016 3l9.025 17.962H2.988z"></path></svg>
          </div>
          <template v-if="!$v.allAnswered.required">Provide answer for all items or select 'There is no amount on this line'</template>
        </div>

      </div>

      <div class="col-md-6 d-none d-sm-none d-md-block">
        <div class="std-pdf-row">
          <WebViewer :url="this.pdf"
                     ref="viewer"
                     :se-fields="seFields"
                     :tax-form-year="this.taxFormYear"
                     :tax-form-id="this.taxFormId"
                     @viewerLoaded="onViewerLoaded"
                     :key="this.pdfViewerKey"/>
        </div>
      </div>
    </div>

    <se-help :visible.sync="showPageHelp">
      <p>
        Use this screen to provide information from the borrower’s 1065, U.S. Return of Partnership Income
      </p>
      <p>
        Q. I have business tax returns, but the borrower satisfies all the conditions to waive business tax returns.
        Am I required to provide business tax return data to {{ appName }}?<br>
        A. No. {{ appName }} will evaluate income based on the data provided.
      </p>
      <p>
        <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
        Learn more about Verification of Income (and waivning business tax returns) in the <a target="_blank" href="https://selling-guide.fanniemae.com/Selling-Guide/Origination-thru-Closing/Subpart-B3-Underwriting-Borrowers/Chapter-B3-3-Income-Assessment/Section-B3-3-2-Self-Employment-Income/1032990811/B3-3-2-01-Underwriting-Factors-and-Documentation-for-a-Self-Employed-Borrower-10-24-2016.htm">Underwriting Factors and Documentation for a Self-Employed Borrower</a> section of the Fannie Mae Selling Guide.
      </p>
      <p>
        <icon-base width="22" height="22" iconColor="#0c77ba"><icon-flag/></icon-base>
        {{ appName }} may collect information not traditionally used in evaluating self-employment income.
        Some data may be used to validate data integrity or determine if income from other tax return forms
        or schedules may be needed. In some cases, Fannie Mae may use this data for future research.
      </p>
    </se-help>

    <se-help :visible.sync="showFieldHelp.businessActivityCode">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. I’m not used to considering this information when I evaluate self-employment income.
          Will {{ appName }} be using this information in its monthly qualifying income calculation?<br>
          A. No. {{ appName }} will not use this information when it calculates the monthly qualifying income.
          {{ appName }} may collect information not traditionally used in evaluating self-employment income.
          Some information may be used to validate data integrity or determine if income from other tax return forms or schedules may be needed.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.isM3Attached">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. I’m not used to considering this information when I evaluate self-employment income.
          Will {{ appName }} be using this information in its monthly qualifying income calculation?<br>
          A. No. {{ appName }} will not use this information when it calculates the monthly qualifying income.
          {{ appName }} may collect information not traditionally used in evaluating self-employment income.
          Some information may be used to validate data integrity or determine if income from other tax return forms or schedules may be needed.
        </p>
        <p>
          Q. Why is this information important to {{ appName }}?<br>
          A. Schedule M-3 may contain Meals and entertainment expenses that need to be subtracted from the monthly qualifying income.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.incorporatedDate">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. I’m not used to considering this information when I evaluate self-employment income.
          Will {{ appName }} be using this information in its monthly qualifying income calculation?<br>
          A. No. {{ appName }} will not use this information when it calculates the monthly qualifying income.
          {{ appName }} may collect information not traditionally used in evaluating self-employment income.
          Some information may be used to validate data integrity or determine if income from other tax return forms or schedules may be needed.
        </p>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. {{ appName }} needs to evaluate the length of time the borrower has had this income.
        </p>
        <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
        Learn more about Length of Self-Employment in the <a target="_blank" href="https://selling-guide.fanniemae.com/Selling-Guide/Origination-thru-Closing/Subpart-B3-Underwriting-Borrowers/Chapter-B3-3-Income-Assessment/Section-B3-3-2-Self-Employment-Income/1032990811/B3-3-2-01-Underwriting-Factors-and-Documentation-for-a-Self-Employed-Borrower-10-24-2016.htm">Underwriting Factors and Documentation for a Self-Employed Borrower</a> section of the Fannie Mae Selling Guide.
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.businessStartedDate">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. I’m not used to considering this information when I evaluate self-employment income.
          Will {{ appName }} be using this information in its monthly qualifying income calculation?<br>
          A. No. {{ appName }} will not use this information when it calculates the monthly qualifying income.
          {{ appName }} may collect information not traditionally used in evaluating self-employment income.
          Some information may be used to validate data integrity or determine if income from other tax return forms or schedules may be needed.
        </p>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. {{ appName }} needs to evaluate the length of time the borrower has had this income.
        </p>
        <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
        Learn more about Length of Self-Employment in the <a target="_blank" href="https://selling-guide.fanniemae.com/Selling-Guide/Origination-thru-Closing/Subpart-B3-Underwriting-Borrowers/Chapter-B3-3-Income-Assessment/Section-B3-3-2-Self-Employment-Income/1032990811/B3-3-2-01-Underwriting-Factors-and-Documentation-for-a-Self-Employed-Borrower-10-24-2016.htm">Underwriting Factors and Documentation for a Self-Employed Borrower</a> section of the Fannie Mae Selling Guide.
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.totalAssets">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. I’m not used to considering this information when I evaluate self-employment income.
          Will {{ appName }} be using this information in its monthly qualifying income calculation?<br>
          A. No. {{ appName }} will not use this information when it calculates the monthly qualifying income.
          {{ appName }} may collect information not traditionally used in evaluating self-employment income.
          Some information may be used to validate data integrity or determine if income from other tax return forms or schedules may be needed.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.numberOfSchedulesK1">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Do I report the number on Line I or the number of borrowers on the loan who are owners?<br>
          A. Report the number on Line I.
        </p>
        <p>
          Q. I’m not used to considering this information when I evaluate self-employment income.
          Will {{ appName }} be using this information in its monthly qualifying income calculation?<br>
          A. No. {{ appName }} will not use this information when it calculates the monthly qualifying income.
          {{ appName }} may collect information not traditionally used in evaluating self-employment income.
          Some information may be used to validate data integrity or determine if income from other tax return forms or schedules may be needed.
        </p>
      </div>
    </se-help>
    <se-help :visible.sync="showFieldHelp.isSchCAndM3Attached">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. I’m not used to considering this information when I evaluate self-employment income.
          Will {{ appName }} be using this information in its monthly qualifying income calculation?<br>
          A. No. {{ appName }} will not use this information when it calculates the monthly qualifying income.
          {{ appName }} may collect information not traditionally used in evaluating self-employment income.
          Some information may be used to validate data integrity or determine if income from other tax return forms or schedules may be needed.
        </p>
        <p>
          Q. Why is this information important to {{ appName }}?<br>
          A. Schedule M-3 may contain Meals and entertainment expenses that need to be subtracted from the monthly qualifying income.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.numberOfShareholders">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. I’m not used to considering this information when I evaluate self-employment income.
          Will {{ appName }} be using this information in its monthly qualifying income calculation?<br>
          A. No. {{ appName }} will not use this information when it calculates the monthly qualifying income.
          {{ appName }} may collect information not traditionally used in evaluating self-employment income.
          Some information may be used to validate data integrity or determine if income from other tax return forms or schedules may be needed.
        </p>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. {{ appName }} may use this data to validate the owner’s share of the business (on Line F). Fannie Mae may use this data for future research.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.grossReceiptsOrSales">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. {{ appName }} uses this data in the Comparative Income Analysis.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about Analysis of Borrower’s Business Income in the <a target="_blank" href="https://selling-guide.fanniemae.com/Selling-Guide/Origination-thru-Closing/Subpart-B3-Underwriting-Borrowers/Chapter-B3-3-Income-Assessment/Section-B3-3-2-Self-Employment-Income/1032990811/B3-3-2-01-Underwriting-Factors-and-Documentation-for-a-Self-Employed-Borrower-10-24-2016.htm">Underwriting Factors and Documentation for a Self-Employed Borrower</a> section of the Fannie Mae Selling Guide.
        </p>
        <p>
          Q. There is no amount on this line. Is that common?<br>
          A. No. Most Partnerships have Gross receipts or sales to report. Please double-check the line entry.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.ordinaryIncomeLossFromOtherPartnershipsEstates">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. Ordinary income (loss) amounts from other partnerships, estates, and trusts may be included in the monthly qualifying income calculation.
        </p>
        <p>
         Q. The Fannie Mae Selling Guide allows me to document income using one year of tax returns.
          Will {{ appName }} consider Ordinary income (loss) amounts from other partnerships, estates, and trusts to be recurring if they are only documented with one year of tax returns?<br>
          A. {{ appName }} may consider Ordinary income (loss) amounts from other partnerships, estates, and trusts to be recurring even if they are documented with only one year of tax returns.
        </p>
        <p>
          Q. Am I required to obtain supporting documentation from the borrower regarding the nature of the Ordinary income (loss) amounts from other partnerships, estates, and trusts?<br>
          A. {{ appName }} may not require supporting documentation for Ordinary income (loss) amounts from other partnerships, estates, and trusts.
        </p>

      </div>

    </se-help>
    <se-help :visible.sync="showFieldHelp.netFarmProfitLoss">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. Net farm profits may be included in the monthly qualifying income calculation.
        </p>
        <p>
          Q. The Fannie Mae Selling Guide allows me to document income using one year of tax returns.
          Will {{ appName }} consider Net farm profit (loss) amounts to be recurring if they are only documented with one year of tax returns?<br>
          A. {{ appName }} may consider Net farm profit (loss) amounts to be recurring even if they are documented with only one year of tax returns.
        </p>
        <p>
          Q. Am I required to obtain supporting documentation from the borrower regarding the nature of the Net farm profit (loss) amounts?<br>
          A. {{ appName }} may not require supporting documentation for Net farm profit (loss) amounts.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.netGainLoss4797">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. Net gains from Form 4797 may be included in the monthly qualifying income calculation.
        </p>
        <p>
           Q. The Fannie Mae Selling Guide allows me to document income using one year of tax returns.
          Will {{ appName }} consider Net gain (loss) amounts from Form 4797 to be recurring if they are only documented with one year of tax returns?<br>
          A. {{ appName }} may consider Net gain (loss) amounts from Form 4797 to be recurring even if they are documented with only one year of tax returns.
        </p>
        <p>
          Q. Am I required to obtain supporting documentation from the borrower regarding the nature of the Net gain (loss) amounts from Form 4797?<br>
          A. {{ appName }} may not require supporting documentation for Net gain (loss) amounts from Form 4797.
        </p>
      </div>
    </se-help>
    <se-help :visible.sync="showFieldHelp.otherIncomeLoss">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. Other income (loss) amounts may be included in the monthly qualifying income calculation.
        </p>
        <p>
          Q. The Fannie Mae Selling Guide allows me to document income using one year of tax returns.
          Will {{ appName }} consider Other income (loss) amounts to be recurring if they are only documented with one year of tax returns?<br>
          A. {{ appName }} may consider Other income (loss) amounts to be recurring even if they are documented with only one year of tax returns.
        </p>
        <p>
          Q. Am I required to obtain supporting documentation from the borrower regarding the nature of the Other income (loss) amounts?<br>
          A. {{ appName }} may not require supporting documentation for Other income (loss) amounts.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.guaranteedPaymentToPartners">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
         Q. I’m not used to considering this information when I evaluate self-employment income.
          Will {{ appName }} be using this information in its monthly qualifying income calculation?<br>
          A. No. {{ appName }} will not use this information when it calculates the monthly qualifying income.
          {{ appName }} may collect information not traditionally used in evaluating self-employment income.
          Some information may be used to validate data integrity or determine if income from other tax return forms or schedules may be needed.
        </p>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. {{ appName }} may use this data to validate K-1 income amounts.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.returnsAndAllowances">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. {{ appName }} uses this data in the Comparative Income Analysis.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about Analysis of Borrower’s Business Income in the <a target="_blank" href="https://selling-guide.fanniemae.com/Selling-Guide/Origination-thru-Closing/Subpart-B3-Underwriting-Borrowers/Chapter-B3-3-Income-Assessment/Section-B3-3-2-Self-Employment-Income/1032990811/B3-3-2-01-Underwriting-Factors-and-Documentation-for-a-Self-Employed-Borrower-10-24-2016.htm">Underwriting Factors and Documentation for a Self-Employed Borrower</a> section of the Fannie Mae Selling Guide.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.costOfGoodsSold">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. {{ appName }} uses this data in the Comparative Income Analysis.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about Analysis of Borrower’s Business Income in the <a target="_blank" href="https://selling-guide.fanniemae.com/Selling-Guide/Origination-thru-Closing/Subpart-B3-Underwriting-Borrowers/Chapter-B3-3-Income-Assessment/Section-B3-3-2-Self-Employment-Income/1032990811/B3-3-2-01-Underwriting-Factors-and-Documentation-for-a-Self-Employed-Borrower-10-24-2016.htm">Underwriting Factors and Documentation for a Self-Employed Borrower</a> section of the Fannie Mae Selling Guide.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.otherIncomeLoss">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
       Q. Why is this important to {{ appName }}?<br>
          A. Other income (loss) amounts may be included in the monthly qualifying income calculation.
        </p>
        <p>
          Q. The Fannie Mae Selling Guide allows me to document income using one year of tax returns.
          Will {{ appName }} consider Other income (loss) amounts to be recurring if they are only documented with one year of tax returns?<br>
          A. {{ appName }} may consider Other income (loss) amounts to be recurring even if they are documented with only one year of tax returns.
        </p>
        <p>
          Q. Am I required to obtain supporting documentation from the borrower regarding the nature of the Other income (loss) amounts?<br>
          A. {{ appName }} may not require supporting documentation for Other income (loss) amounts.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.depreciation">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. Any amount on this line will be added to the monthly qualifying income calculation.
        </p>
        <p>
          Q. There is no amount on this line. Is that common?<br>
          A. Yes. Many Partnerships do not have Depreciation to report.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.depletion">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. Any amount on this line will be added to the monthly qualifying income calculation.
        </p>
        <p>
          Q. There is no amount on this line. Is that common?<br>
          A. Yes. Most Partnerships do not have Depletion to report.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.otherDeductions">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. I’m not used to considering this information when I evaluate self-employment income.
          Will {{ appName }} be using this information in its monthly qualifying income calculation?<br>
          A. No. {{ appName }} will not use this information when it calculates the monthly qualifying income.
          {{ appName }} may collect information not traditionally used in evaluating self-employment income.
          Some information may be used to validate data integrity or determine if income from other tax return forms or schedules may be needed.
        </p>
        <p>
          Q. Why is this information important to {{ appName }}?<br>
          A. {{ appName }} uses this information to determine if income data from other tax return schedules may be needed.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.totalDeductions">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. {{ appName }} uses this data in the Comparative Income Analysis.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about Analysis of Borrower’s Business Income in the <a target="_blank" href="https://selling-guide.fanniemae.com/Selling-Guide/Origination-thru-Closing/Subpart-B3-Underwriting-Borrowers/Chapter-B3-3-Income-Assessment/Section-B3-3-2-Self-Employment-Income/1032990811/B3-3-2-01-Underwriting-Factors-and-Documentation-for-a-Self-Employed-Borrower-10-24-2016.htm">Underwriting Factors and Documentation for a Self-Employed Borrower</a> section of the Fannie Mae Selling Guide.
        </p>
        <p>
          Q. There is no amount on this line. Is that common?<br>
          A. No. Most Partnerships have Total deductions to report. Please double-check the line entry.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.ordinaryBusinessIncomeLoss">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. {{ appName }} uses this data as Taxable Income in the Comparative Income Analysis.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about Analysis of Borrower’s Business Income in the <a target="_blank" href="https://selling-guide.fanniemae.com/Selling-Guide/Origination-thru-Closing/Subpart-B3-Underwriting-Borrowers/Chapter-B3-3-Income-Assessment/Section-B3-3-2-Self-Employment-Income/1032990811/B3-3-2-01-Underwriting-Factors-and-Documentation-for-a-Self-Employed-Borrower-10-24-2016.htm">Underwriting Factors and Documentation for a Self-Employed Borrower</a> section of the Fannie Mae Selling Guide.
        </p>
        <p>
          Q. There is no amount on this line. Is that common?<br>
          A. No. Most Partnerships have Ordinary business income (or loss) to report. Please double-check the line entry.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.amortization">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. Any Amortization amount will be added to the monthly qualifying income calculation.
        </p>
        <p>
          Q. There is no amount on this line. Is that common?<br>
          A. Yes. Many self-employed borrowers do not have Amortization to report.
        </p>
        <p>
          Q. Why is the Amortization information grayed-out (not able to be provided)?<br>
          A. Based on the Other deductions entered on Line 21 (tax year 2023) or Line 20 (tax year 2022/2021),
          the business tax returns do not contain an attached statement where amortization would be reported.
        </p>
        <p>
          Q. I reviewed the complete 1065 and I cannot find an attachment or statement in the tax return with the word “Amortization” or
          abbreviations like “Amort.” or “AMZT”. What should I do?<br>
          A. If you cannot locate an attachment or statement with amortization, select “There is no attachment or no Amortization amount on the attachment”.
        </p>
        <p>
          Q. There is an expense on an attachment or statement identified as “BLDG AMTZ”, “Standard Amort.”, “Equipment Amortization”,
          or similar language; should I include these in the amortization amount?<br>
          A. Some tax returns will simply identify amortization as the expense description without any other detail.
          Other tax returns may identify the property being amortized or provide some other kind of description. Enter the sum of all amounts representing amortization.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.netRentalRealEstateIncomeLoss">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. I’m not used to considering this information when I evaluate self-employment income.
          Will {{ appName }} be using this information in its monthly qualifying income calculation?<br>
          A. No. {{ appName }} will not use this information when it calculates the monthly qualifying income.
          {{ appName }} may collect information not traditionally used in evaluating self-employment income.
          Some information may be used to validate data integrity or determine if income from other tax return forms or schedules may be needed.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.nonDeductibleExpenses">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. I’m not used to considering this information when I evaluate self-employment income.
          Will {{ appName }} be using this information in its monthly qualifying income calculation?<br>
          A. No. {{ appName }} will not use this information when it calculates the monthly qualifying income.
          {{ appName }} may collect information not traditionally used in evaluating self-employment income.
          Some information may be used to validate data integrity or determine if income from other tax return forms or schedules may be needed.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.cashEndOfTaxYear">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. {{ appName }} uses this data in the Business Liquidity Analysis and in its determination of whether the business has
          sufficient liquid assets to cover the total amount of obligations on mortgages, notes, or bonds that are payable in less than one year.
        </p>
        <p>
        <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
        Learn more about Borrower’s Proportionate Share of Income or Loss (and business liquidity) in the
        <a target="_blank" href="https://selling-guide.fanniemae.com/Selling-Guide/Origination-thru-Closing/Subpart-B3-Underwriting-Borrowers/Chapter-B3-3-Income-Assessment/Section-B3-3-4-Self-Employment-Documentation-for-Business/1032991391/B3-3-4-01-Analyzing-Returns-for-a-Partnership-06-05-2019.htm">Analyzing Returns for a Partnership</a>
        section of the Fannie Mae Selling Guide.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about Adjustments to Business Cash Flow in the
          <a target="_blank" href="https://selling-guide.fanniemae.com/Selling-Guide/Origination-thru-Closing/Subpart-B3-Underwriting-Borrowers/Chapter-B3-3-Income-Assessment/Section-B3-3-4-Self-Employment-Documentation-for-Business/1032991391/B3-3-4-01-Analyzing-Returns-for-a-Partnership-06-05-2019.htm">Analyzing Returns for a Partnership</a>
          section of the Fannie Mae Selling Guide.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.tradeNotesAndAccountsReceivableLessAllowanceEndOfTaxYear">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. {{ appName }} uses this data in the Business Liquidity Analysis.
        </p>
        <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
        Learn more about Borrower’s Proportionate Share of Income or Loss (and business liquidity) in the
        <a target="_blank" href="https://selling-guide.fanniemae.com/Selling-Guide/Origination-thru-Closing/Subpart-B3-Underwriting-Borrowers/Chapter-B3-3-Income-Assessment/Section-B3-3-4-Self-Employment-Documentation-for-Business/1032991391/B3-3-4-01-Analyzing-Returns-for-a-Partnership-06-05-2019.htm">Analyzing Returns for a Partnership</a> section of the Fannie Mae Selling Guide.
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.inventoriesEndOfTaxYear">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. {{ appName }} uses this data in the Business Liquidity Analysis.
        </p>
        <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
        Learn more about Borrower’s Proportionate Share of Income or Loss (and business liquidity) in the
        <a target="_blank" href="https://selling-guide.fanniemae.com/Selling-Guide/Origination-thru-Closing/Subpart-B3-Underwriting-Borrowers/Chapter-B3-3-Income-Assessment/Section-B3-3-4-Self-Employment-Documentation-for-Business/1032991391/B3-3-4-01-Analyzing-Returns-for-a-Partnership-06-05-2019.htm">Analyzing Returns for a Partnership</a> section of the Fannie Mae Selling Guide.
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.govObligationsEndOfTaxYear">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. {{ appName }} uses this data in the Business Liquidity Analysis.
        </p>
        <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
        Learn more about Borrower’s Proportionate Share of Income or Loss (and business liquidity) in the
        <a target="_blank" href="https://selling-guide.fanniemae.com/Selling-Guide/Origination-thru-Closing/Subpart-B3-Underwriting-Borrowers/Chapter-B3-3-Income-Assessment/Section-B3-3-4-Self-Employment-Documentation-for-Business/1032991391/B3-3-4-01-Analyzing-Returns-for-a-Partnership-06-05-2019.htm">Analyzing Returns for a Partnership</a> section of the Fannie Mae Selling Guide.
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.taxExemptSecuritiesEndOfTaxYear">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. {{ appName }} uses this data in the Business Liquidity Analysis.
        </p>
        <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
        Learn more about Borrower’s Proportionate Share of Income or Loss (and business liquidity) in the
        <a target="_blank" href="https://selling-guide.fanniemae.com/Selling-Guide/Origination-thru-Closing/Subpart-B3-Underwriting-Borrowers/Chapter-B3-3-Income-Assessment/Section-B3-3-4-Self-Employment-Documentation-for-Business/1032991391/B3-3-4-01-Analyzing-Returns-for-a-Partnership-06-05-2019.htm">Analyzing Returns for a Partnership</a> section of the Fannie Mae Selling Guide.
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.otherCurrentAssetsEndOfTaxYear">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. {{ appName }} uses this data in the Business Liquidity Analysis.
        </p>
        <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
        Learn more about Borrower’s Proportionate Share of Income or Loss (and business liquidity) in the
        <a target="_blank" href="https://selling-guide.fanniemae.com/Selling-Guide/Origination-thru-Closing/Subpart-B3-Underwriting-Borrowers/Chapter-B3-3-Income-Assessment/Section-B3-3-4-Self-Employment-Documentation-for-Business/1032991391/B3-3-4-01-Analyzing-Returns-for-a-Partnership-06-05-2019.htm">Analyzing Returns for a Partnership</a> section of the Fannie Mae Selling Guide.
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.accountsPayableEndOfTaxYear">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. {{ appName }} uses this data in the Business Liquidity Analysis.
        </p>
        <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
        Learn more about Borrower’s Proportionate Share of Income or Loss (and business liquidity) in the
        <a target="_blank" href="https://selling-guide.fanniemae.com/Selling-Guide/Origination-thru-Closing/Subpart-B3-Underwriting-Borrowers/Chapter-B3-3-Income-Assessment/Section-B3-3-4-Self-Employment-Documentation-for-Business/1032991391/B3-3-4-01-Analyzing-Returns-for-a-Partnership-06-05-2019.htm">Analyzing Returns for a Partnership</a> section of the Fannie Mae Selling Guide.
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.mortgagesNotesPayableLess1YrBeginningOfTaxYear">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. I’m not used to considering this information when I evaluate self-employment income.
          Will {{ appName }} be using this information in its monthly qualifying income calculation?<br>
          A. No. {{ appName }} will not use this information when it calculates the monthly qualifying income.
          {{ appName }} may collect information not traditionally used in evaluating self-employment income.
          Some information may be used to validate data integrity or determine if income from other tax return forms or schedules may be needed.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.mortgagesNotesPayableLess1YrEndOfTaxYear">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. {{ appName }} uses this data in the Business Liquidity Analysis. In addition, mortgages, notes or bonds payable in less than 1 year (short-term debt)
          may be subtracted from qualifying income, based on the business’ cash position and if necessary, supplemental information provided.
          {{ appName }} evaluates short term debt as follows:
        </p>
        <ul>
          <li>If the business has sufficient cash (shown on Form 1065, Schedule L, Line 1(d)) to pay off the short-term debt (shown on Form 1065, Schedule L, Line 16d),
            the short-term debt is not subtracted from the monthly qualifying income.</li>
          <li>If some or all of the short-term debt is a line of credit or is regularly rolled over, those amounts are not subtracted from the monthly qualifying income.</li>
          <li>If the business does not have sufficient cash to pay off the short-term debt, and the short-term debt is not a line of credit or does not regularly roll over,
            the short-term debt is subtracted from the monthly qualifying income.</li>
        </ul>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about Borrower’s Proportionate Share of Income or Loss (and business liquidity) in the
          <a target="_blank" href="https://selling-guide.fanniemae.com/Selling-Guide/Origination-thru-Closing/Subpart-B3-Underwriting-Borrowers/Chapter-B3-3-Income-Assessment/Section-B3-3-4-Self-Employment-Documentation-for-Business/1032991391/B3-3-4-01-Analyzing-Returns-for-a-Partnership-06-05-2019.htm">Analyzing Returns for a Partnership</a> section of the Fannie Mae Selling Guide.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about Adjustments to Business Cash Flow in the
          <a target="_blank" href="https://selling-guide.fanniemae.com/Selling-Guide/Origination-thru-Closing/Subpart-B3-Underwriting-Borrowers/Chapter-B3-3-Income-Assessment/Section-B3-3-4-Self-Employment-Documentation-for-Business/1032991391/B3-3-4-01-Analyzing-Returns-for-a-Partnership-06-05-2019.htm">Analyzing Returns for a Partnership</a> section of the Fannie Mae Selling Guide.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.otherCurrentLiabilitiesEndOfTaxYear">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. {{ appName }} uses this data in the Business Liquidity Analysis.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about Borrower’s Proportionate Share of Income or Loss (and business liquidity) in the
          <a target="_blank" href="https://selling-guide.fanniemae.com/Selling-Guide/Origination-thru-Closing/Subpart-B3-Underwriting-Borrowers/Chapter-B3-3-Income-Assessment/Section-B3-3-4-Self-Employment-Documentation-for-Business/1032991391/B3-3-4-01-Analyzing-Returns-for-a-Partnership-06-05-2019.htm">Analyzing Returns for a Partnership</a> section of the Fannie Mae Selling Guide.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.retainedEarningsEndOfTaxYear">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
         Q. I’m not used to considering this information when I evaluate self-employment income.
          Will {{ appName }} be using this information in its monthly qualifying income calculation?<br>
          A. No. {{ appName }} will not use this information when it calculates the monthly qualifying income.
          {{ appName }} may collect information not traditionally used in evaluating self-employment income.
          Some information may be used to validate data integrity or determine if income from other tax return forms or schedules may be needed.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.travelEntertainment">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. Any Travel and entertainment amount will be subtracted from the monthly qualifying income.
        </p>
      </div>
    </se-help>
    <se-help :visible.sync="showFieldHelp.capAccountsBalanceEndOfTaxYear">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
         Q. I’m not used to considering this information when I evaluate self-employment income.
          Will {{ appName }} be using this information in its monthly qualifying income calculation?<br>
          A. No. {{ appName }} will not use this information when it calculates the monthly qualifying income.
          {{ appName }} may collect information not traditionally used in evaluating self-employment income.
          Some information may be used to validate data integrity or determine if income from other tax return forms or schedules may be needed.
        </p>
      </div>
    </se-help>


    <se-buttons
      :display-submit="false"
      :display-save="false"
      :display-waive="this.isWaiveFormBtnDisplayed"
      :display-un-waive="this.isUnWaiveFormBtnDisplayed"
      @seNext="next"
      @sePrevious="previous"
      @seWaive="waiveBusinessReturn"
      @seUnWaive="unWaiveBusinessReturn">
    >
    </se-buttons>

  </div>

</template>
<script>
import SeButtons from './SeButtons.vue'
import SeHelp from './SeHelp.vue'
import {Checkbox, Radio} from 'src/components/index'
import LSwitch from 'src/components/Switch.vue'
import Swal from 'sweetalert2'
import { Collapse, CollapseItem } from 'element-ui'
import {mapGetters, mapState} from 'vuex'
import Enums from 'src/model/enums'
import {
  SKIP_BUSINESS_TAX_FORMS
} from 'src/store/actions/se'
import WebViewer from 'src/components/WebViewerROC.vue'
import {Table, TableColumn, Drawer} from 'element-ui'
import {required, requiredIf} from "vuelidate/lib/validators";
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'
import { taxMultiPartFormMixin } from 'src/mixins/TaxMultiPartFormMixin'
import appNameMixin from '../../../mixins/AppNameMixin'

export default {
  mixins: [taxMultiPartFormMixin, appNameMixin],
  components: {
    Checkbox,
    Radio,
    LSwitch,
    SeButtons,
    SeHelp,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    WebViewer,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Drawer.name]: Drawer
  },
  props: {
  },
  data() {
    return {
    }
  },
  validations: {
    allAnswered: {
      required: (v) => v
    },
    nonRolloverMortgagePayableLess1YrAmount: {
      withinLimits(row) {
        if (!row) return true
        let numValue = Number(row.value.replace(/,|\$|\%/g, '')) ?? 0
        return 0 <= numValue && numValue <= this.mortgagesNotesPayableLess1YrEndOfTaxYear
      }
    }
  },
  mounted() {
    this.$nextTick(function () {
      //check and ask to waive business tax return if applicable
      if (this.isWaiveFormBtnDisplayed && !this.$route.query?.loc) {
        this.waiveBusinessReturn()
      }
    })
  },
  computed: {
    dateMaskPipe() {
      return createAutoCorrectedDatePipe('mm/dd/yyyy', {
        minYear: 1900,
        maxYear: parseInt(this.taxFormYear)
      })
    },

    MortgagePayableLess1YrRolloverType: () => Enums.MortgagePayableLess1YrRolloverType,
    otherDeductionsTableData() {
      return this.getValue("otherDeductions")
    },
    cashEndOfTaxYear() {
      return this.getValueAsNumber("cashEndOfTaxYear")
    },
    mortgagesNotesPayableLess1YrEndOfTaxYear() {
      return this.getValueAsNumber("mortgagesNotesPayableLess1YrEndOfTaxYear")
    },
    mortgagePayableLess1YrRolloverType() {
      return this.getValue("mortgagePayableLess1YrRolloverType")
    },
    displayMortgagesNotesPayableLessThanOneYearSCorpExcludedAmount() {
      return this.isEmploymentOwnershipInterestProvidedGte25OrNull
        && (this.mortgagesNotesPayableLess1YrEndOfTaxYear > 0)
        && (this.cashEndOfTaxYear - this.mortgagesNotesPayableLess1YrEndOfTaxYear < 0)
    },
    nonRolloverMortgagePayableLess1YrAmount() {
      return this.getField('nonRolloverMortgagePayableLess1YrAmount')
    },
    isSkipped() {
      return this.tableData.map(f => {
        if (this.skippedForm) return true
        let skipped = false;
        if (f.id === "amortization") {
          if (this.isEmploymentOwnershipInterestProvidedLt25 || this.getValueAsNumber("otherDeductions") <= 0) skipped = true;
        }
        return skipped;
      })
    },
    isHidden() {
      //certain fields should be hidden when they are skipped
      return this.tableData.map((f,index) => {
        if (["amortization"].includes(f.id)) {
          return this.isSkipped[index]

        } else if ("mortgagePayableLess1YrRolloverType" === f.id) {
          return !this.displayMortgagesNotesPayableLessThanOneYearSCorpExcludedAmount

        } else if ("nonRolloverMortgagePayableLess1YrAmount" === f.id) {
          return true
        }
        return false
      })
    },
    isWaiveFormBtnDisplayed() {
      return (this.isEmploymentOwnershipInterestProvidedGte25OrNull
        && this.getPY1 === this.taxFormYear
        && this.$store.getters.getBusinessIncomeIncreasingReturnWaiverIndicator
        && this.$store.getters.isFiveYearSelfEmploymentIndicator
        && !this.$store.getters.get1040SkippedPY2
        && !this.seRequest.extraData.waiveBusinessReturnsIndicator)
    },
    isUnWaiveFormBtnDisplayed() {
      return (this.getPY1 === this.taxFormYear
        && this.$store.getters.getBusinessIncomeIncreasingReturnWaiverIndicator
        && !this.$store.getters.get1040SkippedPY2
        && this.seRequest.extraData.waiveBusinessReturnsIndicator)
    },

  },
  watch: {
    displayMortgagesNotesPayableLessThanOneYearSCorpExcludedAmount(display) {
      let f = this.getField('mortgagePayableLess1YrRolloverType');
      if (f) {
        if (display) {
          f.isEmpty = false
        } else {
          f.isEmpty = true
          f.value = ''
        }
      }
    },
    mortgagePayableLess1YrRolloverType(value) {
      let f = this.getField('nonRolloverMortgagePayableLess1YrAmount')
      if (f) {
        if (value === Enums.MortgagePayableLess1YrRolloverType.some) {
          f.isEmpty = false
        } else {
          f.value = ""
          f.isEmpty = true
        }
      }
    },
  },
  methods: {
    handleHelp(index, row) {
      row.showHelp = true;
    },

    waiveBusinessReturn() {
      let msgIcon = "question"
      let msgHtml = "The borrower’s individual tax returns show an increase in self-employment income over the past two years. The requirement for business tax returns may be waived if:"
      + "<p align='left'><ul>"
      + " <li align='left'>the borrower has been self-employed in the same business for at least five years, and</li>"
      + " <li align='left'>the borrower is using his or her own individual funds to pay the down payment and closing costs and satisfy applicable reserve requirements</li>"
      + "</ul></p>"

      Swal.fire({
        title: `Do you want to waive business tax returns?`,
        html: msgHtml,
        width: 800,
        showCancelButton: true,
        buttonsStyling: false,
        customClass: {confirmButton: 'bp-button bp-button--primary', cancelButton: 'bp-button'},
        confirmButtonText: `Waive Business Tax Returns`,
        cancelButtonText: `Provide Business Tax Return Data`,
        icon: msgIcon
      }).then((result) => {
        if (result.isConfirmed) {
          this.skippedForm = true
          this.$store.dispatch(SKIP_BUSINESS_TAX_FORMS, {skipped: this.skippedForm});
        }
      })
    },

    unWaiveBusinessReturn() {
      this.skippedForm = false
      this.$store.dispatch(SKIP_BUSINESS_TAX_FORMS, {skipped: this.skippedForm});
    }

  }
}
</script>
<style >

.required > div:after {
  content:" *";
  color: red;
  text-nowrap: true
}

.std-pdf-row {
  position: fixed;
  width: 40%;
  height: 90%;
}


</style>
