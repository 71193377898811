/*!

=========================================================
* Vue Light Bootstrap Dashboard - v1.3.1
=========================================================

* Product Page: http://www.creative-tim.com/product/vue-light-bootstrap-dashboard-pro
* Copyright 2019 Creative Tim (http://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard/blob/master/LICENSE.md)

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import LightBootstrap from './light-bootstrap-main'
import Vuelidate from 'vuelidate'
import VueMask from 'v-mask'
import MaskedInput from 'vue-text-mask'
import SvgSprite from 'vue-svg-sprite'

// Plugins
import App from './App.vue'
import store from './store'
import seApi from 'src/util/seapi'

import IconBase from '@/components/IconBase.vue'
import {format} from "date-fns";
import { getInstance } from 'recaptcha-v3'

// router setup
import routes from './routes/routes'
// plugin setup
Vue.use(VueRouter)
Vue.use(Vuelidate)
Vue.use(LightBootstrap)
Vue.use(VueMask)
Vue.use(SvgSprite)
Vue.component('masked-input', MaskedInput);

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: 'active',
  scrollBehavior() {
    return {x: 0, y: 0, behavior: 'smooth'}
  }
})

const onRouterError = (e) => {
  // avoid NavigationDuplicated
  if (e.name !== 'NavigationDuplicated') throw e
}

router.beforeEach( (to, from, next) => {
  let r = getInstance()
  if (r) {
    if (to.path === '/se-eval/eval-findings') {
      r.hideBadge()
    } else {
      r.showBadge()
    }
  }

  try {
    if (!store.state.se.accessAllowed && to.name !== 'Welcome') {
      next('/home/welcome')
    } else {
      next()
    }
  } catch (e) {
    onRouterError(e)
  }
})

Vue.filter('toCurPercent', function (value, isCurrency, isPercent, scale) {
  if ((!isCurrency && !isPercent) || isNaN(parseFloat(value))) {
    return value;
  }

  if (value && (typeof value === 'string' || value instanceof String)) {
    value = value.replace(/,|\$|\%/g, '')
  }

  if (isCurrency) {
    let fmtCur = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    });
    return fmtCur.format(value);
  }

  if (isPercent) {
    if (value === null || value === undefined) {
      return 'N/A'
    }
    let fmtPct = new Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: scale?? 2
    });
    return fmtPct.format(value / 100);
  }

  return value;
});

Vue.filter('toCurDecimal', function (value, isCurrency, isDecimal) {
  if ((!isCurrency && !isDecimal) || isNaN(parseFloat(value))) {
    return value;
  }

  if (value && (typeof value === 'string' || value instanceof String)) {
    value = value.replace(/,|\$|\%/g, '')
  }

  if (isCurrency) {
    let fmtCur = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    });
    return fmtCur.format(value);
  }

  if (isDecimal) {
    if (value === null || value === undefined) {
      return 'N/A'
    }
    let fmtDecimal = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2
    });
    return fmtDecimal.format(value);
  }

  return value;
});

Vue.filter('toCurrency', function (value, isCurrency) {
  if (!isCurrency || isNaN(parseFloat(value))) {
    return value;
  }
  let formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  });
  return formatter.format(value);
});

Vue.filter('toPercent', function (value) {
  if (value === null || value === undefined) {
    return 'N/A'
  }
  if (isNaN(parseFloat(value))) {
    return value;
  }
  let formatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 2
  });
  return formatter.format(value);
});

Vue.filter('toDate', function (value) {
  if (value === null || value === undefined) {
    return ''
  }
  return format(new Date(value), 'yyyy-MM-dd')
});

Vue.filter('toDateTime', function (value) {
  if (value === null || value === undefined) {
    return ''
  }
  return format(new Date(value), 'yyyy-MM-dd HH:mm:ss')
});


Vue.component('icon-base', IconBase);

seApi.setEnv();

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store
})
