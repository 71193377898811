import Enums from "../../../../model/enums";
import {ty} from "./ty";

export default {
  id: Enums.SeTaxFormType.tf1040s1,
  pdf: `static/forms/${ty}/${ty}_1040s1.pdf`,
  route: `/se-eval/std-tax-form-1040s1/${Enums.SeTaxFormType.tf1040s1}/${ty}`,
  fields: [
    {
      id: "businessIncomeLoss",
      fieldName: "form1[0].Page1[0].f1_06[0]",
      label: "Business income or (loss)",
      line: "Line 3",
      isCurrency: true,
      isPositive: false,
      hasHelp: true
    },
    {
      id: "rentalReRoyaltiesPartnerships",
      fieldName: "form1[0].Page1[0].f1_08[0]",
      label: "Rental real estate, royalties, partnerships, S corporations, trusts, etc.",
      line: "Line 5",
      isCurrency: true,
      isPositive: false,
      hasHelp: true
    },
    {
      id: "otherIncomeLoss",
      fieldName: "form1[0].Page1[0].f1_13[0]",
      label: "Other income",
      line: "Line 8",
      isCurrency: true,
      isPositive: false,
      hasHelp: true
    },
    {
      id: "carryoverLoss",
      fieldName: "",
      label: "Carryover loss",
      line: "Carryover loss",
      description: "Look at the description of Other Income. Do any descriptions contain words like: “Net Operating Loss”, “Carryover Loss”, “Carryforward Loss”, “465(d) Carryover” or “Prior Year Loss”?  If a description references a statement or attachment, you may need to look further in the return to determine if a Carryover Loss is present. If the borrower has more than one business, ensure the Carryover Loss provided relates to the business you want Income Calculator to evaluate.",
      isCurrency: true,
      isPositive: true,
      isEmptyByDefault: true,
      hasHelp: true
    },
  ]
}

