import Enums from "../../../../model/enums";
import {ty} from "./ty";

export default {
  id: Enums.SeTaxFormType.tf1040c,
  parts: [
    {
      part: "1",
      pdf: `static/forms/${ty}/${ty}_1040scPg1.pdf`,
      route: `/se-eval/std-tax-form-1040sc/${Enums.SeTaxFormType.tf1040c}/${ty}/1`,
      fields: [
        {
          id: "businessActivityCode",
          formPart: 0,
          fieldName: "topmostSubform[0].Page1[0].BComb[0].f1_4[0]",
          label: "Business Code",
          line: "Line B",
          isInteger: true,
          hasHelp: true
        },
        {
          id: "businessStartedAcquired",
          formPart: 0,
          fieldName: "topmostSubform[0].Page1[0].c1_3[0]",
          label: `Started/Acquired in ${ty}`,
          value: "Box checked",
          noValue: "Box not checked",
          isBoolean: true,
          required: true,
          line: "Line H",
          hasHelp: true,
          defaultValue: "No"
        },
        {
          id: "grossReceiptsOrSales",
          formPart: 0,
          fieldName: "topmostSubform[0].Page1[0].f1_10[0]",
          label: "Gross Receipts or sales",
          line: "Line 1",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "returnsAndAllowances",
          formPart: 0,
          fieldName: "topmostSubform[0].Page1[0].f1_11[0]",
          label: "Returns and allowances",
          line: "Line 2",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "costOfGoodsSold",
          formPart: 0,
          fieldName: "topmostSubform[0].Page1[0].f1_13[0]",
          label: "Cost of goods sold",
          line: "Line 4",
          isCurrency: true,
          isPositive: false,
          hasHelp: true
        },
        {
          id: "otherIncomeLoss",
          formPart: 0,
          fieldName: "topmostSubform[0].Page1[0].f1_15[0]",
          label: "Other Income",
          line: "Line 6",
          isCurrency: true,
          isPositive: false,
          hasHelp: true
        },
        {
          id: "carTruckExpenses",
          formPart: 1,
          fieldName: "topmostSubform[0].Page1[0].Lines8-17[0].f1_18[0]",
          label: "Car and truck expenses",
          line: "Line 9",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "depletion",
          formPart: 1,
          fieldName: "topmostSubform[0].Page1[0].Lines8-17[0].f1_21[0]",
          label: "Depletion",
          line: "Line 12",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "depreciation",
          formPart: 1,
          fieldName: "topmostSubform[0].Page1[0].Lines8-17[0].f1_22[0]",
          label: "Depreciation",
          line: "Line 13",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "mortgagePaidToBanks",
          formPart: 1,
          fieldName: "topmostSubform[0].Page1[0].Lines8-17[0].f1_25[0]",
          label: "Mortgage (paid to banks, etc.)",
          line: "Line 16a",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "deductibleMeals",
          formPart: 1,
          fieldName: "topmostSubform[0].Page1[0].Lines18-27[0].f1_36[0]",
          label: "Deductible meals",
          line: "Line 24b",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "nonDeductibleMeals",
          formPart: 1,
          fieldName: "",
          label: "Nondeductible meals",
          line: "Nondeductible meals",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "wagesLessEmploymentCredits",
          formPart: 1,
          fieldName: "topmostSubform[0].Page1[0].Lines18-27[0].f1_38[0]",
          label: "Wages (less employment credits)",
          line: "Line 26",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "otherExpenses",
          formPart: 1,
          fieldName: "topmostSubform[0].Page1[0].Lines18-27[0].f1_39[0]",
          label: "Other expenses",
          line: "Line 27a",
          isCurrency: true,
          isPositive: false,
          hasHelp: true
        },
        {
          id: "totalExpenses",
          formPart: 1,
          fieldName: "topmostSubform[0].Page1[0].f1_41[0]",
          label: "Total expenses",
          line: "Line 28",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "businessUseHome",
          formPart: 1,
          fieldName: "topmostSubform[0].Page1[0].f1_45[0]",
          label: "Expenses for business use of home",
          line: "Line 30",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "netProfitLoss",
          formPart: 1,
          fieldName: "topmostSubform[0].Page1[0].f1_46[0]",
          label: "Net profit or (loss)",
          line: "Line 31",
          isCurrency: true,
          isPositive: false,
          hasHelp: true
        }
      ]
    },
    {
      part: "2",
      pdf: `static/forms/${ty}/${ty}_1040scPg2.pdf`,
      route: `/se-eval/std-tax-form-1040sc/${Enums.SeTaxFormType.tf1040c}/${ty}/2`,
      fields: [
        {
          id: "vehicleServiceStartDate",
          formPart: 1,
          fieldName: "topmostSubform[0].Page2[0].f2_9[0]",
          fieldHighlights: ["topmostSubform[0].Page2[0].f2_9[0]", "topmostSubform[0].Page2[0].f2_10[0]", "topmostSubform[0].Page2[0].f2_11[0]"],
          label: "the date when you placed your vehicle in service for business purposes",
          line: "Line 43",
          isCurrency: false,
          isPositive: false,
          hasHelp: true
        },
        {
          id: "businessMileage",
          formPart: 2,
          fieldName: "topmostSubform[0].Page2[0].f2_12[0]",
          label: "Business Mileage",
          line: "Line 44a",
          isInteger: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "amortization",
          formPart: 3,
          fieldName: "topmostSubform[0].Page2[0].PartVTable[0].Item1[0].f2_16[0]",
          label: "Amortization",
          line: "Part V",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "amortizationLabel",
          formPart: 3,
          fieldName: "topmostSubform[0].Page2[0].PartVTable[0].Item1[0].f2_15[0]",
          value: "Amortization",
          staticText: true
        }
      ]
    }
  ]
}

