<template>
  <div>

      <div class="row">

        <div class="col-md-6 pr-5">
          <h1 class="mt-0 se-std-bp-title-sb mb-3">Input Data: Form 1040 - Sch E ({{this.taxFormYear}})
            <a class="btn-link se-page-help-icon" v-tooltip.top-center="'Learn More'" @click="showPageHelp = true">
              <i class="fa fa-info-circle"></i>
            </a>
          </h1>

          <h4 class="mt-0 se-bp-title-sb">Find part II of Schedule E (See Page 2)</h4>
          <br>
          <p>To ensure you are reviewing the correct Schedule E, check that the name shown on return
          in the header section of Schedule E is associated to the borrower who owns the business.
          </p>

          <p>Some borrowers will have more than one business shown in part II.  Check that the Name shown Line 28(a) is associated
            to the business you want {{appName}} to evaluate.
          </p>

          <form autocomplete="off">
            <div class="bp-form-group mr-5"
                 v-for="(row, index) in tableData">

                <label :for="row.id" class="se-std-field-group-label">
                  <span>{{row.line}}</span>
                </label>
                <a class="se-help-icon btn-link" v-tooltip.top-center="'Learn More'" @click="handleFieldHelp(row.id)" v-show="row.hasHelp">
                  <i class="fa fa-info-circle"></i>
                </a>

                <div @mouseover="highlightHoveredOverFormField(row)" @mouseleave="highlightHoveredOverFormField()">
                  <radio :label="false"
                         v-model="row.isEmpty"
                         :disabled="isSkipped[index] || isLocked"
                         :class="{'opacity-5': isSkipped[index]}"
                         class="mb-1">
                    <span class="se-std-label">{{row.name}}</span>
                  </radio>
                  <input :id="row.id"
                         :readonly="isLocked"
                         class="se-tax-field-input-short ml-4 bp-form-input bp-u-shadow-04"
                         type="text"
                         v-mask="psMask"
                         @focus="highlightFocusedFormField(row)"
                         @blur="fieldIsFocused = false"
                         :disabled="row.isEmpty || isSkipped[index]"
                         :value="row.value.toUpperCase()"
                         @input="row.value=$event.target.value.toUpperCase()"
                         />
                  <radio :label="true"
                         v-model="row.isEmpty"
                         :disabled="isSkipped[index] || isLocked"
                         :class="{'opacity-5': isSkipped[index]}"
                         class="mt-1"><span class="se-std-label">There is no value on this line</span>
                  </radio>
                </div>
            </div>
          </form>

          <div class="bp-help is-error mb-2" v-if="$v.allAnswered.$error">
            <div class="bp-ucon" data-src="feedback-warning"><svg class="bp-svg" viewBox="0 0 24 24" id="feedback-warning" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M13.009 13.987V8.934h-1.956v5.053h1.956zm0 4.016v-1.962h-1.956v1.962h1.956zM2.988 20.962L12.016 3l9.025 17.962H2.988z"></path></svg>
            </div>
            <template v-if="!$v.allAnswered.required">Provide answer for all items or select 'There is no amount on this line'</template>
          </div>

        </div>

        <div class="col-md-6 d-none d-sm-none d-md-block">
          <div class="std-pdf-row">
          <WebViewer :url="this.pdf"
                     ref="viewer"
                     :se-fields="seFields"
                     :tax-form-year="this.taxFormYear"
                     :tax-form-id="this.taxFormId"
                     @viewerLoaded="onViewerLoaded"
                     :key="this.pdfViewerKey"/>
          </div>

        </div>
      </div>

    <se-help :visible.sync="showPageHelp">
        <p>
          Use this screen to provide information about Supplemental Income and Loss. {{ appName }} will display Schedule E when the Business Structure is Limited Liability Company (LLC) or unknown.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-flag/></icon-base>
          {{ appName }} may collect information not traditionally used in evaluating self-employment income. Some data may be used to validate data integrity or determine if income from other tax return forms or schedules may be needed.
        </p>
    </se-help>

    <se-help :visible.sync="showFieldHelp.pOrS">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this information important to {{ appName }}?<br>
          A. {{ appName }} uses this information to determine if income data from other tax return forms or schedules may be needed.
        </p>
      </div>
    </se-help>

    <se-buttons
      :display-submit="false"
      :display-save="false"
      :display-skip="this.isSkipFormBtnDisplayed"
      :display-un-skip="this.isUnSkipFormBtnDisplayed"
      :disable-submit="true"
      :disable-save="true"
      :disable-next="false"
      :disable-previous="false"
      @seSkip="skipTaxForm"
      @seUnSkip="unSkipTaxForm"
      @seNext="next"
      @sePrevious="previous">
    </se-buttons>

  </div>

</template>
<script>
import Vue from 'vue'
import SeButtons from './SeButtons.vue'
import SeHelp from './SeHelp.vue'
import {Checkbox, Radio} from 'src/components/index'
import LSwitch from 'src/components/Switch.vue'
import Swal from 'sweetalert2'
import { Collapse, CollapseItem } from 'element-ui'
import {mapGetters, mapState} from 'vuex'
import Enums from 'src/model/enums'
import {SAVE_SE_REQUEST, SET_REQUEST_DATA, SET_TAX_FORM_DATA} from 'src/store/actions/se'
import WebViewer from 'src/components/WebViewerROC.vue'
import {Table, TableColumn, Drawer} from 'element-ui'
import {required, requiredIf} from "vuelidate/lib/validators";
import { taxSingleFormMixin } from 'src/mixins/TaxSingleFormMixin'
import createNumberMask from "text-mask-addons/dist/createNumberMask"
import appNameMixin from '../../../mixins/AppNameMixin'
export default {
  mixins: [taxSingleFormMixin, appNameMixin],
  components: {
    Checkbox,
    Radio,
    LSwitch,
    SeButtons,
    SeHelp,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    WebViewer,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Drawer.name]: Drawer,
  },
  data() {
    return {
      psMask: [/P|p|S|s/]
    }
  },
  validations: {
    allAnswered: {
      required: (v) => v
    }
  },
  computed: {
    isSkipped() {
      return this.tableData.map(f => {
        if (this.skippedForm) return true

        return false;
      })
    },
    isSkipFormBtnDisplayed() {
      //allow skip only for LLC and Unknown and for CY
      return ([Enums.SeBusinessStructureType.llc, Enums.SeBusinessStructureType.unknown].includes(this.$store.getters.getBusinessStructureProvided))
        && this.getPY1 === this.taxFormYear && !this.skippedForm
    },
    isUnSkipFormBtnDisplayed() {
      return (this.getPY1 === this.taxFormYear) && this.skippedForm
    },
  },
  methods: {
    next() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.saveToStore()

      //this form will change the evaluated business structure, so the
      this.updateSelectedTaxForms()

      this.saveRequest()
      let ri = this.seTaxFormRoutes.indexOf(this.$router.currentRoute.path);
      if (ri < this.seTaxFormRoutes.length - 1) {
        this.$router.push(this.seTaxFormRoutes[ri + 1])
      } else {
        this.$router.push('/se-eval/business-length')
      }
    },
    previous() {
      let ri = this.seTaxFormRoutes.indexOf(this.$router.currentRoute.path);
      if (ri > 0) {
        this.$router.push(this.seTaxFormRoutes[ri - 1])
      } else {
        this.$router.push('/se-eval/tax-form-select')
      }
    },
    updateSelectedTaxForms() {
      //this form determines the business structure in case of LLC/Unknown
      let updatedSelectedTaxForms = []
      this.seRequest.selectedTaxForms.forEach(stf => {
        let updatedStf = {year: stf.year, formIds: stf.formIds.slice()}
        updatedSelectedTaxForms.push(updatedStf)

        if (stf.formIds && stf.formIds.length > 0) {
          let businessStructure = this.$store.getters.getBusinessStructureEvaluatedAndDerived.evaluated
          let formIdToAdd = undefined
          if (businessStructure == Enums.SeBusinessStructureType.soleProprietorship) {
            //noop
          } else if (businessStructure == Enums.SeBusinessStructureType.corp) {
            formIdToAdd = Enums.SeTaxFormType.tf1120
          } else if (businessStructure == Enums.SeBusinessStructureType.sCorp) {
            formIdToAdd = Enums.SeTaxFormType.tf1120s
          } else if (businessStructure == Enums.SeBusinessStructureType.partnership) {
            formIdToAdd = Enums.SeTaxFormType.tf1065
          }
          if (formIdToAdd && !updatedStf.formIds.includes(formIdToAdd)) {
            updatedStf.formIds.splice(updatedStf.formIds.indexOf(Enums.SeTaxFormType.tf1040c), 1)
            updatedStf.formIds.push(formIdToAdd)
          }
        }
      })
      this.$store.commit(SET_REQUEST_DATA, { selectedTaxForms: updatedSelectedTaxForms })
    },
    handleHelp(index, row) {
      row.showHelp = true;
    },
  }
}
</script>
<style >

.required > div:after {
  content:" *";
  color: red;
  text-nowrap: true
}

.std-pdf-row {
  position: fixed;
  width: 40%;
  height: 90%;
}


</style>
