<template>
  <div>

    <div class="table-responsive table-full-width se-findings-table mt-1" >
      <el-table
        ref="collectedDataTable"
        :data="this.tableData"
        row-key="id"
        :border="false"
        header-row-class-name="se-findings-table-header"
        :header-cell-class-name="headerCellClassName"
        row-class-name="se-findings-table-row"
        :cell-class-name="cellClassName">

        <el-table-column
          label="Business Income from Form 1120"
          class-name="se-findings-item-column"
          :min-width="150">
          <template slot-scope="scope">
            {{scope.row.name}}
            <a class="se-help-icon btn-link" v-tooltip.top-center="'Learn More'" @click="handleFieldHelp(scope.row.id)" v-show="scope.row.hasHelp">
              <i class="fa fa-info-circle"></i>
            </a>
          </template>
        </el-table-column>

        <el-table-column
          header-align="center"
          label="Tax Returns">
          <el-table-column
            :label="getPY1"
            header-align="center"
            align="right"
            :width="185"
            :min-width="40">
            <template slot-scope="scope">
              <div class="row" v-if="scope.row.id !== 'Total'">
                <div class="col-8">{{ scope.row.currentYearTaxValue | toCurPercent(scope.row.isCurrency, scope.row.isPercent, scope.row.scale) }}<span v-if="scope.row.currentYearTaxValue2"><br>{{ scope.row.currentYearTaxValue2 }}</span></div>
                <div v-if="scope.row.currentYearFieldLocation">
                  <a href="#" @click="editField(scope.row.currentYearFieldLocation)">
                    <icon-base width="22" height="22" iconColor="rgba(0,0,0,0.80)" icon-name="Edit"><icon-edit/></icon-base>
                  </a>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="this.getNumberOfSelectedTaxFormYears > 1"
            :label="getPY2"
            header-align="center"
            align="right"
            :width="185"
            :min-width="40">
            <template slot-scope="scope">
              <div class="row" v-if="scope.row.id !== 'Total'">
                <div class="col-8">{{ scope.row.pastYearTaxValue | toCurPercent(scope.row.isCurrency, scope.row.isPercent, scope.row.scale) }}<span v-if="scope.row.pastYearTaxValue2"><br>{{ scope.row.pastYearTaxValue2 }}</span></div>
                <div v-if="scope.row.pastYearFieldLocation">
                  <a href="#" @click="editField(scope.row.pastYearFieldLocation)">
                    <icon-base width="22" height="22" iconColor="rgba(0,0,0,0.80)" icon-name="Edit" ><icon-edit/></icon-base>
                  </a>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column
          align="right"
          :width="20"
          :min-width="20">
        </el-table-column>

        <el-table-column
          header-align="center"
          :label="appName">
          <el-table-column
            :label="getPY1"
            label-class-name="se-findings-table-eval-result-header"
            class-name="se-eval-result"
            align="right"
            header-align="center"
            :width="185"
            :min-width="40">
            <template slot-scope="scope">
              <div class="col-10">
                    <span :class="{'font-weight-bold': scope.row.id === 'Total' }" >
                      <template v-if="scope.row.currentYearSeValueMemo"><a class="se-help-a" v-tooltip.top-center="'Learn More'" @click="showSeMemo(scope.row.id, scope.row.currentYearSeValue, scope.row.cy, scope.row.currentYearSeValueMemo)">{{ scope.row.currentYearSeValue | toCurPercent(scope.row.isCurrency, scope.row.isPercent, scope.row.scale) }}</a></template>
                      <template v-else>{{ scope.row.currentYearSeValue | toCurPercent(scope.row.isCurrency, scope.row.isPercent, scope.row.scale) }}</template>
                    </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :label="getPY2"
            label-class-name="se-findings-table-eval-result-header"
            align="right"
            class-name="se-eval-result"
            header-align="center"
            :width="185"
            :min-width="40">
            <template slot-scope="scope">
              <div class="col-10">
                    <span :class="{'font-weight-bold': scope.row.id === 'Total' }">
                      <template v-if="scope.row.pastYearSeValueMemo"><a class="se-help-a" v-tooltip.top-center="'Learn More'" @click="showSeMemo(scope.row.id, scope.row.pastYearSeValue, scope.row.py, scope.row.pastYearSeValueMemo)">{{ scope.row.pastYearSeValue | toCurPercent(scope.row.isCurrency, scope.row.isPercent, scope.row.scale) }}</a></template>
                      <template v-else>{{ scope.row.pastYearSeValue | toCurPercent(scope.row.isCurrency, scope.row.isPercent, scope.row.scale) }}</template>
                    </span>
              </div>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column
          v-if="this.getNumberOfSelectedTaxFormYears > 1"
          label="Change"
          align="center"
          class-name="cell-narrow se-eval-result-change"
          :width="100"
          :min-width="20">
          <template slot-scope="scope"><span :class="{'font-weight-bold': scope.row.id === 'Total' }" v-if="scope.row.isDisplayChange">{{ scope.row.seValueChangePct | toPercent}}</span></template>
        </el-table-column>
      </el-table>
    </div>

    <se-help :visible.sync="showFieldHelp.taxableIncome">
      <div class="se-field-help">
        <p>
          Form 1120, Line 30.<br>
          Any amount on this line is added to the monthly qualifying income calculation, if the borrower(s) are 100% owners of the business.
        </p>
      </div>
    </se-help>
    <se-help :visible.sync="showFieldHelp.totalTax">
      <div class="se-field-help">
        <p>
          Form 1120, Line 31.<br>
          Any amount on this line is subtracted from the monthly qualifying income calculation, if the borrower(s) are 100% owners of the business.
        </p>
      </div>
    </se-help>
    <se-help :visible.sync="showFieldHelp.capitalGainNetIncome">
      <div class="se-field-help">
        <p>
          Form 1120, Line 8.<br>
          Any amount shown on Line 8 has already been accounted for in the Taxable income shown on Form 1120, Line 30.
        </p>

          <b>FAQs:</b>
        <p>
          Q. Why is {{ appName }} subtracting the Capital gain net income amount reported on the tax returns?<br>
          A. If two years tax return data is provided, Capital gain net income amounts reported on the tax returns will be subtracted if they have not been received for two years.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about Adjustments to Business Cash Flow in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.4-03/analyzing-returns-corporation">Analyzing Returns for a Corporation</a> section of the Fannie Mae Selling Guide.
        </p>
        <p>
          Q. Why is {{ appName }} not counting (setting to zero) the Capital gain net income amount reported on the tax returns?<br>
          A. The Capital gain net income amounts are being included in the monthly qualifying income calculation. Any amount shown on
          Line 8 has already been accounted for in the Taxable income shown on Line 30.
        </p>
        <p>
         Q. The Fannie Mae Selling Guide allows me to document income using one year of tax returns.
          Will {{ appName }} consider Capital gain net income amounts to be recurring if they are only documented with one year of tax returns?<br>
         A. {{ appName }} may consider Capital gain net income amounts to be recurring even if they are documented with only one year of tax returns.
        </p>
         Q. Am I required to obtain supporting documentation from the borrower regarding the nature of the Capital gain net income amounts?<br>
         A. {{ appName }} may not require supporting documentation for Capital gain net income amounts.
        </p>
        <p>
          Q. Why isn’t {{ appName }} adding back the negative Capital gain net income amount reported on the tax returns?<br>
          A. {{ appName }} does not add back negative Capital gain net income amounts since they have already been accounted for in the Taxable income shown on Line 30.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.netGainLoss4797">
      <div class="se-field-help">
        <p>
          Form 1120, Line 9.<br>
          Any amount shown on Line 9 has already been accounted for in the Taxable income shown on Form 1120, Line 30.
        </p>

        <b>FAQs:</b>
        <p>
          Q. Why is {{ appName }} subtracting the Net gains from Form 4797 reported on the tax returns?<br>
          A. If two years tax return data is provided, Net gains from Form 4797 reported on the tax returns will be subtracted if they have not been received for two years.
        </p>

        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about Adjustments to Business Cash Flow in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.4-03/analyzing-returns-corporation">Analyzing Returns for a Corporation</a> section of the Fannie Mae Selling Guide.
        </p>
        <p>
          Q. Why is {{ appName }} not counting (setting to zero) the Net gain from Form 4797 reported on the tax returns?<br>
          A. The Net gain from Form 4797 is being included in the monthly qualifying income calculation.
          Any amount shown on Line 9 has already been accounted for in the Taxable income amount shown on Line 30.
        </p>
        <p>
          Q. The Fannie Mae Selling Guide allows me to document income using one year of tax returns.
          Will {{ appName }} consider Net gain (loss) amounts from Form 4797 to be recurring if they are only documented with one year of tax returns?<br>
         A. {{ appName }} may consider Net gain (loss) amounts from Form 4797 to be recurring even if they are documented with only one year of tax returns.
        </p>
         Q. Am I required to obtain supporting documentation from the borrower regarding the nature of the Net gain (loss) amounts from Form 4797?<br>
         A. {{ appName }} may not require supporting documentation for Net gain (loss) amounts from Form 4797.
        </p>
        <p>
          Q. Why isn’t {{ appName }} adding back the Net loss amounts from Form 4797 reported on the tax returns?<br>
          A. {{ appName }} does not add back Net loss amounts from Form 4797 since they have already been accounted for in the Taxable income shown on Line 30.
        </p>
      </div>
    </se-help>
    <se-help :visible.sync="showFieldHelp.otherIncomeLoss">
      <div class="se-field-help">
        <p>
          Form 1120, Line 10.<br>
          Any amount shown on Line 10 has already been accounted for in the Taxable income shown on Form 1120, Line 30.
        </p>
        <b>FAQs:</b>
        <p>
          Q. Why is {{ appName }} subtracting the Other income amounts reported on the tax returns?<br>
          A. If two years tax return data is provided, Other income amounts reported on the tax returns will be subtracted if they have not been received for two years.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about Adjustments to Business Cash Flow in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.4-03/analyzing-returns-corporation">Analyzing Returns for a Corporation</a> section of the Fannie Mae Selling Guide.
        </p>
        <p>
          Q. Why is {{ appName }} not counting (setting to zero) the Other income amounts reported on the tax returns?<br>
          A. The Other income amounts are being included in the monthly qualifying income calculation.
          Any amount shown on Line 10 has already been accounted for in the Taxable income amount shown on Line 30.
        </p>
        <p>
          Q. The Fannie Mae Selling Guide allows me to document income using one year of tax returns.
          Will {{ appName }} consider Other income (loss) amounts to be recurring if they are only documented with one year of tax returns?<br>
         A. {{ appName }} may consider Other income (loss) amounts to be recurring even if they are documented with only one year of tax returns.
        </p>
         Q. Am I required to obtain supporting documentation from the borrower regarding the nature of the Other income (loss) amounts?<br>
         A. {{ appName }} may not require supporting documentation for Other income (loss) amounts.
        </p>
        <p>
          Q. Why isn’t {{ appName }} adding back the Other (loss) amounts reported on the tax returns?<br>
          A. {{ appName }} does not add back Other (loss) amounts since they have already been accounted for in the Taxable income shown on Line 30.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.depreciation">
      <div class="se-field-help">
        <p>
          Form 1120, Line 20<br>
          Any amount on this line is added to the monthly qualifying income calculation,
          if the borrower(s) are 100% owners of the business.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.depreciation8825">
      <div class="se-field-help">
        <p>
          Form 8825, Line 14(a) through 14(h).<br>
          Any amount on this line is added to the monthly qualifying income calculation.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.depletion">
      <div class="se-field-help">
        <p>
          Form 1120, Line 21<br>
          Any amount on this line is added to the monthly qualifying income calculation,
          if the borrower(s) are 100% owners of the business.

        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.amortization">
      <div class="se-field-help">
        <p>
          Form 1120, Attached Statement.<br>
          Any amount identified by the lender as amortization is added to the
          monthly qualifying income, if the borrower(s) are 100% owners of the business.
        </p>
        <b>FAQs:</b>
        <p>
          Q. Why is the pencil icon grayed-out (not able to be selected)?<br>
          A. {{ appName }} will display Amortization when there are Other Deductions on Form 1120, Page 1, Line 26
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.amortization4562">
      <div class="se-field-help">
        <p>
          Form 4562, Line 44.<br>
          Any amount on this line is added to the monthly qualifying income, if the borrower(s) are 100% owners of the business.
        </p>
        <b>FAQs:</b>
        <p>
          Q. Why is the pencil icon grayed-out (not able to be selected)?<br>
          A. {{ appName }} will display Form 4562 when there are
          Other Deductions on Form 1120, Page 1, Line 26 and no Amortization amount has been identified on Form 1120.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.casualtyTheftLoss">
    <div class="se-field-help">
      <p>
        Form 4684, Lines 31, 32, 36 and 37<br>
        {{ appName }} determines if there are gains or losses from any casualties or thefts.
        Any loss amounts are added to the monthly qualifying income calculation, if the borrower(s) are 100% owners of the business.
        If the borrower owns more than one business, ensure the amounts provided relate to the business you want Income Calculator to evaluate.       </p>

      <b>FAQs:</b>
      <p>
        Q. Why is the pencil icon grayed-out (not able to be selected)?<br>
        A. {{ appName }} will display Form 4864 when identified it is included
        in the tax returns on the Losses and Expenses screen.

      </p>


    </div>
  </se-help>

    <se-help :visible.sync="showFieldHelp.extraordinaryOneTimeExpenses">
      <div class="se-field-help">
        <p>
          Any amount identified by the lender as an extraordinary one-time expense
          is added back to the monthly qualifying income, if the borrower(s) are 100% owners of the business.
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about Analyzing Individual Tax Returns (and extraordinary one-time expenses) in the
        <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.3-01/general-information-analyzing-individual-tax-returns">General Information on Analyzing Individual Tax Returns</a> section of the Fannie Mae Selling Guide.
        </p>


      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.netOperatingLossDeduction">
    <div class="se-field-help">
      <p>
        Form 1120, Line 29a<br>
        Any amount on this line is added to the monthly qualifying
        income calculation, if the borrower(s) are 100% owners of the business.
      </p>
    </div>
  </se-help>

    <se-help :visible.sync="showFieldHelp.specialDeductions">
      <div class="se-field-help">
        <p>
          Form 1120, Line 29b<br>
          Any amount on this line is added to the monthly qualifying income calculation,
          if the borrower(s) are 100% owners of the business.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.mortgagesNotesPayableLessThanOneYearEndOfYear">
      <div class="se-field-help">
        <p>
          {{ appName }} determines the amount of Mortgages, notes or bonds payable in less than 1 year (short-term debt) that are subtracted from qualifying income, based on the business’ cash position and if necessary, supplemental information. Income Calculator evaluates short term debt as follows:
        </p>
        <ul>
          <li>If the business has sufficient cash (shown on Form 1120S, Schedule L, Line 1(d) to pay off the short-term debt (shown on Form 1120S, Schedule L, Line 17D), the short-term debt is not subtracted from the monthly qualifying income.</li>
          <li>If some or all of the short-term debt is a line of credit or is regularly rolled over, those amounts are not subtracted from the monthly qualifying income.</li>
          <li>If the business does not have sufficient cash to pay off the short-term debt, and the the short-term debt is not a line of credit or does not regularly roll over, the short-term debt is subtracted from the monthly qualifying income.</li>
        </ul>

          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about Adjustments to Business Cash Flow in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.4-03/analyzing-returns-corporation">Analyzing Returns for a Corporation</a> section of the Fannie Mae Selling Guide.
             </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.travelEntertainment">
      <div class="se-field-help">
        <p>
          Form 1120, Schedule M-1, Line 5c (Travel and entertainment)<br>
          Any amount on this line is subtracted from the monthly qualifying income, if the borrower(s) are 100% owners of the business.
        </p>

      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.cashDistributions">
      <div class="se-field-help">
        <p>
          Form 1120, Schedule M-2, Line 5(a)<br>
          Any amount on this line is subtracted from the monthly qualifying income, if the borrower(s)
          are 100% owners of the business. Cash distributions include dividends paid to the corporation’s stockholders (owners).
        </p>
        <p>
          Q. Can dividend income be used as qualifying income?<br>
          A. Dividend income may be used as qualifying income in accordance with the Fannie Mae Selling Guide. Dividend income is reported
          to the IRS on Schedule B of the borrower’s individual (personal) tax returns. Eligible dividend income used for loan qualification
          should be reported to Desktop Underwriter as dividend income.
        </p>
         <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.1-09/other-sources-income">Income Reported on IRS Form 1040 (including Dividend Income)</a> in the Income Assessment section of the Fannie Mae Selling Guide.
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.deductibleMeals">
      <div class="se-field-help">
        <p>
          Form 1120, Schedule M-3, Part III, Line 11c (Meals and entertainment)<br>
          Any amount on this line is subtracted from the monthly qualifying income if the borrower(s) are 100% owners of the business.
        </p>
        <b>FAQs</b>
        <p>
          Q. Why is the pencil icon grayed-out (not able to be selected)?<br>
          A. {{ appName }} will display Schedule M-3 for a Corporation when the user reports that the checkbox on Line A4 of Form 1120 has been checked.
        </p>

      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.ownershipPercent">
      <div class="se-field-help">
        <p>
          Form 1125-E, Line d (common stock ownership) or as determined by {{ appName }} based on the data provided.<br>
          The percentage of the business owned by the borrower.

        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.totalBusinessIncomeShare">
      <div class="se-field-help">
        <p>
          {{ appName }} calculates the borrower’s share of business income by multiplying the subtotal business income
          (or loss) from Form 1120, by the borrower’s ownership percentage , if the borrower(s) are 100% owners of the business.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.w2MedicareWages">
      <div class="se-field-help">
        <p>
          Form W-2, Box 5.<br>
          Any amount in this box is added to the monthly qualifying income calculation.
        </p>
        <b>FAQs:</b>
        <p>
          Q. Why is the pencil icon grayed-out (not able to be selected)?<br>
          A. {{ appName }} will display Form W-2 for a Corporation when
          there are Wages, Salaries and Tips on Line 1/1a of Form 1040.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.compensationOfOfficers">
      <div class="se-field-help">
        <p>
          Form 1120, Line 12<br>
          If a W-2 cannot be located, and the lender identifies this amount has been included on the borrower’s
          individual (personal) income tax return, the amount is added to the monthly qualifying income calculation.

        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.distributionGuaranteedPaymentOrK1Income">
      <div class="se-field-help">
        <p>
          {{ appName }} determines whether to add the K-1 Income or the Distribution amount to the monthly qualifying income based on the history of distributions and, if necessary, business liquidity analysis.
       </p>
        The K-1 Income is used if:
        <ul>
          <li>the lender has waived business tax returns,</li>
          <li>the average annual K-1 Income is less than or equal to the Distribution for the current year, or</li>
          <li>the average annual K-1 Income is greater than the Distribution for the current year AND the business has demonstrated adequate liquidity to support the withdrawal of earnings because (a) the Quick Ratio is greater than or equal to 1 or (2) inventory is less than or equal to 10% of taxable income and the Current Ratio is great than or equal to 1.</li>
        </ul>
        <p>
          The Distribution amount is used if the average annual K-1 Income is greater than the Distribution for the current year, AND the business has NOT demonstrated adequate liquidity to support the withdrawal of earnings. If there is no Distribution amount, no income from the borrower’s K-1 will be used.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showHelpMessageMemo" title="Learn More">
      <p>
        {{showHelpMessageMemoText}}
      </p>
      <p v-if="showHelpMessageLearnMoreUrl">
        <a target="_blank" :href="showHelpMessageLearnMoreUrl">Learn more in the Fannie Mae Selling Guide</a>
      </p>
    </se-help>
  </div>

</template>
<script>
import {Radio, IconEdit, IconCheckMark} from 'src/components/index'
import LSwitch from 'src/components/Switch.vue'
import SeButtons from './SeButtons.vue'
import Enums from 'src/model/enums'
import SeHelp from './SeHelp.vue'
import {Collapse, CollapseItem, Drawer, Table, TableColumn} from "element-ui"
import {FindingsMixin} from "src/mixins/FindingsMixin";
import appNameMixin from '../../../mixins/AppNameMixin'
export default {
  mixins: [FindingsMixin, appNameMixin],
  components: {
    Radio,
    LSwitch,
    SeButtons,
    SeHelp,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Drawer.name]: Drawer,
    IconEdit,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    IconCheckMark
  },
  data() {
    return {
      tableData: [],
      tableDataCasualtyTheftLoss: []
    }
  },
  mounted() {
    let currTaxFormFields1120 = this.getTaxFormFields(this.getPY1, Enums.SeTaxFormType.tf1120);
    let prevTaxFormFields1120 = this.getTaxFormFields(this.getPY2, Enums.SeTaxFormType.tf1120);

    let currTaxFormFields1125e = this.getTaxFormFields(this.getPY1, Enums.SeTaxFormType.tf1125e);
    let prevTaxFormFields1125e = this.getTaxFormFields(this.getPY2, Enums.SeTaxFormType.tf1125e);

    let currTaxFormFields4562 = this.getTaxFormFields(this.getPY1, Enums.SeTaxFormType.tf4562);
    let prevTaxFormFields4562 = this.getTaxFormFields(this.getPY2, Enums.SeTaxFormType.tf4562);

    let currTaxFormFields4684 = this.getTaxFormFields(this.getPY1, Enums.SeTaxFormType.tf4684);
    let prevTaxFormFields4684 = this.getTaxFormFields(this.getPY2, Enums.SeTaxFormType.tf4684);

    let currTaxFormFields1120m3 = this.getTaxFormFields(this.getPY1, Enums.SeTaxFormType.tf1120m3);
    let prevTaxFormFields1120m3 = this.getTaxFormFields(this.getPY2, Enums.SeTaxFormType.tf1120m3);

    let currTaxFormFieldsW2 = this.getTaxFormFields(this.getPY1, Enums.SeTaxFormType.tfw2);
    let prevTaxFormFieldsW2 = this.getTaxFormFields(this.getPY2, Enums.SeTaxFormType.tfw2);

    let currTaxFormFieldsLossExp = this.getTaxFormFields(this.getPY1, Enums.SeTaxFormType.tfLossesExpenses);
    let prevTaxFormFieldsLossExp = this.getTaxFormFields(this.getPY2, Enums.SeTaxFormType.tfLossesExpenses);

    //casualty theft loss
    Object.entries(Enums.SeFindingsCasualtyTheftLossLineType).forEach( ([k,v]) => {
      let row = {};
      row['id'] = k;
      row['name'] = v.name;

      row.currentYearTaxValue = this.getTaxFormValue(currTaxFormFields4684, k);
      row.pastYearTaxValue = this.getTaxFormValue(prevTaxFormFields4684, k);
      row.currentYearFieldLocation = this.getTaxFormCorpFieldLocation(this.getPY1, Enums.SeTaxFormType.tf4684, currTaxFormFields4684, k);
      row.pastYearFieldLocation = this.getTaxFormCorpFieldLocation(this.getPY2, Enums.SeTaxFormType.tf4684, prevTaxFormFields4684, k);

      if ([Enums.SeFindingsCasualtyTheftLossLineType.casualtyTheftIncomePropertyShortTermLossAmount.id,
        Enums.SeFindingsCasualtyTheftLossLineType.casualtyTheftLongTermLossAmount.id].includes(k)) {
        row.currentYearTaxValue = row.currentYearTaxValue == 0 ? row.currentYearTaxValue : -row.currentYearTaxValue
        row.pastYearTaxValue = row.pastYearTaxValue == 0 ? row.pastYearTaxValue : -row.pastYearTaxValue
      }

      row.isCurrency = true
      row.seValueChangePct = 0
      row.currentYearSeValue = ''
      row.pastYearSeValue = ''
      row.isDisplayChange = false

      this.tableDataCasualtyTheftLoss.push(row)
    });

    //main entries
    Object.entries(Enums.SeFindingsSummaryCorpLineType).forEach( ([k,v]) => {
      let row = {};
      row['id'] = k;
      row['name'] = v.name;
      row.isDisplayChange = true
      row.changeOverYearFromTaxReturn = v.changeOverYearFromTaxReturn
      row.memoCy = v.memoCy
      row.memoPy = v.memoPy
      row.responseFieldId = v.responseFieldId

      let fieldId = v.fieldId ?? k;
      if (k === Enums.SeFindingsSummaryCorpLineType.amortization4562.id) {
        row.currentYearTaxValue = this.getTaxFormValue(currTaxFormFields4562, fieldId)
        row.pastYearTaxValue = this.getTaxFormValue(prevTaxFormFields4562, fieldId)
        row.currentYearFieldLocation = this.getTaxFormCorpFieldLocation(this.getPY1, Enums.SeTaxFormType.tf4562, currTaxFormFields4562, fieldId)
        row.pastYearFieldLocation = this.getTaxFormCorpFieldLocation(this.getPY2, Enums.SeTaxFormType.tf4562, prevTaxFormFields4562, fieldId)
        row.isCurrency = true

      } else if ([Enums.SeFindingsSummaryCorpLineType.totalBusinessIncome.id,
            Enums.SeFindingsSummaryCorpLineType.totalBusinessIncomeShare.id].includes(k)) {
        row.currentYearTaxValue = ''
        row.pastYearTaxValue = ''
        row.isCurrency = true
        row.isDisplayChange = false

      } else if (k === Enums.SeFindingsSummaryCorpLineType.casualtyTheftLoss.id) {
        row.currentYearTaxValue = this.getCasualtyTheftLossTotal(currTaxFormFields4684)
        row.pastYearTaxValue = this.getCasualtyTheftLossTotal(prevTaxFormFields4684)
        row.children = this.tableDataCasualtyTheftLoss
        row.isCurrency = true

      } else if (k === Enums.SeFindingsSummaryCorpLineType.mortgagesNotesPayableLessThanOneYearEndOfYear.id) {
        row.currentYearTaxValue = this.getMortgagesNotesPayableLessThanOneYearEndOfYear(currTaxFormFields1120)
        row.pastYearTaxValue = this.getMortgagesNotesPayableLessThanOneYearEndOfYear(prevTaxFormFields1120)
        row.currentYearFieldLocation = this.getTaxFormCorpFieldLocation(this.getPY1, Enums.SeTaxFormType.tf1120, currTaxFormFields1120, fieldId)
        row.pastYearFieldLocation = this.getTaxFormCorpFieldLocation(this.getPY2, Enums.SeTaxFormType.tf1120, prevTaxFormFields1120, fieldId)
        row.isCurrency = true

      } else if (k === Enums.SeFindingsSummaryCorpLineType.deductibleMeals.id) {
        row.currentYearTaxValue = this.getTaxFormValue(currTaxFormFields1120m3, fieldId)
        row.pastYearTaxValue = this.getTaxFormValue(prevTaxFormFields1120m3, fieldId)
        row.currentYearFieldLocation = this.getTaxFormCorpFieldLocation(this.getPY1, Enums.SeTaxFormType.tf1120m3, currTaxFormFields1120m3, fieldId)
        row.pastYearFieldLocation = this.getTaxFormCorpFieldLocation(this.getPY2, Enums.SeTaxFormType.tf1120m3, prevTaxFormFields1120m3, fieldId)
        row.isCurrency = true

      } else if (k === Enums.SeFindingsSummaryCorpLineType.w2MedicareWages.id) {
        row.currentYearTaxValue = this.getTaxFormValue(currTaxFormFieldsW2, fieldId)
        row.pastYearTaxValue = this.getTaxFormValue(prevTaxFormFieldsW2, fieldId)
        row.currentYearFieldLocation = this.getTaxFormCorpFieldLocation(this.getPY1, Enums.SeTaxFormType.tfw2, currTaxFormFieldsW2, fieldId)
        row.pastYearFieldLocation = this.getTaxFormCorpFieldLocation(this.getPY2, Enums.SeTaxFormType.tfw2, prevTaxFormFieldsW2, fieldId)
        row.isCurrency = true

      } else if (k === Enums.SeFindingsSummaryCorpLineType.extraordinaryOneTimeExpenses.id) {
        row.currentYearTaxValue = this.getTaxFormValue(currTaxFormFieldsLossExp, fieldId)
        row.pastYearTaxValue = this.getTaxFormValue(prevTaxFormFieldsLossExp, fieldId)
        row.currentYearFieldLocation = this.getTaxFormCorpFieldLocation(this.getPY1, Enums.SeTaxFormType.tfLossesExpenses, currTaxFormFieldsLossExp, fieldId)
        row.pastYearFieldLocation = this.getTaxFormCorpFieldLocation(this.getPY2, Enums.SeTaxFormType.tfLossesExpenses, prevTaxFormFieldsLossExp, fieldId)
        row.isCurrency = true

      } else if (k === Enums.SeFindingsSummaryCorpLineType.ownershipPercent.id) {
        let ownershipCy = this.getOwnershipPercent(currTaxFormFields1125e)
        let ownershipPy = this.getOwnershipPercent(prevTaxFormFields1125e)
        row.currentYearTaxValue = ownershipCy.ownershipPercent
        row.pastYearTaxValue = ownershipPy.ownershipPercent
        row.currentYearFieldLocation = this.getTaxFormCorpFieldLocation(this.getPY1, Enums.SeTaxFormType.tf1125e, currTaxFormFields1125e, ownershipCy.ownershipPercentFieldLoc)
        row.pastYearFieldLocation = this.getTaxFormCorpFieldLocation(this.getPY2, Enums.SeTaxFormType.tf1125e, prevTaxFormFields1125e, ownershipPy.ownershipPercentFieldLoc)
        row.isCurrency = false
        row.isPercent = true
        row.scale = 4
        row.isDisplayChange = false

      } else {
        row.currentYearTaxValue = this.getTaxFormValue(currTaxFormFields1120, fieldId)
        row.pastYearTaxValue = this.getTaxFormValue(prevTaxFormFields1120, fieldId)
        row.currentYearFieldLocation = this.getTaxFormCorpFieldLocation(this.getPY1, Enums.SeTaxFormType.tf1120, currTaxFormFields1120, fieldId)
        row.pastYearFieldLocation = this.getTaxFormCorpFieldLocation(this.getPY2, Enums.SeTaxFormType.tf1120, prevTaxFormFields1120, fieldId)
        row.isCurrency = true
      }

      row.seValueChangePct = 0
      row.currentYearSeValue = ''
      row.pastYearSeValue = ''

      if ([Enums.SeFindingsSummarySCorpLineType.totalBusinessIncome.id].includes(k)) {
        row.hasHelp = false
      } else {
        row.hasHelp = true
      }
      this.$set(this.showFieldHelp, k, false)

      this.tableData.push(row)
    });
    this.tableData.push({id: 'Total', name: 'TOTAL', currentYearSeValue:'', pastYearSeValue:'', isCurrency:true, isDisplayChange:true});
  },
  computed: {
  },
  methods: {
    getOwnershipPercent(fields1125e) {
      let ownershipPercent = 0
      let ownershipPercentFieldLoc = "businessOwnerCount"

      let businessOwnerCount = this.getTaxFormValueAsNumber(fields1125e, 'businessOwnerCount')
      if (businessOwnerCount == 0) {
        businessOwnerCount = this.getTaxFormValueAsNumber(fields1125e, 'corporationOwnerCount')
        ownershipPercentFieldLoc = "corporationOwnerCount"
      }

      if (businessOwnerCount == 1.0) {
        ownershipPercent = 100.0
        ownershipPercentFieldLoc = "businessOwnerCount"

      } else  {
        let stockOwnershipPercent  = this.getTaxFormValueAsNumber(fields1125e, 'commonStockOwnershipPercent')
        if (stockOwnershipPercent > 0) {
          ownershipPercent = stockOwnershipPercent
          ownershipPercentFieldLoc = "commonStockOwnershipPercent"

        } else {
          if (businessOwnerCount > 1 && this.getTaxFormValue(fields1125e, 'corporationOwnershipInterestGte25Pct') === 'Yes' &&
            this.getTaxFormValue(fields1125e, 'allCorporationOwnersAreBorrowers') === 'Yes') {
            ownershipPercent = 100 / businessOwnerCount
          }

          if (this.getTaxFormValue(fields1125e, 'corporationOwnershipInterestGte25Pct') === 'Yes' &&
            this.getTaxFormValue(fields1125e, 'allCorporationOwnersAreBorrowers') !== 'Yes') {
            ownershipPercent = 25
            ownershipPercentFieldLoc = "corporationOwnershipInterestGte25Pct"
          }
        }
      }
      return {ownershipPercent, ownershipPercentFieldLoc}
    },
    handleEvalResults() {
      let cyResponseFields = this.seResponse.taxYears?.find(ty => ty.year === this.getPY1) ?? {}
      let pyResponseFields = this.seResponse.taxYears?.find(ty => ty.year === this.getPY2) ?? {}
      let changeOverYearFields = this.seResponse.details.changeOverYear ?? {}
      let changeOverYearTaxReturnFields = this.seResponse.details.changeOverYearTaxReturn ?? {}

      this.tableData.forEach(row => {
        if (row.id === 'Total') {
          row.currentYearSeValue = cyResponseFields.totalIncome || 0;
          row.pastYearSeValue = pyResponseFields.totalIncome || 0;
          row.seValueChangePct = changeOverYearFields.totalIncome

        } else {
          let responseFieldId = row.responseFieldId ?? row.id
          row.currentYearSeValue = cyResponseFields[responseFieldId] || 0;
          row.pastYearSeValue = pyResponseFields[responseFieldId] || 0;
          row.seValueChangePct = row.changeOverYearFromTaxReturn ? changeOverYearTaxReturnFields[responseFieldId] : changeOverYearFields[responseFieldId]

          row.cy = this.getPY1
          row.py = this.getPY2
          row.currentYearSeValueMemo = row.currentYearTaxValue != row.currentYearSeValue ? this.findFirstMessageId(row.memoCy) : false
          row.pastYearSeValueMemo = row.pastYearTaxValue != row.pastYearSeValue ? this.findFirstMessageId(row.memoPy) : false
        }
      });

    },
    getTaxFormCorpFieldLocation(taxYear, taxFormType, fields, fieldId) {
      return this.getTaxFormFieldLocation(taxYear, Enums.SeTaxFormType.tf1120, taxFormType, fields, fieldId)
    },
    getCasualtyTheftLossTotal(fields) {
      let total = this.getTaxFormValueAsNumber(fields, "casualtyTheftNonIncomePropertyShortTermGainLossAmount")
        - this.getTaxFormValueAsNumber(fields, "casualtyTheftIncomePropertyShortTermLossAmount")
        + this.getTaxFormValueAsNumber(fields, "casualtyTheftLongTermGainAmount")
        - this.getTaxFormValueAsNumber(fields, "casualtyTheftLongTermLossAmount")
      return total < 0 ? -Math.abs(total) : 0.0
    },
    cellClassName({row, column, rowIndex, columnIndex}) {
      let className = ''
      if (columnIndex === 1 || (this.getNumberOfSelectedTaxFormYears > 1 && columnIndex === 2)) {
        className+=' se-findings-tax-return-columns';
      }
      if (columnIndex === 3) {
        className+=' cell-no-border'
      }
      if (row.id === 'Total' || row.id === Enums.SeFindingsSummarySCorpLineType.businessIncomeTitle.id) {
        className+= ' font-weight-bold'
      }
      return className;
    },
    headerCellClassName({row, column, rowIndex, columnIndex}) {
      let className = ''
      if (rowIndex === 0 && columnIndex === 0) {
        className+=' se-findings-table-items-header';
      }
      if (rowIndex === 0 && columnIndex === 2) {
        className+=' se-findings-table-sign-header';
      }
      if (rowIndex === 0 && columnIndex === 3) {
        className+=' se-findings-table-eval-result-header';
      }
      if (rowIndex === 0 && columnIndex === 4) {
        className+=' se-findings-table-change-header';
      }
      return className;
    },
    showSeMemo(id, value, year, msgId) {
      if (id === Enums.SeFindingsSummaryCorpLineType.otherIncomeLoss.id) {
        this.displayMessageMemo(msgId, "https://selling-guide.fanniemae.com/sel/b3-3.2-01/underwriting-factors-and-documentation-self-employed-borrower")

      } else if (msgId) {
        this.displayMessageMemo(msgId)
      }
    },
  }

}
</script>
<style>


.se-findings-table > div {
  border: 0;
  /*margin-right: 50px;*/
}

.se-findings-table .el-table:before {
  height: 0 !important;
}
.se-findings-table .el-table:after {
  width: 0 !important;
}

.el-table__row--level-1 .se-findings-item-column > div{
  text-indent: -36px;
  padding-left: 36px !important;
  word-break: break-word;
}

.se-findings-table-header {
  font-size: 1.1rem;
  color: rgba(0,0,0,0.75) ;
}

.se-findings-table-header > th {
  border-right: 0;
}

.se-findings-table-row > td {
  border-right: 0;
}

.se-findings-item-column {
  padding-left: 0 !important;
  font-size: 1.0rem;
}

.se-findings-item-column-total {
  padding-left: 0 !important;
  font-size: 1.0rem;
  font-weight: bold;
}

.cell-no-border {
  border-top: 0 !important;
  border-bottom: 0
}

.se-findings-tax-return-columns {
  background-color: #F5F7FA !important;
  font-size: 1.0rem;
}

.se-findings-table-items-header {
  background-color: #fff !important;
  vertical-align: bottom !important;
}

.se-findings-table-change-header {
  background-color: #fff !important;
  border-top: 0 !important;
  border-right: 0 !important;
}

.se-findings-table-sign-header {
  background-color: #fff !important;
  border-top: 0 !important;
  border-bottom: 0 !important;
}

.se-findings-table-eval-result-header {
  background-color: rgba(229,248,255,1.0) !important;
  /*background-color: rgba(35, 129, 150, 0.2) !important;*/
  font-weight: 700 !important;
  font-size: 1.1rem !important;
  color: rgba(0,0,0,0.75) !important;
}

.se-eval-result {
  background-color: rgba(229,248,255,1.0) !important;
  /*background-color: rgba(35, 129, 150, 0.2) !important;*/
  font-weight: 500;
  font-size: 1rem;
  color: black;
}

.se-eval-result-change {
  font-size: 1rem;
  color: black;
}

</style>
