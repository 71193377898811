import Enums from "../../../../model/enums";
import {ty} from "./ty";

export default {
  id: Enums.SeTaxFormType.tf1120s,
  waivable: true,
  parts: [
    {
      part: "1",
      pdf: `static/forms/${ty}/${ty}_1120sPg1.pdf`,
      route: `/se-eval/std-tax-form-1120s/${Enums.SeTaxFormType.tf1120s}/${ty}/1`,
      fields: [
        {
          id: "businessActivityCode",
          fieldName: "topmostSubform[0].Page1[0].ABC[0].f1_08[0]",
          label: "Business activity code number",
          line: "Line B",
          isInteger: true,
          hasHelp: true,
          pageHeader: `Find Business Tax Returns for ${ty}`
        },
        {
          id: "isM3Attached",
          fieldName: "topmostSubform[0].Page1[0].ABC[0].c1_1[0]",
          label: "Schedule M-3",
          isBoolean: true,
          required: true,
          line: "Line C",
          hasHelp: true,
          defaultValue: "No"
        },
        {
          id: "incorporatedDate",
          fieldName: "topmostSubform[0].Page1[0].f1_10[0]",
          label: "Date incorporated",
          required: true,
          line: "Line E",
          hasHelp: true
        },
        {
          id: "totalAssets",
          fieldName: "topmostSubform[0].Page1[0].f1_11[0]",
          label: "Total assets",
          line: "Line F",
          isCurrency: true,
          isPositive: false,
          hasHelp: true
        },
        {
          id: "numberOfShareholders",
          fieldName: "topmostSubform[0].Page1[0].f1_12[0]",
          label: "Number of shareholders who were shareholders during any part of the tax year",
          line: "Line I",
          isInteger: true,
          isCurrency: false,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "grossReceiptsOrSales",
          fieldName: "topmostSubform[0].Page1[0].f1_13[0]",
          label: "Gross receipts or sales",
          line: "Line 1 (a)",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "returnsAndAllowances",
          fieldName: "topmostSubform[0].Page1[0].f1_14[0]",
          label: "Returns and allowances",
          line: "Line 1 (b)",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "costOfGoodsSold",
          fieldName: "topmostSubform[0].Page1[0].f1_16[0]",
          label: "Cost of goods sold",
          line: "Line 2",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "netGainLoss4797",
          fieldName: "topmostSubform[0].Page1[0].f1_18[0]",
          label: "Net gain (loss) from Form 4797",
          line: "Line 4",
          isCurrency: true,
          isPositive: false,
          hasHelp: true
        },
        {
          id: "otherIncomeLoss",
          fieldName: "topmostSubform[0].Page1[0].f1_19[0]",
          label: "Other income (loss)",
          line: "Line 5",
          isCurrency: true,
          isPositive: false,
          hasHelp: true
        },
        {
          id: "compensationOfOfficers",
          fieldName: "topmostSubform[0].Page1[0].f1_21[0]",
          label: "Compensation of officers",
          line: "Line 7",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "isCompensationOfOfficersIncludedAsIncome",
          fieldName: "",
          label: "Was the Compensation of officers amount included as income in the borrower’s personal tax return?",
          isBoolean: true,
          required: true,
          line: "Compensation of officers",
          hasHelp: true,
          defaultValue: "No"
        },
        {
          id: "depreciation",
          fieldName: "topmostSubform[0].Page1[0].f1_28[0]",
          label: "Depreciation",
          line: "Line 14",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "depletion",
          fieldName: "topmostSubform[0].Page1[0].f1_29[0]",
          label: "Depletion",
          line: "Line 15",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "otherDeductions",
          fieldName: "topmostSubform[0].Page1[0].f1_33[0]",
          label: "Other deductions (attach statement)",
          line: "Line 19",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "totalDeductions",
          fieldName: "topmostSubform[0].Page1[0].f1_34[0]",
          label: "Total deductions",
          line: "Line 20",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "ordinaryBusinessIncomeLoss",
          fieldName: "topmostSubform[0].Page1[0].f1_35[0]",
          label: "Ordinary business income (loss)",
          line: "Line 21",
          isCurrency: true,
          isPositive: false,
          hasHelp: true
        },
        {
          id: "amortization",
          fieldName: "",
          label: "Amount from attachment",
          line: "Amortization",
          isCurrency: true,
          isPositive: true,
          isEmptyByDefault: true,
          hasHelp: true
        }
      ]
    },
    {
      part: "2",
      pdf: `static/forms/${ty}/${ty}_1120sPg4.pdf`,
      route: `/se-eval/std-tax-form-1120s/${Enums.SeTaxFormType.tf1120s}/${ty}/2`,
      fields: [
        {
          id: "nonDeductibleExpenses",
          fieldName: "topmostSubform[0].Page4[0].f4_9[0]",
          label: "Nondeductible expenses",
          line: "Line 16c",
          isCurrency: true,
          isPositive: true,
          hasHelp: true,
          pageHeader: "Find Schedule K of Form 1120S (page 4)"
        },
        {
          id: "cashEndOfTaxYear",
          fieldName: "topmostSubform[0].Page4[0].Table_Assets[0].Line1[0].f4_04[0]",
          label: "Cash (End of tax year)",
          line: "Line 1(d)",
          isCurrency: true,
          isPositive: false,
          hasHelp: true,
          pageHeader: "Find Schedule L of Form 1120S (page 4)"
        },
        {
          id: "tradeNotesAndAccountsReceivableLessAllowanceEndOfTaxYear",
          fieldName: "topmostSubform[0].Page4[0].Table_Assets[0].Line2b[0].f4_12[0]",
          label: "Trade notes and accounts receivable less allowances (End of tax year)",
          line: "Line 2b(d)",
          isCurrency: true,
          isPositive: false,
          hasHelp: true
        },
        {
          id: "inventoriesEndOfTaxYear",
          fieldName: "topmostSubform[0].Page4[0].Table_Assets[0].Line3[0].f4_16[0]",
          label: "Inventories (End of tax year)",
          line: "Line 3(d)",
          isCurrency: true,
          isPositive: false,
          hasHelp: true
        },
        {
          id: "govObligationsEndOfTaxYear",
          fieldName: "topmostSubform[0].Page4[0].Table_Assets[0].Line4[0].f4_20[0]",
          label: "U.S. government obligations (End of tax year)",
          line: "Line 4(d)",
          isCurrency: true,
          isPositive: false,
          hasHelp: true
        },
        {
          id: "taxExemptSecuritiesEndOfTaxYear",
          fieldName: "topmostSubform[0].Page4[0].Table_Assets[0].Line5[0].f4_24[0]",
          label: "Tax-exempt securities (End of tax year)",
          line: "Line 5(d)",
          isCurrency: true,
          isPositive: false,
          hasHelp: true
        },
        {
          id: "otherCurrentAssetsEndOfTaxYear",
          fieldName: "topmostSubform[0].Page4[0].Table_Assets[0].Line6[0].f4_28[0]",
          label: "Other current assets (End of tax year)",
          line: "Line 6(d)",
          isCurrency: true,
          isPositive: false,
          hasHelp: true
        },
        {
          id: "accountsPayableEndOfTaxYear",
          fieldName: "topmostSubform[0].Page4[0].Table_Liabilities[0].Line16[0].f4_80[0]",
          label: "Accounts payable (End of tax year)",
          line: "Line 16(d)",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "mortgagesNotesPayableLess1YrBeginningOfTaxYear",
          fieldName: "topmostSubform[0].Page4[0].Table_Liabilities[0].Line17[0].f4_82[0]",
          label: "Mortgages, notes, bonds payable in less than 1 year (Beginning of tax year)",
          line: "Line 17(b)",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "mortgagesNotesPayableLess1YrEndOfTaxYear",
          fieldName: "topmostSubform[0].Page4[0].Table_Liabilities[0].Line17[0].f4_84[0]",
          label: "Mortgages, notes, bonds payable in less than 1 year (End of tax year)",
          line: "Line 17(d)",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "otherCurrentLiabilitiesEndOfTaxYear",
          fieldName: "topmostSubform[0].Page4[0].Table_Liabilities[0].Line18[0].f4_88[0]",
          label: "Other current liabilities (End of tax year)",
          line: "Line 18(d)",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "retainedEarningsEndOfTaxYear",
          fieldName: "topmostSubform[0].Page4[0].Table_Liabilities[0].Line24[0].f4_112[0]",
          label: "Retained earnings (End of tax year)",
          line: "Line 24(d)",
          isCurrency: true,
          isPositive: false,
          hasHelp: true
        },
        {
          id: "mortgagePayableLess1YrRolloverType",
          fieldName: "",
          label: "Amount that does not rollover regularly or is not a lone of credit",
          line: "",
          radio: true,
          isEmptyByDefault: true
        },
        {
          id: "nonRolloverMortgagePayableLess1YrAmount",
          fieldName: "",
          label: "Amount that does not rollover regularly or is not a loan of credit",
          line: "",
          isCurrency: true,
          isPositive: true,
          isEmptyByDefault: true
        },
      ]
    },
    {
      part: "3",
      pdf: `static/forms/${ty}/${ty}_1120sPg5.pdf`,
      route: `/se-eval/std-tax-form-1120s/${Enums.SeTaxFormType.tf1120s}/${ty}/3`,
      fields: [
        {
          id: "travelEntertainment",
          fieldName: "topmostSubform[0].Page5[0].SchM-1_Left[0].f5_07[0]",
          label: "Travel and entertainment",
          line: "Line 3b",
          isCurrency: true,
          isPositive: true,
          hasHelp: true,
          pageHeader: "Find Schedule M-1 of Form 1120S (page 5)"
        }
      ]
    }
  ]
}

