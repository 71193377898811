<template>
  <div>

    <div class="table-responsive table-full-width se-findings-table mt-1" >
          <el-table
            ref="collectedDataTable"
            :data="this.tableData"
            row-key="id"
            :border="false"
            header-row-class-name="se-findings-table-header"
            :header-cell-class-name="headerCellClassName"
            row-class-name="se-findings-table-row"
            :cell-class-name="cellClassName">

            <el-table-column
              class-name="se-findings-item-column"
              :min-width="150">
              <template slot-scope="scope">
                {{scope.row.name}}
                <a class="se-help-icon btn-link" v-tooltip.top-center="'Learn More'" @click="handleFieldHelp(scope.row.id)" v-show="scope.row.hasHelp">
                  <i class="fa fa-info-circle"></i>
                </a>
              </template>
            </el-table-column>

            <el-table-column
              header-align="center"
              label="Tax Returns">
              <el-table-column
                :label="getPY1"
                header-align="center"
                align="right"
                :width="185"
                :min-width="40">
                <template slot-scope="scope">
                  <div class="row" v-if="scope.row.id !== 'Total'">
                    <div class="col-8">{{ scope.row.currentYearTaxValue | toCurrency(scope.row.isCurrency) }}<span v-if="scope.row.currentYearTaxValue2"><br>{{ scope.row.currentYearTaxValue2 }}</span></div>
                    <div v-if="scope.row.currentYearFieldLocation">
                      <a href="#" @click="editField(scope.row.currentYearFieldLocation)">
                      <icon-base width="22" height="22" iconColor="rgba(0,0,0,0.80)" icon-name="Edit"><icon-edit/></icon-base>
                      </a>
                    </div>
                  </div>
              </template>
              </el-table-column>
              <el-table-column
                v-if="this.getNumberOfSelectedTaxFormYears > 1"
                :label="getPY2"
                header-align="center"
                align="right"
                :width="185"
                :min-width="40">
                <template slot-scope="scope">
                  <div class="row" v-if="scope.row.id !== 'Total'">
                    <div class="col-8">{{ scope.row.pastYearTaxValue | toCurrency(scope.row.isCurrency) }}<span v-if="scope.row.pastYearTaxValue2"><br>{{ scope.row.pastYearTaxValue2 }}</span></div>
                    <div v-if="scope.row.pastYearFieldLocation">
                      <a href="#" @click="editField(scope.row.pastYearFieldLocation)">
                        <icon-base width="22" height="22" iconColor="rgba(0,0,0,0.80)" icon-name="Edit" ><icon-edit/></icon-base>
                      </a>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table-column>

            <el-table-column
              align="right"
              :width="20"
              :min-width="20">
            </el-table-column>

            <el-table-column
              header-align="center"
              :label="appName">
              <el-table-column
                :label="getPY1"
                label-class-name="se-findings-table-eval-result-header"
                class-name="se-eval-result"
                align="right"
                header-align="center"
                :width="185"
                :min-width="40">
                <template slot-scope="scope">
                  <div class="col-9">
                    <span :class="{'font-weight-bold': scope.row.id === 'Total' }" >
                      <template v-if="scope.row.currentYearSeValueMemo"><a class="se-help-a" v-tooltip.top-center="'Learn More'" @click="showSeMemo(scope.row.id, scope.row.currentYearSeValue, scope.row.cy, scope.row.currentYearSeValueMemo)">{{ scope.row.currentYearSeValue | toCurrency(true) }}</a></template>
                      <template v-else>{{ scope.row.currentYearSeValue | toCurrency(true) }}</template>
                    </span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                :label="getPY2"
                label-class-name="se-findings-table-eval-result-header"
                align="right"
                class-name="se-eval-result"
                header-align="center"
                :width="185"
                :min-width="40">
                <template slot-scope="scope">
                  <div class="col-9">
                    <span :class="{'font-weight-bold': scope.row.id === 'Total' }">
                      <template v-if="scope.row.pastYearSeValueMemo"><a class="se-help-a" v-tooltip.top-center="'Learn More'" @click="showSeMemo(scope.row.id, scope.row.pastYearSeValue, scope.row.py, scope.row.pastYearSeValueMemo)">{{ scope.row.pastYearSeValue | toCurrency(true) }}</a></template>
                      <template v-else>{{ scope.row.pastYearSeValue | toCurrency(true) }}</template>
                    </span>
                  </div>
                </template>
              </el-table-column>
            </el-table-column>

            <el-table-column
              v-if="this.getNumberOfSelectedTaxFormYears > 1"
              label="Change"
              align="center"
              class-name="cell-narrow se-eval-result-change"
              :width="100"
              :min-width="20">
              <template slot-scope="scope"><span :class="{'font-weight-bold': scope.row.id === 'Total' }">{{ scope.row.seValueChangePct | toPercent}}</span></template>
            </el-table-column>

          </el-table>
        </div>

    <se-help :visible.sync="showFieldHelp.netProfitLoss">
      <div class="se-field-help">
        <p>
          Form 1040, Schedule C, Line 31.<br>
          Any amount on this line is added to (or subtracted from) the monthly qualifying income.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.otherIncomeLoss">
      <div class="se-field-help">
        <p>
          Form 1040, Schedule C, Line 6.<br>
          Any amount shown on Schedule C, Line 6 has already been accounted for in the Net profit (or loss) amount shown on Schedule C, Line 31.
        </p>
        <b>FAQs:</b>
        <p>
          Q. Why is {{ appName }} subtracting the Other Income reported on the tax returns?<br>
          A. If two years tax return data is provided, positive Other Income reported on the tax returns will be subtracted if it has not been received for two years.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about Recurring vs. Nonrecurring Income and Expenses in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.3-03/income-or-loss-reported-irs-form-1040-schedule-c">Income or Loss Reported on IRS Form 1040 Schedule C</a> section of the Fannie Mae Selling Guide.
        </p>
        <p>
          Q. Why is {{ appName }} not counting (setting to zero) the Other Income reported on the tax returns?<br>
          A. The Other Income amount is being included in the monthly qualifying income calculation. Any amount shown on Schedule C, Line 6 has already been accounted for in the Net profit (or loss) amount shown on Schedule C, Line 31.
        </p>
         <p>
          Q. The Fannie Mae Selling Guide allows me to document income using one year of tax returns. Will {{ appName }} consider Other income amounts to be recurring if they are only documented with one year of tax returns?<br>
          A. {{ appName }} may consider Other income amounts to be recurring even if they are documented with only one year of tax returns.
        </p>
        <p>
          Q. Am I required to obtain supporting documentation from the borrower regarding the nature of the Other income amounts?<br>
          A. {{ appName }} may not require supporting documentation for Other income amounts.
        </p>
        <p>
          Q. Why isn’t {{ appName }} adding back the negative Other Income amount reported on the tax returns?<br>
          A. {{ appName }} does not add back negative Other income amounts since they have already been accounted for in the Net profit (or loss) amount shown on Schedule C, Line 31.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.depletion">
      <div class="se-field-help">
        <p>
          Form 1040, Schedule C, Line 12.<br>
          Any amount on this line is added to the monthly qualifying income.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.depreciation">
      <div class="se-field-help">
        <p>
          Form 1040, Schedule C, Line 13.<br>
          Any amount on this line is added to the monthly qualifying income.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.vehicleDepreciation">
      <div class="se-field-help">
        <p>
          If {{ appName }} determines the borrower has chosen to use the mileage-based vehicle deduction method, it uses reported number of business miles driven from Form 1040, Schedule C, Line 44a or Form 4562. The number of miles is multiplied by the depreciation percent published annually by the IRS to determine the depreciation amount shown on the Income Calculator Findings Report.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          View the <a target="_blank" href="https://www.irs.gov/pub/irs-drop/n-23-03.pdf">Rate of Depreciation Allowed in Standard Mileage Rate</a> from the IRS website.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.deductibleMeals">
      <div class="se-field-help">
        <p>
          Form 1040, Schedule C, Line 24b.

        </p>
        <p>
          Fannie Mae requires lenders to exclude the borrower’s business meals
          from qualifying income. Any amount shown on Schedule C, Line 24b has already been accounted
          for in the Net profit (or loss) amount shown on Schedule C, Line 31. </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.nonDeductibleMeals">
      <div class="se-field-help">
        <p>
          This data is not collected on a specific line in the tax returns.<br>
          Any amount provided is subtracted from the monthly qualifying income calculation.

        </p>
        <p>
          Fannie Mae requires lenders to exclude 100% of the borrower’s business meals from qualifying income.
        <ul>
          <li>
            Generally, taxpayers may claim 50% or 80% of business meals as an expense.
            Note: The borrower’s profession determines which percentage is applied.
            <ul>
              <li>If a nondeductible meals amount is provided, {{ appName }} will validate
                the 50% or 80% deduction percentage, and will subtract the amount from the monthly qualifying income.
              </li>
              <li>If a nondeductible meals amount is not provided, {{ appName }} will subtract
                the deductible meals amount to achieve the 100% exclusion.
              </li>
            </ul>
          <li>For tax years 2021 and 2022, the IRS had temporarily permitted taxpayers to claim up to 100% of restaurant business meals as an expense.
            The lender must determine the amount of nondeductible meals, if any. {{ appName }} will subtract the amount from the monthly qualifying income.
          </li>
        </ul>

        <b>FAQs:</b>
        <p>
          Q. Why is {{ appName }} not counting (setting to zero) the Nondeductible Meals amount provided for Tax Year 2023?<br>
          A. If {{ appName }} is unable to validate the 50% or 80% deduction percentage it will not be considered in the calculation and a warning message will be generated.
        </p>
        <p>
          Q. How do I determine the amount of nondeductible meals for Tax Years 2021 and 2022?
          The temporary IRS 100% deduction only applies to restaurant meals, where is this information
          located in the tax return?<br>
          A. There is no IRS-defined location in the tax return forms or schedules where the borrower indicates which business meals are 100% deductible and which meals are 50% (or 80%) deductible.
          Fannie Mae requires lenders to determine the amount of non deductible meals, if any. {{ appName }} will subtract the amount from the monthly qualifying income.

        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.businessUseHome">
      <div class="se-field-help">
        <p>
          Form 1040, Schedule C, Line 30.<br>
          Any amount on this line is added to the monthly qualifying income.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.amortization">
      <div class="se-field-help">
        <p>
          Form 1040, Schedule C, Part V.<br>
          Any amount identified by the lender as amortization is added to the monthly qualifying income.
        </p>
        <P>
          <B>FAQs:</B><br>
          Q. Why is the pencil icon grayed-out (not able to be selected)?<br>
          A. {{ appName }} will display Amortization when there are Other Expenses on Form 1040, Schedule C, Line 27a

        </P>

      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.amortization4562">
      <div class="se-field-help">
        <p>
          Form 4562, Line 44.<br>
          Any amount on this line is added to the monthly qualifying income.
        </p>
        <P>
          <B>FAQs:</B><br>
          Q. Why is the pencil icon grayed-out (not able to be selected)?<br>
          A. {{ appName }} will display Form 4562 when there are Other Expenses on Form
          1040, Schedule C, Line 27a and no Amortization amount has been identified on Schedule C.

        </P>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.casualtyTheftLoss">
      <div class="se-field-help">
        <p>
          Form 4684, Lines 31, 32, 36 and 37. <br>
          {{ appName }} determines if there are gains or losses from any casualties or thefts.
          Any loss amounts are added to the monthly qualifying income. If the borrower owns more than one business,
          ensure the amounts provided relate to the business being evaluated by {{ appName }}.
        </p>
        <p>
          <B>FAQs:</B><br>
          Q. Why is the pencil icon grayed-out (not able to be selected)?<br>
          A. {{ appName }} will display Form 4684 when it is identified as being included
          in the tax returns on the Losses and Expenses screen.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.extraordinaryOneTimeExpenses">
      <div class="se-field-help">
        <p>
          Any amount identified by the lender as an extraordinary one-time expense is added back to the monthly qualifying income.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about Analyzing Individual Tax Returns (and extraordinary one-time expenses) in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.3-01/general-information-analyzing-individual-tax-returns">General Information on Analyzing Individual Tax Returns</a> section of the Fannie Mae Selling Guide.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.w2MedicareWages">
      <div class="se-field-help">
        <p>
          Form W-2, Box 5.<br>
          Any amount in this box is added to the monthly qualifying income.
        </p>
        <P>
          <B>FAQs:</B><br>
          Q. Why is the pencil icon grayed-out (not able to be selected)?<br>
          A. {{ appName }} will display Form W-2 for a Sole Proprietorship when there are Wages, Salaries and Tips on Line 1/1a of Form 1040
          and there are Wages on Line 26 of Schedule C.
        </P>
      </div>
    </se-help>

    <se-help :visible.sync="showHelpMessageMemo" title="Learn More">
      <p>
        {{showHelpMessageMemoText}}
      </p>
      <p v-if="showHelpMessageLearnMoreUrl">
        <a target="_blank" :href="showHelpMessageLearnMoreUrl">Learn more in the Fannie Mae Selling Guide</a>
      </p>
    </se-help>
  </div>

</template>
<script>
import {Radio, IconEdit, IconCheckMark} from 'src/components/index'
import LSwitch from 'src/components/Switch.vue'
import SeButtons from './SeButtons.vue'
import Enums from 'src/model/enums'
import SeHelp from './SeHelp.vue'
import {Collapse, CollapseItem, Drawer, Table, TableColumn} from "element-ui"
import {FindingsMixin} from "src/mixins/FindingsMixin";
import appNameMixin from '../../../mixins/AppNameMixin'

export default {
  mixins: [FindingsMixin, appNameMixin],
  components: {
    Radio,
    LSwitch,
    SeButtons,
    SeHelp,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Drawer.name]: Drawer,
    IconEdit,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    IconCheckMark
  },
  data() {
    return {
      tableData: [],
      tableDataCasualtyTheftLoss: []
    }
  },
  mounted() {
    const currVehicleDepreciationRate =  this.seConf.taxYears.find(ty => ty.year === this.getPY1).vehicleDepreciationRate;
    const prevVehicleDepreciationRate =  this.seConf.taxYears.find(ty => ty.year === this.getPY2).vehicleDepreciationRate;

    let currTaxFormFields = this.getTaxFormFields(this.getPY1, Enums.SeTaxFormType.tf1040c);
    let prevTaxFormFields = this.getTaxFormFields(this.getPY2, Enums.SeTaxFormType.tf1040c);

    let currTaxFormFieldsLossExp = this.getTaxFormFields(this.getPY1, Enums.SeTaxFormType.tfLossesExpenses);
    let prevTaxFormFieldsLossExp = this.getTaxFormFields(this.getPY2, Enums.SeTaxFormType.tfLossesExpenses);

    let currTaxFormFields4562 = this.getTaxFormFields(this.getPY1, Enums.SeTaxFormType.tf4562);
    let prevTaxFormFields4562 = this.getTaxFormFields(this.getPY2, Enums.SeTaxFormType.tf4562);

    let currTaxFormFields4684 = this.getTaxFormFields(this.getPY1, Enums.SeTaxFormType.tf4684);
    let prevTaxFormFields4684 = this.getTaxFormFields(this.getPY2, Enums.SeTaxFormType.tf4684);

    let currTaxFormFieldsW2 = this.getTaxFormFields(this.getPY1, Enums.SeTaxFormType.tfw2);
    let prevTaxFormFieldsW2 = this.getTaxFormFields(this.getPY2, Enums.SeTaxFormType.tfw2);

    //casualty theft loss
    Object.entries(Enums.SeFindingsCasualtyTheftLossLineType).forEach( ([k,v]) => {
      let row = {};
      row['id'] = k;
      row['name'] = v.name;

      row.currentYearTaxValue = this.getTaxFormValue(currTaxFormFields4684, k);
      row.pastYearTaxValue = this.getTaxFormValue(prevTaxFormFields4684, k);
      row.currentYearFieldLocation = this.getTaxFormSchCFieldLocation(this.getPY1, Enums.SeTaxFormType.tf4684, currTaxFormFields4684, k);
      row.pastYearFieldLocation = this.getTaxFormSchCFieldLocation(this.getPY2, Enums.SeTaxFormType.tf4684, prevTaxFormFields4684, k);

      row.isCurrency = true;
      row.seValueChangePct = 0
      row.currentYearSeValue = ''
      row.pastYearSeValue = ''

      this.tableDataCasualtyTheftLoss.push(row)
    });

    //main entries
    Object.entries(Enums.SeFindingsSummaryLineType).forEach( ([k,v]) => {
      let row = {}
      row['id'] = k
      row['name'] = v.name
      row.changeOverYearFromTaxReturn = v.changeOverYearFromTaxReturn
      row.memoCy = v.memoCy
      row.memoPy = v.memoPy
      row.responseFieldId = v.responseFieldId

      if (k === Enums.SeFindingsSummaryLineType.vehicleDepreciation.id) {
        let kk1040c = "businessMileage"
        let kk4562 = "totalBusinessMilesDriven"

        let v1040c = this.getTaxFormValueAsNumber(currTaxFormFields, kk1040c)
        let v4562 = this.getTaxFormValueAsNumber(currTaxFormFields4562, kk4562)
        if (v1040c >= v4562) {
          row.currentYearTaxValue = this.getTaxFormValue(currTaxFormFields, kk1040c) + " mi"
          row.currentYearFieldLocation = this.getTaxFormSchCFieldLocation(this.getPY1, Enums.SeTaxFormType.tf1040c, currTaxFormFields, kk1040c)
        } else {
          row.currentYearTaxValue = this.getTaxFormValue(currTaxFormFields4562, kk4562) + " mi"
          row.currentYearFieldLocation = this.getTaxFormSchCFieldLocation(this.getPY1, Enums.SeTaxFormType.tf4562, currTaxFormFields4562, kk4562)
        }

        v1040c = this.getTaxFormValueAsNumber(prevTaxFormFields, kk1040c)
        v4562 = this.getTaxFormValueAsNumber(prevTaxFormFields4562, kk4562)
        if (v1040c >= v4562) {
          row.pastYearTaxValue = this.getTaxFormValue(prevTaxFormFields, kk1040c) + " mi"
          row.pastYearFieldLocation = this.getTaxFormSchCFieldLocation(this.getPY2, Enums.SeTaxFormType.tf1040c, prevTaxFormFields, kk1040c)
        } else {
          row.pastYearTaxValue = this.getTaxFormValue(prevTaxFormFields4562, kk4562) + " mi"
          row.pastYearFieldLocation = this.getTaxFormSchCFieldLocation(this.getPY2, Enums.SeTaxFormType.tf4562, prevTaxFormFields4562, kk4562)
        }

        row.currentYearTaxValue2 = `@ ${currVehicleDepreciationRate} cent/mi`
        row.pastYearTaxValue2 = `@ ${prevVehicleDepreciationRate} cent/mi`

      } else if (k === Enums.SeFindingsSummaryLineType.amortization4562.id) {
        row.currentYearTaxValue = this.getTaxFormValue(currTaxFormFields4562, k)
        row.pastYearTaxValue = this.getTaxFormValue(prevTaxFormFields4562, k)
        row.currentYearFieldLocation = this.getTaxFormSchCFieldLocation(this.getPY1, Enums.SeTaxFormType.tf4562, currTaxFormFields4562, k)
        row.pastYearFieldLocation = this.getTaxFormSchCFieldLocation(this.getPY2, Enums.SeTaxFormType.tf4562, prevTaxFormFields4562, k)

      } else if (k === Enums.SeFindingsSummaryLineType.extraordinaryOneTimeExpenses.id) {
        row.currentYearTaxValue = this.getTaxFormValue(currTaxFormFieldsLossExp, k);
        row.pastYearTaxValue = this.getTaxFormValue(prevTaxFormFieldsLossExp, k);
        row.currentYearFieldLocation = this.getTaxFormSchCFieldLocation(this.getPY1, Enums.SeTaxFormType.tfLossesExpenses, currTaxFormFieldsLossExp, k);
        row.pastYearFieldLocation = this.getTaxFormSchCFieldLocation(this.getPY2, Enums.SeTaxFormType.tfLossesExpenses, prevTaxFormFieldsLossExp, k);

      } else if (k === Enums.SeFindingsSummaryLineType.casualtyTheftLoss.id) {
        row.currentYearTaxValue = this.getCasualtyTheftLossTotal(currTaxFormFields4684)
        row.pastYearTaxValue = this.getCasualtyTheftLossTotal(prevTaxFormFields4684)
        row.children = this.tableDataCasualtyTheftLoss

      } else if (k === Enums.SeFindingsSummaryLineType.w2MedicareWages.id) {
        row.currentYearTaxValue = this.getTaxFormValue(currTaxFormFieldsW2, k);
        row.pastYearTaxValue = this.getTaxFormValue(prevTaxFormFieldsW2, k);
        row.currentYearFieldLocation = this.getTaxFormSchCFieldLocation(this.getPY1, Enums.SeTaxFormType.tfw2, currTaxFormFieldsW2, k);
        row.pastYearFieldLocation = this.getTaxFormSchCFieldLocation(this.getPY2, Enums.SeTaxFormType.tfw2, prevTaxFormFieldsW2, k);

      } else {
        row.currentYearTaxValue = this.getTaxFormValue(currTaxFormFields, k);
        row.pastYearTaxValue = this.getTaxFormValue(prevTaxFormFields, k);
        row.currentYearFieldLocation = this.getTaxFormSchCFieldLocation(this.getPY1, Enums.SeTaxFormType.tf1040c, currTaxFormFields, k);
        row.pastYearFieldLocation = this.getTaxFormSchCFieldLocation(this.getPY2, Enums.SeTaxFormType.tf1040c, prevTaxFormFields, k);
      }

      row.isCurrency = k === Enums.SeFindingsSummaryLineType.vehicleDepreciation.id ? false : true
      row.seValueChangePct = 0
      row.currentYearSeValue = ''
      row.pastYearSeValue = ''

      row.hasHelp = true
      this.$set(this.showFieldHelp, k, false)

      this.tableData.push(row)
    });
    this.tableData.push({id: 'Total', name: 'TOTAL', currentYearSeValue:'', pastYearSeValue:''});

  },
  computed: {
  },
  methods: {
    handleEvalResults() {
      let currResponseFields = this.seResponse.taxYears?.find(ty => ty.year === this.getPY1) ?? {}
      let prevResponseFields = this.seResponse.taxYears?.find(ty => ty.year === this.getPY2) ?? {}
      let changeOverYearFields = this.seResponse.details.changeOverYear ?? {}
      let changeOverYearTaxReturnFields = this.seResponse.details.changeOverYearTaxReturn ?? {}

      this.tableData.forEach(row => {
        if (row.id === 'Total') {
          row.currentYearSeValue = currResponseFields.totalIncome || 0
          row.pastYearSeValue = prevResponseFields.totalIncome || 0
          row.seValueChangePct = changeOverYearFields.totalIncome

        } else {
          if (row.id === Enums.SeFindingsSummaryLineType.deductibleMeals.id) {
            //do not show
            row.seValueChangePct = ''
          } else {
            let responseFieldId = row.responseFieldId ?? row.id
            row.currentYearSeValue = currResponseFields[responseFieldId] || 0;
            row.pastYearSeValue = prevResponseFields[responseFieldId] || 0;
            row.seValueChangePct = row.changeOverYearFromTaxReturn ? changeOverYearTaxReturnFields[responseFieldId] : changeOverYearFields[responseFieldId]
          }

          row.cy = this.getPY1
          row.py = this.getPY2
          row.currentYearSeValueMemo = row.currentYearTaxValue != row.currentYearSeValue ? this.findFirstMessageId(row.memoCy) : false
          row.pastYearSeValueMemo = row.pastYearTaxValue != row.pastYearSeValue ? this.findFirstMessageId(row.memoPy) : false
        }
      });
    },
    getTaxFormSchCFieldLocation(taxYear, taxFormType, fields, fieldId) {
      return this.getTaxFormFieldLocation(taxYear, Enums.SeTaxFormType.tf1040c, taxFormType, fields, fieldId)
    },
    getCasualtyTheftLossTotal(fields) {
      let total = this.getTaxFormValueAsNumber(fields, "casualtyTheftNonIncomePropertyShortTermGainLossAmount")
        - this.getTaxFormValueAsNumber(fields, "casualtyTheftIncomePropertyShortTermLossAmount")
        + this.getTaxFormValueAsNumber(fields, "casualtyTheftLongTermGainAmount")
        - this.getTaxFormValueAsNumber(fields, "casualtyTheftLongTermLossAmount")
      return total < 0 ? -Math.abs(total) : 0.0
    },
    cellClassName({row, column, rowIndex, columnIndex}) {
      let className = ''
      if (columnIndex === 1 || (this.getNumberOfSelectedTaxFormYears > 1 && columnIndex === 2)) {
        className+=' se-findings-tax-return-columns';
      }
      if (columnIndex === 3) {
        className+=' cell-no-border'
      }
      if (row.id === 'Total') {
        className+= 'font-weight-bold'
      }
      return className;
    },
    headerCellClassName({row, column, rowIndex, columnIndex}) {
      let className = ''
      if (rowIndex === 0 && columnIndex === 0) {
        className+='se-findings-table-items-header';
      }
      if (rowIndex === 0 && columnIndex === 2) {
        className+=' se-findings-table-sign-header';
      }
      if (rowIndex === 0 && columnIndex === 3) {
        className+=' se-findings-table-eval-result-header';
      }
      if (rowIndex === 0 && columnIndex === 4) {
        className+=' se-findings-table-change-header';
      }
      return className;
    },
    showSeMemo(id, value, year, msgId) {
      if (id === Enums.SeFindingsSummaryLineType.otherIncomeLoss.id) {
        this.displayMessageMemo(msgId, "https://selling-guide.fanniemae.com/sel/b3-3.3-03/income-or-loss-reported-irs-form-1040-schedule-c")
      } else if (msgId) {
        this.displayMessageMemo(msgId)
      }
    }

  }

}
</script>
<style>


.se-findings-table > div {
  border: 0;
  /*margin-right: 50px;*/
}

.se-findings-table .el-table:before {
  height: 0 !important;
}
.se-findings-table .el-table:after {
  width: 0 !important;
}

.el-table__row--level-1 .se-findings-item-column > div{
  text-indent: -36px;
  padding-left: 36px !important;
  word-break: break-word;
}

.se-findings-table-header {
  font-size: 1.1rem;
  color: rgba(0,0,0,0.75) ;
}

.se-findings-table-header > th {
  border-right: 0;
}

.se-findings-table-row > td {
  border-right: 0;
}

.se-findings-item-column {
  padding-left: 0 !important;
  font-size: 1.0rem;
}

.se-findings-item-column-total {
  padding-left: 0 !important;
  font-size: 1.0rem;
  font-weight: bold;
}

.cell-no-border {
  border-top: 0 !important;
  border-bottom: 0
}

.se-findings-tax-return-columns {
  background-color: #F5F7FA !important;
  font-size: 1.0rem;
}

.se-findings-table-items-header {
  background-color: #fff !important;
}

.se-findings-table-change-header {
  background-color: #fff !important;
  border-top: 0 !important;
  border-right: 0 !important;
}

.se-findings-table-sign-header {
  background-color: #fff !important;
  border-top: 0 !important;
  border-bottom: 0 !important;
}

.se-findings-table-eval-result-header {
  background-color: rgba(229,248,255,1.0) !important;
  /*background-color: rgba(35, 129, 150, 0.2) !important;*/
  font-weight: 700 !important;
  font-size: 1.1rem !important;
  color: rgba(0,0,0,0.75) !important;
}

.se-eval-result {
  background-color: rgba(229,248,255,1.0) !important;
  /*background-color: rgba(35, 129, 150, 0.2) !important;*/
  font-weight: 500;
  font-size: 1rem;
  color: black;
}

.se-eval-result-change {
  font-size: 1rem;
  color: black;
}

</style>
