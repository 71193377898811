<template>
  <div>

      <div class="row">
        <div class="col-md-6 pr-5">

          <h1 class="mt-0 se-std-bp-title-sb mb-3">Input Data: Form 1040 - Sch C ({{this.taxFormYear}})
            <a class="btn-link se-page-help-icon" v-tooltip.top-center="'Learn More'" @click="showPageHelp = true">
              <i class="fa fa-info-circle"></i>
            </a>
          </h1>

          <form autocomplete="off">
            <div class="bp-form-group mr-5"
                 v-for="(row, index) in tableData">


                <template v-if="row.id === 'amortization'">
                  <h4>Find Part V</h4>
                  <p>
                  Look at the expenses listed in the left column. Do any expenses contain the word “Amortization” or abbreviations like “Amort” or “Amtz”? If a description references a statement or attachment, you may need to look further in the return to determine if an amortization expense is present.
                  </p>
                </template>

                <label :for="row.id" class="se-std-field-group-label"><span>{{row.line}}</span></label>
                <a class="se-help-icon btn-link" v-tooltip.top-center="'Learn More'" @click="handleFieldHelp(row.id)" v-show="row.hasHelp">
                  <i class="fa fa-info-circle"></i>
                </a>

                <div @mouseover="highlightHoveredOverFormField(row)" @mouseleave="highlightHoveredOverFormField()">
                  <template v-if="row.id === 'businessActivityCode'">
                      <radio :label="false"
                             v-model="row.isEmpty"
                             :disabled="isSkipped[index] || isLocked"
                             :class="{'opacity-5': isSkipped[index]}"
                             class="mb-1"><span class="se-std-label">Enter {{row.name}}</span>
                      </radio>
                      <input :id="row.id"
                             :readonly="isLocked"
                             class="se-tax-field-input ml-4 bp-form-input"
                             type="text"
                             v-mask="'######'"
                             @focus="highlightFocusedFormField(row)"
                             @blur="fieldIsFocused = false;"
                             :disabled="row.isEmpty || isSkipped[index]"
                             v-model="row.value" />
                      <radio :label="true"
                             v-model="row.isEmpty"
                             :disabled="isSkipped[index] || isLocked"
                             :class="{'opacity-5': isSkipped[index]}"
                             class="mt-1"><span class="se-std-label">There is no value on this line</span>
                      </radio>
                  </template>

                  <template v-else-if="row.id === 'businessStartedAcquired'">
                      <radio :label="Enums.SeYesNoType.Yes"
                             v-model="row.value"
                             :disabled="isSkipped[index] || isLocked"
                             :class="{'opacity-5': isSkipped[index]}"
                             class="mb-1"><span class="se-std-label">Yes, the checkbox on {{row.line}} is checked</span>
                      </radio>
                      <radio :label="Enums.SeYesNoType.No"
                             v-model="row.value"
                             :disabled="isSkipped[index] || isLocked"
                             :class="{'opacity-5': isSkipped[index]}"
                             class="mt-1"><span class="se-std-label">No, the checkbox on {{row.line}} is not checked</span>
                      </radio>
                  </template>


                  <template v-else-if="row.id === 'vehicleServiceStartDate'">
                    <radio :label="false"
                           v-model="row.isEmpty"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mb-1"><span class="se-std-label">Enter {{row.name}}</span>
                    </radio>
                    <masked-input :id="row.id"
                           class="se-tax-field-input ml-4 bp-form-input"
                           type="text"
                          :mask="[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]"
                          :guide="false"
                          :pipe="dateMaskPipe"
                           @focus="highlightFocusedFormField(row)"
                           @blur="fieldIsFocused = false;"
                           :disabled="row.isEmpty || isSkipped[index]"
                           placeholder="MM/DD/YYYY"
                           v-model="row.value" />
                    <radio :label="true"
                           v-model="row.isEmpty"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mt-1"><span class="se-std-label">There is no date on this line</span>
                    </radio>
                  </template>

                  <template v-else-if="row.id === 'businessMileage'">
                    <radio :label="false"
                           v-model="row.isEmpty"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mb-1"><span class="se-std-label">Enter {{row.name}}</span>
                    </radio>
                    <input :id="row.id"
                           :readonly="isLocked"
                           class="se-tax-field-input ml-4 bp-form-input"
                           type="text"
                           v-mask="'######'"
                           @focus="highlightFocusedFormField(row)"
                           @blur="fieldIsFocused = false;"
                           :disabled="row.isEmpty || isSkipped[index]"
                           v-model="row.value" />
                    <radio :label="true"
                           v-model="row.isEmpty"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mt-1"><span class="se-std-label">There is no value on this line</span>
                    </radio>
                  </template>

                  <template v-else-if="['amortization'].includes(row.id)">
                    <radio :label="false"
                           v-model="row.isEmpty"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mb-1"><span class="se-std-label">Enter {{row.name}}</span>
                    </radio>
                    <input :id="row.id"
                           :readonly="isLocked"
                           class="se-tax-field-input ml-4 bp-form-input"
                           type="text"
                           v-mask="row.mask"
                           @focus="highlightFocusedFormField(row);"
                           @blur="fieldIsFocused = false;"
                           :disabled="row.isEmpty || isSkipped[index]"
                           v-model="row.value" />
                    <radio :label="true"
                           v-model="row.isEmpty"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mt-1"><span class="se-std-label">There is no {{row.name}} amount in Part V</span>
                    </radio>
                  </template>

                  <template v-else-if="['nonDeductibleMeals'].includes(row.id)">
                    <radio :label="false"
                           v-model="row.isEmpty"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mb-1"><span class="se-std-label">Enter {{row.name}}</span>
                    </radio>
                    <input :id="row.id"
                           :readonly="isLocked"
                           class="se-tax-field-input ml-4 bp-form-input"
                           type="text"
                           v-mask="row.mask"
                           @focus="highlightFocusedFormField(row);"
                           @blur="fieldIsFocused = false;"
                           :disabled="row.isEmpty || isSkipped[index]"
                           v-model="row.value" />
                    <radio :label="true"
                           v-model="row.isEmpty"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mt-1"><span class="se-std-label">There is no {{row.name}} amount</span>
                    </radio>
                  </template>

                  <template v-else>
                    <radio :label="false"
                           v-model="row.isEmpty"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mb-1"><span class="se-std-label">Enter {{row.name}}</span>
                    </radio>
                    <input :id="row.id"
                           :readonly="isLocked"
                           class="se-tax-field-input ml-4 bp-form-input"
                           type="text"
                           v-mask="row.mask"
                           @focus="highlightFocusedFormField(row);"
                           @blur="fieldIsFocused = false;"
                           :disabled="row.isEmpty || isSkipped[index]"
                           v-model="row.value" />
                    <radio :label="true"
                           v-model="row.isEmpty"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mt-1"><span class="se-std-label">There is no amount on this line</span>
                    </radio>
                  </template>

                  <div class="bp-help is-error" v-if="$v.tableData.$each[index].$error">
                    <div class="bp-ucon" data-src="feedback-warning"><svg class="bp-svg" viewBox="0 0 24 24" id="feedback-warning" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M13.009 13.987V8.934h-1.956v5.053h1.956zm0 4.016v-1.962h-1.956v1.962h1.956zM2.988 20.962L12.016 3l9.025 17.962H2.988z"></path></svg></div>
                    <template v-if="!$v.tableData.$each[index].checkHasAnswer">Please provide an answer</template>
                  </div>

                  <template v-if="row.id === 'nonDeductibleMeals'">
                    <div class="bp-help is-error" v-if="$v.nonDeductibleMealsField.$error">
                      <div class="bp-ucon" data-src="feedback-warning"><svg class="bp-svg" viewBox="0 0 24 24" id="feedback-warning" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M13.009 13.987V8.934h-1.956v5.053h1.956zm0 4.016v-1.962h-1.956v1.962h1.956zM2.988 20.962L12.016 3l9.025 17.962H2.988z"></path></svg></div>
                      <template v-if="!$v.nonDeductibleMealsField.withinLimits">Must be between $0 and ${{deductibleMeals}}</template>
                    </div>
                  </template>

                </div>
            </div>
          </form>

          <div class="bp-help is-error mb-2" v-if="$v.allAnswered.$error">
            <div class="bp-ucon" data-src="feedback-warning"><svg class="bp-svg" viewBox="0 0 24 24" id="feedback-warning" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M13.009 13.987V8.934h-1.956v5.053h1.956zm0 4.016v-1.962h-1.956v1.962h1.956zM2.988 20.962L12.016 3l9.025 17.962H2.988z"></path></svg>
            </div>
            <template v-if="!$v.allAnswered.required">Provide answer for all items or select 'There is no amount on this line'</template>
          </div>

        </div>

        <div class="col-md-6 d-none d-sm-none d-md-block">
          <div class="std-pdf-row">
          <WebViewer :url="this.pdf"
                     ref="viewer"
                     :se-fields="seFields"
                     :tax-form-year="this.taxFormYear"
                     :tax-form-id="this.taxFormId"
                     @viewerLoaded="onViewerLoaded"
                     :key="this.pdfViewerKey"/>
          </div>
        </div>
      </div>

    <se-help :visible.sync="showPageHelp">
      <p>
        Use this screen to provide information about Profit or Loss From Business (Sole Proprietorship).
      </p>
      <p>
        <icon-base width="22" height="22" iconColor="#0c77ba"><icon-flag/></icon-base>
        {{ appName }} may collect information not traditionally used in evaluating self-employment income.
        Some data may be used to validate data integrity or determine if income from other tax return forms or schedules may be needed.
      </p>
    </se-help>

    <se-help :visible.sync="showFieldHelp.businessActivityCode">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. I’m not used to considering this information when I evaluate self-employment income. Will {{ appName }} be using this information in its monthly qualifying income calculation?<br>
          A. No. {{ appName }} will not use this information when it calculates the monthly qualifying income.
          {{ appName }} may collect information not traditionally used in evaluating self-employment income. 
          Some information may be used to validate data integrity or determine if income from other tax return forms or schedules may be needed.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.businessStartedAcquired">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. {{ appName }} needs to evaluate the length of time the borrower has had this income.
        </p>
        <p>
        <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about Length of Self-Employment in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.2-01/underwriting-factors-and-documentation-self-employed-borrower">Underwriting Factors and Documentation for a Self-Employed Borrower</a> section of the Fannie Mae Selling Guide.
        </p>
        <p>
          Q. I think the tax return may have an issue. I have two years tax returns for this business, and I don’t think the borrower started or acquired
          the business during this year. What should I provide to {{ appName }}? Do I need to notify the borrower of this potential filing error?
          Does the borrower need to amend his/her IRS tax return filing?<br>
          A. You should provide the information to {{ appName }} exactly as it appears on the borrower’s tax returns.
          {{ appName }} will use other available information to help determine the borrower’s length of employment. You are not required to notify
          the borrower of any potential errors as part of using {{ appName }}.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba" icon-name="icon-flag"><icon-book/></icon-base>
          {{ appName }} should not be relied upon for tax advice.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.grossReceiptsOrSales">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. {{ appName }} uses this data in the Comparative Income Analysis.
        </p>
        <p>
        <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about Analysis of Borrower’s Business Income in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.2-01/underwriting-factors-and-documentation-self-employed-borrower">Underwriting Factors and Documentation for a Self-Employed Borrower</a> section of the Fannie Mae Selling Guide.
        </p>
        <p>
          Q. There is no amount on this line. Is that common?<br>
          A. No. Most sole proprietors have Gross receipts or sales to report. Please double-check the line entry.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.returnsAndAllowances">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. {{ appName }} uses this data in the Comparative Income Analysis.
        </p>
        <p>
        <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about Analysis of Borrower’s Business Income in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.2-01/underwriting-factors-and-documentation-self-employed-borrower">Underwriting Factors and Documentation for a Self-Employed Borrower</a> section of the Fannie Mae Selling Guide.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.costOfGoodsSold">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. {{ appName }} uses this data in the Comparative Income Analysis.
        </p>
        <p>
        <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about Analysis of Borrower’s Business Income in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.2-01/underwriting-factors-and-documentation-self-employed-borrower">Underwriting Factors and Documentation for a Self-Employed Borrower</a> section of the Fannie Mae Selling Guide.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.otherIncomeLoss">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. Other income amounts may be included in the monthly qualifying income calculation.
        </p>
        <p>
          Q. The Fannie Mae Selling Guide allows me to document income using one year of tax returns. Will {{ appName }} consider Other income amounts to be recurring if they are only documented with one year of tax returns?<br>
          A. {{ appName }} may consider Other income amounts to be recurring even if they are documented with only one year of tax returns.
        </p>
        <p>
          Q. Am I required to obtain supporting documentation from the borrower regarding the nature of the Other income amounts?<br>
          A.  {{ appName }} may not require supporting documentation for Other income amounts.
        </p>

      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.carTruckExpenses">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. I’m not used to considering this information when I evaluate self-employment income. Will {{ appName }} be using this information in its monthly qualifying income calculation?<br>
          A. No. {{ appName }} will not use this information when it calculates the monthly qualifying income.
          {{ appName }} may collect information not traditionally used in evaluating self-employment income. 
          Some information may be used to validate data integrity or determine if income from other tax return forms or schedules may be needed.
        </p>
        <p>
          Q. Why is this information important to {{ appName }}?<br>
          A. {{ appName }} uses this information to determine if income data from other tax return schedules may be needed.
        </p>
        <p>
          Q. Can Car and truck expenses be added back to the borrower’s qualifying income?<br>
          A. Fannie Mae permits depreciation expenses to be added back to qualifying income. Income Calculator will determine the depreciation
        amount that can be added back to the borrower’s qualifying income. Understanding how to correctly calculate depreciation for business
        vehicle(s) can be challenging because depreciation is likely only a portion of the overall vehicle expense and the IRS permits
        taxpayers to choose between two different methods of reporting business vehicle depreciation. The taxpayer can:
          <ol>
            <li>	Report the actual vehicle depreciation amount, along with any other non-vehicle depreciation on Line 13 Depreciation, or</li>
            <li> Report a mileage-based vehicle deduction(regardless of the actual expenses) which includes vehicle depreciation along with all
              other vehicle expenses, such as gas and repairs, on Line 9 Car and Truck expenses.</li>
          </ol>
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.depletion">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. Any amount on this line will be added to the monthly qualifying income.
        </p>
        <p>
          Q. There is no amount on this line. Is that common?<br>
          A. Yes. Most sole proprietors do not have Depletion to report.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.mortgagePaidToBanks">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
         Q. I’m not used to considering this information when I evaluate self-employment income. Will {{ appName }} be using this information in its monthly qualifying income calculation?<br>
          A. No. {{ appName }} will not use this information when it calculates the monthly qualifying income.
          {{ appName }} may collect information not traditionally used in evaluating self-employment income. 
          Some information may be used to validate data integrity or determine if income from other tax return forms or schedules may be needed.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.depreciation">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. Any amount on this line will be added to the monthly qualifying income.
        </p>
        <p>
          Q. There is no amount on this line. Is that common?<br>
          A. Yes. Many sole proprietors do not have Depreciation to report.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.nonDeductibleMeals">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. Fannie Mae requires 100% of the borrower’s business meals be excluded from
          qualifying income. Any amount provided will be subtracted from the monthly qualifying income.
        </p>
        <p>
        <ul>
          <li>Generally, taxpayers may claim 50% or 80% of business meals as an expense.
            Note: The borrower’s profession determines which percentage is applied.
            <ul>
              <li>
                If a nondeductible meals amount is provided, {{ appName }} will validate the 50% or 80%
                deduction percentage and will subtract the amount from the monthly qualifying income.
              </li>
              <li>
                If a nondeductible meals amount is not provided, {{ appName }}
                will subtract the deductible meals amount to achieve the 100% exclusion.
              </li>
            </ul>
            For tax years 2021 and 2022, the IRS had temporarily permitted taxpayers to claim up to 100% of restaurant business meals as an expense.
            The lender must determine the amount of nondeductible meals, if any.  {{ appName }} will subtract all nondeductible business meals from the monthly qualifying income.
          </li>
        </ul>
        </p>
        <p>
          Q. How do I determine the amount of nondeductible meals for Tax Years 2021 and 2022?
          The temporary IRS 100% deduction only applies to restaurant meals, where is this information located in the tax return?<br>
          A. There is no IRS-defined location in the tax return forms or schedules where the borrower indicates which business meals are 100% deductible and which meals are 50% (or 80%) deductible.
          Fannie Mae requires lenders to determine the amount of nondeductible meals, if any. {{ appName }} will subtract the amount from the monthly qualifying income.
        </p>
      </div>
    </se-help>


    <se-help :visible.sync="showFieldHelp.deductibleMeals">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. Amounts on this line may be used by {{ appName }} to assist lenders with their determination of the nondeductible portion of business meals.
          {{ appName }} will subtract all nondeductible business meals from the monthly qualifying income.
        </p>
        <p>
          Q. There is no amount on this line. Is that common?<br>
          A. Yes. Many sole proprietors do not have Deductible meals to report.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.wagesLessEmploymentCredits">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
         Q. I’m not used to considering this information when I evaluate self-employment income. Will {{ appName }} be using this information in its monthly qualifying income calculation?<br>
          A. No. {{ appName }} will not use this information when it calculates the monthly qualifying income.
          {{ appName }} may collect information not traditionally used in evaluating self-employment income. 
          Some information may be used to validate data integrity or determine if income from other tax return forms or schedules may be needed.
        </p>
        <p>
          Q. Why is this information important to {{ appName }}?<br>
          A. {{ appName }} uses this information to determine if income data from other tax return schedules may be needed.
        </p>
        <p>
          Q. There is no amount on this line. Is that common?<br>
          A. Typically a sole proprietor does not pay themselves wages. However, the business may have employees that are paid wages.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.otherExpenses">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
         Q. I’m not used to considering this information when I evaluate self-employment income. Will {{ appName }} be using this information in its monthly qualifying income calculation?<br>
          A. No. {{ appName }} will not use this information when it calculates the monthly qualifying income.
          {{ appName }} may collect information not traditionally used in evaluating self-employment income. 
          Some information may be used to validate data integrity or determine if income from other tax return forms or schedules may be needed.
        </p>
        <p>
          Q. Why is this information important to {{ appName }}?<br>
          A. {{ appName }} uses this information to determine if income data from other tax return schedules may be needed.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.totalExpenses">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. {{ appName }} uses this data in the Comparative Income Analysis.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about Analysis of Borrower’s Business Income in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.2-01/underwriting-factors-and-documentation-self-employed-borrower">Underwriting Factors and Documentation for a Self-Employed Borrower</a> section of the Fannie Mae Selling Guide.
        </p>
        <p>
          Q. There is no amount on this line. Is that common?<br>
          A. No. Most sole proprietors have Total expenses to report. Please double-check the line entry.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.businessUseHome">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. Any amount on this line will be included in the monthly qualifying income.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.netProfitLoss">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. Any positive amount on this line will be added to the monthly qualifying income. Any negative amount on this line will be subtracted from the monthly qualifying income.
          {{ appName }} uses this data as Taxable Income in the Comparative Income Analysis.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about Income (or Loss) from a Sole Proprietorship in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.3-03/income-or-loss-reported-irs-form-1040-schedule-c">Income or Loss Reported on IRS Form 1040 Schedule C</a> section of the Fannie Mae Selling Guide.
        </p>
        <p>
          Q. There is no amount on this line. Is that common?<br>
          A. No. Most sole proprietors have Net profit (or loss) to report. Please double-check the line entry.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.vehicleServiceStartDate">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. I’m not used to considering this information when I evaluate self-employment income. Will {{ appName }} be using this information in its monthly qualifying income calculation?<br>
          A. No. {{ appName }} will not use this information when it calculates the monthly qualifying income.
          {{ appName }} may collect information not traditionally used in evaluating self-employment income. 
          Some information may be used to validate data integrity or determine if income from other tax return forms or schedules may be needed.
        </p>
        <p>
          Q. Why is this information important to {{ appName }}?<br>
          A. It will help {{ appName }} determine if the employment start date was entered correctly.
          If a vehicle was placed in service before the borrower’s reported
          employment start date, {{ appName }} will generate a data integrity message.
        </p>
        <p>
        Q. There is no date on this line. Is that common?<br>
        A. Yes. Sole proprietors may not use vehicles for their business or do not report vehicle expenses on Schedule C.<br>
          Part IV of Schedule C is designed for taxpayers to input data for only one vehicle.
          If more than one vehicle is used for the business, the tax return may refer to an attachment or statement where you should look for vehicle information.
          In some cases, the tax return may contain a single Schedule C page 1, but multiple Schedule C page 2s.
        </p>
        <p>
        Q. There is more than one business vehicle. What date should be provided?<br>
        A.  Provide the oldest date.
        </p>

        <p>
          Q. I think the tax return may have an issue. The date the vehicle was placed in service is before the validated employment start date.
          What should I provide to {{ appName }}? Do I need to notify the borrower of this potential error?<br>
          A. You should provide the information to {{ appName }} exactly as it appears on the borrower’s tax returns. {{ appName }} will use other
          available information to help determine the borrower’s length of employment. You are not required to notify the borrower of any potential
          errors as part of using {{ appName }}.
        </p>

        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          {{ appName }} should not be relied upon for tax advice.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.businessMileage">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this information important to {{ appName }}?<br>
          A. If {{ appName }} determines the borrower has chosen to use the mileage based vehicle deduction,
          it uses reported number of business miles driven from Form 1040, Schedule C, Line 44a or Form 4562.
          The number of miles is multiplied by the depreciation percent published by the IRS to determine the
          depreciation amount shown on the {{ appName }} Findings Report.
        </p>
        <p>
          Q. There is no amount on this line. Is that common?<br>
          A. Yes. Many sole proprietors may not use vehicles for their business or do not report vehicle expenses on Schedule C.<br>
          Part IV of Schedule C is designed for taxpayers to input data for only one vehicle. If more than one vehicle is used for the business, the tax return may refer
          to an attachment or statement where you should look for vehicle information. In some cases,
          the tax return may contain a single Schedule C page 1, but multiple Schedule C page 2s.
        </p>
        <p>
          Q. There is no value on line 44a, but I see the borrower has used another form, such as Form 4562, to declare business miles. 
          Should I input business miles from another location in the tax return on line 44a?<br>
          A. No. When applicable, {{ appName }} will display additional data collection screens, such as Form 4562, to collect business miles. 
          You should provide all requested tax return data exactly as it appears on the tax return forms provided.
        </p>
        <p>
          Q. There is more than one business vehicle. What miles should be provided?<br>
          A.  Provide the total business miles for all vehicles.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.amortization">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. Any Amortization amount will be added to the monthly qualifying income.
        </p>
        <p>
          Q. There is no amount on this line. Is that common?<br>
          A. Yes. Many sole proprietors do not have Amortization to report.
        </p>
        <p>
          Q. Why is the Amortization information grayed-out (not able to be provided)?<br>
          A. Based on the Other expenses entered on Line 27a, the tax returns do not contain information in Part V where amortization would be reported.
        </p>
        <p>
          Q. There is an expense identified as “BLDG AMTZ”, “Standard Amort.”, “Equipment Amortization”, or similar language; should I include these in the amortization amount?<br>
          A. Yes. Some tax returns will simply identify amortization as the expense description without any other detail. Other tax returns may identify the property being
          amortized or provide some other kind of supplemental description. All amortization amounts should be summed and reported to {{ appName }}.
        </p>
        <p>
          Q. There is an expense identified as “see statement”, “STMT”, “Statement 1”, “see attachment” or similar language; do I need to look for an attachment or statement? What if I can’t find an attachment or statement?<br>
          A. Review the complete 1040 tax return to locate the attachment or statement referenced in Part V. Review the attachment or statement the same way you reviewed the expense lines in Part V, looking for the word “Amortization” or abbreviations like “Amort.” or “AMZT”.
            <br>
            If you cannot locate an attachment or statement, select “There is no Amortization amount in Part V”. or you may contact the borrower to obtain any missing documentation.
        </p>
      </div>
    </se-help>


    <se-buttons
      :display-submit="false"
      :display-save="false"
      :display-skip="this.isSkipFormBtnDisplayed"
      :display-un-skip="this.isUnSkipFormBtnDisplayed"
      @seNext="next"
      @sePrevious="previous"
      @seSkip="skipTaxForm"
      @seUnSkip="unSkipTaxForm">
    </se-buttons>

  </div>

</template>
<script>
import SeButtons from './SeButtons.vue'
import SeHelp from './SeHelp.vue'
import {Checkbox, Radio} from 'src/components/index'
import LSwitch from 'src/components/Switch.vue'
import { Collapse, CollapseItem } from 'element-ui'
import {mapGetters, mapState} from 'vuex'
import Enums from 'src/model/enums'
import WebViewer from 'src/components/WebViewerROC.vue'
import {Table, TableColumn, Drawer} from 'element-ui'
import {required, requiredIf} from "vuelidate/lib/validators";
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'
import { taxMultiPartFormMixin } from 'src/mixins/TaxMultiPartFormMixin'
import appNameMixin from '../../../mixins/AppNameMixin'
const autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/dd/yyyy')

export default {
  mixins: [taxMultiPartFormMixin, appNameMixin],
  components: {
    Checkbox,
    Radio,
    LSwitch,
    SeButtons,
    SeHelp,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    WebViewer,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Drawer.name]: Drawer,
  },
  props: {
  },
  data() {
    return {
      dateMask: autoCorrectedDatePipe
    }
  },
  validations: {
    tableData: {
      $each: {
        checkHasAnswer(row) {
          return this.checkHasAnswer(row)
        },
        checkRequired(row) {
          return this.checkRequired(row)
        }
      }
    },
    allAnswered: {
      answered: (v) => v
    },
    nonDeductibleMealsField: {
      withinLimits(field) {
        return this.withinLimitsOrEmpty(field, 0, this.deductibleMeals);
      }
    },
  },
  computed: {
    dateMaskPipe() {
      return createAutoCorrectedDatePipe('mm/dd/yyyy', {
        minYear: 1950,
        maxYear: parseInt(this.taxFormYear)
      })
    },
    isSkipped() {
      return this.tableData.map(f => {
        let skipped = false;
        if (this.skippedForm) return true

        if (f.id === "businessMileage") {
          if (this.carTruckExpenses  <= 0) skipped = true
        } else if (f.id === "amortization") {
          if (this.otherExpenses <= 0) skipped = true
        }
        return skipped
      })
    },
    isSkipFormBtnDisplayed() {
      //allow skip only for LLC and Unknown and for CY
      return ([Enums.SeBusinessStructureType.llc, Enums.SeBusinessStructureType.unknown].includes(this.$store.getters.getBusinessStructureProvided))
        && this.getPY1 === this.taxFormYear && !this.skippedForm
    },
    isUnSkipFormBtnDisplayed() {
      return (this.getPY1 === this.taxFormYear) && this.skippedForm
    },
    nonDeductibleMealsField() {
      return this.getField('nonDeductibleMeals')
    },
    deductibleMeals() {
      return this.getValueAsNumber("deductibleMeals")
    },
    carTruckExpenses() {
      return this.getFormFieldValueAsNumber(Enums.SeTaxFormType.tf1040c, 'carTruckExpenses')
    },
    otherExpenses() {
      return this.getFormFieldValueAsNumber(Enums.SeTaxFormType.tf1040c, 'otherExpenses')
    }

  },
  methods: {
    handleHelp(index, row) {
      row.showHelp = true;
    },

  }
}
</script>
<style >

.required > div:after {
  content:" *";
  color: red;
  text-nowrap: true
}

.std-pdf-row {
  position: fixed;
  width: 40%;
  height: 90%;
}


</style>
