<template>
  <div>

      <div class="row">
        <div class="col-md-6 pr-5">
          <h1 class="mt-0 se-std-bp-title-sb mb-3">Input Data: Form 4562 ({{this.taxFormYear}})
            <a class="btn-link se-page-help-icon" v-tooltip.top-center="'Learn More'" @click="showPageHelp = true">
              <i class="fa fa-info-circle"></i>
            </a>
          </h1>

          <template v-if="taxFormPart==='1'">
            <h4 class="mt-3">Find Part V – Section B of Form 4562 (see page 2)</h4>
            <h4 class="mt-3">Business Mileage</h4>
            <p>
              While it is not common, some borrowers report the number of miles driven using business vehicles on Form 4562.
            </p>
            <p>
              Some borrowers have more than one business and will file more than one Form 4562. To ensure you are reviewing the correct Form 4562,
              check that the <b>Name shown on return</b> and/or the <b>Business or activity to which this form relates</b> in the header section of Form 4562 are associated to the business you want {{appName}} to evaluate.
            </p>
          </template>
          <template v-else-if="taxFormPart==='2'">
            <h4 class="mt-3">Find Part VI – Form 4562 (see page 2)</h4>
            <h4 class="mt-3">Amortization</h4>

            <template v-if="businessStructureEvaluated === Enums.SeBusinessStructureType.soleProprietorship" >
              <p>
                Amortization can be reported in detail on IRS Form 4562 Depreciation and Amortization. Look for this form in the tax returns.
              </p>
              <p>
                Some borrowers have more than one business and will file more than one Form 4562. To ensure you are reviewing the correct Form 4562,
                check that the <b>Name shown on return</b> and/or the <b>Business or activity to which this form relates</b> in the header section of Form 4562 are associated to the business you want {{appName}} to evaluate.
              </p>
            </template>
            <template v-else>
              <p>
                Amortization can be reported in detail on IRS Form 4562 Depreciation and Amortization. Look for this form in the business tax returns.
              </p>
            </template>

          </template>

          <form autocomplete="off">
            <div class="bp-form-group mr-5"
             v-for="(row, index) in tableData">

            <label :for="row.id" class="se-std-field-group-label">
              <span>{{row.line}}</span>
            </label>
            <a class="se-help-icon btn-link" v-tooltip.top-center="'Learn More'" @click="handleFieldHelp(row.id)" v-show="row.hasHelp">
              <i class="fa fa-info-circle"></i>
            </a>

            <div @mouseover="highlightHoveredOverFormField(row)" @mouseleave="highlightHoveredOverFormField()">
              <template v-if="row.id === 'totalBusinessMilesDriven'">
                <radio :label="false"
                       v-model="row.isEmpty"
                       :disabled="isSkipped[index] || isLocked"
                       :class="{'opacity-5': isSkipped[index]}"
                       class="mb-1">
                  <span class="se-std-label">{{row.name}}</span>
                </radio>
                <input :id="row.id"
                       :readonly="isLocked"
                       class="se-tax-field-input ml-4 bp-form-input bp-u-shadow-04"
                       type="text"
                       v-mask="'######'"
                       @focus="highlightFocusedFormField(row)"
                       @blur="fieldIsFocused = false"
                       :disabled="row.isEmpty || isSkipped[index]"
                       v-model="row.value" />
                <radio :label="true"
                       v-model="row.isEmpty"
                       :disabled="isSkipped[index] || isLocked"
                       :class="{'opacity-5': isSkipped[index]}"
                       class="mt-1"><span class="se-std-label">There is no Form 4562 or no values on this line</span>
                </radio>
              </template>
              <template v-else>
                <radio :label="false"
                       v-model="row.isEmpty"
                       :disabled="isSkipped[index] || isLocked"
                       :class="{'opacity-5': isSkipped[index]}"
                       class="mb-1"><span class="se-std-label">Enter {{row.name}}</span>
                </radio>
                <input :id="row.id"
                       :readonly="isLocked"
                       class="se-tax-field-input ml-4 bp-form-input"
                       type="text"
                       v-mask="row.mask"
                       @focus="highlightFocusedFormField(row);"
                       @blur="fieldIsFocused = false;"
                       :disabled="row.isEmpty || isSkipped[index]"
                       v-model="row.value" />
                <radio :label="true"
                       v-model="row.isEmpty"
                       :disabled="isSkipped[index] || isLocked"
                       :class="{'opacity-5': isSkipped[index]}"
                       class="mt-1"><span class="se-std-label">There is no Form 4562 or no amount on this line</span>
                </radio>
              </template>


            </div>
        </div>
          </form>

        <div class="bp-help is-error mb-2" v-if="$v.allAnswered.$error">
          <div class="bp-ucon" data-src="feedback-warning"><svg class="bp-svg" viewBox="0 0 24 24" id="feedback-warning" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M13.009 13.987V8.934h-1.956v5.053h1.956zm0 4.016v-1.962h-1.956v1.962h1.956zM2.988 20.962L12.016 3l9.025 17.962H2.988z"></path></svg>
          </div>
          <template v-if="!$v.allAnswered.required">Provide answer for all items or select 'There is no amount on this line'</template>
        </div>

      </div>

      <div class="col-md-6 d-none d-sm-none d-md-block">
        <div class="std-pdf-row">
        <WebViewer :url="this.pdf"
                   ref="viewer"
                   :se-fields="seFields"
                   :tax-form-year="this.taxFormYear"
                   :tax-form-id="this.taxFormId"
                   @viewerLoaded="onViewerLoaded"
                   :key="this.pdfViewerKey"/>
        </div>
      </div>
    </div>

    <template v-if="taxFormPart==='1'">
      <se-help :visible.sync="showPageHelp">
        <p>
          Use this screen to provide information about Depreciation and Amortization. {{ appName }} will display Form 4562 
          when there are Car and Truck Expenses on Line 9 of Schedule C. 
        </p>
      </se-help>
    </template>
    <template v-if="taxFormPart==='2'">
      <se-help :visible.sync="showPageHelp">
        <p>
          Use this screen to provide information about Depreciation and Amortization. {{ appName }} will display Form 4562 
          when there are Other Expenses on Form 1040, Schedule C, Line 27a and no Amortization amount has been identified on Schedule C. 
        </p>
        <p>
          Some borrowers have more than one business and will file more than one Form 4562. To ensure you are reviewing the correct Form 4562,
          check that the Name shown on return and/or the Business or activity to which this form relates in the header section of Form 4562 are associated to the business you want {{appName}} to evaluate.
        </p>
      </se-help>
    </template>

    <se-help :visible.sync="showFieldHelp.totalBusinessMilesDriven">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this information important to {{ appName }}?<br>
          A. If {{ appName }} determines the borrower has chosen to use the mileage based vehicle
          deduction, it uses reported number of business miles driven from Form 1040, Schedule C,
          Line 44a or Form 4562. The number of miles is multiplied by the depreciation percent published
          by the IRS to determine the depreciation amount shown on the {{ appName }} Findings Report.
        </p>
        <p>
          Q. I cannot locate page 2 of Form 4562. Is that common?<br>
          A. Yes. The data the IRS needs may be reported on Page 1 of Form 4562 and there is
          no page 2.  Select “There is no Form 4562 or no amount on this line”.
        </p>
        <p>
          Q. There is no amount on this line. Is that common?<br>
          A. Yes. Many sole proprietors do not have Business mileage to report.
        </p>
      </div>
    </se-help>
    <se-help :visible.sync="showFieldHelp.depreciationDeduction">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this information important to {{ appName }}?<br>
          A. {{ appName }} uses this information to help determine if the borrower has chosen to use
          the mileage-based vehicle deduction.
        </p>
        <p>Q. I cannot locate page 2 of Form 4562. Is that common?<br>
          A. Yes. The data the IRS needs may be reported on Page 1 of Form 4562 and there is
          no page 2.  Select “There is no Form 4562 or no amount on this line”.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.amortization4562">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. Any Amortization amount will be added to the monthly qualifying income.
        </p>
        <p>
          Q. I cannot locate page 2 of Form 4562. Is that common?<br>
          A. Yes. The data the IRS needs may be reported on Page 1 of Form 4562 and there is
          no page 2.  Select “There is no Form 4562 or no amount on this line”.
        </p>
        <p>
          Q. There is no amount on this line. Is that common?<br>
          A. Yes. Many self-employed borrowers do not have Amortization to report.
        </p>
      </div>
    </se-help>


    <se-buttons
      :display-submit="false"
      :display-save="false"
      :disable-submit="true"
      :disable-save="true"
      :disable-next="false"
      :disable-previous="false"
      @seNext="next"
      @sePrevious="previous">
    </se-buttons>

  </div>

</template>
<script>
import SeButtons from './SeButtons.vue'
import SeHelp from './SeHelp.vue'
import {Checkbox, Radio} from 'src/components/index'
import LSwitch from 'src/components/Switch.vue'
import { Collapse, CollapseItem } from 'element-ui'
import WebViewer from 'src/components/WebViewerROC.vue'
import {Table, TableColumn, Drawer} from 'element-ui'
import {required, requiredIf} from "vuelidate/lib/validators";
import {taxMultiPartFormMixin} from "../../../mixins/TaxMultiPartFormMixin";
import appNameMixin from '../../../mixins/AppNameMixin'
export default {
  mixins: [taxMultiPartFormMixin, appNameMixin],
  components: {
    Checkbox,
    Radio,
    LSwitch,
    SeButtons,
    SeHelp,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    WebViewer,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Drawer.name]: Drawer,
  },
  data() {
    return {
    }
  },
  validations: {
    allAnswered: {
      required: (v) => v
    }
  },
  computed: {
    isSkipped() {
      return this.tableData.map(f => {
        if (this.skippedForm) return true

        return false;
      })
    },
    businessStructureEvaluated() {
      return this.$store.getters.getBusinessStructureEvaluatedAndDerived.evaluated
    }
  },
  methods: {
    handleHelp(index, row) {
      row.showHelp = true;
    },
  }
}
</script>
<style >

.required > div:after {
  content:" *";
  color: red;
  text-nowrap: true
}

.std-pdf-row {
  position: fixed;
  width: 40%;
  height: 90%;
}


</style>
