<template>
  <div>
    <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <h1 class="se-bp-title">Welcome to {{appName}}</h1>
        <h4>Use {{appName}} to quickly and accurately determine monthly qualifying income from self-employment or business ownership.
        </h4>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">

        <div class="row mt-4">
          <div class="col">
            <ul class="se-ul-no-bullets">
              <li><icon-base width="30" height="30" iconColor="rgba(9,134,32,1)" icon-name="checkMark"><icon-check-mark/></icon-base>Easy to Use</li>
              <li><icon-base width="30" height="30" iconColor="rgba(9,134,32,1)" icon-name="checkMark"><icon-check-mark/></icon-base>Immediate Results</li>
              <li><icon-base width="30" height="30" iconColor="rgba(9,134,32,1)" icon-name="checkMark"><icon-check-mark/></icon-base>Originate with Confidence</li>
            </ul>
          </div>
          <div class="col">
            <img class="se-welcome-img" src="static/img/user-computer.svg" alt="Welcome to Income Calculator"/>
          </div>
        </div>

        <p class="mt-3">
          <strong>Notice:</strong> Income Calculator is not intended for consumer use and must only be used by <a @click="showMtgProfHelp=true" href="#">Mortgage Professionals</a>. Consumers should contact a mortgage lender to understand their personal financing options and qualifying income.
        </p>

        <checkbox class="se-text-transform-none" v-model="acceptedTerms" :disabled="!seAccessAllowed">
          I acknowledge that I have read the <a target="_blank" href="https://www.fanniemae.com/about-us/corporate-governance/online-privacy-notice">Privacy Notice</a> and agree to the <a @click="showLicenseHelp=true" href="#">Terms and Conditions</a>. I further certify I am a <a @click="showMtgProfHelp=true" href="#">Mortgage Professional</a>.
        </checkbox>
        <div class="bp-help is-error" v-if="$v.acceptedTerms.$error">
          <div class="bp-ucon" data-src="feedback-warning"><svg class="bp-svg" viewBox="0 0 24 24" id="feedback-warning" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M13.009 13.987V8.934h-1.956v5.053h1.956zm0 4.016v-1.962h-1.956v1.962h1.956zM2.988 20.962L12.016 3l9.025 17.962H2.988z"></path></svg>
          </div>
          <template v-if="!$v.acceptedTerms.checked">Please indicate acceptance of the {{appName}} Terms and Conditions</template>
        </div>

        <se-help-license-agreement :visible.sync="showLicenseHelp" title="Software License Agreement"></se-help-license-agreement>

        <se-help :visible.sync="showMtgProfHelp" title="Mortgage Professional">
          <h4>Mortgage Professional</h4>
          A “Mortgage Professional” is any individual employed in the residential mortgage lending industry who provides mortgage-related services to consumers or financial institutions.  A Mortgage Professional includes, but is not limited to, mortgage loan officers and brokers; loan underwriters and processors; correspondent lenders and loan aggregators; and mortgage insurers.  Individual mortgage loan applicants and borrowers or prospective mortgage loan applicants and borrowers are not Mortgage Professionals.
        </se-help>

        <div class="mb-4">
          <se-buttons
            :display-previous="false"
            :display-submit="false"
            :display-save="false"
            :disable-next="!this.seAccessAllowed"
            @seNext="next"
            @sePrevious="previous">
          </se-buttons>
        </div>

      </div>
      <div class="col-md-6">

        <card class="se-video-card">
          <h2 class="se-bp-title mt-0">Reference videos to help you get started</h2>
          <div class="row mb-3">
            <div class="col-4"><img class="se-bp-title-video-item" src="static/img/video1.png" alt="The Basics video" @click="showVideo('video1')"/></div><div class="col-8 my-auto">Basics (2:45)</div>
          </div>
          <div class="row mb-3">
            <div class="col-4"><img class="se-bp-title-video-item" src="static/img/video2.png" alt="How it works video" @click="showVideo('video2')"/></div><div class="col-8 my-auto">How It Works (2:31)</div>
          </div>
          <div class="row mb-3">
            <div class="col-4"><img class="se-bp-title-video-item" src="static/img/video3.png" alt="Application screens video" @click="showVideo('video3')"/></div><div class="col-8 my-auto">Application Screens (2:58)</div>
          </div>
        </card>

      </div>


    </div>

    <se-help :visible.sync="showHelp"
             title="Learn More"
             size="40%">
      <card class="card-plain">
        <h3 class="mt-0">Learn More about {{appName}}<sup><small> ®</small></sup></h3>
        <h5>UNDER CONSTRUCTION: More info here about benefits and our phased approach</h5>
        <img src="static/img/learn-more-schedule.png" height="425" alt="crash"/>
      </card>
    </se-help>
    </div>
  </div>
</template>
<script>

    import {Checkbox, Radio, IconCheckMark} from 'src/components/index'
    import SeButtons from './SeButtons.vue'
    import SeHelp from './SeHelp.vue'
    import Enums from "../../../model/enums";
    import {
      SET_STATE_DATA,
      CHECK_CONVERSATION_ID,
    } from "../../../store/actions/se";
    import {mapState} from "vuex";
    import Swal from "sweetalert2";
    import SeHelpLicenseAgreement from './SeHelpLicenseAgreement.vue'
    import appNameMixin from '../../../mixins/AppNameMixin'

    export default {
        mixins:[appNameMixin],
        components: {
            IconCheckMark,
            Checkbox,
            Radio,
            SeButtons,
            SeHelpLicenseAgreement,
            SeHelp
        },
        data() {
            return {
                showHelp: false,
                showLicenseHelp: false,
                showMtgProfHelp: false
            }
        },
        validations: {
            acceptedTerms: {
                checked: (val) => {
                    return val
                }
            }
        },
        beforeRouteUpdate(to, from, next) {
          this.checkConversationId(to.params.conversationId)
          next();
        },
        created() {
          this.checkConversationId(this.$route.params.conversationId)
        },
        computed: {
            acceptedTerms: {
                get() { return this.seAcceptedTerms },
                set(acceptedTerms) { this.$store.commit(SET_STATE_DATA, {key:'acceptedTerms', value:acceptedTerms}) }
            },
            ...mapState({
                seRequest: state => state.se.request,
                seAcceptedTerms: state => state.se.acceptedTerms,
                seAccessAllowed: state => state.se.accessAllowed
            }),
        },
        methods: {
            showVideo (videoId) {

                let url = "https://fanniemae.qumucloud.com/view/kZWe8bfKlA0M1BytlGSiU9?captions=en"
                if (videoId === "video2") {
                  url = "https://fanniemae.qumucloud.com/view/Ga8a69ws6eDlae94fbuUnZ?captions=en"
                } else if (videoId === "video3") {
                  url = "https://fanniemae.qumucloud.com/view/00KFZveYFeeKNuQL98PLgi?captions=en"
                }

                const fnmSwal = Swal.mixin({
                    customClass: {
                        confirmButton: 'bp-button bp-button--primary',
                        htmlContainer: 'se-video-container',
                        popup: 'se-video-container-popup bp-u-shadow-08'
                    },
                    buttonsStyling: false
                });

                let el = document.getElementById(videoId)
                fnmSwal.fire({
                    width: '1000px',
                    heightAuto: true,
                    padding: 0,
                    scrollbarPadding: false,
                    confirmButtonText: 'Close',
                    html:
                      `<iframe width="1000" height="560" src="${url}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
                })
            },
            next() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.$router.push('/se-eval/start')
            },
            previous() {
                this.$router.push('welcome')
            },

            async checkConversationId(conversationId) {
              if (this.seAccessAllowed) return

              try {
                let id = conversationId ? conversationId : ""
                await this.$store.commit(SET_STATE_DATA, {key: 'conversationId', value: id})
                await this.$store.dispatch(CHECK_CONVERSATION_ID);

              } catch ( resp ) {
                let title = `Unable to connect`
                let msg = `Contact Income_Calculator@FannieMae.com <br>for assistance.`
                if ([401, 403].includes(resp.status)) {
                  title = `Access Unauthorized`
                  msg = `Contact Income_Calculator@FannieMae.com <br>for assistance.`
                }

                await Swal.fire({
                  title: title,
                  icon: 'error',
                  buttonsStyling: false,
                  customClass: {confirmButton: 'bp-button bp-button--primary'},
                  html: msg
                })
              }
            }

        }
    }
</script>
<style>

  .se-ul-no-bullets {
    list-style-type:none;
    font-weight: 600;
    font-size: 1rem;
    margin-top: 1rem;
  }
  .se-ul-no-bullets li {
    margin-bottom: 1.2rem;
  }
  .se-ul-no-bullets svg {
    margin-right: 1rem;
  }
  .se-video-card {
    background-color: rgba(247,247,247,1);
    color: rgba(8,82,128,1);
    border: 0;
    border-radius: 10px;
    margin-top: 1rem;
    font-size: 1.2rem;
    font-weight: 600;
  }
  .se-bp-title-video-item {
    cursor: pointer;
    min-width: 100px;
  }

  .se-video-card progress {
    height: unset;
  }

  .se-video-container {
    padding: 0 !important;
  }

  .se-video-container-popup {
    padding: 0 0 1rem 0 !important;
  }

  .se-video-insert {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .se-welcome-img {
    min-width: 100px;
  }

</style>
