import Enums from "../../../../model/enums";
import {ty} from "./ty";

export default {
  id: Enums.SeTaxFormType.tf1120,
  waivable: true,
  parts: [
    {
      part: "1",
      pdf: `static/forms/${ty}/${ty}_1120Pg1.pdf`,
      route: `/se-eval/std-tax-form-1120/${Enums.SeTaxFormType.tf1120}/${ty}/1`,
      fields: [
        {
          id: "taxYearEnding",
          fieldName: "topmostSubform[0].Page1[0].PgHeader[0].f1_2[0]",
          fieldHighlights: [
            "topmostSubform[0].Page1[0].PgHeader[0].f1_2[0]",
            "topmostSubform[0].Page1[0].PgHeader[0].f1_3[0]"
          ],
          label: "Tax year ending",
          required: true,
          line: "Tax year ending",
          hasHelp: true,
          pageHeader: `Find Business Tax Returns for ${ty}`
        },
        {
          id: "isM3Attached",
          fieldName: "topmostSubform[0].Page1[0].A[0].c1_5[0]",
          label: "Check if Sch. M-3 attached",
          isBoolean: true,
          required: true,
          line: "Line A4",
          hasHelp: true,
          defaultValue: "No"
        },
        {
          id: "incorporatedDate",
          fieldName: "topmostSubform[0].Page1[0].f1_8[0]",
          label: "Date incorporated",
          required: true,
          line: "Line C",
          hasHelp: true
        },
        {
          id: "totalAssets",
          fieldName: "topmostSubform[0].Page1[0].f1_9[0]",
          label: "Total assets",
          line: "Line D",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "isFinal",
          fieldName: "topmostSubform[0].Page1[0].c1_7[0]",
          label: "Final return",
          isBoolean: true,
          required: true,
          line: "Line E(2)",
          hasHelp: true,
          defaultValue: "No"
        },
        {
          id: "isFinalReturnOverride",
          fieldName: "",
          label: "Final return",
          isBoolean: true,
          required: true,
          line: "Final return",
          hasHelp: false,
          defaultValue: "Yes"
        },
        {
          id: "grossReceiptsOrSales",
          fieldName: "topmostSubform[0].Page1[0].f1_10[0]",
          label: "Gross receipts or sales",
          line: "Line 1 (a)",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "returnsAndAllowances",
          fieldName: "topmostSubform[0].Page1[0].f1_11[0]",
          label: "Returns and allowances",
          line: "Line 1 (b)",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "costOfGoodsSold",
          fieldName: "topmostSubform[0].Page1[0].f1_13[0]",
          label: "Cost of goods sold",
          line: "Line 2",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "capitalGainNetIncome",
          fieldName: "topmostSubform[0].Page1[0].f1_19[0]",
          label: "Capital gain net income",
          line: "Line 8",
          isCurrency: true,
          isPositive: false,
          hasHelp: true
        },
        {
          id: "netGainLoss4797",
          fieldName: "topmostSubform[0].Page1[0].f1_20[0]",
          label: "Net gain or (loss) from Form 4797",
          line: "Line 9",
          isCurrency: true,
          isPositive: false,
          hasHelp: true
        },
        {
          id: "otherIncomeLoss",
          fieldName: "topmostSubform[0].Page1[0].f1_21[0]",
          label: "Other income (loss)",
          line: "Line 10",
          isCurrency: true,
          isPositive: false,
          hasHelp: true
        },
        {
          id: "compensationOfOfficers",
          fieldName: "topmostSubform[0].Page1[0].f1_23[0]",
          label: "Compensation of officers",
          line: "Line 12",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "isCompensationOfOfficersIncludedAsIncome",
          fieldName: "",
          label: "Was the Compensation of officers amount included as income in the borrower’s personal tax return?",
          isBoolean: true,
          required: true,
          line: "Compensation of officers",
          hasHelp: true,
          defaultValue: "No"
        },
        {
          id: "depreciation",
          fieldName: "topmostSubform[0].Page1[0].f1_31[0]",
          label: "Depreciation",
          line: "Line 20",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "depletion",
          fieldName: "topmostSubform[0].Page1[0].f1_32[0]",
          label: "Depletion",
          line: "Line 21",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "otherDeductions",
          fieldName: "topmostSubform[0].Page1[0].f1_37[0]",
          label: "Other deductions",
          line: "Line 26",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "totalDeductions",
          fieldName: "topmostSubform[0].Page1[0].f1_38[0]",
          label: "Total deductions",
          line: "Line 27",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "netOperatingLossDeduction",
          fieldName: "topmostSubform[0].Page1[0].f1_39[0]",
          label: "Net operating loss deduction",
          line: "Line 29a",
          isCurrency: true,
          isPositive: false,
          hasHelp: true
        },
        {
          id: "specialDeductions",
          fieldName: "topmostSubform[0].Page1[0].f1_41[0]",
          label: "Special deductions",
          line: "Line 29b",
          isCurrency: true,
          isPositive: false,
          hasHelp: true
        },
        {
          id: "taxableIncome",
          fieldName: "topmostSubform[0].Page1[0].f1_43[0]",
          label: "Taxable income",
          line: "Line 30",
          isCurrency: true,
          isPositive: false,
          hasHelp: true
        },
        {
          id: "totalTax",
          fieldName: "topmostSubform[0].Page1[0].f1_44[0]",
          label: "Total tax",
          line: "Line 31",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "amortization",
          fieldName: "",
          label: "Amortization from attachment",
          line: "Amortization",
          isCurrency: true,
          isPositive: true,
          isEmptyByDefault: true,
          hasHelp: true
        }
      ]
    },
    {
      part: "2",
      pdf: `static/forms/${ty}/${ty}_1120Pg6.pdf`,
      route: `/se-eval/std-tax-form-1120/${Enums.SeTaxFormType.tf1120}/${ty}/2`,
      fields: [
        {
          id: "cashEndOfTaxYear",
          fieldName: "topmostSubform[0].Page6[0].SchLTable[0].Line1[0].f6_4[0]",
          label: "Cash (End of tax year)",
          line: "Line 1(d)",
          isCurrency: true,
          isPositive: true,
          hasHelp: true,
          pageHeader: "Find Schedule L of Form 1120 (page 6)"
        },
        {
          id: "tradeNotesAndAccountsReceivableLessAllowanceEndOfTaxYear",
          fieldName: "topmostSubform[0].Page6[0].SchLTable[0].Line2b[0].f6_12[0]",
          label: "Trade notes and accounts receivable less allowances (End of tax year)",
          line: "Line 2b(d)",
          isCurrency: true,
          isPositive: false,
          hasHelp: true
        },
        {
          id: "inventoriesEndOfTaxYear",
          fieldName: "topmostSubform[0].Page6[0].SchLTable[0].Line3[0].f6_16[0]",
          label: "Inventories (End of tax year)",
          line: "Line 3(d)",
          isCurrency: true,
          isPositive: false,
          hasHelp: true
        },
        {
          id: "govObligationsEndOfTaxYear",
          fieldName: "topmostSubform[0].Page6[0].SchLTable[0].Line4[0].f6_20[0]",
          label: "U.S. government obligations (End of tax year)",
          line: "Line 4(d)",
          isCurrency: true,
          isPositive: false,
          hasHelp: true
        },
        {
          id: "taxExemptSecuritiesEndOfTaxYear",
          fieldName: "topmostSubform[0].Page6[0].SchLTable[0].Line5[0].f6_24[0]",
          label: "Tax-exempt securities (End of tax year)",
          line: "Line 5(d)",
          isCurrency: true,
          isPositive: false,
          hasHelp: true
        },
        {
          id: "otherCurrentAssetsEndOfTaxYear",
          fieldName: "topmostSubform[0].Page6[0].SchLTable[0].Line6[0].f6_28[0]",
          label: "Other current assets (End of tax year)",
          line: "Line 6(d)",
          isCurrency: true,
          isPositive: false,
          hasHelp: true
        },
        {
          id: "accountsPayableEndOfTaxYear",
          fieldName: "topmostSubform[0].Page6[0].SchLTable[0].Line16[0].f6_80[0]",
          label: "Accounts payable (End of tax year)",
          line: "Line 16(d)",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "mortgagesNotesPayableLess1YrBeginningOfTaxYear",
          fieldName: "topmostSubform[0].Page6[0].SchLTable[0].Line17[0].f6_82[0]",
          label: "Mortgages, notes, bonds payable in less than 1 year (Beginning of tax year)",
          line: "Line 17(b)",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "mortgagesNotesPayableLess1YrEndOfTaxYear",
          fieldName: "topmostSubform[0].Page6[0].SchLTable[0].Line17[0].f6_84[0]",
          label: "Mortgages, notes, bonds payable in less than 1 year (End of tax year)",
          line: "Line 17(d)",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "otherCurrentLiabilitiesEndOfTaxYear",
          fieldName: "topmostSubform[0].Page6[0].SchLTable[0].Line18[0].f6_88[0]",
          label: "Other current liabilities (End of tax year)",
          line: "Line 18(d)",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "mortgagePayableLess1YrRolloverType",
          fieldName: "",
          label: "Amount that does not rollover regularly or is not a lone of credit",
          line: "",
          radio: true,
          isEmptyByDefault: true
        },
        {
          id: "nonRolloverMortgagePayableLess1YrAmount",
          fieldName: "",
          label: "Amount that does not rollover regularly or is not a loan of credit",
          line: "",
          isCurrency: true,
          isPositive: true,
          isEmptyByDefault: true
        },
        {
          id: "retainedEarningsAppropriatedEndOfTaxYear",
          fieldName: "topmostSubform[0].Page6[0].SchLTable[0].Line24[0].f6_116[0]",
          label: "Retained earnings - Appropriated (End of tax year)",
          line: "Line 24(d)",
          isCurrency: true,
          isPositive: false,
          hasHelp: true
        },
        {
          id: "retainedEarningsUnappropriatedEndOfTaxYear",
          fieldName: "topmostSubform[0].Page6[0].SchLTable[0].Line25[0].f6_120[0]",
          label: "Retained earnings - Unappropriated (End of tax year)",
          line: "Line 25(d)",
          isCurrency: true,
          isPositive: false,
          hasHelp: true
        },
        {
          id: "travelEntertainment",
          fieldName: "topmostSubform[0].Page6[0].SchM-1_Left[0].f6_141[0]",
          label: "Travel and entertainment",
          line: "Line 5c",
          isCurrency: true,
          isPositive: true,
          hasHelp: true,
          pageHeader: "Find Schedule M-1 of Form 1120 (page 6)"
        },
        {
          id: "cashDistributions",
          fieldName: "topmostSubform[0].Page6[0].SchM-2_Right[0].f6_163[0]",
          label: "Distributions: Cash",
          line: "Line 5a",
          isCurrency: true,
          isPositive: false,
          hasHelp: true,
          pageHeader: "Find Schedule M-2 of Form 1120 (page 6)"
        }
      ]
    }
  ]
}

