<template>
  <div>
    <div class="container">

    <h1 class="se-bp-title">
      Business Structure
      <a class="btn-link se-page-help-icon" v-tooltip.top-center="'Learn More'" @click="showPageHelp = true">
        <i class="fa fa-info-circle"></i>
      </a>
    </h1>

    <h4 class="mt-3 mb-3">Thinking about the business you want {{appName}} to evaluate, select the business structure.
    </h4>

    <radio :label="SeBusinessStructureType.soleProprietorship.id" :disabled="isLocked"
           v-model="businessStructureProvided">{{SeBusinessStructureType.soleProprietorship.name}}
          <a class="btn-link se-help-icon" v-tooltip.top-center="'Learn More'" @click="showHelpSoleProprietorship = true"><i class="fa fa-info-circle"></i></a>
    </radio>

    <radio :label="SeBusinessStructureType.sCorp.id" :disabled="isLocked"
           v-model="businessStructureProvided">{{SeBusinessStructureType.sCorp.name}}
          <a class="btn-link se-help-icon" v-tooltip.top-center="'Learn More'" @click="showHelpSCorp = true"><i class="fa fa-info-circle"></i></a>
    </radio>

    <radio :label="SeBusinessStructureType.partnership.id" :disabled="isLocked"
           v-model="businessStructureProvided">{{SeBusinessStructureType.partnership.name}}
          <a class="btn-link se-help-icon" v-tooltip.top-center="'Learn More'" @click="showHelpPartnership = true"><i class="fa fa-info-circle"></i></a>
    </radio>

    <radio :label="SeBusinessStructureType.corp.id" :disabled="isLocked"
           v-model="businessStructureProvided">{{SeBusinessStructureType.corp.name}}
          <a class="btn-link se-help-icon" v-tooltip.top-center="'Learn More'" @click="showHelpCorp = true"><i class="fa fa-info-circle"></i></a>
    </radio>

    <radio :label="SeBusinessStructureType.llc.id" :disabled="isLocked"
           v-model="businessStructureProvided">{{SeBusinessStructureType.llc.name}}
          <a class="btn-link se-help-icon" v-tooltip.top-center="'Learn More'" @click="showHelpCorpLlc = true"><i class="fa fa-info-circle"></i></a>
    </radio>

    <radio :label="SeBusinessStructureType.unknown.id" :disabled="isLocked"
           v-model="businessStructureProvided">{{SeBusinessStructureType.unknown.name}}
          <a class="btn-link se-help-icon" v-tooltip.top-center="'Learn More'" @click="showHelpUnknown = true"><i class="fa fa-info-circle"></i></a>
    </radio>

    <div class="mt-5" v-if="showEmploymentOwnershipInterestProvided">
      <h4 class="mt-3 mb-3">Describe the borrower’s current ownership share in this {{getBusinessStructureProvided.derivedName}}
        <a class="btn-link se-help-icon" v-tooltip.top-center="'Learn More'" @click="showHelpEmploymentOwnershipInterestProvidedType = true"><i class="fa fa-info-circle"></i></a>
      </h4>

      <radio :label="EmploymentOwnershipInterestProvidedType.gte25"
             :disabled="isLocked"
             v-model="employmentOwnershipInterestProvided">The borrower has an ownership share of 25% or more in the business
      </radio>
      <radio :label="EmploymentOwnershipInterestProvidedType.lt25"
             :disabled="isLocked"
             v-model="employmentOwnershipInterestProvided">The borrower has an ownership share of less than 25% in the business
      </radio>
    </div>

    <div class="mt-5" v-if="showEmploymentOwnershipFiveYearHistoryIndicator">
      <h4 class="mt-3 mb-3">Has the  borrower had an ownership share in this {{getBusinessStructureProvided.derivedName}} for the past five (5) years
        <a class="btn-link se-help-icon" v-tooltip.top-center="'Learn More'" @click="showHelpEmploymentOwnershipFiveYearHistoryIndicator = true"><i class="fa fa-info-circle"></i></a>
      </h4>

      <radio :label="true"
             :disabled="isLocked"
             v-model="employmentOwnershipFiveYearHistoryIndicator">Yes, the borrower has had an ownership share of 25% or more in this business for at least the past five (5) consecutive years
      </radio>
      <radio :label="false"
             :disabled="isLocked"
             v-model="employmentOwnershipFiveYearHistoryIndicator">No, the borrower has not had an ownership share of 25% or more in this business for the past five (5) consecutive years
      </radio>
    </div>

    <div class="mt-5" v-if="showEmploymentOwnershipRecentAcquisitionIndicator">
      <h4 class="mt-3 mb-3">Did the borrower acquire their ownership share in this {{getBusinessStructureProvided.derivedName}} in {{new Date().getFullYear() - 1}}
        <a class="btn-link se-help-icon" v-tooltip.top-center="'Learn More'" @click="showHelpEmploymentOwnershipRecentAcquisitionIndicator = true"><i class="fa fa-info-circle"></i></a>
      </h4>

      <radio :label="true"
             :disabled="isLocked"
             v-model="employmentOwnershipRecentAcquisitionIndicator">Yes, the borrower acquired their ownership share in this business in {{new Date().getFullYear() - 1}}
      </radio>
      <radio :label="false"
             :disabled="isLocked"
             v-model="employmentOwnershipRecentAcquisitionIndicator">No, the borrower did not  acquire their ownership share in this business in {{new Date().getFullYear() - 1}}
      </radio>
    </div>


    <se-help :visible.sync="showPageHelp" title="Learn More">
      <p>
        Use this screen to identify the business’ structure if you know what it is.
        {{ appName }} can help you identify the correct business structure
        if you’re not sure. Knowing the business structure will enable {{ appName }}
        to streamline tax return data collection.
      </p>
      <p>
        <icon-base width="22" height="22" iconColor="#0c77ba" icon-name="icon-right-arrow"><icon-book/></icon-base>
        Learn more about <u><a href="https://selling-guide.fanniemae.com/sel/b3-3.2-02/business-structures" target="_blank">Business Structures</a>
      </u> in the Fannie Mae Selling Guide
      </p>

      <p>
        <b>FAQs:</b><br>
        Q. How do I determine if a business is a Limited Liability Company (LLC)?<br>
        A. The name of the business on the tax returns may contain “Limited Liability Company” or “LLC,” or there may be other information in the loan file.
      </p>
      <p>
        Q. If my business is structured as a Limited Liability Company (LLC), but is classified as a sole proprietorship, partnership, S-Corporation or Corporation
        for federal income tax purposes, which option should I select?<br>
        A. Select “Limited Liability Company (LLC).”
      </p>

      <p>
        Q. What happens if I select “I don’t know”?<br>
        A. If you select “I don’t know,” {{ appName }} will try to identify the
        correct business structure as you provide the borrower’s tax return data. The presence or
        absence of certain IRS tax return forms or data can help narrow down the business structure.
      </p>

      <p>
        Q. The borrower’s business structure has recently changed. For example, the business is
        structured as a Corporation in the current tax year but was structured as a Partnership in
        the prior tax year. What value should I select?<br>
        A. Select the current business structure. If two year’s business tax returns are required
        for documentation of income, or less than 12 months of income is reported on the current
        year’s business tax returns, you will need to use another method to calculate the borrower’s income.
        {{ appName }} does not currently evaluate income when the borrower’s business structure has changed from one year to the next.

      </p>
    </se-help>

    <se-help :visible.sync="showHelpBusinessStructure" title="Learn More">
      <p>
      ddd   </p>
    </se-help>

    <se-help :visible.sync="showHelpSoleProprietorship" title="Sole Proprietorship (Schedule C)">
      <h4>Sole Proprietorship</h4>
      <p>
        A sole proprietorship is an unincorporated business that is individually owned and managed.
        The individual owner has unlimited personal liability for all debts of the business.
      </p>
      <p>
        <icon-base width="22" height="22" iconColor="#0c77ba" icon-name="icon-right-arrow"><icon-book/></icon-base>
        Learn more about Sole Proprietorships in the <u><a href="https://selling-guide.fanniemae.com/sel/b3-3.2-02/business-structures" target="_blank">Business Structures</a>
      </u> section of the Fannie Mae Selling Guide.
      </p>
      <p>
        <b>FAQs:</b><br>
        Q. If my business is structured as a Limited Liability Company (LLC), but is classified as a sole proprietorship, partnership,
        S-Corporation or Corporation for federal income tax purposes, which option should I select?<br>
		A. Select “Limited Liability Company (LLC)."
      </p>
    </se-help>

    <se-help :visible.sync="showHelpSCorp" title="S Corporation">
      <h4>S Corporation</h4>
      <p>
        An S corporation is a legal entity that has a limited number of stockholders
        and elects not to be taxed as a regular corporation. Business gains and losses are passed on to the stockholders.
      </p>
      <p>
        <icon-base width="22" height="22" iconColor="#0c77ba" icon-name="icon-right-arrow"><icon-book/></icon-base>
        Learn more about S-Corporations in the <u><a href="https://selling-guide.fanniemae.com/sel/b3-3.2-02/business-structures" target="_blank">Business Structures</a>
      </u> section of the Fannie Mae Selling Guide.
      </p>
      <p>
        <b>FAQs:</b><br>
        Q. If my business is structured as a Limited Liability Company (LLC), but is classified as a sole proprietorship, partnership,
        S-Corporation or Corporation for federal income tax purposes, which option should I select?<br>
		A. Select “Limited Liability Company (LLC)."
      </p>
    </se-help>

    <se-help :visible.sync="showHelpPartnership" title="Partnership">
      <h4>Partnership</h4>
      <p>
       A partnership is an arrangement between two or more individuals who have
        pooled their assets and skills to form a business and who will share profits
        and losses according to predetermined proportions that are set out in the partnership agreement.
      </p>
      <p>
        <icon-base width="22" height="22" iconColor="#0c77ba" icon-name="icon-right-arrow"><icon-book/></icon-base>
        Learn more about Partnerships in the <u><a href="https://selling-guide.fanniemae.com/sel/b3-3.2-02/business-structures" target="_blank">Business Structures</a>
      </u> section of the Fannie Mae Selling Guide.
      </p>
      <p>
        <b>FAQs:</b><br>
        Q. If my business is structured as a Limited Liability Company (LLC), but is classified as a sole proprietorship, partnership,
        S-Corporation or Corporation for federal income tax purposes, which option should I select?<br>
		A. Select “Limited Liability Company (LLC)."
      </p>
    </se-help>

    <se-help :visible.sync="showHelpCorp" title="Corporation">
      <h4>Corporation</h4>
      <p>
        A corporation is a state-chartered legal entity that exists separately and
        distinctly from its owners (who are called stockholders or shareholders).
      </p>
      <p>
        <icon-base width="22" height="22" iconColor="#0c77ba" icon-name="icon-right-arrow"><icon-book/></icon-base>
        Learn more about Corporations in the <u><a href="https://selling-guide.fanniemae.com/sel/b3-3.2-02/business-structures" target="_blank">Business Structures</a>
      </u> section of the Fannie Mae Selling Guide.
      </p>
      <p>
        <b>FAQs:</b><br>
        Q. If my business is structured as a Limited Liability Company (LLC), but is classified as a sole proprietorship, partnership,
        S-Corporation or Corporation for federal income tax purposes, which option should I select?<br>
		A. Select “Limited Liability Company (LLC)."
      </p>
    </se-help>

    <se-help :visible.sync="showHelpCorpLlc" title="Limited Liability Company (LLC) ">
      <h4>LLC</h4>
      <p>
        A limited liability company (LLC) is a hybrid business structure that
        is designed to offer its member-owners the tax efficiencies of a partnership
        or sole proprietorship and the limited liability advantages of a corporation.
      </p>
      <p>
        <icon-base width="22" height="22" iconColor="#0c77ba" icon-name="icon-right-arrow"><icon-book/></icon-base>
        Learn more about Limited Liability Companies (LLCs) in the <u><a href="https://selling-guide.fanniemae.com/sel/b3-3.2-02/business-structures" target="_blank">Business Structures</a>
      </u> section of the Fannie Mae Selling Guide.
      </p>
      <p>
        <b>FAQs:</b><br>
        Q. How do I determine if a business is a Limited Liability Company (LLC)?<br>
        A. The name of the business on the tax returns may contain “Limited Liability Company” or “LLC,” or there may be other information in the loan file.
      </p>
      <p>
        Q. If my business is structured as a Limited Liability Company (LLC), but is classified as a sole proprietorship, partnership,
        S-Corporation or Corporation for federal income tax purposes, which option should I select?<br>
        A. Select “Limited Liability Company (LLC).”
      </p>
    </se-help>

    <se-help :visible.sync="showHelpUnknown" title="I don’t know">

      <p>
        Select “I don’t know” if you want {{ appName }} to help you
        identify the correct business structure as you provide the tax return data.
      </p>
      <p>
        <icon-base width="22" height="22" iconColor="#0c77ba" icon-name="icon-right-arrow"><icon-book/></icon-base>
        Learn more about <u><a href="https://selling-guide.fanniemae.com/sel/b3-3.2-02/business-structures" target="_blank">Business Structures</a>
      </u> in the Fannie Mae Selling Guide.
      </p>
    </se-help>

    <se-help :visible.sync="showHelpEmploymentOwnershipInterestProvidedType">
      <p>
       <b>FAQs:</b>
      </p>
      <p>
        Q. Should the borrower's ownership share in the business provided to {{ appName }} match the borrower's ownership share in the business on the loan application?<br>
		A. Yes. The ownership share information should match.
      </p>
      <p>
        Q. The borrower's ownership share in the business changed after their last tax return was filed. What information should I provide to {{ appName }}?<br>
		A. Provide the borrower's ownership share as of the loan application date.
      </p>
    </se-help>

    <se-help :visible.sync="showHelpEmploymentOwnershipFiveYearHistoryIndicator">
      <p>
        Most borrower's ownership share does not change from their employment start date.
        Because Fannie Mae's self-employment income documentation requirements are determined by whether the borrower had an
        ownership share of 25% or more for at least the past five (5) consecutive years, {{ appName }} requires this information.
      </p>
      <p>
        <b>FAQs:</b>
      </p>
      <p>
        Q. Does Fannie Mae require documenation (such as a business license search or partnership agreement) to support the
        specific date the borrower became a 25% or greater owner in their business?<br>
		A. Generally, Fannie Mae does not require additional documentation to support the specific date the borrower became a 25% or greater owner in their business.
        However, because this date is an important policy consideration, the lender must ensure the loan file contains documentation to resolve any inconsistencies
        among the employment dates provided on the loan application (Form 1003), the tax returns, and any other documents in the loan file.
      </p>
      <p>
        Q. When does the five (5) year period begin? Is it tax years or calendar years?<br>
		A. Five (5) years from the date on the Uniform Residential Loan Application (Form 1003).
      </p>
        Q.  Can you provide some examples of ownership share changes and show the information I should provide to {{ appName }}?<br>
		A. In the past five (5) years, if the borrower's share changed from:<br><ul><li>
		zero (0% - no ownership) to 25% or higher; select "No"</li><li>
		6.25% to 25% or higher; select "No"</li><li>
		24% to 25% or higher; select "No"</li><li>
		5% to 10% to 15% to 25% or higher; select "No"</li><li>
		25% to 26% or higher; select "Yes"</li><li>
		100% to 50%; select "Yes"</li><li>
		50% to 33% to 25%; select "Yes"</li></ul>
    </se-help>

    <se-help :visible.sync="showHelpEmploymentOwnershipRecentAcquisitionIndicator">
      <p>
        <b>FAQs:</b>
      </p>
      <p>
        Q. Why is this information important to {{ appName }}?<br>
		A. {{ appName }} uses this information to determine if the borrower may be eligible for the exception to the
        two year requirement of receiving Guaranteed Payments to Partners described in the Fannie Mae Selling Guide.
      </p>
      <p>
      <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
      Learn more about Schedule K-1 income (including Guaranteed Payments to Partners) in
        the<a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.1-09/other-sources-income"> Other Sources of Income</a> section of the Fannie Mae Selling Guide.
      </p>
      <p>
        Q. What does it mean "the borrower <b>acquired</b> their ownership share"?<br>
		A. Select "Yes" only if the borrower's ownership share in the business went from zero (0% - not an owner) to a less than 25% in the most recent tax year.
      </p>
        Q. The borrower started the last tax year with an ownership share in the business of less than 25% (for example 5%)
      then change to a different ownership share that is still less than 25% (for example 10%). What information should I provide to {{ appName }}?<br>
		A. Select "No" because the borrower's ownership share in the business did not start the last tax year with zero (0%) ownership share.
    </se-help>

    <se-buttons
      :display-save="false"
      :display-submit="false"
      @seNext="next"
      @sePrevious="previous">
    </se-buttons>
  </div>
  </div>

</template>
<script>
import {Checkbox, Radio, IconBook} from 'src/components/index'
import LSwitch from 'src/components/Switch.vue'
import SeButtons from './SeButtons.vue'
import {mapGetters, mapState} from 'vuex'
import Enums from 'src/model/enums'
import {
  PURGE_HIDDEN_TAX_FORMS,
  RETAIN_BUS_STRUCT_COMPATIBLE_TAX_FORMS,
  SET_EXTRA_DATA,
  SET_REQUEST_DATA,
  DESELECT_BUSINESS_FORMS_PY
} from 'src/store/actions/se'
import SeHelp from './SeHelp.vue'

import appNameMixin from '../../../mixins/AppNameMixin'
import Swal from "sweetalert2";


const allBusinessForms = [Enums.SeTaxFormType.tf1040c, Enums.SeTaxFormType.tf1065, Enums.SeTaxFormType.tf1120, Enums.SeTaxFormType.tf1120s]

export default {
  mixins:[appNameMixin],
  components: {
    IconBook,
    Radio,
    LSwitch,
    SeButtons,
    SeHelp,
  },
  data() {
    return {
      showPageHelp: false,
      showHelpBusinessStructure: false,
      showHelpSoleProprietorship: false,
      showHelpSCorp: false,
      showHelpPartnership: false,
      showHelpCorp: false,
      showHelpCorpLlc: false,
      showHelpUnknown: false,
      showHelpEmploymentOwnershipInterestProvidedType: false,
      showHelpEmploymentOwnershipFiveYearHistoryIndicator: false,
      showHelpEmploymentOwnershipRecentAcquisitionIndicator: false
    }
  },
  computed: {
    SeBusinessStructureType: () => Enums.SeBusinessStructureType,
    EmploymentOwnershipInterestProvidedType: () => Enums.EmploymentOwnershipInterestProvidedType,
    businessStructureProvided: {
      get () {  return this.seRequest.extraData.businessStructureProvided },
      set (businessStructureProvided) {
        this.$store.commit(SET_EXTRA_DATA, {businessStructureProvided})
        //unselect tax forms incompatible with business structure
        this.$store.commit(RETAIN_BUS_STRUCT_COMPATIBLE_TAX_FORMS)
      }
    },
    employmentOwnershipInterestProvided: {
      get () {  return this.seRequest.extraData.employmentOwnershipInterestProvided },
      set (employmentOwnershipInterestProvided) {
        this.$store.commit(SET_EXTRA_DATA, {employmentOwnershipInterestProvided})
        this.$store.commit(DESELECT_BUSINESS_FORMS_PY, this.getPY2)
        this.$store.dispatch(PURGE_HIDDEN_TAX_FORMS);
      }
    },
    employmentOwnershipFiveYearHistoryIndicator: {
      get () {  return this.seRequest.employmentOwnershipFiveYearHistoryIndicator },
      set (employmentOwnershipFiveYearHistoryIndicator) {
        this.$store.commit(SET_REQUEST_DATA, {employmentOwnershipFiveYearHistoryIndicator})
      }
    },
    employmentOwnershipRecentAcquisitionIndicator: {
      get () {  return this.seRequest.extraData.employmentOwnershipRecentAcquisitionIndicator },
      set (employmentOwnershipRecentAcquisitionIndicator) {
        this.$store.commit(SET_EXTRA_DATA, {employmentOwnershipRecentAcquisitionIndicator})
      }
    },
    showEmploymentOwnershipInterestProvided() {
      return this.businessStructureProvided !== Enums.SeBusinessStructureType.soleProprietorship.id
    },
    showEmploymentOwnershipFiveYearHistoryIndicator() {
      return this.getEmploymentMonthsCount >= 60
        && (this.businessStructureProvided === Enums.SeBusinessStructureType.soleProprietorship.id || this.employmentOwnershipInterestProvided === Enums.EmploymentOwnershipInterestProvidedType.gte25)
    },
    showEmploymentOwnershipRecentAcquisitionIndicator() {
      return [Enums.SeBusinessStructureType.partnership.id, Enums.SeBusinessStructureType.llc.id].includes(this.businessStructureProvided)
        && this.employmentOwnershipInterestProvided === Enums.EmploymentOwnershipInterestProvidedType.lt25
        && this.getEmploymentMonthsCount >= 24
    },

    ...mapState({
      seRequest: state => state.se.request
    }),
    ...mapGetters([
      'isDisplaySelectTaxYear',
      'isLocked',
      'getBusinessStructureProvided',
      'getEmploymentMonthsCount',
      'getPY2'
    ])
  },
  watch: {
    showEmploymentOwnershipFiveYearHistoryIndicator(show) {
      if (show) {
        if (this.employmentOwnershipFiveYearHistoryIndicator === null)
          this.employmentOwnershipFiveYearHistoryIndicator = true
      } else {
        this.employmentOwnershipFiveYearHistoryIndicator = null
      }
    },
    showEmploymentOwnershipRecentAcquisitionIndicator(show) {
      if (show) {
        if (this.employmentOwnershipRecentAcquisitionIndicator === null)
          this.employmentOwnershipRecentAcquisitionIndicator = false
      } else {
        this.employmentOwnershipRecentAcquisitionIndicator = null
      }
    }
  },
  methods: {
    next() {
      if ([Enums.SeBusinessStructureType.partnership.id, Enums.SeBusinessStructureType.sCorp.id].includes(this.businessStructureProvided)
        && this.employmentOwnershipInterestProvided === Enums.EmploymentOwnershipInterestProvidedType.lt25
        && this.getEmploymentMonthsCount < 24) {
        this.alertOwnLT25EmploymentMonthsLT24();
        return
      }
      this.doNext()
    },
    doNext() {
      this.$router.push( this.isDisplaySelectTaxYear ? 'tax-year' : 'tax-form-select')
    },
    previous() {
      this.$router.push('du-evaluation')
    },

    alertOwnLT25EmploymentMonthsLT24() {
      let msgIcon = "warning"
      let msgHtml = "<p>Based on the Employment Start Date, there is less than a 24-month history of business ownership. The Fannie Mae Selling Guide income requirements cannot be met.</p>"
      + "<p>Income Calculator will not return a positive qualifying income amount.</p>"

      Swal.fire({
        title: `WARNING!`,
        html: msgHtml,
        width: 640,
        showCancelButton: true,
        buttonsStyling: false,
        customClass: {confirmButton: 'bp-button bp-button--primary', cancelButton: 'bp-button'},
        confirmButtonText: `OK`,
        cancelButtonText: `Cancel`,
        icon: msgIcon
      }).then((result) => {
        if (result.isConfirmed) {
          this.doNext()
        }
      })
    },

  }
}
</script>
<style>



</style>
