<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       :viewBox="viewBox"
       :aria-labelledby="iconName"
  >
    <title :id="iconName" lang="en">{{iconName}}</title>
    <defs>
      <path d="M8,0 C12.431891,0 16,3.56810897 16,8 C16,12.431891 12.431891,16 8,16 C3.56810897,16 0,12.431891 0,8 C0,3.56810897 3.56810897,0 8,0 Z M8,14.5 C11.5898509,14.5 14.5,11.5898509 14.5,8 C14.5,4.41014913 11.5898509,1.5 8,1.5 C4.41014913,1.5 1.5,4.41014913 1.5,8 C1.5,11.5898509 4.41014913,14.5 8,14.5 Z M12.4945821,5.43275636 L11.7317614,4.61325139 C11.5912042,4.46224954 11.3634539,4.46224954 11.2233731,4.61325139 L6.65121373,9.52516249 L4.47615041,7.17209294 C4.3355932,7.02109109 4.10831933,7.02109109 3.96776212,7.17209294 L3.20541791,7.99159791 C3.0648607,8.14259976 3.0648607,8.38727395 3.20541791,8.53776393 L6.39439903,11.9867486 C6.53495624,12.1377505 6.76223011,12.1377505 6.90278732,11.9867486 L12.4945821,5.97892238 C12.6351393,5.8284324 12.6351393,5.58375821 12.4945821,5.43275636 Z" id="complete-path-1"></path>
    </defs>
    <g id="complete-V2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="complete-Tax-Forms---1040" transform="translate(-40.000000, -328.000000)">
        <g id="complete-.-Navigation/Stepper/Vertical/Sample" transform="translate(40.000000, 88.000000)">
          <g id="complete-👁-Icon" transform="translate(0.000000, 240.000000)">
            <mask id="complete-mask-2" fill="white">
              <use xlink:href="#complete-path-1"></use>
            </mask>
            <g id="complete-Icon/feedback-attention"></g>
            <rect id="complete-Icon-Color" fill="#098620" mask="url(#complete-mask-2)" x="0" y="0" width="16" height="16"></rect>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    viewBox: { type: String, default: '0 0 24 24'},
    iconName: { type: String, default: ''},
    width: { type: [Number, String], default: 18 },
    height: {type: [Number, String], default: 18 },
    iconColor: {type: String, default: 'currentColor'}
  }
}
</script>
