<template>
  <div>
    <div class="container">

    <h1 class="se-bp-title">
          Desktop Underwriter (DU)
      <a class="btn-link se-page-help-icon" v-tooltip.top-center="'Learn More'" @click="showPageHelp = true">
        <i class="fa fa-info-circle"></i>
      </a>
    </h1>

    <form class="form-horizontal" autocomplete="off">
       <h4 class="mt-4">Associate this {{ appName }} evaluation to an existing Desktop Underwriter Casefile ID (optional)
          <a class="btn-link se-help-icon" v-tooltip.top-center="'Learn More'" @click="showAssociateDuHelp = true">
            <i class="fa fa-info-circle"></i>
          </a>
        </h4>

      <div class="ml-3">
        <div class="row">
            <fg-input class="mt-3 mb-0"
                      label="Desktop Underwriter Casefile ID"
                      type="text"
                      :optional=true
                      ref="candidateCaseFileId"
                      :readonly="!!duCaseFileId || (isLocked && externalMode)"
                      v-model="candidateCaseFileId"
                      :showHelpIcon=false>
            </fg-input>
            <div class="col-auto mt-4 pt-3" v-if="duCaseFileId">
              <a @click="deAssociateCaseFile" v-tooltip.top-center="'Remove association with this casefile ID'" v-if="!isLocked || !externalMode">
                <icon-base width="32" height="32" iconColor="#0c77ba" icon-name="trashcan"><icon-trash/></icon-base>
              </a>
              <template v-if="!seRequest.duData || !seRequest.duData.associationSuccessIndicator">
                <span class="ml-5"><icon-base class="mr-1 mb-1" width="24" height="24" iconColor="rgba(189, 43, 43, 1)" icon-name="checkMark"><icon-warning/></icon-base>Failed to Associate to Casefile ID</span>
              </template>
              <template v-else-if="seRequest.duData.automatedUnderwritingSubmissionDate">
                <span class="ml-2">{{seRequest.duData.automatedUnderwritingSubmissionDate}}</span><span class="ml-2">(Date of Desktop Underwriter Submission)</span>
              </template>
            </div>
        </div>

        <template v-if="candidateCaseFileId && !duCaseFileId">
          <div class="row mt-5">
              <fg-input class=""
                        inputClasses="se-tax-field-input"
                        label="What is the 5-digit or 9-digit ZIP Code for the borrower’s current residence provided in the DU submission? "
                        type="text"
                        v-model="duResidencyPostalCode"
                        @show-help="showHelpDuResidencyPostalCode = true">

                <template slot="infoBlock">
                  <div class="bp-help is-error" v-if="$v.duResidencyPostalCode.$error">
                    <div class="bp-ucon" data-src="feedback-warning"><svg class="bp-svg" viewBox="0 0 24 24" id="feedback-warning" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M13.009 13.987V8.934h-1.956v5.053h1.956zm0 4.016v-1.962h-1.956v1.962h1.956zM2.988 20.962L12.016 3l9.025 17.962H2.988z"></path></svg></div>
                    <template v-if="!$v.duResidencyPostalCode.required">Answer is required</template>
                  </div>
                </template>
              </fg-input>
          </div>

          <div class="row">
            <div>
              <h4 class="mt-1">What is the borrower’s employment start date provided in the DU submission?
                <a class="btn-link se-help-icon" v-tooltip.top-center="'Learn More'" @click="showHelpDuStartDate = true">
                  <i class="fa fa-info-circle"></i>
                </a>
              </h4>
            </div>
          </div>

          <div class="row">
            <masked-input
              type="text"
              class="se-tax-field-input bp-form-input bp-u-shadow-04"
              readonly="true"
              placeholder="MM/DD/YYYY"
              :mask="[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]"
              :guide="false"
              :pipe="dateMaskPipe"
              v-model.lazy="duEmploymentStartDate">
            </masked-input>
          </div>

          <div class="row">
            <div class="bp-help is-error mt-2" v-if="$v.duEmploymentStartDate.$error">
              <div class="bp-ucon" data-src="feedback-warning"><svg class="bp-svg" viewBox="0 0 24 24" id="feedback-warning" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M13.009 13.987V8.934h-1.956v5.053h1.956zm0 4.016v-1.962h-1.956v1.962h1.956zM2.988 20.962L12.016 3l9.025 17.962H2.988z"></path></svg></div>
              <template v-if="!$v.duEmploymentStartDate.isProvided">Provide Employment Start Date on Length of Employment screen</template>
            </div>
          </div>


          <template v-if="requestIncomeAmount">

            <div class="row mt-4">
            <h4 class="mt-4">Desktop Underwriter contains more than one self-employment income.
              <a class="btn-link se-help-icon" v-tooltip.top-center="'Learn More'" @click="showMultiIncomeAssociateDuHelp = true">
                <i class="fa fa-info-circle"></i>
              </a>
            </h4>
            </div>
            <div class="row">
              Specify the requested association:
            </div>

            <div class="row mt-4">
            <div>
              <h4 class="mt-1">Associate by income amount
                <a class="btn-link se-help-icon" v-tooltip.top-center="'Learn More'" @click="showHelpDuStartDate = true">
                  <i class="fa fa-info-circle"></i>
                </a>
              </h4>
            </div>
          </div>
          <div class="row">
            Associate this {{ appName }} evaluation to the self-employment in Desktop Underwriter with income of
          </div>
          <div class="row mt-1">
            <input class="se-tax-field-input bp-form-input bp-u-shadow-04"
                 type="text"
                 v-mask="currencyMask"
                 v-model="duIncomeAmount" />
          </div>
          <div class="row">
            <div class="bp-help is-error mt-2" v-if="$v.duIncomeAmount.$error">
              <div class="bp-ucon" data-src="feedback-warning"><svg class="bp-svg" viewBox="0 0 24 24" id="feedback-warning" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M13.009 13.987V8.934h-1.956v5.053h1.956zm0 4.016v-1.962h-1.956v1.962h1.956zM2.988 20.962L12.016 3l9.025 17.962H2.988z"></path></svg></div>
              <template v-if="!$v.duIncomeAmount.required">Answer is required</template>
            </div>
          </div>
          </template>

        </template>
      </div>
    </form>

    <se-help :visible.sync="showPageHelp" title="Learn More">
      <p>
        Use this screen to provide relevant Desktop Underwriter (DU) information.
      </p>
      <p>
        <icon-base width="22" height="22" iconColor="#0c77ba" icon-name="icon-right-arrow"><icon-arrow-right/></icon-base>
        If the loan has been evaluated by Desktop Underwriter (DU), you can <b>associate</b> (link) or
        disassociate (unlink) this {{ appName }} evaluation to the DU Casefile ID.
      </p>
      <br>
      <p>
        <b>FAQs:</b><br>
        Q. Is {{ appName }} the same as Desktop Underwriter (DU)?<br>
        A. No. {{ appName }} focuses only on calculation of qualifying income,
        whereas Desktop Underwriter (DU) provides an eligibility and risk assessment for the entire loan.
      </p>
      <p>
        Q. Do I need to associate (link) a DU Casefile ID to obtain the {{ appName }} response?<br>
        A. No. Linking a DU Casefile ID is optional.
      </p>
      <p>
      Q. Do I need to associate (link) a DU Casefile ID to obtain the enforcement relief from representations and warranties
        related to the income calculation available with {{ appName }}?<br>
        A. Yes. Linking the DU Casefile ID used for loan delivery is <b>required</b> to obtain the relief from enforcement of representations and
        warranties for income calculation available with {{ appName }}. For manually underwritten loans, provide the DU Casefile ID generated
        by the UCD Collection Solution.
      </p>
      <a href="#" @click="showAssociateDuHelp = true">More DU Casefile ID association help</a>
    </se-help>

    <se-help :visible.sync="showUnderwrittenByDuHelp" title="Learn More">
      <p>
        TBD
      </p>
    </se-help>

    <se-help :visible.sync="showAssociateDuHelp" title="" size="50%">
      <p>
        <icon-base width="22" height="22" iconColor="#0c77ba" icon-name="icon-right-arrow"><icon-arrow-right/></icon-base>
        Enter a valid <b>DU Casefile ID</b> to associate (link) it to your {{ appName }} data. {{ appName }} will
        retrieve loan data from Desktop Underwriter (DU) and may use it to populate some of the {{ appName }} information.
      </p>
      <p>
        <icon-base width="22" height="22" iconColor="#0c77ba" icon-name="icon-right-arrow"><icon-arrow-right/></icon-base>
        Use the trashcan icon to <b>remove the association</b> (unlink).
      </p>

      <p>
        <b>FAQs:</b><br>
        Q. Do I need to associate (link) a DU Casefile ID to obtain the enforcement relief from
        representations and warranties related to the income calculation available with {{ appName }}?  <br>
        A. Yes. Linking the DU Casefile ID used for loan delivery is <b>required</b> to obtain the relief from enforcement of
        representations and warranties for income calculation available with {{ appName }}. For manually underwritten loans,
        provide the DU Casefile ID generated by the UCD Collection Solution.
      </p>
      <p>
        Q. I haven’t submitted the loan to Desktop Underwriter (DU) yet. Can I associate the DU Casefile ID later?<br>
        A. Yes. You can update your {{ appName }} data to add the DU Casefile ID.
      </p>
      <p>
        Q. Can I associate a {{ appName }} evaluation to more than one DU Casefile ID?<br>
        A. No. Each {{ appName }} evaluation can only be associated to a single DU Casefile ID. If multiple
        borrowers share ownership in the same business, a borrower has multiple self-employed businesses, or
        a borrower has multiple mortgage applications being processed at the same time; {{ appName }} data
        can be cloned (copied) from the Start screen to avoid manual reentry of tax return data.
      </p>
      <p>
        Q. Can I associate more than one {{ appName }} evaluation to a DU Casefile ID?<br>
        A. Yes. A DU Casefile ID can have multiple {{ appName }} associations.
        For example, loans with more than one self-employed borrower or loans where one borrower has multiple
        self-employment income sources could have more than one {{ appName }} evaluation associated to
        a single DU Casefile ID.
      </p>
      <p>
        Q. If I remove the association, can I reassociate the same DU Casefile ID
        or a different DU Casefile ID later?<br>
        A. Yes. You can associate, disassociate, and reassociate the same or different DU Casefile IDs.
      </p>
      <p>
        Q. When a DU Casefile ID is associated, what kind of data does {{ appName }} retrieve from Desktop Underwriter (DU)?<br>
        A. {{ appName }} retrieves selected Desktop Underwriter (DU) loan and income data,
        such as:  income documentation messages, employment start date for any
        self-employed borrower, and mortgage type (e.g. conventional).
      </p>
      <p>
        Q. If I associate (link) a {{ appName }} evaluation to a DU Casefile ID, and then
        I change data in Desktop Underwriter (DU), is {{ appName }} automatically updated?<br>
        A. Yes. {{ appName }} retrieves the most recent Desktop Underwriter (DU) submission data when it is associated
        (linked) and it is refreshed whenever the evaluation is resubmitted.
      </p>
      <p>
        Q. Does{{ appName }} check that the correct DU Casefile ID has been associated?<br>
        A. No. Warning messages may be generated in the {{ appName }} Findings Report if conflicting data is identified.
      </p>
      <p>
        Q. Do I need to submit the loan to Desktop Underwriter (DU) before I submit the income information to {{ appName }}?<br>
        A. No. {{ appName }} can be used before or after the loan has been submitted to Desktop Underwriter (DU).
      </p>
      <p>
        Q. If I provide one year of tax return data in my {{ appName }} evaluation,
        and Desktop Underwriter (DU) has returned messaging requiring two years tax returns, what happens?<br>
        A. For DU Casefile IDs created in 2024, if the borrower's primary employment is from self-employment, and DU has returned
        messaging requiring two years tax returns, {{ appName }} will generate Findings Report messages requiring two years tax return
        data to perform its evaluation. For all other scenarios, {{ appName }} will attempt to determine if the employment history
        permits the income to be documented using one year of tax return data as described in the October 4, 2023 Fannie Mae Selling
        Guide announcement, and will generate Findings Report messages as appropriate. <b>Desktop Underwriter (DU) does not issue any
        messages based on {{ appName }} data</b>.
      </p>
      <p>
        Q. If I provide one year of tax return data in my {{ appName }} evaluation, and the loan has not yet been submitted to Desktop Underwriter (DU) what happens?<br>
        A. {{ appName }} will attempt to determine if the employment history permits the income to be documented using one year of tax return data as described Fannie
        Mae Selling Guide and will generate Findings Report messages as appropriate. Desktop Underwriter (DU) does not issue any messages based on {{ appName }}
        data.
      </p>
    </se-help>

    <se-help :visible.sync="showNumberYearsHelp" title="Learn More">
      <p>
        TBD
      </p>
    </se-help>

    <se-help :visible.sync="showCasefile" title="">
      <p>
        <b>FAQs:</b><br>
        Q. Do I need to associate (link) a Desktop Underwriter Casefile ID to obtain the representation and warranty
        relief for income calculation available with {{ appName }}? <br>
        A. Yes. Linking a Desktop Underwriter Casefile ID is <b>required</b> to obtain the representation and warranty
        relief for income calculation available with {{ appName }}.
      </p>
      <p>
        Q. I haven’t submitted the loan to Desktop Underwriter yet. Can I associate the Desktop Underwriter Casefile ID later?<br>
        A. Yes. You can update your {{ appName }} data to add the Desktop Underwriter Casefile ID.
      </p>
      <p>
        Q. Can I associate more than one DU Casefile ID to a {{ appName }} evaluation?<br>
        A. No. Each {{ appName }} can only be associated to a single Desktop Underwriter Casefile ID.
      </p>
      <p>
        Q. Can I associate more than one {{ appName }} evaluation to a DU Casefile ID?<br>
        A. Yes. A Desktop Underwriter Casefile ID can have multiple {{ appName }} associations. For example, loans with more than one self-employed borrower or loans where one borrower has multiple self-employments would have multiple associations.
    </p><p>
        Q. If I remove the association, can I reassociate the same Desktop Underwriter Casefile ID
      or a different Desktop Underwriter Casefile ID later?<br>
        A. Yes. You can associate, disassociate, and reassociate the same or different DU Casefile IDs.
    </p><p>
        Q. What kind of data does {{ appName }} retrieve from Desktop Underwriter?<br>
        A. The income documentation messages, employment start date for any self-employed borrower, and whether the self-employment is associated to a primary or secondary (part-time) job. {{ appName }} does not retrieve personally identifying information (such as the borrower’s name or social security number).
      </p>
      <p>
        Q. If I associate (link) a Desktop Underwriter Casefile ID to Desktop Underwriter,
      and then the Desktop Underwriter information changes, is {{ appName }} automatically updated?<br>
        A. No. {{ appName }} retrieves data from Desktop Underwriter when it is associated (linked).
      You can refresh the associated Desktop Underwriter data by disassociating, then reassociating the Desktop Underwriter Casefile ID.
      </p>
      <p>
        Q. Does {{ appName }} check that the correct Desktop Underwriter Casefile ID has been associated?<br>
        A. No. {{ appName }} does not collect the borrower’s name or social security number, limiting the ability to validate the correct Desktop Underwriter Casefile ID has been associated. Warning messages will be generated in the {{ appName }} Findings Report if data conflicts are identified.
    </p><p>
        Q. Do I need to submit the loan to Desktop Underwriter before I submit the income information to {{ appName }}?<br>
        A. No. {{ appName }} can be used before or after the loan has been submitted to Desktop Underwriter.
    </p><p>
      Q. What are the advantages of submitting the loan to Desktop Underwriter <b>before</b> using {{ appName }}?<br>
        A. The Desktop Underwriter evaluation may result in messaging permitting the collection of only one year’s tax returns. This may reduce the amount of tax return data you choose to enter into {{ appName }}. However, this requires you to estimate the monthly qualifying income amount in the initial Desktop Underwriter submission. Once {{ appName }} generates its monthly qualify income amount, you may need to resubmit the loan to Desktop Underwriter using {{ appName }}’s income amount.
    </p><p>
      Q. What are the advantages of submitting the loan to Desktop Underwriter <b>after</b> using {{ appName }}?<br>
        A. If you submit the loan to Desktop Underwriter after using {{ appName }} you will be able to use the monthly qualifying income generated by {{ appName }} in your Desktop Underwriter submission; not use an estimate. However, without the Desktop Underwriter messaging, you will not know if reduced income documentation is acceptable.
      </p>
      <p>
        Q. If I choose to use one year of tax return data in my {{ appName }} evaluation,
      and Desktop Underwriter has returned messaging requiring two years tax returns, what happens?<br>
      A. If you use one year of tax return data in your {{ appName }} evaluation you <b>must</b>
      validate that Desktop Underwriter generated messaging permitting income documentation using one
      year of tax returns. {{ appName }} will issue on-screen warnings and Findings Report
      messages to remind you to perform this validation. <b>Desktop Underwriter does not currently issue
      any messages based on {{ appName }} data.</b> If the loan’s income is calculated using
      documentation that does not meet the Selling Guide standards (including applicable Desktop Underwriter flexibilities),
      the loan is subject to repurchase.
      </p>
      <p>
        Q. Are there plans to create a more seamless {{ appName }} Desktop Underwriter experience?<br>
        A. Yes. In the future Fannie Mae intends to create a more seamless user experience.
      </p>
    </se-help>



    <se-buttons
      :display-save="false"
      :display-submit="false"
      :is-loading="isLoading"
      @seNext="next"
      @sePrevious="previous">
    </se-buttons>
  </div>
  </div>

</template>
<script>
import {Radio, IconTrash, IconArrowRight, IconWarning} from 'src/components/index'
import LSwitch from 'src/components/Switch.vue'
import SeButtons from './SeButtons.vue'
import Swal from 'sweetalert2'
import {mapGetters, mapState} from 'vuex'
import Enums from 'src/model/enums'
import {
  SET_REQUEST_DATA,
  CREATE_CASEFILE_ASSOCIATION,
  REMOVE_CASEFILE_ASSOCIATION,
  SAVE_SE_REQUEST
} from 'src/store/actions/se'
import SeHelp from './SeHelp.vue'
import {required, requiredIf} from 'vuelidate/lib/validators'
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";
import { isValidDate } from 'src/util/seutil'
import {format} from "date-fns";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import appNameMixin from '../../../mixins/AppNameMixin'


const currMaskType = createNumberMask({
  prefix: '$',
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: true,
  integerLimit: 9
});


export default {
    mixins:[appNameMixin],
  components: {
    IconArrowRight,
    Radio,
    LSwitch,
    SeButtons,
    SeHelp,
    IconTrash,
    IconWarning
  },
  data() {
    return {
      candidateCaseFileId: "",
      showPageHelp: false,
      showUnderwrittenByDuHelp: false,
      showAssociateDuHelp: false,
      showMultiIncomeAssociateDuHelp: false,
      showNumberYearsHelp: false,
      isLoading: false,
      showCasefile: false,
      duResidencyPostalCode: '',
      duEmploymentStartDate: '',
      showHelpDuResidencyPostalCode: false,
      showHelpDuStartDate: false,
      requestIncomeAmount: false,
      duIncomeAmount: '',
      currencyMask: currMaskType,
    }
  },
  validations: {
    duResidencyPostalCode: {
      required: requiredIf( function () { return !!this.candidateCaseFileId && !this.duCaseFileId })
    },
    duEmploymentStartDate: {
      isProvided(value) {
        return this.duCaseFileId || !this.candidateCaseFileId || (value && isValidDate(value))
      }
    },
    duIncomeAmount: {
      required: requiredIf( function () { return !!this.candidateCaseFileId && !this.duCaseFileId && this.requestIncomeAmount })
    },
  },
  mounted() {
    this.candidateCaseFileId = this.duCaseFileId
  },
  created() {
    this.duEmploymentStartDate = this.seRequest.extraData.employmentStartDateProvided
  },
  computed: {
    SeEvalRequestYearsType: () => Enums.SeEvalRequestYearsType,
    duCaseFileId: {
      get () {  return this.seRequest.duCaseFileId },
      set (duCaseFileId) { this.$store.commit(SET_REQUEST_DATA, {duCaseFileId})  }
    },
    ...mapState({
        seRequest: state => state.se.request,
        externalMode: state => state.se.externalMode
    }),
    ...mapGetters([
      'isLocked'
    ]),
    dateMaskPipe() {
      return createAutoCorrectedDatePipe('mm/dd/yyyy', {
        minYear: 1950,
        maxYear: parseInt(this.getPY1)
      })
    },
  },
  methods: {
    next() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      //new candidate casefileId was entered
      if (this.candidateCaseFileId && this.candidateCaseFileId.length > 0 && !this.seRequest.duCaseFileId) {
        this.associateCaseFile()
      } else {
        this.$router.push('business-structure')
      }
    },

    previous() {
      this.$router.push('/se-eval/business-length')
    },

    async associateCaseFile() {
      try {
        let employmentStartDate = this.duEmploymentStartDate ? format(new Date(this.duEmploymentStartDate), "yyyy-MM-dd") : this.duEmploymentStartDate

        //need to save to ensure we have request Id
        await this.$store.dispatch(SAVE_SE_REQUEST);
        await this.$store.dispatch(CREATE_CASEFILE_ASSOCIATION,
          {
            casefileId: this.candidateCaseFileId,
            zip: this.duResidencyPostalCode,
            employmentStartDate: employmentStartDate,
            incomeAmount: this.duIncomeAmount.replace(/,|\$|\%/g, '') ?? null
          });

        await this.$router.push('business-structure')

      } catch ( resp ) {

        let msg = `Unable to complete association to Desktop Underwriter Casefile ID <b>${this.candidateCaseFileId}</b>.`
        if (1401 == resp.data?.errorCode) {
          this.requestIncomeAmount = true
          return

        } else if ([400, 429].includes(resp.data?.errorCode)) {
          msg = `${resp.data.errorMessage} <b>${this.candidateCaseFileId}</b>.`
        }

        await Swal.fire({
          title: `Not found`,
          icon: 'error',
          buttonsStyling: false,
          customClass: {confirmButton: 'bp-button bp-button--primary'},
          html: msg
        })
      }
    },

    async deAssociateCaseFile() {
      const { value: isConfirmed } = await Swal.fire({
        title: 'Are you sure?',
        text: `Remove the association with casefile ID ${this.candidateCaseFileId}`,
        icon: 'warning',
        buttonsStyling: false,
        customClass: { confirmButton: 'bp-button bp-button--primary', cancelButton: 'bp-button'},
        showCancelButton: true,
        confirmButtonText: 'Remove Association'
      })
      if (isConfirmed) {
        try {
          await this.$store.dispatch(REMOVE_CASEFILE_ASSOCIATION);

          this.candidateCaseFileId = this.duCaseFileId;
          await Swal.fire({
              title: 'Removed!',
              text: `Association with casefile has been removed`,
              icon: 'success',
              buttonsStyling: false,
              customClass: { confirmButton: 'bp-button bp-button--primary' }
            }
          )

        } catch (e) {
          await Swal.fire({
            title: `Error`,
            buttonsStyling: false,
            customClass: {confirmButton: 'bp-button bp-button--primary'},
            html:
              `Could not remove the association with casefile.`
          })
        }
      }
    },
  }
}
</script>
<style>


</style>
