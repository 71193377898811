import Enums from "../../../../model/enums";
import {ty} from "./ty";

export default {
  id: Enums.SeTaxFormType.tf1040sr,
  pdf: `static/forms/${ty}/${ty}_1040sr.pdf`,
  route: `/se-eval/std-tax-form-1040/${Enums.SeTaxFormType.tf1040sr}/${ty}`,
  fields: [
    {
      id: "wagesSalaries",
      fieldName: "topmostSubform[0].Page1[0].f1_28[0]",
      label: "Wages, Salaries, Tips, etc.",
      line: "Line 1",
      isCurrency: true,
      isPositive: true,
      hasHelp: true
    },
    {
      id: "otherIncomeLoss",
      fieldName: "topmostSubform[0].Page1[0].f1_40[0]",
      label: "Other Income Schedule 1",
      line: "Line 8",
      isCurrency: true,
      isPositive: false,
      required: true,
      hasHelp: true
    },
    {
      id: "totalIncome",
      fieldName: "topmostSubform[0].Page1[0].f1_41[0]",
      label: "Total Income",
      line: "Line 9",
      isCurrency: true,
      isPositive: false,
      hasHelp: true
    },
    {
      id: "adjustedGrossIncome",
      fieldName: "topmostSubform[0].Page1[0].f1_45[0]",
      label: "Adjusted Gross Income",
      line: "Line 11",
      isCurrency: true,
      isPositive: false,
      hasHelp: true
    },
    {
      id: "taxableIncome",
      fieldName: "topmostSubform[0].Page2[0].f2_04[0]",
      label: "Taxable Income",
      line: "Line 15",
      isCurrency: true,
      isPositive: false,
      hasHelp: true
    }
  ]
}
