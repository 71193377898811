import Enums from "../../../../model/enums";
import {ty} from "./ty";

export default {
  id: Enums.SeTaxFormType.tf1065sm3,
  waivable: true,
  pdf: `static/forms/${ty}/${ty}_1065sm3.pdf`,
  route: `/se-eval/std-tax-form-1065sm3/${Enums.SeTaxFormType.tf1065sm3}/${ty}`,
  fields: [
    {
      id: "mealsAndEntertainment",
      fieldName: "topmostSubform[0].Page3[0].Table_Part3[0].Line6[0].f3_25[0]",
      label: "Meals and entertainment. Permanent difference",
      line: "Line 6(c)",
      isCurrency: true,
      isPositive: true,
      required: false,
      hasHelp: true,
      pageHeader: "Find Part III of Schedule M-3 (page 3)"
    }
  ]
}

