import Enums from "../../../../model/enums";
import {ty} from "./ty";

export default {
  id: Enums.SeTaxFormType.tfw2,
  pdf: `static/forms/${ty}/${ty}_w2.pdf`,
  route: `/se-eval/std-tax-form-w2/${Enums.SeTaxFormType.tfw2}/${ty}`,
  fields: [
    {
      id: "w2Wages",
      fieldName: "Wages",
      label: "Wages, tips, other compensation",
      line: "Line 1",
      isCurrency: true,
      isPositive: true,
      isEmptyByDefault: true,
      hasHelp: true
    },
    {
      id: "w2MedicareWages",
      fieldName: "MedicareWages",
      label: "Medicare wages and tips",
      line: "Line 5",
      isCurrency: true,
      isPositive: true,
      isEmptyByDefault: true,
      hasHelp: true
    }
  ]
}

