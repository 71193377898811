<template>
  <div id="webviewer" ref="viewer"></div>
</template>

<script>
import { ref, onMounted } from 'vue';
import WebViewer from '@pdftron/webviewer';
import { getInstance } from '@pdftron/webviewer'
import {mapState} from "vuex";

export default {
  name: 'WebViewer',
  props: {
    url: String,
    seFields: {},
    taxFormYear: String,
    taxFormId: String,
    pageNumber: {
      type: Number,
      default: 1
    },
    verticalPosition: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      fieldManager: null,
      highlightedFieldNames: [],
      viewerLoaded: false
    }
  },

  mounted: function () {

    const path = `${process.env.BASE_URL}webviewer`;
    WebViewer({
      licenseKey: "Federal National Mortgage Association(fanniemae.com):OEM:Self Employment Calculator::B+:AMS(20221005):D3A5A16204A7360A7360B13AC9A2537860613FC51E785A25062C45CA5CE508F642A2B6F5C7",
      path,
      css: 'static/css/webviewer.css',
      disabledElements: [
        'viewControlsOverlay',
        'viewControlsButton',
        'ribbons',
        'toolsHeader',
        'leftPanelButton',
        'selectToolButton',
        'searchButton',
        'toggleNotesButton',
        'menuButton',
      ],
      isReadOnly: false,
      preloadWorker: "pdf",
      backendType: "ems",
      initialDoc: this.url, // replace with your own PDF file
    }, this.$refs.viewer).then((instance) => {

      const { docViewer, annotManager, Annotations, CoreControls } = instance;
      var FitMode = instance.FitMode;
      instance.setFitMode(FitMode.FitWidth);

      //download substitute fonts from the DUIU server
      if (location.hostname != "localhost") {
        CoreControls.setCustomFontURL(`${location.origin}/static/fonts/pdf/`);
      }

      const isFieldEditable = (fieldName) => {
        return this.seFields.some(f => f.fieldName === fieldName && !f.staticText);
      };

      const getFieldStaticText = (fieldName) => {
        let staticFields = this.seFields.filter(f => f.fieldName === fieldName && f.staticText);
        if (staticFields.length > 0) {
          return staticFields[0];
        }
        return null;
      };

      docViewer.on('documentLoaded', () => {
        // console.log(`pdf ${this.url} is loaded`);
      });

      const createInnerElement = Annotations.TextWidgetAnnotation.prototype.createInnerElement;
      Annotations.TextWidgetAnnotation.prototype.createInnerElement = function () {
        let element = createInnerElement.apply(this, arguments);
        if (isFieldEditable(this.fieldName)) {
          element.onchange = (e) => {
            // updateState(this.fieldName, e.target.value);
          };
        } else {
          element.tabIndex = -1;
        }
        return element;
      };

      docViewer.on('annotationsLoaded', () => {
        this.fieldManager = annotManager.getFieldManager();
        const annots = annotManager.getAnnotationsList();
        const widgetAnnots = annots.filter(a => a instanceof Annotations.WidgetAnnotation)

        widgetAnnots.forEach(a => {
            a.fieldFlags.set('ReadOnly', true);

            let staticFieldText = getFieldStaticText(a.fieldName);
            if (staticFieldText) {
              this.setValue(a.fieldName, staticFieldText.value)
            }
          }
        )

        docViewer.displayPageLocation(this.pageNumber, 0, this.verticalPosition);
        this.viewerLoaded = true
        this.$emit('viewerLoaded')
      });

      Annotations.WidgetAnnotation.getCustomStyles = widget => {
        if (widget instanceof Annotations.TextWidgetAnnotation) {
          // can check widget properties
          if (this.highlightedFieldNames.includes(widget.fieldName)) {
            return {
              'background-color': 'yellow'
            };
          }
          // return {
          //   'background-color': 'lightgrey',
          //   color: 'brown'
          // };
        } else if (widget instanceof Annotations.PushButtonWidgetAnnotation) {
          return {
            'background-color': 'black',
            color: 'white'
          };
        } else if (widget instanceof Annotations.CheckButtonWidgetAnnotation) {
          if (this.highlightedFieldNames.includes(widget.fieldName)) {
            return {
              'background-color': 'yellow',
              'outline': 'yellow solid 5px'
            };
          }
          return {
            'outline': 'none'
          };
        }
      };

    });
  },
  computed: {
    ...mapState({
      seRequest: state => state.se.request
    })
  },
  methods: {
    setValue(fieldId, value) {
      if (this.fieldManager) {
        const field = this.fieldManager.getField(fieldId);
        field.setValue(value);
      }
    },

    highlightField(fieldNames, scrollToPage, posY) {
      this.highlightedFieldNames = fieldNames;
      if (this.viewerLoaded) {
        const instance = getInstance(this.$refs.viewer);
        const {docViewer, annotManager, Annotations} = instance;

        const annots = annotManager.getAnnotationsList();
        const widgetAnnots = annots.filter(a => a instanceof Annotations.WidgetAnnotation)

        const isRedrawAnnots = (fieldName) => {
          return this.seFields.some(f => f.fieldName === fieldName);
        };

        let annotsToRedraw = widgetAnnots.filter(a => isRedrawAnnots(a.fieldName));
        annotManager.showAnnotations(annotsToRedraw);
        docViewer.displayPageLocation(scrollToPage, 0, posY);
      }
    },

  }

};
</script>

<style>
#webviewer {
  height: 100%;
}
</style>
