import Enums from "../../../../model/enums";
import {ty} from "./ty";

export default {
  id: Enums.SeTaxFormType.tf4562,
  waivable: true,
  parts: [
    {
      part: "1",
      pdf: `static/forms/${ty}/${ty}_4562pg1.pdf`,
      route: `/se-eval/std-tax-form-4562/${Enums.SeTaxFormType.tf4562}/${ty}/1`,
      fields: [
        {
          id: "depreciationDeduction",
          fieldName: "topmostSubform[0].Page2[0].f2_53[0]",
          label: "Total column (h)",
          line: "Line 28",
          isCurrency: false,
          isEmptyByDefault: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "totalBusinessMilesDriven",
          fieldName: "topmostSubform[0].Page2[0].Table_Ln30-33[0].Line30[0].f2_55[0]",
          fieldHighlights: [
            "topmostSubform[0].Page2[0].Table_Ln30-33[0].Line30[0].f2_55[0]",
            "topmostSubform[0].Page2[0].Table_Ln30-33[0].Line30[0].f2_56[0]",
            "topmostSubform[0].Page2[0].Table_Ln30-33[0].Line30[0].f2_57[0]",
            "topmostSubform[0].Page2[0].Table_Ln30-33[0].Line30[0].f2_58[0]",
            "topmostSubform[0].Page2[0].Table_Ln30-33[0].Line30[0].f2_59[0]",
            "topmostSubform[0].Page2[0].Table_Ln30-33[0].Line30[0].f2_60[0]"
          ],
          label: "Add the miles shown from Line 30, columns (a), (b), (c), (d), (e), and (f)",
          line: "Line 30",
          isCurrency: false,
          isEmptyByDefault: true,
          isInteger: true,
          hasHelp: true
        }
      ]
    },
    {
      part: "2",
      pdf: `static/forms/${ty}/${ty}_4562pg2.pdf`,
      route: `/se-eval/std-tax-form-4562/${Enums.SeTaxFormType.tf4562}/${ty}/2`,
      fields: [
        {
          id: "amortization4562",
          fieldName: "topmostSubform[0].Page2[0].f2_80[0]",
          label: "Total Amortization",
          line: "Line 44",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        }
      ]
    }
  ]
}
