<template>

    <el-drawer
      :title="title"
      :visible="this.visible"
      @close="hide"
      custom-class="se-help"
      size="50%"
      direction="rtl">

      <WebViewer url="static/img/SoftwareLicenseAgreement.pdf"/>
    </el-drawer>

</template>
<script>
import {Drawer} from 'element-ui'
import WebViewer from 'src/components/WebViewerRO.vue'


export default {
  components: {
    [Drawer.name]: Drawer,
    WebViewer
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: "Why is this important to Income Calculator?"
    }
  },
  data() {
    return {
      vis: this.visible
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.$emit('open');
      } else {
        if (!this.closed) this.$emit('close');
      }
    }
  },
  methods: {
    hide() {
      this.$emit('update:visible', false);
    }
  }
}
</script>
<style>

.se-help > header {
  color: white;
  background: #085280;
  padding-bottom: 1rem;
}

.el-drawer__body {
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
}

.pdf-example-row {
  height: 50vh;
  /*min-height: 700px;*/
}

.title-example-pdf {
  margin-top: 0;
  color: #085280;
  font-weight: bold;
}

</style>
