<template>
  <div>
    <div class="container">

    <h1 class="se-bp-title">
      Income Type
      <a class="btn-link se-page-help-icon" v-tooltip.top-center="'Learn More'" @click="showHelp = true">
        <i class="fa fa-info-circle"></i>
      </a>
    </h1>

    <form class="form-horizontal">

      <div>
        <radio :label="SeIncomeTypeType.selfEmployed"
               :disabled="isLocked"
               v-model="incomeType">Self-Employed or Business Owner</radio>

        <radio :label="SeIncomeTypeType.rental"
               :disabled="isLocked"
               v-model="incomeType" disabled>Rental</radio>

        <div class="bp-help is-error" v-if="$v.incomeType.$error">
          <div class="bp-ucon" data-src="feedback-warning"><svg class="bp-svg" viewBox="0 0 24 24" id="feedback-warning" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M13.009 13.987V8.934h-1.956v5.053h1.956zm0 4.016v-1.962h-1.956v1.962h1.956zM2.988 20.962L12.016 3l9.025 17.962H2.988z"></path></svg>
          </div>
          <template v-if="!$v.incomeType.required">Please make a selection</template>
        </div>
      </div>

    </form>

    <se-help :visible.sync="showHelp" title="Learn More">
      <p>
        Use this screen to tell {{ appName }} the kind of income you want it to evaluate.
      </p>
      <p>
        <icon-base width="22" height="22" iconColor="#0c77ba"><icon-arrow-right/></icon-base>
        Select <b>Self-Employed or Business Owner</b> if you want {{ appName }} to evaluate income from a
        Sole Proprietorship (Schedule C), Partnership, S-Corporation (S-Corp),
        standard Corporation (C-Corp), or Limited Liability Company (LLC).
      </p>
      <b>FAQs:</b>
      <p>
        Q. Why is the Rental selection unavailable?<br>
        A. {{ appName }} does not support evaluation of rental income at this time.
      </p>
      <p>
        Q. Will I need to complete a separate {{ appName }} evaluation for each business?<br>
        A. Yes. If the borrower has multiple self-employment businesses, you will need to complete a
        separate {{ appName }} evaluation for each business.
      </p>
      <p>
        For example, if the borrower is a partner in a tree growing business, is also a partner in a
        landscape design business, and files separate business tax returns for each business, then you
        will need two separate {{ appName }} evaluations; one for each business.
      </p>
      <p>
        Q. There are two borrowers on the loan. The borrowers are each 50% owners of the Partnership,
        S-Corporation, Corporation, or LLC. Will I need to complete a separate {{ appName }} evaluation for each borrower?<br>
        A. Yes. Completing separate income evaluations is <b>required</b> for each borrower.
      </p>

    </se-help>

    <se-buttons
      :display-save="false"
      :display-submit="false"
      @seNext="next"
      @sePrevious="previous">
    </se-buttons>
    </div>
  </div>

</template>
<script>
import {Radio, IconBook, IconArrowRight} from 'src/components/index'
import LSwitch from 'src/components/Switch.vue'
import SeButtons from './SeButtons.vue'
import Swal from 'sweetalert2'
import {mapGetters, mapState} from 'vuex'
import Enums from 'src/model/enums'
import {SET_REQUEST_DATA} from 'src/store/actions/se'
import SeHelp from './SeHelp.vue'
import {required, requiredIf} from 'vuelidate/lib/validators'
import appNameMixin from '../../../mixins/AppNameMixin'

export default {
  mixins: [appNameMixin],
  components: {
    Radio,
    LSwitch,
    SeButtons,
    SeHelp,
    IconBook, IconArrowRight
  },
  data() {
    return {
      showHelp: false
    }
  },
  validations: {
    incomeType: {
      required: required
    }
  },
  computed: {
    SeIncomeTypeType: () => Enums.SeIncomeTypeType,
    incomeType: {
      get () {  return this.seRequest.incomeType },
      set (incomeType) { this.$store.commit(SET_REQUEST_DATA, {incomeType})  }
    },
    ...mapState({
      seRequest: state => state.se.request
    }),
    ...mapGetters([
      'isLocked'
    ])
  },
  methods: {
    next() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.$router.push('business-name')
    },
    previous() {
      this.$router.push('start')
    }
  }
}
</script>
<style>


</style>
