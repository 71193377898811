<template>
  <div>

    <div class="row mt-5 mb-3 se-buttons">
      <div class="bp-u-shadow-08" v-if="displayPrevious">
        <button class="bp-button" @click="previous" :disabled="disablePrevious">Back</button>
      </div>

      <span class="ml-2" v-if="displaySubmit">
        <l-button type="primary" wide @click="submit" :disabled="disableSubmit">Submit</l-button>
      </span>

      <span class="ml-2 bp-u-shadow-08" v-if="displayNext" v-tooltip="nextTooltip">
        <button class="bp-button bp-button--primary" @click="next" :disabled="disableNext"><span>Continue<i v-if='isLoading' class="bp-ucon-loader bp-ucon nc-icon spin ml-2"></i></span></button>
      </span>

      <span class="ml-2" v-if="displaySave">
        <button type="primary" wide @click="save" :disabled="disableSave">Save</button>
      </span>

      <div class="ml-5 bp-u-shadow-08" v-if="displaySkip">
        <button class="bp-button bp-button--primary" @click="skip">Skip</button>
      </div>

      <div class="ml-5 bp-u-shadow-08" v-if="displayUnSkip">
        <button class="bp-button bp-button--primary" @click="unSkip">Provide</button>
      </div>

      <div class="ml-5 bp-u-shadow-08" v-if="displayWaive">
        <button class="bp-button bp-button--primary" @click="waive">Waive Business Tax Returns</button>
      </div>

      <div class="ml-5 bp-u-shadow-08" v-if="displayUnWaive">
        <button class="bp-button bp-button--primary" @click="unWaive">Provide Business Tax Returns</button>
      </div>

      <span class="ml-auto bp-u-shadow-08" v-if="displayExport">
        <button class="bp-button bp-button--primary" @click="exportData" :disabled="disableExport">Export Data</button>
      </span>

      <span class="ml-2" v-if="displayPrint">
        <button class="bp-button bp-button--primary" @click="print" :disabled="disablePrint">View/Save/Print PDF</button>
      </span>

<!--    <div class="row se-buttons">-->
<!--      <div class="col-auto mr-auto" v-if="displayPrevious">-->
<!--        <l-button type="primary" wide @click="previous" :disabled="disablePrevious">Back</l-button>-->
<!--      </div>-->

<!--        <span class="ml-2" v-if="displayNext" v-tooltip="nextTooltip">-->
<!--          <l-button type="primary" wide @click="next" :disabled="disableNext">Next</l-button>-->
<!--        </span>-->

<!--        <span class="ml-2" v-if="displayExport">-->
<!--          <l-button type="primary" wide @click="exportData" :disabled="disableExport">Export</l-button>-->
<!--        </span>-->
<!--        <span class="ml-2" v-if="displayPrint">-->
<!--          <l-button type="primary" wide @click="print" :disabled="disablePrint">Print</l-button>-->
<!--        </span>-->
<!--      </div>-->



    </div>
  </div>

</template>
<script>
export default {
  props: {
    displayPrevious: {
      type: Boolean,
      default: true
    },
    displayNext: {
      type: Boolean,
      default: true
    },
    displaySave: {
      type: Boolean,
      default: true
    },
    displaySubmit: {
      type: Boolean,
      default: true
    },
    displayExport: {
      type: Boolean,
      default: false
    },
    displayPrint: {
      type: Boolean,
      default: false
    },
    displaySkip: {
      type: Boolean,
      default: false
    },
    displayUnSkip: {
      type: Boolean,
      default: false
    },
    displayWaive: {
      type: Boolean,
      default: false
    },
    displayUnWaive: {
      type: Boolean,
      default: false
    },


    disablePrevious: {
      type: Boolean,
      default: false
    },
    disableNext: {
      type: Boolean,
      default: false
    },
    disableSave: {
      type: Boolean,
      default: false
    },
    disableSubmit: {
      type: Boolean,
      default: true
    },
    disableExport: {
      type: Boolean,
      default: false
    },
    disablePrint: {
      type: Boolean,
      default: false
    },

    nextTooltip: {
      type: String,
      default: ""
    },

    isLoading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    next() {
      this.$emit('seNext')
    },
    previous() {
      this.$emit('sePrevious')
    },
    submit() {
      this.$emit('seSubmit')
    },
    save() {
      this.$emit('seSave')
    },
    exportData() {
      this.$emit('seExportData')
    },
    print() {
      this.$emit('sePrint')
    },
    skip() {
      this.$emit('seSkip')
    },
    unSkip() {
      this.$emit('seUnSkip')
    },
    waive() {
      this.$emit('seWaive')
    },
    unWaive() {
      this.$emit('seUnWaive')
    },

  }
}
</script>
<style>

.se-buttons {
  padding-left: 15px;
  /*position: absolute;*/
  /*bottom: 75px;*/
  /*right: 50px;*/
  /*left: 50px;*/
}


</style>
