<template>
  <footer class="footer">
    <div class="container-fluid d-flex flex-wrap justify-content-between">
      <nav>
        <ul class="footer-menu">
          <li>
<!--            <router-link :to="{path:'/admin'}">DU Income Underwriter</router-link>-->
            <a href="http://www.fanniemae.com/portal/privacy-statement.html" target="_blank">Privacy</a>
          </li>
          <li>
            <a href="http://www.fanniemae.com/portal/legal-disclosure.html" target="_blank">Legal</a>
          </li>
        </ul>
      </nav>


      <div class="copyright">
        ©2021 Fannie Mae
      </div>
    </div>
  </footer>
</template>
<script>
  export default {
  }

</script>
<style>

</style>
